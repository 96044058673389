import React from 'react';
import { Flex } from '@chakra-ui/react';

// Component
import SearchDoc from '../block/SearchDoc';
import Restricted from '../../../components/Restricted';

function CreateCallback(){
    return(
        <Restricted to={['ROLE_ADMIN']}>
            <Flex justifyContent='space-between' gap={4}> 
                <SearchDoc /> 
            </Flex>
        </Restricted>
    )
}

export default CreateCallback;