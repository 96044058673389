import React, { useContext, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { 
    Box, 
    Text,
    Stack,
    Switch,
    Skeleton,
    Popover,
    PopoverBody,
    PopoverArrow,
    PopoverTrigger,
    PopoverContent,
    PopoverCloseButton,

} from '@chakra-ui/react';
import { FaInfoCircle } from 'react-icons/fa';

// Components
import SubTitle from '../../../components/SubTitle';

// Context
import DocumentDetailContext from '../../../context/DocumentContext';

const ClauseItem = forwardRef( ({ clause, onChange }, ref) => {

    const handleChange = useCallback( (evt) => {
        if( typeof( onChange ) === 'function' ){
            if( evt.target.checked ){
                onChange('enableClause', clause);
            }else{
                onChange('disableClause', clause);
            }
        } 
    }, [] );

    return(
        <Stack direction='row' justifyContent='space-between' paddingY={3} borderBottomWidth={1} borderBottomColor='#5f5c5c' position='relative' className='pack_clause_item'>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}  width='100%'>
                <Popover
                    placement='right-end'
                    closeOnBlur={true}
                >    
                    <Stack direction='row' alignItems='center' gap={4}>
                        <Text color='white'> { clause.title } </Text>
                        <Box position='relative'>
                            <PopoverTrigger>
                                <Box>
                                <FaInfoCircle color='#2d9cdb' cursor='pointer' />
                                </Box>
                            </PopoverTrigger>
                            <PopoverContent color='white' bg='#2d9cdb' borderColor='#2d9cdb'>
                                <PopoverCloseButton />
                                <PopoverArrow bg='#2d9cdb' boxShadow='-1px 1px 0px 0 #2d9cdb' />
                                <PopoverBody color='white'>{clause.information}</PopoverBody>
                            </PopoverContent>
                        </Box>
                    </Stack>
                </Popover>
                <Switch 
                    size='md'  
                    id='status' 
                    colorScheme='teal' 
                    defaultChecked={false}
                    onChange={handleChange}
                />
            </Stack>
        </Stack>
    )

});

function Clauses ({ isFetching, onClauseChange }){
    
    const document  = useContext(DocumentDetailContext);

    if( document && !document.clauses.length ){
        return null
    }
    
    return (
        <Skeleton isLoaded={!isFetching} minHeight={isFetching ? '180px' : 'auto'} borderRadius={8} mb={12}>
            <SubTitle style={{ fontSize: '20px', color: 'white', marginBottom: '24px' }}>Liste des clauses :</SubTitle>
            <Box className='pack_clauses_list'>
                {
                    document?.clauses.map( clause => (
                        <ClauseItem key={clause.id} clause={clause} onChange={onClauseChange} />
                    ))
                }
            </Box>
        </Skeleton>
    )

}

Clauses.defaultProps = {
    isFetching : false
}

Clauses.propTypes = {
    isFetching : PropTypes.bool,
    onClauseChange : PropTypes.func.isRequired
}

export default Clauses;