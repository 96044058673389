import React, { useState, useRef, useEffect } from 'react';
import { Box, Flex, Text, Input, Button, Stack } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { renderAsync } from 'docx-preview';
import Template from '../../components/dashboard/Template';
import Callback from './callback/Callback';
import { Link } from 'react-router-dom';

export const DropFile = () => {
    const [file, setFile] = useState(null);
    const containerRef = useRef(null);

    useEffect(() => {
        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const arrayBuffer = e.target.result;
                await renderAsync(arrayBuffer, containerRef.current);
            };
            reader.readAsArrayBuffer(file);
        }
    }, [file]);

    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];
        if (uploadedFile) {
            setFile(uploadedFile);
        } else {
            alert("Only DOCX files are allowed");
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
    };

    const handleDownloadFile = () => {
        if (file) {
            const url = URL.createObjectURL(file);
            const a = document.createElement('a');
            a.href = url;
            a.download = file.name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }
    };

    const handlePrintFile = () => {
        if (containerRef.current) {
            const printWindow = window.open('', '_blank');
            printWindow.document.write('<html><head><title>Print</title></head><body>');
            printWindow.document.write(containerRef.current.innerHTML);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.print();
        }
    };

    return (
        <Template
            title='Documents'
            callback={<Callback />}
            restrictedTo={['ROLE_ADMIN', 'ROLE_USER']}
        >
            <Box bg="dark.300" height="100vh" borderRadius={8} padding='12px'>
                {!file ? (
                    <Flex
                        rounded={8}
                        height='80px'
                        bgColor="dark.300"
                        align="center"
                        justify="center"
                        cursor="pointer"
                        w="full"
                        position="relative"
                    >
                        <label htmlFor="dropzone-file">
                            <Flex
                                cursor="pointer"
                                flexDir="column"
                                align="center"
                                justify="center"
                                pt={5}
                                pb={6}
                            >
                                <Icon style={{ color: "white" }} icon="bytesize:upload" />
                                <Text
                                    mb={2}
                                    fontSize="sm"
                                    color="gray.500"
                                    className="font-semibold"
                                >
                                    Click to upload
                                </Text>
                                <Text
                                    fontSize="xs"
                                    color="gray.500"
                                >
                                    Only  DOCX files are allowed
                                </Text>
                            </Flex>
                            <Input
                                style={{ display: "none" }}
                                id="dropzone-file"
                                name="file"
                                type="file"
                                accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                className="hidden"
                                onChange={handleFileChange}
                            />
                        </label>
                    </Flex>
                ) : (
                    <Flex height="100%">
                        <Box width="300px" bg="dark.300" paddingY="10px" paddingX="5px">
                            <Stack dir='row' gap={5}>
                                <Text justifyContent="center" textColor="#6c757d" mb={4} fontSize="xs">{file.name}</Text>
                                <label htmlFor="dropzone-file">
                                    <Flex
                                        cursor="pointer"
                                        flexDir="column"
                                        align="center"
                                        justify="center"
                                        pt={5}
                                        pb={6}
                                        border="1px solid #495057"
                                        borderRadius={4}
                                    >
                                        <Icon style={{ color: "white" }} icon="bytesize:upload" />
                                        <Text
                                            mb={2}
                                            fontSize="sm"
                                            color="gray.500"
                                            className="font-semibold"
                                        >
                                            Click to upload
                                        </Text>
                                        <Text
                                            fontSize="xs"
                                            color="gray.500"
                                        >
                                            Only  DOCX files are allowed
                                        </Text>
                                    </Flex>
                                    <Input
                                        style={{ display: "none" }}
                                        id="dropzone-file"
                                        name="file"
                                        type="file"
                                        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        className="hidden"
                                        onChange={handleFileChange}
                                    />
                                </label>
                                <Button
                                    width="100%"
                                    bg="#161515"
                                    _hover={{ bg: 'yellow.100', textColor: "dark.300" }}
                                    onClick={handlePrintFile}
                                    textColor="white"
                                    size="sm"
                                    border="1px solid #495057"
                                    fontWeight="thin"
                                    gap={2}
                                    alignItems="center"
                                >
                                    <Icon icon="material-symbols-light:print-outline" />
                                    Imprimer
                                </Button>
                                <Button
                                    width="100%"
                                    bg="#161515"
                                    _hover={{ bg: 'yellow.100', textColor: "dark.300" }}
                                    onClick={handleDownloadFile}
                                    textColor="white"
                                    size="sm"
                                    border="1px solid #495057"
                                    fontWeight="thin"
                                    gap={2}
                                    alignItems="center"
                                >
                                    <Icon icon="material-symbols:download" />
                                    Télecharger
                                </Button>
                                <Link to={"/dashboard/document/"}>
                                    <Button
                                        width="100%"
                                        bg="#161515"
                                        _hover={{ bg: 'yellow.100', textColor: "dark.300" }}
                                        textColor="white"
                                        size="sm"
                                        border="1px solid #495057"
                                        fontWeight="thin"
                                        gap={2}
                                        alignItems="center"
                                    >
                                        <Icon icon="simple-line-icons:docs" />
                                        Liste de documents
                                    </Button>
                                </Link>
                                <Button
                                    width="100%"
                                    bg="#161515"
                                    _hover={{ bg: 'red.100' }}
                                    onClick={handleRemoveFile}
                                    textColor="white"
                                    size="sm"
                                    border="1px solid #e63946"
                                    fontWeight="thin"
                                >
                                    Supprimer
                                </Button>
                            </Stack>
                        </Box>
                        <Box
                            id="doc-viewer"
                            ref={containerRef}
                            width="100%"
                            height="600px"
                            overflow="auto"
                            bgColor="white"
                            padding={0}
                        />
                    </Flex>
                )}
            </Box>
        </Template>
    );
};
