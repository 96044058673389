import React, { useContext } from "react";

// Components
import CategoryGrid from "./CategoryGrid";
import DocumentGrid from "./DocumentGrid";

// Context
import DocumentViewContext from "../../../context/DocumentViewContext";

function DocumentViewer() {

    const { view } = useContext( DocumentViewContext );

    return (
        <>{ view === 'category' ? <CategoryGrid /> : <DocumentGrid /> }</>
    )

}

export default DocumentViewer;