import http from '../../utils/http'
import { useMutation,useQueryClient } from '@tanstack/react-query';

const deleteCategory = async(categoryId) => {
    const response = await http.delete(`/document_categories/${categoryId}`);
    return response.data;
}

export default function useDeleteCategory(){
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey : ['deleteCategory'],
        mutationFn : (categoryId) => deleteCategory(categoryId),
        onSuccess: () => {
            queryClient.invalidateQueries(['fetchCategories']);
          },
    })

}