import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@chakra-ui/react'

function TextInput ({ value, width, height, lineHeight,background }) {
    return (
        <Text
            bg={background ? background :'dark.100'}
            height={height}
            color='white'
            fontSize='16px'
            padding='16px'
            borderWidth={1}
            borderRadius={8}
            width={width}
            lineHeight={lineHeight}
            borderColor='dark.100'
        >{ value }</Text>
    )
}

TextInput.propTypes = {
    value : PropTypes.any.isRequired
}

TextInput.defaultProps = {
    width : 'auto',
    height : '48px',
    lineHeight : '13px'
}

export default TextInput;