const initialState = {
    page : 0,
    totals : 0,
    lastPage : 0,
    packs : [],
    docs : [],
    isError : false,
    isDeleted : false, 
    isFetching : true,
}

const PacksReducer = (state = initialState, action) => {

    switch(action.type){
        case 'SET_PACKS':
            return {
                ...state,
                packs : action.playload,
            }
        case 'ADD_DOC_TO_PACK':
            const isAlearidyUsed = state.docs?.filter( doc => doc.id === action.playload.id )
            return{
                ...state,
                docs : isAlearidyUsed.length ? [...state.docs] : [...state.docs, action.playload]
            }

        case 'REMOVE_DOC_TO_PACK':
            return{
                ...state,
                docs : state.docs?.filter( doc => doc.id !== action.playload.id )
            }

        case 'PACKS_FETCHING':
            return {
                ...state,
                page : 0,
                totals : 0,
                lastPage : 0,
                packs : [],
                isError : false,
                isDeleted : false,
                isFetching : true
            }
        case 'PACKS_FETCHING_SUCCESS':
            return {
                ...state,
                isError : false,
                isDeleted : false,
                isFetching : false,
                page : action.page,
                totals : action.totals,
                lastPage : action.lastPage,
                packs : action.playload
            }
        case 'PACKS_MORE_SUCCESS':
            return {
                ...state,
                isError : false,
                isDeleted : false,
                isFetching : false,
                page : action.page,
                totals : action.totals,
                lastPage : action.lastPage,
                packs : [...state.packs, ...action.playload]
            }
        case 'PACKS_FAIL':
            return {
                ...state,
                page : 0,
                totals : 0,
                lastPage : 0,
                isDeleted : false,
                isFetching : false,
                isError : true,
                packs: []
            }
        case 'PACKS_DELETE_ONE_ITEM' :
            return {
                ...state,
                isDeleted : true,
                packs : state.packs.filter( pack => pack.id !== action.playload )
            }
        case 'PACKS_RESET' : 
            return {
                ...state,
                page : 0,
                totals : 0,
                lastPage : 0,
                packs: [],
                docs : [],
                isError : false,
                isFetching : false,
                isDeleted : false
            }

        default:
            return state;
    }

}

export default PacksReducer;