import React from 'react';
import Lottie from 'react-lottie'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Stack, Button, Heading } from '@chakra-ui/react';

// Lottie
import * as NoContentAnimation from '../assets/lotties/NotFound.json';

function NoContent ({ link, showLink, linkText, message }){

    return (
        <Stack pb={10}>
            <Lottie 
                height={250}
                width={250}
                options = {{
                    animationData : NoContentAnimation
                }}
            />
            <Heading color='white' textAlign='center' size='18px'>{message}</Heading>
            {
                showLink &&
                <Stack mt={2} direction='row' justifyContent='center'>
                    <Button 
                        as={Link}
                        to={link}
                        fontSize='15px'
                        height = '40px' 
                        bg = 'yellow.100' 
                        fontWeight = {500}
                        _hover = {{ bg: 'green.100' }} 
                    >{linkText}</Button>
                </Stack>
            }
        </Stack>
    )

}

NoContent.defaultProps = {
    showLink : false,
    link : '/dashboard',
    linkText :  'Dasboard',
    message : 'Aucune contenu trouvée'
}

NoContent.propTypes = {
    link : PropTypes.string,
    showLink : PropTypes.bool,
    message : PropTypes.string,
    linkText : PropTypes.string,
}

export default NoContent;