import React, { useState, useContext, forwardRef, useEffect } from 'react';
import Fuse from 'fuse.js'
import { FormikContext } from 'formik';
import { SearchIcon } from '@chakra-ui/icons';
import { Stack, Button, Input, InputGroup, InputLeftElement, Skeleton, FormControl, FormErrorMessage } from '@chakra-ui/react';

// Components
import VariableItem from './VariableItem';
import SubTitle from '../../../components/SubTitle';
import NoContent from '../../../components/NoContent';

// Hooks
import { useFetchVariables } from '../../../hooks/variable';
import theme from '../../../config/theme';


const Variables = forwardRef( ( { handleChange }, ref ) => {

    // states
    const [ pattern, setPattern ] = useState('');
    const [ variablesList, setVariablesList ] = useState([]);

    // hooks
    const formik = useContext( FormikContext );
    const { data:variables, isFetching, isError } = useFetchVariables('publish');

    // lifecycle
    useEffect( () => {

        if(variables){
            setVariablesList(variables)
        }

    }, [ variables ] );

    // search functions
    const handleSearch = (e) => {
        const pattern = e.target.value;
        const fuseOptions = {
            keys: [
                "name",
            ],
            findAllMatches : true,
        }
        setPattern( pattern );
        const fuse = new Fuse(variables, fuseOptions);
        const result = fuse.search(pattern);
        if( result.length === 0 && pattern.length === 0 ){
            setVariablesList(variables);
        }else{
            setVariablesList(result);
        }
    }

    const renderVariables = () => {
        return (
            <Stack 
                bg='dark.200'
                style={{padding:"4"}}
                spacing={3} 
                maxHeight={420} 
                direction='column' 
                overflowY='scroll'
                className='variable_list custom_scrollbar'
            >
                {
                    variablesList?.length && variablesList.map( variable => {
                        return (
                            <VariableItem 
                                variable={variable} 
                                handleEdit={handleChange} 
                                key={variable.item ? variable.item.id : variable.id} 
                            />
                        )
                    } )
                }
            </Stack>
        )
    }

    if( isError || ( !isFetching && !variables )){
        return (
            <Stack direction='column' spacing={4}>
                <SubTitle>Liste des variables :</SubTitle>
                <NoContent 
                    showLink = { true }
                    link = '/dashboard/variable'
                    linkText = 'Ajouter une variable'
                    message = 'Variable introuvable'
                />
            </Stack>
        )
    }

    return (
        <FormControl isInvalid={formik.errors.variables}>
            <Stack direction='column' spacing={4}>
                <SubTitle>Liste des variables :</SubTitle>
                <Skeleton isLoaded={!isFetching} borderRadius={8}>
                    <InputGroup mb={4}>
                        <InputLeftElement pointerEvents='none' height='48px'>
                            <SearchIcon color='gray.300' />
                        </InputLeftElement>
                        <Input 
                            id='search_variable'
                            name='search_variable'
                            type='search'
                            height='48px'
                            color='#fff'
                            borderRadius={8}
                            borderColor='#323232'
                            backgroundColor='#323232'
                            value={pattern}
                            onChange={e => handleSearch(e)}
                            placeholder='Rechercher une variable'
                        />
                    </InputGroup>
                    { renderVariables() }
                    <Stack spacing={variables?.length ? 4 : 0} mt={8}>
                        <Button 
                            width ='100%'
                            type='button'
                            bg = 'red.100' 
                            color = 'white'
                            height = '44px'
                            fontWeight = {500}
                            spinnerPlacement = 'end'
                            _hover = {{ bg: 'yellow.100', color: 'dark.200' }} 
                            onClick = { () => {
                                handleChange('addVariable', null);
                            } }
                            >Ajouter une variable +</Button>
                    </Stack>
                </Skeleton>
            </Stack>
            <FormErrorMessage>{formik.errors.variables}</FormErrorMessage>
        </FormControl>
    )

})


export default Variables;