import logger from 'redux-logger'
import rootReducer from './reducers'; 
import { revalidateToken } from '../utils';
import { configureStore } from '@reduxjs/toolkit';

const tokenMiddelware = store => next => async action => {
    const status = await revalidateToken();
    if( status ){
        console.info('Token revalidate');
    }
    return next(action);
}


const store = configureStore({
    reducer : rootReducer,
    middleware : ( getDefaultMiddleware ) => getDefaultMiddleware({
        immutableCheck : false,
        serializableCheck : false
    })
    .concat(logger)
    .concat(tokenMiddelware)
});

export default store;