import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// Utils 
import { isLoggedIn } from '../utils';

function ProtectedRoutes({ children }){
    
    const isLoggin = isLoggedIn();
    const location = useLocation();

    if( !isLoggin ){
        return (
            <Navigate to='/' location={location} /> 
        )
    }

    return(
        <>
            { isLoggin && children }
        </>
    )
}

export default ProtectedRoutes;