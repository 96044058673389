import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { DeleteIcon } from '@chakra-ui/icons'
import Restricted from "../../../components/Restricted";
import { Box, Stack, Image, Button, Icon, Skeleton } from "@chakra-ui/react";

// Components
import SubTitle from "../../../components/SubTitle";

// Assets
import DocumentIcon from '../../../assets/svg/Document.svg';


function PackItem ( { id, name, url, isLoading, onDelete } ){

    const handleDeleteClick = useCallback( () => {
        if( typeof(onDelete) === 'function' ){
            onDelete( id );
        }
    }, [id] );

    return (
        <Box>
            <Skeleton height={160} position='relative' isLoaded={!isLoading} borderRadius={10} mb={3}>
                <Restricted to={['ROLE_ADMIN']}>
                    <Button bg="bark.100" _hover={{ bg : 'dark.100' }} position='absolute' right={0} onClick={handleDeleteClick}>
                        <Icon as={DeleteIcon} color='white'/>
                    </Button>
                </Restricted>
                <Stack 
                    mb={3}
                    height={170} 
                    bg='dark.100' 
                    borderRadius={10} 
                    alignItems='center' 
                    justifyContent='center' 
                >
                    <Image src={DocumentIcon} width='56px' />
                </Stack>
            </Skeleton>
            <Skeleton isLoaded={!isLoading} borderRadius={10}>
                <Box
                    as={Link}
                    to={url}
                    state={{view: 'grid'}}
                    className="pack_item"
                >
                    <SubTitle color='white' fontSize={16}>{name}</SubTitle>
                </Box>
            </Skeleton>
        </Box>
    )
}

PackItem.propTypes = {
    name : PropTypes.string.isRequired,
    url : PropTypes.string.isRequired,
    isLoading : PropTypes.bool
}

PackItem.defaultProps = {
    url : '#',
    name : 'Pack',
    isLoading : false
}

export default PackItem;