import React  from 'react';
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

// Components
import Template from '../../components/dashboard/Template';
import AdminCallback from './callback/adminCallback';
import ProTableView from './view/proTableView';
import ProfessionalCallback from './callback/proCallback';

function ProPage() {
    return (
        <Template
            title="Liste des professionels"
            callback={<ProfessionalCallback />}
        >
            <Box bg="dark.300" borderRadius={8} paddingY={30}>
                <Box paddingX={{ base: 2, lg: 8 }}>
                    <ProTableView />
                </Box>
            </Box>
        </Template>
    );
}

export default ProPage;
