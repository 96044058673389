import React, { useCallback } from 'react';
import Fuse from 'fuse.js';
import styled from '@emotion/styled';
import { SearchIcon } from '@chakra-ui/icons';
import { Box, Skeleton } from '@chakra-ui/react';
import { connect, useDispatch } from 'react-redux';

// Middelware
import { fetchAllAction } from '../../../actions/document/DocumentsAction';

// Styled
const FormWrap = styled.div`
    background: #504c4c;
    border-radius: 38px;
    overflow:hidden;
`;

const Form = styled.form`
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    padding: 0 20px;
`;

const Input = styled.input`
    font-size: 16px;
    color: #fff;
    background: none;
    outline: none;
    box-shadow: none;
    height:48px;
    width: 100%;
`;

function Search({ documents, isFecthing, fetchDocuments }){

    const dispatch = useDispatch();

    const handleSearch = useCallback( (e) => {
        const pattern = e.target.value;
        if(pattern.length >= 3){
            const fuseOptions = {
                keys: [
                    "name",
                ],
                findAllMatches : true
            }
            const fuse = new Fuse(documents, fuseOptions);
            const result = fuse.search(pattern);
            if( result.length ){
                let documents = [];
                result.forEach( document => {
                    documents.push( document.item );
                } )
                dispatch({ type : 'SET_DOCUMENTS', playload: documents })
            }else{
                dispatch({ type : 'SET_DOCUMENTS', playload: result })
            }
        }else if(pattern.length === 0){
            fetchDocuments();
        }
    }, [ dispatch, documents, fetchDocuments ] );


    return (
        <Box className='search_form search_doc' width={{ base: 'auto', lg: '300px' }} display={{ base: 'none', lg: 'block' }} >
            <Skeleton isLoaded={!isFecthing} borderRadius={38} height='48px'>
                <FormWrap>
                    <Form action='/search' method='post'>
                        <SearchIcon fontSize={19} color='#fff'/>
                        <Input name="search" color='white' fontWeight={600} className='search_input_field' autoComplete='off' required placeholder='Recherche un document' onChange={ handleSearch } />
                    </Form>
                </FormWrap>
            </Skeleton>
        </Box>
    )
}

const mapStateToProps = ( state ) => {
    return {
        documents : state.documents.documents,
        isFecthing : state.documents.isFetching
    }
}

const mapDispatchToProps = {
    fetchDocuments : fetchAllAction
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);