import React from "react";
import { Provider } from "react-redux";
import { ChakraProvider } from '@chakra-ui/react';
import { createStandaloneToast } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Context
import { isGranted } from "./utils";
import PermissionsContext from "./context/PermissionsContext";

// Router
import ConsylApp from "./Consyl";

// Config
import theme from "./config/theme";

// Store 
import store from "./reducers/store";

// Init
const queryClient = new QueryClient();
const { ToastContainer } = createStandaloneToast({ theme : theme });

function App() {
    
    const permission = { isGranted }

    return (
        <Provider store={store}>
            <ChakraProvider theme={theme} toastOptions={{ defaultOptions:{position:'top'} }}>
                <QueryClientProvider client={queryClient}>
                    <PermissionsContext.Provider value={permission}>
                        <ConsylApp />
                    </PermissionsContext.Provider>
                </QueryClientProvider>
            </ChakraProvider>
            <ToastContainer />
        </Provider>
    );
}

export default App;