import React, { useState } from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Skeleton, chakra, Text, HStack, Button, ButtonGroup } from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import PropTypes from 'prop-types';

function DataTable(props) {
  const { data, columns, isLoading } = props;
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const table = useReactTable({
    columns,
    data,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      pagination,
    },
    onPaginationChange: setPagination,
  });

  const renderPagination = () => {
    if (table.getPageCount() <= 1) {
      return null;
    }
    return (
      <Box padding={4}>
        <HStack justifyContent="space-between" alignItems="center">
          <Box>
            <ButtonGroup spacing={0} padding={0}>
              <Button
                colorScheme="dark"
                onClick={() => table.previousPage()}
                isDisabled={!table.getCanPreviousPage()}
              >
                {'<'}
              </Button>
              <Button
                colorScheme="dark"
                onClick={() => table.nextPage()}
                isDisabled={!table.getCanNextPage()}
              >
                {'>'}
              </Button>
            </ButtonGroup>
          </Box>
          <Box>
            <HStack paddingX={4}>
              <Text color="#fff">Page</Text>
              <Text color="#fff">
                {pagination.pageIndex + 1} sur {table.getPageCount()}
              </Text>
            </HStack>
          </Box>
        </HStack>
      </Box>
    );
  };

  return (
    <Box overflowX="auto">
      <Table id="appTable">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id} className="table-head">
              {headerGroup.headers.map((header) => {
                const meta = header.column.columnDef.meta;
                return (
                  <Th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                    color="#fff"
                  >
                    <Skeleton
                      isLoaded={!isLoading}
                      height="44px"
                      display="flex"
                      alignItems="center"
                    >
                      <Text fontSize={14}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Text>
                      <chakra.span pl="4">
                        {header.column.getIsSorted() ? (
                          header.column.getIsSorted() === "desc" ? (
                            <TriangleDownIcon aria-label="sorted descending" />
                          ) : (
                            <TriangleUpIcon aria-label="sorted ascending" />
                          )
                        ) : null}
                      </chakra.span>
                    </Skeleton>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr
              key={row.id}
              valign="middle"
              className="table-row"
              _hover={{ bg: "dark.100" }}
            >
              {row.getVisibleCells().map((cell) => {
                const meta = cell.column.columnDef.meta;
                return (
                  <Td key={cell.id} isNumeric={meta?.isNumeric} color="#fff">
                    <Skeleton
                      isLoaded={!isLoading}
                      height="30px"
                      display="flex"
                      alignItems="center"
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Skeleton>
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
      {renderPagination()}
    </Box>
  );
}

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
};

export default DataTable;
