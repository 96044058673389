import React, { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { jwtDecode } from "jwt-decode";
import {
    Box,
    Button,
    AlertIcon,
    Container,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Stack,
    Text,
    Flex,
    Image,
    Center,
    Alert,
    AlertDescription,
    FormErrorMessage,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

//   Components
import PasswordField from './PasswordField';

//   Assets
import Logo from '../../components/parts/sidebar/assets/img/Logo.webp';

// Utils
import { useLogin } from '../../hooks';
import { handleLogin } from '../../utils';
import { VALIDATION_ERROR } from '../../config/constants';

// Schema
const Schema = object({
    email: string().email('Adresse e-mail invalide').required('Ce champ est obligatoire'),
    password : string().min(4,'Mot de passe trop court').required('Ce champ est obligatoire')
});

function LoginPage(){

    const [isError, setIsError] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const emailForget = localStorage.getItem("emailForget");
    
    // Hook
    const mutation = useLogin();
    const formik = useFormik({
        initialValues: {
            email : emailForget || '',
            password : ''
        },
        validateOnChange: false,
        validationSchema: Schema,
        onSubmit : ( values ) => {
            setIsFetching(true);
            mutation.mutate(values, {
                onError: (error) => {
                    setIsError(true);
                    setErrorMessage(VALIDATION_ERROR);
                },
                onSuccess : (response) => {
                    if(response.token){
                        setIsError(false);
                        const user = jwtDecode(response.token);
                        const userData =  {
                            iat : user.iat,
                            exp : user.exp,
                            roles : user.roles,
                            username : user.username,
                            email : formik.values.email,
                        };
                        handleLogin(userData, response);
                        window.location.href = `${window.location.origin}/dashboard`;
                        localStorage.removeItem("emailForget");
                    }
                },
                onSettled: () => {
                    setIsFetching(false);
                }
            })
        }
    });

    // Ref
    const emailRef = useRef(null);

    // onDidMount
    useEffect(() => {
        if( emailRef ){
            emailRef.current.focus();
        }
    }, []);

    const renderErrorBlock = () => {
        if( isError && errorMessage !== '' ){
            return (
                <Alert status='error'>
                    <AlertIcon color='white' />
                    <AlertDescription color='white'>{errorMessage}</AlertDescription>
                </Alert>
            )
        }
    }

    return (
        <Flex bg='dark.100' minHeight='100vh' alignItems='center'>
            <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
                <Center mb={6}><Image src={Logo} maxWidth={170} /></Center>
                <Stack paddingTop={8} paddingBottom={3} spacing="0" bg="dark.200">
                    <Stack spacing="6">
                        <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                            <Heading size={{ base: 'sm', md: 'md' }} color='white'>Bienvenue</Heading>
                            <Text color='white'>
                                Connectez-vous à votre compte.
                            </Text>
                        </Stack>
                    </Stack>
                    <Box
                        py={{ base: '4', sm: '8' }}
                        px={{ base: '4', sm: '10' }}
                        bg={{ base: 'transparent', sm: 'bg.surface' }}
                        boxShadow={{ base: 'none', sm: 'md' }}
                        borderRadius={{ base: 'none', sm: 'xl' }}
                    >
                    <form onSubmit={formik.handleSubmit}>
                        <Stack spacing="6">
                            { renderErrorBlock() }
                            <Stack spacing="5">
                                <FormControl isInvalid={formik.errors.email}>
                                    <FormLabel htmlFor="email" color='white'>Email</FormLabel>
                                    <Input 
                                        id = "email" 
                                        height = {42} 
                                        type = "email" 
                                        color = 'white' 
                                        value = {emailForget ? emailForget: formik.values.email} 
                                        disabled = {isFetching}
                                        onChange = {formik.handleChange}  
                                        tabIndex={1}
                                        ref={emailRef}
                                    />
                                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                                </FormControl>
                                <PasswordField 
                                    disabled = {isFetching}
                                    value = {formik.values.password} 
                                    onChange = {formik.handleChange} 
                                    isInvalid = {formik.errors.password}
                                    errorMessage = {formik.errors.password}
                                />
                            </Stack>
                            <Stack spacing="6">
                                <Button 
                                    type='submit'
                                    height = '44px' 
                                    bg = 'yellow.100' 
                                    fontWeight = {500}
                                    spinnerPlacement = 'end'
                                    isLoading = {isFetching}
                                    tabIndex={4}
                                    _hover = {{ bg: 'green.100' }}
                                >Se connecter</Button>
                            </Stack>
                        </Stack>
                        <Link to={`/forgot-password`}>
                            <Text textColor="green.100" mt={6}>Mot de passe oublié ?</Text>
                        </Link>
                    </form>
                    </Box>
                </Stack>
            </Container>
        </Flex>
    )

}

export default LoginPage