import React from 'react';
import { Flex } from '@chakra-ui/react';

// Component
import ButtonAction from '../../../components/ButtonAction';

function AdminCallback(){
    return(
        <Flex justifyContent='space-between' gap={4}>
            <ButtonAction 
                title='Créer un Admin'
                to="/dashboard/users/create/admin" 
            />
        </Flex>
    )
}

export default AdminCallback;