import React from 'react';
import { createColumnHelper } from "@tanstack/react-table";

// Components
import DataTable from '../../../components/DataTable';

function CategoryPlaceholder ({ isLoading }){

    const categories = Array(10).fill({ 
        'id' : 1,
        'nom' : 'cat', 
        'slug' : 'slug', 
        'status' : true, 
    });

    const getColumns = () => {
        const columnHelper = createColumnHelper();
        const columns = [
            columnHelper.accessor('name', {
                cell: (info) => info.getValue(),
                header: 'Nom'
            }),
            columnHelper.accessor('slug', {
                cell: (info) => info.getValue(),
                header: 'Slug'
            }),
            columnHelper.accessor('status', {
                cell: (info) => info.getValue(),
                header: 'Etat',
                enableSorting: false
            }),
            columnHelper.accessor('id', {
                cell: (info) => info.getValue(),
                header: 'Actions',
                enableSorting: false
            })
        ];
        return columns;
    }

    const categoryColumn =  getColumns();

    return (
        <DataTable 
            data = {categories} 
            columns = {categoryColumn} 
            isLoading = {isLoading} 
        /> 
    )

}

CategoryPlaceholder.defaultProps = {
    isLoading : true
}

export default CategoryPlaceholder;