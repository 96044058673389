import React from 'react';

// Components
import UserTable from './UserTable';

function UserPlaceholder ({ isLoading }){

    const documents = Array(10).fill({ 
        'id' : 1,
        'email' : 'john.doe@yahoo.fr', 
        'lastname' : { lastname : 'doe' }, 
        'firstname' : { firstname : 'john' }, 
        'companyName' : { companyName : 'consyl' },  
    });

    return (
        <UserTable 
            packData = {documents}
            isFetching = {isLoading}
        />
    )

}

UserPlaceholder.defaultProps = {
    isLoading : true
}

export default UserPlaceholder;