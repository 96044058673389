import React, { useContext, useState, useCallback } from 'react';
import { BsArrowReturnLeft } from 'react-icons/bs';
import { useLocation, Link, useParams } from 'react-router-dom';
import { BsCopy, BsEye } from 'react-icons/bs';
import { 
    Box,
    Icon,
    Text,
    Image,
    Button,
    Center,
    HStack,
    Portal,
    Skeleton,
    useToast,
    useDisclosure,
    Modal,
    ModalBody,
    ModalOverlay,
    ModalContent
} from '@chakra-ui/react';

// Assets
import DocumentIcon from '../../assets/svg/Document.svg';

// Components
import ClientSelect from './block/ClientSelect';
import SubTitle from '../../components/SubTitle';
import DocumentTable from './block/DocumentTable';
import NoContent from '../../components/NoContent';
import Restricted from '../../components/Restricted';
import ListeCallback from './callback/ListeCallback';
import Template from '../../components/dashboard/Template';
import DocumentTablePlaceholder from './block/DocumentTablePlaceholder';
import SearchDocOnPack from './block/SearchDocOnPack';

// Hooks
import { useFetchPack, useGenerateUrl, useFetchPackDocs } from '../../hooks/pack';

// Context
import PermissionsContext from '../../context/PermissionsContext';

// Constants
import { DENIED_ACTION_TITLE, DENIED_ACTION_ERROR } from "../../config/constants";

function ViewPackPage() {
    // states
    const [url, setUrl] = useState(null);
    const [isGenerating, setIsGenerating] = useState(false);
    const [filteredDocuments, setFilteredDocuments] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    // hooks
    const { packId } = useParams();
    const location = useLocation();
    const toast = useToast();
    const generateUrl = useGenerateUrl();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { data: pack, isFetching: isPackLoading, isError: isPackFail } = useFetchPack(packId);
    const { data: documents, isFetching: isDocLoading, isError: isDocFail } = useFetchPackDocs(packId);

    // context
    const { isGranted } = useContext(PermissionsContext);

    // callback
    const handleGenerateUrl = useCallback(() => {
        if (!isGranted(['ROLE_USER'])) {
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: DENIED_ACTION_ERROR
            });
            return false;
        }
        if (!packId) {
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: "ID pack manquant"
            });
            return false;
        }
        setIsGenerating(true);
        generateUrl.mutate({ pack_id: parseInt(packId) }, {
            onSuccess: (response) => {
                if (response) {
                    const uri = `${process.env.REACT_APP_SITE_URL}/pack/${response.url}`;
                    setUrl(uri);
                    onOpen();
                } else {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'error',
                        title: 'Génération URL',
                        description: "Une erreur est survenue lors de la génération de votre URL"
                    });
                }
            },
            onError: (e) => {
                if (e.response.status === 400) {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'error',
                        title: 'Génération URL',
                        description: e.response.data.detail
                    });
                } else if (e.response.status === 403) {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'error',
                        title: DENIED_ACTION_TITLE,
                        description: DENIED_ACTION_ERROR
                    });
                } else {
                    console.error(e.response.data.detail)
                }
            },
            onSettled: () => setIsGenerating(false)
        });
    }, [packId, isGranted, generateUrl, onOpen, toast]);

    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(url);
        onClose();
    }, [url, onClose]);

    const handleFilteredDocuments = useCallback((filteredDocs) => {
        setFilteredDocuments(filteredDocs);
        setIsSearching(true);
    }, []);

    const renderContent = () => {
        if (isDocLoading) {
            return <DocumentTablePlaceholder />;
        }

        if (isPackFail || isDocFail || (!documents?.length && !filteredDocuments?.length)) {
            return <NoContent />;
        }

        return (
            <DocumentTable 
                isFetching={false}
                documentData={isSearching ? filteredDocuments : documents}
            />
        );
    }

    const renderGenerateBtn = () => {
        if (!documents?.length) {
            return null;
        }
        return (
            <Restricted to={['ROLE_USER']}>
                <Center mt={10}>
                    <Button
                        paddingX='50px'
                        height='50px'
                        bg='green.100'
                        fontSize='16px'
                        fontWeight={600}
                        spinnerPlacement='end'
                        isLoading={isGenerating}
                        onClick={handleGenerateUrl}
                        _hover={{ bg: 'blue.100' }}
                    >Remplir le document</Button>
                </Center>
            </Restricted>
        );
    }

    return (
        <Template 
            title={`Liste de documents dans ${pack?.name}`}
            callback={<ListeCallback handleFilteredDocuments={handleFilteredDocuments} />}
            restrictedTo={['ROLE_ADMIN', 'ROLE_USER']}
        >
            <Box bg="dark.300" borderRadius={8} paddingY={30}>
                <Box paddingX={{ base: 2, lg: 8 }}>
                    <HStack justifyContent='space-between' alignItems='baseline' mb={6}>
                        <Skeleton isLoaded={!isPackLoading} borderRadius={8} maxW={isPackLoading ? '300px' : 'inherit'}>
                            <HStack gap={4} alignItems='center' mb={6}>
                                <Link to='/dashboard/pack' state={{ 'view': location.state ? location.state.view : 'grid' }} title='Retourner' aria-label='Retourner'>
                                    <Icon as={BsArrowReturnLeft} color='white' fontSize={22} position='relative' top='6px' />
                                </Link>
                                <Text color='white' fontSize={22}> / </Text>
                                <HStack gap={3}>
                                    <Image src={DocumentIcon} maxWidth={19} />
                                    <SubTitle color='white' fontSize={17}>{pack?.name}</SubTitle>
                                </HStack>
                            </HStack>
                        </Skeleton>
                        <Skeleton isLoaded={!isPackLoading} borderRadius={8}>
                            <Text 
                                paddingY={0}
                                paddingX={5}
                                fontSize='17px'
                                height='42px'
                                lineHeight='42px'
                                color='#9dc57d'
                                fontWeight={600}
                                borderRadius={8}
                                bg='#252d1f'
                                textAlign='center'
                            >Ce pack nécessite : {pack?.credit} Crédits</Text>
                        </Skeleton>
                        <Restricted to={['ROLE_USER']}>
                            <ClientSelect  isLoading={isPackLoading} />
                        </Restricted>
                    </HStack>
                    {renderContent()}
                    {renderGenerateBtn()}
                </Box>
            </Box>
            <Restricted to={['ROLE_USER']}>
                <Portal>
                    <Modal isCentered blockScrollOnMount={true} closeOnOverlayClick={true} isOpen={isOpen} size='xxl' onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent bg='none'>
                            <ModalBody bg='none' padding={6} maxWidth='700px' margin='auto' width='86%'>
                                <Text align='center' fontSize='20px' fontWeight={600} color='white'>Votre URL :</Text>
                                <Box bg='white' padding={4} borderRadius={8} my={6}>
                                    <Text align='center' fontSize='18px' whiteSpace='nowrap' fontWeight={500} color='black'>{url}</Text>
                                </Box>
                                <HStack justifyContent='center' spacing={4}>
                                    <Button 
                                        color='white'
                                        paddingX={8}
                                        bg='#544267' 
                                        height='52px' 
                                        fontSize='16px'
                                        fontWeight={600}
                                        onClick={handleCopy}
                                        spinnerPlacement='end'
                                        _hover={{ bg: '#331d49' }} 
                                        rightIcon={<Icon color='white' fontSize={22} as={BsCopy} />}
                                    >Copier</Button>
                                    <Button 
                                        to={url}
                                        as={Link}
                                        paddingX={8}
                                        bg='#23397f' 
                                        color='white'
                                        target='_blank'
                                        height='52px' 
                                        fontSize='16px'
                                        fontWeight={600}
                                        spinnerPlacement='end'
                                        _hover={{ bg: '#182b66' }} 
                                        onClick={onClose}
                                        rightIcon={<Icon color='white' fontSize={22} as={BsEye} />}
                                    >Voir la page</Button>
                                </HStack>
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                </Portal>
            </Restricted>
        </Template>
    );
}

export default ViewPackPage;
