import axios from "axios";
import { getTokenUser, revalidateToken } from "./index";

const http = axios.create({
  	baseURL: process.env.REACT_APP_API_URL,
});

http.interceptors.request.use(
	async (config) => {
		const token = getTokenUser();
		console.log(token);
		if (token) {
			const contentType = config.method === 'patch' ? 'application/merge-patch+json' : "application/ld+json";
			config.headers = {
				"Content-Type": contentType,
				Authorization: `Bearer ${token?.token}`,
			};
		}
		return config;
  	},
  	(error) => Promise.reject(error)
);

http.interceptors.response.use((response) => {
		return response
  	}, 
	async function (error) {
		const originalRequest = error.config;
		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			const access_token = await revalidateToken();            
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token.token;
			return http(originalRequest);
		}
		return Promise.reject(error);
	});


export default http;