import React, { useState, useCallback, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik, FormikContext } from 'formik';
import { Flex, Box, useToast } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
    object, 
    string,
    boolean
} from 'yup';


// Context
import PermissionsContext from '../../../context/PermissionsContext';
import TinyForClauseContentMceContext from '../../../context/TinyForClauseContent';

// Components
import ClauseSidebar from '../../document/sidebar/ClauseSidebar';
import ClauseForm from '../../../components/form/clause/ClauseForm';


// Middleware
import { setClausesDoc, setVariablesDoc, resetAction, setDataSheet } from '../../../actions/document/DocumentAction';

// hooks
import { useFetchClause,usePatchClause,usePostClause } from '../../../hooks/clause';


// CONSTANTS
import {APP_ERROR, DENIED_ACTION_TITLE, DENIED_ACTION_ERROR} from "../../../config/constants";
import VariableModal from '../../document/modal/VariableModal';


// Schema
const ClauseSchema = object({
    status: boolean(),
    title: string().required('Ce champ est obligatoire'),
    content: string().optional(),
    information: string().optional()
});

function CreateClause({ variables, setVariables, populateSheet, resetEditor }){

    // states
    const [ element, setElement ] = useState(null);
    const [ isSaved, setIsSaved ] = useState(false);
    const [ isSaving, setIsSaving ] = useState(false);
    const [ isEditMode, setIsEditMode ] = useState(false);
    const [ showVariableModal, setShowVariableModal ] = useState(false);

    // context
    const { isGranted } = useContext(PermissionsContext);
    const { tinyInstance: tinyForClauseContentinstance } = useContext(TinyForClauseContentMceContext);

    useEffect(() => {
        if (tinyForClauseContentinstance) {
            console.log("TinyMCE Instances are ready");
        } else {
            console.error("TinyMCE Instances are not ready");
        }
    }, [tinyForClauseContentinstance]);

    // hooks
    const toast = useToast();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            status : true,
            title : '',
            content : '',
            information : "",
        },
        validateOnChange: false,
        validationSchema: ClauseSchema,
        onSubmit : (values) => {
            if( values.length == 0 ){
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'error',
                    title : 'Création de votre clause',
                    description: 'Le contenu est vide, veuillez ajouter des contenus',
                })
                return false;
            }
            const clauseData = {...values};
            saveClause(clauseData);
        }
    });

    const { clauseId } = useParams();
    const postClause = usePostClause()
    const patchClause = usePatchClause()
    const { data, isFetching, isError } = useFetchClause(clauseId)

    useEffect( () => {
        if( data ){
            setIsEditMode(true);
            initializeClause(data);
        }
        if(isSaved){
            resetEditor();
            formik.resetForm()
            /*
            if( tinyForClauseContentinstance !== null ){
                tinyForClauseContentinstance.editorCommands.commands.exec.mcenewdocument('add_variable_item_to_clause');
            }
            */
        }
        return () => {
            resetEditor();
            formik.resetForm();
        }

    }, [data, isSaved] );


    // callback
    const handleChange = useCallback( (action, value) => {
        switch( action ){
            case 'editVariable' :
                setElement(value);
                setShowVariableModal(true);
                break;
            default : 
                setElement(null);
                setShowVariableModal(true);
        }
    }, [] );

    const initializeClause = (data) => {
        formik.setValues({
            title : data.title,
            content: data.content,
            status: Boolean(data.status),
            information:data.information
        });
        try{
            if( tinyForClauseContentinstance !== null ){
                tinyForClauseContentinstance.editorCommands.commands.exec.setContent(data.content)
            }
        }catch(e){
            resetEditor();
            console.error(e);
        }
    }
    const saveClause = (clauseData) => {
        const { variables, ...cleanedClauseData } = clauseData;
        if( !isGranted(['ROLE_ADMIN', 'ROLE_USER']) ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: DENIED_ACTION_ERROR
            });
            return false;
        }
        setIsSaved(false);
        setIsSaving(true);
        if( isEditMode ){
            updateClause( clauseId, cleanedClauseData );
        }else{
            createClause( cleanedClauseData );
        }
    }

    const createClause = (clause) => {
        postClause
            .mutate(clause, {
                onSuccess: () => {
                    setIsSaved(true);
                    navigate('/dashboard/clause');
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'success',
                        title: 'Votre clause est crée avec succès',
                    });
                },
                onError : () => {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'error',
                        title: APP_ERROR
                    });
                },
                onSettled : () => setIsSaving(false)
            })
    }
    const updateClause = ( clauseId, clause ) => {
        patchClause
            .mutate({ clauseId, clause }, {
                onSuccess: () => {
                    setIsSaved(true);
                    setIsEditMode(false);
                    navigate('/dashboard/clause');
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'success',
                        title: 'Votre clause est mis à jour avec succès',
                    });
                },
                onError : () => {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'error',
                        title: APP_ERROR
                    });
                },
                onSettled : () => setIsSaving(false)
            })
    }

    // context

    return (
        <form onSubmit={formik.handleSubmit}  noValidate>
            <FormikContext.Provider value={formik}>
                <Flex flexDirection={{ base : 'column', lg : 'row' }} gap='20px'>
                    <Box padding='8px' width={{ base : '100%', lg : '27%' }}>
                        <ClauseSidebar handleChange={handleChange} />
                    </Box>
                    <Box 
                        bg="" 
                        borderRadius={8} 
                        position='relative' 
                        width={{ base : '100%', lg : '73%' }}
                    >
                        <ClauseForm/>
                    </Box>
                    <VariableModal 
                        variable={element} 
                        isOpen={showVariableModal} 
                        onClose={ () => setShowVariableModal(false) } 
                    />
                </Flex>
            </FormikContext.Provider>
        </form>
    )

}

const mapStateToProps = (state) => {
    return {
        clauses : state.document.clauses,
        variables : state.document.variables,
    }
}

const mapDispatchToProps = {
    resetEditor : resetAction,
    setClauses : setClausesDoc,
    populateSheet : setDataSheet,
    setVariables : setVariablesDoc,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateClause);