import { 
    array,
    string,
    number,
} from 'yup';

const ERROR_URL = 'Entrer une url valide';
const ERROR_REQUIRE = 'Ce champ est obligatoire';
const ERROR_EMAIL = 'Entrer une adresse e-mail valide';
const ERROR_NUMBER = 'Entrer un nombre entier non null';
const ERROR_PHONE = 'Entrer un numéro téléphone valide';
const PHONE_PATTERN = /\B(?=(\d{2})+(?!\d))(?<!\+3)|\B(?<=\+33)/g;
const URL_PATTERN = /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

// logoField
export const logoSchema = (field) => Object.assign({}, { [field] : string().required(ERROR_REQUIRE)});
// textField
export const textSchema = (field) => Object.assign({}, { [field] : string().required(ERROR_REQUIRE) });
// decimalField
export const decimalSchema = (field) => Object.assign({}, { [field]: number().round('floor').required(ERROR_REQUIRE) });
// emailField
export const emailSchema = (field) => Object.assign({}, { [field]: string().email(ERROR_EMAIL).required(ERROR_REQUIRE) });
// urlField
export const enumSchema = (field) => Object.assign({}, { [field]: array().of(string().required()).required(ERROR_REQUIRE) });
// phoneField
export const urlSchema = (field) => Object.assign({}, { [field]: string().matches(URL_PATTERN, ERROR_URL).required(ERROR_REQUIRE) });
// phoneField
export const phoneSchema = (field) => Object.assign({}, { [field]: string().matches(PHONE_PATTERN, ERROR_PHONE).required(ERROR_REQUIRE) });
// entierField
export const entierSchema = (field) => Object.assign({}, { [field]: number().min(0, ERROR_NUMBER).positive(ERROR_NUMBER).integer(ERROR_NUMBER).required(ERROR_REQUIRE) });