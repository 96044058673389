import React, { useState, useContext, useCallback } from 'react';
import {useQueryClient} from "@tanstack/react-query";
import { Box, useToast, useDisclosure } from '@chakra-ui/react';
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate, useParams } from 'react-router-dom';

// Components
import CategoryActions from './CategoryActions';
import SubTitle from '../../../components/SubTitle';
import NoContent from '../../../components/NoContent';
import DataTable from '../../../components/DataTable';
import CategoryPlaceholder from './CategoryPlaceholder';
import StatusBadge from '../../../components/StatusBadge';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';

// Hooks
import { useFetchCategories, useDeleteCategory } from '../../../hooks/category';

// Context
import PermissionsContext from '../../../context/PermissionsContext';

// Constants
import {APP_ERROR, DENIED_ACTION_TITLE, DENIED_ACTION_ERROR} from "../../../config/constants";

function CategoryTable(){
    
    // states
    const [ categoryId, setCategoryId ] = useState(null);    
    const [ isDeleting, setIsDeleting ] = useState(false);

    // context
    const { isGranted } = useContext( PermissionsContext );

    // hooks
    const toast = useToast();
    const navigate = useNavigate();
    const deleteQuery = useDeleteCategory();
    const queryClient = useQueryClient();
    const { categoryId:categoryParamId } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { data:categoryData, isFetching:isLoading} = useFetchCategories();

    const handleDelete = useCallback( ( id ) => {

        if( !isGranted(['ROLE_ADMIN', 'ROLE_USER']) ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: DENIED_ACTION_ERROR
            });
            return false;
        }
        if( !id ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: "ID catégorie manquant"
            });
            return false;
        }
        onOpen();
        setCategoryId(id);

    }, []);

    const onConfirmation = useCallback( () => {
        if( !categoryId ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: "ID catégorie manquant"
            });
            return false;
        }
        onClose();
        setIsDeleting(true);
        deleteQuery.mutate(categoryId, {
            onSuccess: () => {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'success',
                    title: 'Catégorie supprimée',
                });
                if( categoryId === parseInt( categoryParamId ) ){
                    navigate('/dashboard/category')
                }
                queryClient.invalidateQueries({queryKey: ['fetchCategories']})
            },
            onError : () => {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'error',
                    title: APP_ERROR
                });
            },
            onSettled : () => setIsDeleting(false)
        })

    }, [ categoryId ] );

    const getColumns = () => {
        const columnHelper = createColumnHelper();
        return [
            columnHelper.accessor('name', {
                cell: (info) => info.getValue(),
                header: 'Nom'
            }),
            columnHelper.accessor('color', {
                cell: (info) => {
                    return (<Box bg={info.getValue()} width='45px' height={5} borderRadius={5} />)
                },
                header: 'Couleur'
            }),
            columnHelper.accessor('status', {
                cell: (info) => (<StatusBadge state={ info.getValue() } />),
                header: 'Etat',
                enableSorting: false
            }),
            columnHelper.accessor('id', {
                cell: (info) => ( <CategoryActions id={ info.getValue() } onDelete={handleDelete} /> ),
                header: 'Actions',
                enableSorting: false
            })
        ];
    }

    const renderContent = () => {
        if( isLoading || isDeleting ){
            return (
                <CategoryPlaceholder />
            )
        }
        if( !isLoading && categoryData === undefined ){
            return (
                <NoContent />
            )
        }
        return (
            <DataTable
                data = {categoryData}
                columns = {getColumns()}
                isLoading = {isLoading}
            />
        )
    }

    return (
        <Box>
            <SubTitle marginBottom={6}>Liste des catégories</SubTitle>
            <Box bg="dark.200" paddingY={4} borderRadius={10}>
                { renderContent() }
            </Box>
            <ConfirmationModal
                isOpen = {isOpen}
                onClose = {onClose}
                title = 'Supprimer une catégorie'
                onConfirmation = {onConfirmation}
                description = 'Vous voulez supprimer cette catégorie !'
            />
        </Box>
    )

}

export default CategoryTable