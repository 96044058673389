import React from "react";
import { connect } from 'react-redux';
import { FormLabel, SimpleGrid, Box } from "@chakra-ui/react";

// Components
import DocumentItem from "./DocumentItem";

function PackSelected({ documents}){

    return (
        <Box>
            <FormLabel color='#787675' mb={4}>Liste des documents :</FormLabel>
            <SimpleGrid columns={{base:1, sm: 2, lg: 4, xl: 5}} spacing={10}>
                { 
                    documents?.map( (document) => {
                        
                        return ( 
                            <DocumentItem 
                                key={document.id}
                                doc = {document}
                                preview = {true}
                            />
                        )
                    } )
                }
            </SimpleGrid>
        </Box>
    )

}

const mapStateToProps = ( state ) => {
    return {
        documents : state.packs.docs,
    }
}

export default connect(mapStateToProps)(PackSelected)