import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Flex, Text, Image, Button } from '@chakra-ui/react';

// Assets
import PlusIcon from './parts/header/assets/img/Plus.svg';

function ButtonAction(props){

    const { as, to, title, color, bgColor, bgHover, colorHover, showIcon, onClick } = props;

    return(
        <Box className='app_button_action'>
            <Button 
                to={to} 
                height='44px' 
                bg={bgColor} 
                paddingY='6px' 
                paddingRight={ showIcon ? 4 : '40px' }
                borderRadius='44px' 
                display='flex' 
                color={color}
                as={as}
                paddingLeft={showIcon ? '8px' : '40px'}
                onClick={ (e) => {
                    if( typeof(onClick) == 'function' && onClick !== null ){
                        onClick(e);
                    }
                } }
                _hover={{  backgroundColor: bgHover, textDecoration: 'none', color: colorHover }}
            >
                <Flex alignItems='center' gap='8px'>
                    { showIcon && <Image src={PlusIcon} maxWidth='30px'/> }
                    <Text color='inherit' fontSize={15}>{title}</Text>
                </Flex>
            </Button>
        </Box>
    )

}

ButtonAction.propTypes = {
    showIcon : PropTypes.bool,
    bgColor : PropTypes.string,
    bgHover : PropTypes.string,
    colorHover : PropTypes.string,
    onClick : PropTypes.func,
    to : PropTypes.string,
    title : PropTypes.string.isRequired
}

ButtonAction.defaultProps = {
    as : Link,
    showIcon : true,
    title: 'Créer',
    color : '#EFEFF5',
    bgColor: '#504C4C',
    bgHover : '#1c1c1c',
    colorHover : '#fff',
    onClick : null
}

export default ButtonAction;