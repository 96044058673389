import http from '../../utils/http'
import { useMutation,useQueryClient } from '@tanstack/react-query';

const postUser = async(user) => {

    const response = await http.post('/users', user);
    return response.data;
}

export default function usePostUser(){
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn : (user) => postUser(user),
        onSuccess: () => {
            queryClient.invalidateQueries(['fetchUsers']);
          } 
    })
}