import http from '../../utils/http'
import { useQuery } from '@tanstack/react-query';

export default function useFetchPack(packId){
 
    return useQuery({
        enabled: !!packId,
        queryKey: ['fetchPack', packId],
        queryFn: async () => {
            if( packId === undefined ){
                return null;
            }
            const response =  await http.get(`/packs/${packId}`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false
    });
    
} 
