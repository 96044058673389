import React, { useState, useEffect, useContext, useCallback } from "react";
import { connect } from 'react-redux';
import { Box, useToast, SimpleGrid, useDisclosure } from "@chakra-ui/react";

// Components
import DocumentItem from "./DocumentItem";
import NoContent from "../../../components/NoContent";
import Pagination from "../../../components/Pagination";
import DocumentPlaceholder from '../block/DocumentPlaceholder';
import ConfirmationModal from "../../../components/modal/ConfirmationModal";

// Context
import PermissionsContext from "../../../context/PermissionsContext";

// Hooks
import { useDeleteDocument } from "../../../hooks/document"

// Middleware
import {  deleteDocument, fetchAllAction } from "../../../actions/document/DocumentsAction";

// Constants
import {APP_ERROR, DENIED_ACTION_TITLE, DENIED_ACTION_ERROR} from "../../../config/constants";

function DocumentList({ page, isError, isFetching, documents, documentCount, deleteDocument, fetchDocuments }){

    // states
    const [ isLoading, setIsLoading ] = useState(true);
    const [ documentId, setDocumentId ] = useState(null);

    // context
    const { isGranted } = useContext( PermissionsContext );

    // hooks
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const deleteQuery = useDeleteDocument();

    // onDidMount
    useEffect( () => {
        fetchDocuments();
    }, [] );

    // onDidUpdate
    useEffect( () => {
        setIsLoading(isFetching);
    }, [isFetching] )

    // callback
    const onDelete = useCallback( (id) => {
        if( !isGranted(['ROLE_ADMIN','ROLE_USER']) ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: DENIED_ACTION_ERROR
            });
            return false;
        }
        if( !id ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: "ID document manquant"
            });
            return false;
        }
        onOpen();
        setDocumentId(id);
    }, [] );

    const onConfirmation = useCallback( () => {

        if( !documentId ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: "ID document manquant"
            });
            return false;
        }

        onClose();
        setIsLoading(true)
        deleteQuery.mutate(documentId, {
            onSuccess : () => {
                deleteDocument(documentId);
            },
            onError : () => {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'error',
                    title: APP_ERROR
                });
            },
            onSettled : () => setIsLoading(false)
        })

    }, [ documentId ] );

    const onPaginationChange = useCallback( (selected) => {
        if( selected >= 0 ){
            fetchDocuments(selected+1);
        }
    }, [] );

    if(isLoading){
        return ( <DocumentPlaceholder />  )
    }

    if( !isLoading && ( isError || !documents.length ) ){
        return ( 
            <NoContent 
                showLink = { true }
                link = '/dashboard/document/create'
                linkText = 'Créer un document'
                message = 'Aucun document trouvé'
            /> 
        )
    }

    return (
        <Box>
            <SimpleGrid columns={{base:1, sm: 2, lg: 3, xl: 4}} spacingX={8}>
                { 
                    documents.map( (document) => {
                        return (
                            <DocumentItem 
                                isLoading = {false}
                                id = {document.id}
                                key = {document.id}
                                name = {document.name}
                                onDelete = {onDelete}
                                link = {`/dashboard/document/edit/${document.id}`}
                            />
                        )
                    } )
                }
            </SimpleGrid>
            <Pagination 
                totals={documentCount}
                current={parseInt(page)-1}
                onPageChange={ onPaginationChange }
            />
            <ConfirmationModal
                isOpen = {isOpen}
                onClose = {onClose}
                title = 'Supprimé un document'
                onConfirmation = {onConfirmation}
                description = 'Vous voulez supprimer ce document !'
            />
        </Box>
    )

}

const mapStateToProps = ( state ) => {
    return {
        page : state.documents.page,
        isError : state.documents.isError,
        documents : state.documents.documents,
        documentCount : state.documents.totals,
        isFetching : state.documents.isFetching,
    }
}

const mapDispatchToProps = {
    deleteDocument : deleteDocument,
    fetchDocuments : fetchAllAction
}

export default connect(mapStateToProps,mapDispatchToProps)(DocumentList)