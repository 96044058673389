import React, { useContext } from 'react';
import { FormikContext } from 'formik';
import { Stack, Button, Box } from '@chakra-ui/react';

// Components
import { SwitchField } from '../../../components/fields';

function Save({ isSaving, isEditMode }){

    const formik = useContext( FormikContext );

    return (
        <Stack direction='column' spacing={6}>
            <SwitchField 
                name='status'
                label='Publier'
                color="blue.100"
                fontsSize='18px'
                onChange={formik.handleChange}
                value={Boolean(formik.values.status)}
            />
            <Box>
                <Button 
                    type='submit'
                    height = '44px' 
                    bg = 'yellow.100' 
                    fontWeight = {500}
                    isLoading = {isSaving}
                    spinnerPlacement = 'end'
                    _hover = {{ bg: 'green.100' }} 
                >{ isEditMode ? 'Mettre à jour' : 'Enregistrer' }</Button>
            </Box>
        </Stack>
    )

}

Save.defaultProps = {
    isSaving : false,
    isEditMode : false
}

export default Save;