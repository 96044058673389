import React from 'react';
import PropTypes from 'prop-types';
import { 
    Box, 
    Text,
    Skeleton,
    SimpleGrid, 
    HStack
} from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useLocation } from 'react-router-dom';

// Components
import SubTitle from '../../../components/SubTitle';
import TextInput from '../../../components/TextInput';
import { getNamePack } from '../../../utils/getPackName';

// Hooks
import useFetchTransaction from '../../../hooks/transactions/useFetchTransaction';
import TransactionStatusDetail from './TransactionStatusDetail';
import { useFetchPacks } from '../../../hooks/pack';
import useFetchSharedPacks from '../../../hooks/shared/useFetchSharedPacks';

function TransactionDetail (){

    const getType = (type) => {
        if(type === "use_credit"){
            return "Depense"
        } else if(type === "buy_credit"){
            return "Achat"
        }
    };

    const { transactionId } = useParams();
    const location = useLocation();
    const { data: transaction, isLoading } = useFetchTransaction(transactionId)

    return (
        <>
            {transaction && (
            <Skeleton isLoaded={!isLoading} borderRadius={8} mb={12}>
                <HStack gap={4} alignItems='center' mb={6}>
                    <Link to='/dashboard/profile/transaction' state={{ 'view' : location.state?.view || 'grid' }} title='Retourner' aria-label='Retourner'>
                        <Icon icon="pepicons-pop:down-left" color='white' fontSize={22} position='relative' top='6px' />
                    </Link>
                    <Text color='white' fontSize={22}> / </Text>
                    <HStack gap={3}>
                        <Icon icon="flat-color-icons:folder" style={{fontSize:"24px"}} />
                    </HStack>
                </HStack>
                
                <SimpleGrid columns={2} spacingX={8} marginTop={4}>
                    <Box>
                        <SubTitle style={{ marginBottom: '24px' }}>
                            L'utilisateur :
                        </SubTitle>
                        <Box mt={4}>
                            <Text color='#787675' fontSize='16px' fontWeight={500} mb='8px'>Nom d'utilisateur</Text>
                            <TextInput value={transaction?.user?.profile?.firstname && transaction?.user?.profile?.lastname ? `${transaction.user.profile.firstname} ${transaction.user.profile.lastname}` : "---"} />
                        </Box>
                        <Box mt={4}>
                            <Text color='#787675' fontSize='16px' fontWeight={500} mb='8px'>Email de l'utilisateur</Text>
                            <TextInput value={transaction?.user?.email || '---'} />
                        </Box>
                        <Box mt={4}>
                            <Text color='#787675' fontSize='16px' fontWeight={500} mb='8px'>Entreprise</Text>
                            <TextInput value={transaction?.user?.profile?.companyName?.length > 0 ? transaction.user.profile.companyName : '---'} />
                        </Box>
                        <Box mt={4}>
                            <Text color='#787675' fontSize='16px' fontWeight={500} mb='8px'>Siret</Text>
                            <TextInput value={transaction?.user?.profile?.siret || '---'} />
                        </Box>
                    </Box>
                    <Box>
                        <SubTitle style={{ marginBottom: '24px' }}>
                            Transaction :
                        </SubTitle>
                        <Box mt={4}>
                            <Text color='#787675' fontSize='16px' fontWeight={500} mb='8px'>Date de transaction</Text>
                            <TextInput value={transaction ? new Date(transaction.createdAt).toLocaleDateString() : ''} />
                        </Box>
                        <TransactionStatusDetail status={transaction?.status} />
                        <Box mt={4}>
                            <Text color='#787675' fontSize='16px' fontWeight={500} mb='8px'>Crédit</Text>
                            <TextInput value={transaction?.credit || '---'} />
                        </Box>
                        <Box mt={4}>
                            <Text color='#787675' fontSize='16px' fontWeight={500} mb='8px'>Type de transaction</Text>
                            <TextInput value={transaction ? getType(transaction.type) : '---' || '---'} />
                        </Box>
                    </Box>
                </SimpleGrid>
                <Box width="100%" mt={4}>
                    <Text color='#787675' fontSize='16px' fontWeight={500} mb='8px'>Nom du pack</Text>
                    <TextInput width="100%" value={transaction?.sharedPack?.pack?.name || '---'} />
                </Box>
            </Skeleton>
            )}
        </>
    );
}

TransactionDetail.propTypes = {
    packId: PropTypes.any,
}

export default TransactionDetail;
