import axios from 'axios';
import { createStandaloneToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getApiUrl, getHeader, revalidateToken } from '../../utils';
import http from '../../utils/http';

const { toast } = createStandaloneToast();

const postCreditUser = async ( data ) => {
    await revalidateToken()
    const endpoint = getApiUrl(`/users/chargeCredit`);
    const response = await http.post(endpoint,data, getHeader());
    return response.data;
}

export default function usePostCreditUser() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: ['postCreditUser'],
        mutationFn: (data) => postCreditUser( data),
        onSuccess: (response) => {
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'success',
                title: 'Création de crédit avec success',
            });
            return response
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['fetchUsers']);
          },
        onError: (e) => {
            const status = e.response?.status;
            const description = e.response?.data?.detail;
            console.log(e);
            if (status === 422) {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'error',
                    title: 'Assignation de credit ',
                    description: description || 'Une erreur est survenue',
                });
            } else {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'error',
                    title: 'Erreur',
                    description: 'Une erreur est survenue lors de la création du crédit',
                });
            }
        }
    });
}
