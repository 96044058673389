import React, { useContext } from 'react';
import { FormikContext } from 'formik';
import { Skeleton, FormLabel, FormControl, FormErrorMessage } from '@chakra-ui/react';

// Fields
import TextField from './TextField';
// import LinkField from './LinkField';
// import EnumFields from './EnumFields';
// import PhoneField from './PhoneField';
// import EmailField from './EmailField';
// import EntierField from './EntierField';
// import TextareaField from './TextareaField';

function VariableFields ({ isFetching }){

    const formik = useContext( FormikContext );
    const type = formik.values.type;

    // TODO A supprimer
    // const renderSelectedField = () => {
    //     switch(type){
    //         case 'url' :
    //             return <LinkField />;
    //         case 'email' :
    //             return <EmailField />;
    //         case 'textarea' : 
    //             return <TextareaField />;
    //         case 'phone' : 
    //             return <PhoneField />;
    //         case 'entier':
    //         case 'decimal':
    //             return <EntierField />;
    //         default : 
    //             return <TextField />
    //     }
    // }

    if( !type ){
        return null;
    }

    // if( type === 'logo' ){
    //     return (
    //         <input type='hidden' name='description' id='description' defaultValue='logo' />
    //     )
    // }

    // if( type === 'enum' ){
    //     return <EnumFields isFetching={isFetching} values={formik.values.options} />
    // }

    return (
        <FormControl isInvalid={formik.errors.description}>
            <FormLabel htmlFor='information' color='#787675'>Aide (information)</FormLabel>
            <Skeleton isLoaded={!isFetching} borderRadius={8}>
                <TextField />
            </Skeleton>
            <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
        </FormControl>
    )

}

export default VariableFields;