import http from '../../utils/http'
import { useMutation } from '@tanstack/react-query';

const generateUrl = async(pack) => {
    const response = await http.post('/generate_url', pack);
    console.log(response);
    return response.data;
}

export default function useGenerateUrl(){
    return useMutation({
        mutationKey : ['generateUrl'],
        mutationFn : (pack) => generateUrl(pack)
    })
    
}