import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, HStack } from '@chakra-ui/react';
import styled from '@emotion/styled';


// components
import Sidebar from '../parts/sidebar';
import Header from '../parts/header';
import ProtectedRoutes from '../ProtectedRoutes';

const HStackStyled = styled(HStack)`
  align-items: stretch;
  gap: 0;
  background-color: ${({ theme }) => theme.colors.dark[100]};
  font-size: 12px;
`;

const BoxStyled = styled(Box)`
  width: 93%;
  
  @media (min-width: 1024px) {
    width: 84%;
  }
`;

class BaseLayout extends React.Component{
    
    render(){
        return (
            <ProtectedRoutes>
                <HStackStyled>
                    <Sidebar />
                    <BoxStyled>
                        <Header />
                        <Outlet />
                    </BoxStyled>
                </HStackStyled>
            </ProtectedRoutes>

        )
    }

}

export default BaseLayout;