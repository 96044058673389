import React, { useContext, useCallback } from 'react';
import Fuse from 'fuse.js';
import styled from '@emotion/styled';
import { SearchIcon } from '@chakra-ui/icons';
import { Box, Skeleton } from '@chakra-ui/react';
import { connect, useDispatch } from 'react-redux';

// context
import ListePackViewContext from '../../../context/ListePackViewContext';

// Middelware
import { fetchPacks } from '../../../actions/pack';

// Styled
const FormWrap = styled.div`
    background: #504c4c;
    border-radius: 38px;
    overflow:hidden;
`;

const Form = styled.form`
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    padding: 0 20px;
`;

const Input = styled.input`
    font-size: 16px;
    color: #fff;
    background: none;
    outline: none;
    box-shadow: none;
    height:48px;
    width: 100%;
`;

function SearchPack({ packs, isFecthing, fetchPacks }){

    const dispatch = useDispatch();
    const { view } = useContext(ListePackViewContext);

    const handleSearch = useCallback( (e) => {
        const pattern = e.target.value;
        if(pattern.length >= 1 && packs.length){
            const fuseOptions = {
                keys: [
                    "name",
                ],
                findAllMatches : true
            }
            const fuse = new Fuse(packs, fuseOptions);
            const result = fuse.search(pattern);
            if( result.length ){
                let packs = [];
                result.forEach( pack => {
                    packs.push( pack.item );
                } )
                dispatch({ type : 'SET_PACKS', playload: packs })
            }else{
                dispatch({ type : 'SET_PACKS', playload: result })
            }
        }else if(pattern.length === 0){
            if( view === 'grid' ){
                fetchPacks(1);
            }else{
                fetchPacks();
            }
        }
    }, [ dispatch, packs, fetchPacks ] );


    return (
        <Box className='search_form search_doc' width={{ base: 'auto', lg: '300px' }} display={{ base: 'none', lg: 'block' }} >
            <Skeleton isLoaded={!isFecthing} borderRadius={38} height='48px'>
                <FormWrap>
                    <Form action='/search' method='post'>
                        <SearchIcon fontSize={19} color='#fff' />
                        <Input name="search" color='white' className='search_input_field' autoComplete='off' required placeholder='Recherche un pack' onChange={ handleSearch } />
                    </Form>
                </FormWrap>
            </Skeleton>
        </Box>
    )
}

const mapStateToProps = ( state ) => {
    return {
        packs : state.packs.packs,
        isFecthing : state.packs.isFetching
    }
}

const mapDispatchToProps = {
    fetchPacks : fetchPacks
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPack);