import { useContext } from "react"
import SubTitle from "../../../components/SubTitle"
import { SelectField } from "../../../components/fields"
//import { Select } from "@chakra-ui/react"
import { FormikContext } from "formik"

export const Orientation = () => {
	const formik = useContext(FormikContext)

	const orientation = [
		{
			id: 1,
			name: "Portrait",
			value: "false",
		},
		{
			id: 2,
			name: "Paysage",
			value: "true",
		},
	]
	return (
		<>
			<SubTitle>Orientation de document :</SubTitle>
			<SelectField
				name='orientation'
				value={formik.values.orientation ? "true" : "false"}
				options={orientation}
				onChange={(e) => formik.setFieldValue("orientation", e.target.value === "true")}
			/>
		</>
	)
}
