import http from '../../utils/http';
import { useMutation } from '@tanstack/react-query';

const buyCredit = async(credit) => {
    const response = await http.post('/buy_credit', {quantity: credit});
    return response.data;
}

export default function useBuyCredit(){
    
    return useMutation({
        mutationKey : ['buyCredit'],
        mutationFn : (credit) => buyCredit(credit)
    })
    
}