import React, { useCallback } from 'react';
import { Textarea } from '@chakra-ui/react';

function TextareaField({ name, value, resize, maxHeight, onChange }){

    const handleChange = useCallback( (evt) => {
        onChange(evt);
    }, [] );

    return (
        <Textarea
            id={name}
            name={name}
            height={48}
            color='#fff'
            value={value}
            resize={resize}
            borderRadius={8}
            borderColor='#323232'
            maxHeight={maxHeight}
            onChange={handleChange}
            backgroundColor='#323232'
        >{value}</Textarea>
    )
}

TextareaField.defaultProps = {
    resize: 'auto',
    maxHeight : 'inherit'
}

export default TextareaField