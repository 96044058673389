import axios from "axios";
import { createStandaloneToast } from "@chakra-ui/react";
import { getHeader, getApiUrl, getPatchHeader } from "../../utils";

// Toast
const { toast } = createStandaloneToast();

// Constants
const HEADER = getHeader();
const PATCH_HERADER = getPatchHeader();
const END_POINT = getApiUrl('/documents');

/**
 * @name createAction
 * @param {{title, content, information }} DATA 
 * @reducer DoucmentReducer
 * @description Store document to API
 */
// TODO Suppression des actions unitiles
export const createAction = (DATA) => {

    const createRequest = () => {
        return {
            type : 'DOCUMENT_SAVING'
        }
    }

    const createSuccess = (document) => {
        return {
            type : 'DOCUMENT_SAVED',
            playload : document
        }
    }

    const createFail = () => {
        return {
            type : 'DOCUMENT_SAVE_FAIL'
        }
    }
    
    return ( dispatch ) => {
        dispatch( createRequest() );
        axios.post(END_POINT, DATA, HEADER)
            .then( ( response ) => {
                if( response.status === 201 ){
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'success',
                        title: 'Document crée',
                    })
                    dispatch( createSuccess( response.data ) );
                }
            } )
            .catch( ( error ) => {
                dispatch( createFail() );
            } )
    }

}
/**
 * @name updateAction
 * @param {*} ID
 * @param DATA 
 * @reducer DocumentReducer
 * @description Update document to API
 * @param {{title, content, information }} DATA 
 */
export const updateAction = (ID, DATA) => {
    
    const updateRequest = () => {
        return {
            type : 'DOCUMENT_SAVING'
        }
    }

    const updateSuccess = (document) => {
        return {
            type : 'DOCUMENT_SAVED',
            playload : document
        }
    }

    const updateFail = () => {
        return {
            type : 'DOCUMENT_SAVE_FAIL'
        }
    }

    return ( dispatch ) => {
        dispatch( updateRequest() );
        const URL = END_POINT+'/'+ID;
        axios.patch(URL, DATA, PATCH_HERADER)
            .then( response => {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'success',
                    title: 'Document modifiée'
                })
                dispatch( updateSuccess(response.data) )
            } )
            .catch( error => {
                dispatch( updateFail() )
            } )
    }

}

/**
 * @name deleteAction
 * @param {*} ID 
 * @reducer DocumentReducer
 * @description Delete document from API
 */
export const deleteAction = (ID) => {

    const deleteRequest = () => {
        return {
            type : 'DOCUMENT_DELETING'
        }
    }

    const deleteSuccess = () => {
        return {
            type : 'DOCUMENT_DELETE_SUCCESS'
        }
    }

    const deleteFail = () => {
        return {
            type : 'DOCUMENT_SAVE_FAIL'
        }
    }

    return ( dispatch ) => {
        const URL = END_POINT+'/'+ID;
        dispatch( deleteRequest() )
        axios.delete(URL, HEADER)
            .then( () => {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'success',
                    title: 'Document supprimé',
                })
                dispatch( deleteSuccess() );
            } )
            .catch( error => {
                dispatch( deleteFail() );
            } )
    }

}

/**
 * @name setVariablesDoc
 * @param variables
 * @reducer DocumentReducer
 * @description Intiliaze variables from document variables
 */
export const setVariablesDoc = (variables) => {

    return ( dispatch ) => {
        dispatch({ type : 'SET_VARIABLES_DOC', playload : variables });
    }

}

/**
 * @name setClausesDoc
 * @param clauses
 * @reducer DocumentReducer
 * @description Intiliaze clauses from document clauses
 */
export const setClausesDoc = (clauses) => {

    return ( dispatch ) => {
        dispatch({ type : 'SET_CLAUSES_DOC', playload : clauses });
    }

}

/**
 * @name addColumnToSheet
 * @reducer SpreadSheetReducer
 * @description Add new column at the last of column
 */
export const addColumnToSheet = () => {

    return ( dispatch ) => {
        dispatch( { type : 'ADD_COLUMN' } )
    }

}

/**
 * @name deleteColumnFromSheet
 * @reducer SpreadSheetReducer
 * @description Remove the last column of the sheet
 */
export const deleteColumnFromSheet = () => {

    return ( dispatch ) => {
        dispatch( { type : 'DELETE_COLUMN' } )
    }

}


/**
 * @name setDataSheet
 * @reducer SpreadSheetReducer
 * @description Populate sheet with data from API
 */
export const setDataSheet = (data) => {

    return ( dispatch ) => {
        dispatch( { type : 'SET_SHEET_DATA', playload : data } )
    }

}

/**
 * @name addRowToSheet
 * @reducer SpreadSheetReducer
 * @description Add new row at the last of ligne
 */
export const addRowToSheet = () => {

    return ( dispatch ) => {
        dispatch( { type : 'ADD_ROW' } )
    }

}

/**
 * @name deleteRowFromSheet
 * @reducer SpreadSheetReducer
 * @description Remove the last row of the sheet
 */
export const deleteRowFromSheet = () => {

    return ( dispatch ) => {
        dispatch( { type : 'DELETE_ROW' } )
    }

}

/**
 * @name addDataToSheet
 * @param {start, end, data}playload 
 * @reducer SpreadSheetReducer
 * @description Add Variable / Clause into the sheet
 */
export const addDataToSheet = (playload) => {

    return ( dispatch ) => {
        dispatch( { type : 'ADD_DATA_TO_SHEET', playload } )
    }

}

/**
 * @name deleteDataFromSheet
 * @param playload 
 * @reducer SpreadSheetReducer
 * @description Remove Variable / Clause
 */
export const deleteDataFromSheet = (playload) => {
    
    return ( dispatch ) => {
        dispatch( { type : 'DELETE_DATA_TO_SHEET', playload } )
    }

}

/**
 * @name addVariableToDoc
 * @param variable 
 * @reducer DocumentReducer
 * c
 */
export const addVariableToDoc = (variable) => {

    return ( dispatch ) => {
        dispatch( { type : 'ADD_VARIABLE_TO_DOC', playload : variable } )
    }

}

/**
 * @name removeVariableToDoc
 * @param variable 
 * @reducer DocumentReducer
 * @description Remove Variable
 */
export const removeVariableToDoc = (variable) => {

    return ( dispatch ) => {
        dispatch( { type : 'REMOVE_VARIABLE_TO_DOC', playload : variable } )
    }

}

/**
 * @name addClauseToDoc
 * @param clause 
 * @reducer DocumentReducer
 * @description Add Clause to the text editor
 */
export const addClauseToDoc = (clause) => {

    return ( dispatch ) => {
        dispatch( { type : 'ADD_CLAUSE_TO_DOC', playload : clause } )
    }

}

/**
 * @name removeClauseToDoc
 * @param clause 
 * @reducer DocumentReducer
 * @description Remove Variable
 */
export const removeClauseToDoc = (clause) => {

    return ( dispatch ) => {
        dispatch( { type : 'REMOVE_CLAUSE_TO_DOC', playload : clause } )
    }

}

/**
 * @name resetSheet
 * @reducer SpreadSheetReducer
 * @description Reset sheet to the initial state
 */
export const resetSheet = () => {

    return ( dispatch ) => {
        dispatch( { type : 'RESET_SHEET' } )
    }

}

/**
 * @name resetAction
 * @reducer DocumentReducer
 * @description Reset document editor to the inital state
 */
export const resetAction = () => {

    return (dispatch) => {
        dispatch({ type : 'RESET_SHEET' })
        dispatch({ type : 'DOCUMENT_RESET' })
    }

}