import React from 'react';
import { Box } from '@chakra-ui/react';

// Components
import UserForm from './block/UserForm';
import ListeCallback from './callback/ListeCallback';

// Global
import Template from '../../components/dashboard/Template';
import ProForm from './block/proForm';

// Context
function CreateProPage () {

    return (
        <Template 
            title='Professionnel'
            callback={<ListeCallback />}
            restrictedTo={['ROLE_ADMIN']}
        >
            <Box bg="dark.300" borderRadius={8} paddingY={30}> 
                <Box paddingX={{ base:2, lg: 8 }} paddingY={5}>
                    <ProForm />
                </Box>
            </Box>
        </Template>
    )

}

export default CreateProPage;