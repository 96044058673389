import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { connect, useDispatch } from 'react-redux';
import { FaSave } from "react-icons/fa";
import { 
    Box,
    Icon,
    Stack,
    Center,
    Button,
    useToast,
    FormLabel, 
    SimpleGrid,
    FormControl, 
    FormErrorMessage 
} from '@chakra-ui/react';
import { 
    object, 
    string, 
    number,
    boolean,
} from 'yup';

// Components
import PackSelected from './PackSelected';
import { SwitchField, InputField, TextareaField } from '../../../components/fields';

// Hooks
import { usePostPack } from '../../../hooks/pack';

// Context
import PermissionsContext from '../../../context/PermissionsContext';

// Constants
import { DENIED_ACTION_ERROR, DENIED_ACTION_TITLE } from '../../../config/constants';

// Schema 
const PackSchema = object({
    name : string().required(),
    credit: number().min(1).required(),
    description : string().required(),
    status : boolean(),
})

function PackForm ( { documents } ){

    // states
    const [ isSaving, setIsSaving ] = useState(false);

    // context
    const { isGranted } = useContext( PermissionsContext );

    // hooks
    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const postPack = usePostPack();
    const formik = useFormik({
        initialValues : {
            name : '',
            credit : 0,
            description : '',
            status: true,
        },
        validateOnChange: false,
        validationSchema: PackSchema,
        onSubmit : (values) => {
            let docData = [];
            documents.forEach( document => {
                docData.push( document.apiId );
            } )
            const packData = {...values, documents : docData, status : Boolean(values.status) }
            setIsSaving(false);
            savePack( packData );
        },
    });

    // onUnmount
    useEffect( () => {
        return () => {
            formik.resetForm();
            setIsSaving(false);
            dispatch({ type : 'PACKS_RESET' })
        }
    }, [] );
    
    // callback
    const savePack = ( packData ) => {
        if( !isGranted(['ROLE_ADMIN']) ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: DENIED_ACTION_ERROR
            });
            return false;
        }
        if( documents.length === 0 ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title : 'Pack',
                description: 'Vous devrez ajouté une liste de document pour pouvoir créer un pack !',
            })
            return false;
        }
        setIsSaving(true);
        createPack( packData );
    }

    const createPack = (packData) => {
        postPack
            .mutate( packData, {
                onSuccess: () => {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'success',
                        title : 'Pack crée avec succcés',
                    })
                    navigate('/dashboard/pack')
                },
                onError : (e) => {
                    if( e.response.status === 400 ){
                        toast({
                            position: 'top',
                            duration: 9000,
                            isClosable: true,
                            status: 'error',
                            title: 'Génération pack',
                            description: e.response.data.detail
                        });
                    }else if( e.response.status === 403 ){
                        toast({
                            position: 'top',
                            duration: 9000,
                            isClosable: true,
                            status: 'error',
                            title: DENIED_ACTION_TITLE,
                            description: DENIED_ACTION_ERROR
                        });
                    }else{
                        console.error(e.response.data.detail)
                    }
                },
                onSettled : () => setIsSaving(false)
            } )
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <SimpleGrid columns={2} spacing={10}>
                 <Stack spacing={4}>
                    <FormControl isInvalid={formik.errors.name}>
                        <FormLabel htmlFor='name' color='#787675'>Nom :</FormLabel>
                        <InputField 
                            name='name'
                            value={formik.values.name}
                            onChange={formik.handleChange}
                        />
                        <FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>
                    </FormControl> 
                    <FormControl isInvalid={formik.errors.credit}>
                        <FormLabel htmlFor='credit' color='#787675'>Crédits pour ce pack :</FormLabel>
                        <InputField 
                            type='number'
                            name='credit'
                            value={formik.values.credit}
                            onChange={formik.handleChange}
                        />
                        <FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>
                    </FormControl>    
                 </Stack>
                 <Box>
                    <FormControl isInvalid={formik.errors.description}>
                        <FormLabel htmlFor='name' color='#787675'>Description :</FormLabel>
                        <TextareaField
                            resize='none'
                            maxHeight='144px'
                            name='description'
                            onChange={formik.handleChange}
                            value={formik.values.description}
                        />
                        <FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>
                    </FormControl>
                 </Box>
            </SimpleGrid>
            <SimpleGrid  marginY={6} columns={2} spacing={10}>
                <SwitchField 
                    label='Status'
                    name='status'
                    value={formik.values.status}
                    onChange={formik.handleChange}
                />
                <></>
            </SimpleGrid>
            <Box marginY='40px'>
                <PackSelected />
            </Box>
            <Center mt={4}>
                <Button 
                    type='submit'
                    paddingX='30px'
                    height = '50px' 
                    fontSize='16px'
                    borderWidth={1}
                    bg = 'green.100' 
                    fontWeight = {600}
                    borderColor='white'
                    isLoading={isSaving}
                    spinnerPlacement = 'end'
                    _hover = {{ bg: 'yellow.100' }} 
                    rightIcon={<Icon fontSize={22} as={FaSave} />}
                    >Sauvegarder</Button>
            </Center>
        </form>
    )
}

const mapStateToProps = ( state ) => {
    return {
        documents : state.packs.docs
    }
}

export default connect(mapStateToProps)(PackForm);