import React from 'react';
import { Flex } from '@chakra-ui/react';

// Component
import ButtonAction from '../../../components/ButtonAction';

function ListeCallback(){
    return(
        <Flex justifyContent='space-between' gap={4}>
            <ButtonAction 
                color='dark.200'
                showIcon={false} 
                bgColor='green.100'
                title='Liste des utilisateurs'
                to="/dashboard/users" 
            />
        </Flex>
    )
}

export default ListeCallback;