import http from '../../utils/http'
import { useQuery } from '@tanstack/react-query';

export default function useFetchPackDocs(packId){
 
    return useQuery({
        enabled: !!packId,
        queryKey: ['fetchPackDocs', packId],
        queryFn: async () => {
            const response =  await http.get(`/packs/${packId}/documents?pagination=false&order[createdAt]=desc`);
            return response.data['hydra:member'];
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false
    });
    
} 
