import http from '../../utils/http'
import { useMutation,useQueryClient } from '@tanstack/react-query';

const deletePack = async(packId) => {
    const response = await http.delete(`/packs/${packId}`);
    return response.data;
}

export default function useDeletePack(){
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey : ['deletePack'],
        mutationFn : (packId) => deletePack(packId),
        onSuccess: () => {
            queryClient.invalidateQueries(['fetchPacks']);
          },
        
    })

}