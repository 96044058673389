import React from 'react';

// Components
import PackTable from './PackTable';

function PackTablePlaceholder ({ isLoading }){

    const documents = Array(10).fill({ 'name' : 'document', 'credit' : '15', 'documents' : 1, 'status' : 0, '@id' : '/api/packs/100' })

    return (
        <PackTable 
            packData = {documents}
            isFetching = {isLoading}
        />
    )

}

PackTablePlaceholder.defaultProps = {
    isLoading : true
}

export default PackTablePlaceholder;