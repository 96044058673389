import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import { createColumnHelper } from "@tanstack/react-table";

// Components
import UserActions from './UserActions';
import DataTable from '../../../components/DataTable';
import AdminsActions from './AdminActions';

function AdminTable({ isFetching, packData, onDeleteUser }){
    const getColumns = () => {

        const columnHelper = createColumnHelper();
        return [
            columnHelper.accessor('firstname', {
                cell: (info) => info.getValue().toString(),
                header: 'Nom'
            }),
            columnHelper.accessor('lastname', {
                cell: (info) => info.getValue().toString(),
                header: 'Prénom'
            }),
            columnHelper.accessor('email', {
                cell: (info) => info.getValue(),
                header: 'Email'
            }),
            columnHelper.accessor('id', {
                cell: (info) => (
                    <AdminsActions 
                        id={ info.getValue() } 
                        onDelete={onDeleteUser}
                    /> 
                ),
                header: 'Actions',
                enableSorting: false
            })
        ];
    }

    return (
        <Box>
            <Box bg="dark.200" paddingY={4} borderRadius={10}>
                <DataTable 
                    data = {packData}
                    columns = {getColumns()}
                    isLoading = {isFetching}
                />
            </Box>
        </Box>
    )

}

AdminTable.propTypes = {
    isFetching : PropTypes.bool,
    packData : PropTypes.array,
    onDeletePack : PropTypes.func
}

export default AdminTable;