import React from "react";
import { Stack, Text, Icon } from '@chakra-ui/react';
import { FaExclamationCircle } from 'react-icons/fa';

export default function AccessDenied(){

    return (
        <Stack direction='row' alignItems='center' justifyContent='center' bg="dark.200" borderRadius={4} margin='40px' padding={5}> 
            <Icon as={FaExclamationCircle} color='red' />
            <Text color='white'>Vous n'avez pas le droit necéssaire pour accèder à cette page.</Text>
        </Stack>
    )

}