import http from '../../utils/http'
import { useQuery } from '@tanstack/react-query';

export default function useFetchClause(clauseId){
 
    return useQuery({
        enabled: !!clauseId,
        queryKey: ['fetchClause', clauseId],
        queryFn: async () => {
            if( clauseId === undefined ){
                return null;
            }
            const response =  await http.get(`/clauses/${clauseId}`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false
    });
    
} 
