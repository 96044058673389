import React, { useCallback } from "react";
import PropTypes from 'prop-types';
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, Text, Button, Portal, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, HStack } from '@chakra-ui/react';

function ConfirmationModal ({ title, isOpen, onClose, description, onConfirmation, isLoading }) {

    const onConfirmModal = useCallback( () => {
    
        try{
            onConfirmation();
        }catch( e ){
            console.error(e);
        }

    }, [ onConfirmation ] );

    const onCloseModal = useCallback( () => {

        try{
            if( !isLoading ){
                onClose();
            }
        }catch( e ){
            console.error(e);
        }

    }, [ onClose, isLoading ] );

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={true}>
                <ModalOverlay />
                <ModalContent bg='dark.200'>
                    <ModalHeader color='white'>{title}</ModalHeader>
                    <ModalBody>
                        <Text color='white'>{description}</Text>
                    </ModalBody>
                    <HStack padding={7} justifyContent='space-between' alignItems='center' mt={2}>
                        <Box>
                            <Button 
                                type='button'
                                height = '40px' 
                                bg = 'green.100' 
                                fontSize = '15px'
                                fontWeight = {500}
                                spinnerPlacement = 'end'
                                rightIcon = {<CheckIcon />}
                                _hover = {{ bg: 'yellow.100' }} 
                                isLoading = {isLoading}
                                onClick = {onConfirmModal}
                            >Oui</Button>
                        </Box>
                        <Box>
                            <Button 
                                color='white'
                                type='button'
                                height = '40px' 
                                bg = 'bark.100' 
                                fontSize='15px'
                                borderWidth = {1}
                                fontWeight = {500}
                                borderColor='white'
                                spinnerPlacement = 'end'
                                onClick = {onCloseModal}
                                _hover = {{ bg: 'red.100' }} 
                                rightIcon = {<CloseIcon fontSize={12} />}
                            >Non</Button>
                        </Box>
                    </HStack>
                </ModalContent>
            </Modal>
        </Portal>
    )

}


ConfirmationModal.propTypes = {
    title : PropTypes.string,
    isLoading : PropTypes.bool,
    description : PropTypes.string,
    isOpen : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
    onConfirmation : PropTypes.func.isRequired
}

ConfirmationModal.defaultProps = {
    isOpen : false,
    isLoading : false,
    onClose : () => {},
    title : 'Modal title',
    onConfirmation : () => {},
    description : 'Modal description',
}

export default ConfirmationModal;