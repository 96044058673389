import http from '../../utils/http';
import { useQuery } from '@tanstack/react-query';

export default function useFetchSaisie(saisieId){
 
    return useQuery({
        enabled: !!saisieId,
        queryKey: ['fetchSaisie', saisieId],
        queryFn: async () => {
            if( saisieId === undefined ){
                return null;
            }
            const response =  await http.get(`/shared_packs/${saisieId}`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false
    });
    
} 
