import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { ViewIcon, DeleteIcon } from '@chakra-ui/icons';
import { ButtonGroup, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, FormControl, FormLabel, Input, useDisclosure } from "@chakra-ui/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useToast } from "@chakra-ui/react";
import usePostCreditUser from "../../../hooks/users/useChargeCredit";
import { isGranted } from "../../../utils";

function UserActions({ id, onDelete }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const mutation = usePostCreditUser();
    const toast = useToast();

    const formik = useFormik({
        initialValues: {
            credit: '',
        },
        validationSchema: Yup.object({
            credit: Yup.number().required('Ce champ est obligatoire').positive('Le crédit doit être positif').integer('Le crédit doit être un nombre entier'),
        }),
        onSubmit: async (values) => {
            const userCreditData = {
                user : `api/users/${id}`,
                credit : values.credit
            }
            setIsSubmitting(true);
            try {
                await mutation.mutateAsync(userCreditData);
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'success',
                    title: 'Crédit assigné avec succès',
                });
                formik.resetForm();
            } catch (error) {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'error',
                    title: 'Une erreur est survenue lors de l\'assignation du crédit',
                });
            } finally {
                setIsSubmitting(false);
                onClose();
            }
        },
    });

    const handleDelete = () => {
        if (typeof onDelete === 'function') {
            onDelete(id);
        }
    };

    return (
        <>
            <ButtonGroup variant='outline' spacing={0} padding={0}>
                {isGranted(["ROLE_ADMIN","ROLE_PROFESSIONAL"]) && (
                <Button
                    border={0}
                    padding={0}
                    colorScheme='white'
                    title="Assigner un crédit"
                    onClick={onOpen}
                    _hover={{ bg: 'dark.100' }}
                >
                    <Icon icon="noto-v1:credit-card" />
                </Button>
                )}
                <Button
                    as={Link}
                    border={0}
                    padding={0}
                    colorScheme='white'
                    title="Modifier l'utilisateur"
                    _hover={{ bg: 'dark.100' }}
                    to={`/dashboard/users/edit/${id}`}
                >
                    <ViewIcon />
                </Button>
                <Button
                    border={0}
                    padding={0}
                    colorScheme='red'
                    title="Supprimer l'utilisateur"
                    onClick={handleDelete}
                    _hover={{ bg: 'dark.100' }}
                >
                    <DeleteIcon />
                </Button>
            </ButtonGroup>

            <Modal  isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent textColor="gray.100" bg='dark.200'>
                    <ModalHeader color="white">Assigner un crédit</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={formik.handleSubmit}>
                            <FormControl isInvalid={formik.errors.credit && formik.touched.credit}>
                                <FormLabel htmlFor="credit">Crédit</FormLabel>
                                <Input
                                    id="credit"
                                    name="credit"
                                    type="number"
                                    value={formik.values.credit}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.errors.credit && formik.touched.credit && (
                                    <div>{formik.errors.credit}</div>
                                )}
                            </FormControl>
                            <ModalFooter>
                                <Button size="md" colorScheme="green" mr={3} type="submit" isLoading={isSubmitting}>
                                    Assigner
                                </Button>
                                <Button size="md" variant="red.100" textColor="white" bg="red.100" onClick={onClose}>Annuler</Button>
                            </ModalFooter>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

UserActions.propTypes = {
    id: PropTypes.number.isRequired,
    onDelete: PropTypes.func,
};

export default UserActions;
