import React from 'react';
import { Flex, Box } from '@chakra-ui/react';

// Global
import Template from '../../components/dashboard/Template';

// Components
import Callback from './callback/Callback';
import CategoryForm from './block/CategoryForm';
import CategoryTable from './block/CategoryTable';

class CategoryPage extends React.Component{

    render(){
        return (
            <Template 
                title='Catégories'
                callback={<Callback />}
                restrictedTo={['ROLE_ADMIN', 'ROLE_USER','ROLE_PROFESSIONAL']}
            >
                <Flex flexDirection={{ base : 'column', lg : 'row' }} gap='20px'>
                    <Box bg="dark.300" borderRadius={8} padding='26px' width={{ base : '100%', lg : '30%' }}>
                        <CategoryForm />
                    </Box>
                    <Box bg="dark.300" borderRadius={8} padding='26px' width={{ base : '100%', lg : '70%' }}>
                        <CategoryTable />
                    </Box>
                </Flex>
            </Template>
        )
    }

}

export default CategoryPage;