import React from 'react';
import PropsType from 'prop-types'
import { Text } from '@chakra-ui/react';

function SubTitle(props){
    return (
        <Text {...props}>{props.children}</Text>
    )
}

SubTitle.defaultProps = {
    color : "blue.100",
    fontSize: '18px'
}

SubTitle.propTypes = {
    color: PropsType.string,
    fontSize : PropsType.any
}

export default SubTitle;