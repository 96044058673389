import Template from "../../components/dashboard/Template"

// components
import { Settings } from "./block/Settings"

export const SettingsPage = ()=> {
    return(
        <Template 
                callback=''
                title='Parametres'
                restrictedTo={['ROLE_ADMIN','ROLE_USER']}
            >
                <Settings/>
        </Template>
    )
}