import React, { useCallback } from 'react';
import { Select } from '@chakra-ui/react';
import PropTypes from 'prop-types';

function SelectField ({ name, value, options, placeholder, onChange }) {

    let index = 0;

    const handleChange = useCallback( (evt) => {
        onChange(evt);
    }, [] );

    const renderOptions = (options) => {
        if( !options || options.length === 0 ){
            return null
        }
        return options.map( field => {
            index++;
            return (
                <option key={index} value={field.value}>{field.name}</option>
            )
        } )
    }

    return (
        <Select 
            name={name}
            height='48px'
            color='#fff'
            bg='#323232'
            value={value}
            defaultValue=''
            borderRadius={8}
            borderColor='#323232'
            onChange={handleChange}
            placeholder={placeholder}
        >
        { renderOptions( options ) }
        </Select>
    )
}

SelectField.defaultProps = {
    placeholder : 'Choississez une option'   
}

SelectField.propTypes = {
    options : PropTypes.array.isRequired
}

export default SelectField;