import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

const Flex = styled.span`
    display: flex;
    flex-direction : row;
    align-items: center;
    justify-content: flex-start;
    gap : 17px;
    @media screen and (max-width:991px){
        justify-content: center;
    }
`;

function MenuItem(props){

    const { to, icon, title  } = props;

    return (
        <Box className='menu-item'>
            <Link to={to}>
                <Flex>
                    <Image src={icon} alt={title} maxWidth='20px' width="20px" height="20px" />
                    <Text fontSize={15} color='white' display={{ base: 'none', lg: 'block' }} >{title}</Text>
                </Flex>
            </Link>
        </Box>
    )

}

MenuItem.propTypes = {
    to : PropTypes.string.isRequired,
    icon : PropTypes.string.isRequired,
    title : PropTypes.string.isRequired
}

export default MenuItem;