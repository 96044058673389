import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, HStack, Tooltip, Icon } from '@chakra-ui/react';
import { SlList } from 'react-icons/sl'

// Components
import PackGrid from '../block/PackGrid';
import SubTitle from '../../../components/SubTitle';
import NoContent from '../../../components/NoContent';
import PackPlaceholder from '../block/PackPlaceholder';

// Context
import ListePackViewContext from '../../../context/ListePackViewContext'

// Middleware
import { fetchPacks } from '../../../actions/pack';

function ListePackGridView ({ packs, isError, isLoading, fetchPacks }) {

    // context
    const { setView } = useContext(ListePackViewContext);

    // onDidMount
    useEffect( () => {
        fetchPacks(1);
    }, [] );

    if( isLoading ){
        return (
            <PackPlaceholder />
        )
    }

    if( !isLoading && ( isError || !packs?.length ) ){
        return (
            <NoContent 
                showLink = { true }
                link = '/dashboard/pack/create'
                linkText = 'Créer un pack'
                message = 'Aucun pack trouvé'
            /> 
        )
    }

    return (
        <Box id="pack_view_grid">
            <HStack justifyContent='space-between' alignItems='center' marginBottom={10}>
                <SubTitle>Tous les packs</SubTitle>
                <Tooltip label='Afficher sous forme de tableau' placement='left-start' hasArrow>
                    <span style={{ cursor:'pointer' }} onClick={() => setView('table')} ><Icon fontSize={20} color='white' as={SlList} /></span>
                </Tooltip>
            </HStack>
            <PackGrid />
        </Box>
    )

}

const mapStateToProps = ( state ) => {
    return {
        packs : state.packs.packs,
        isError : state.packs.isError,
        isLoading : state.packs.isFetching
    }
}

const mapDispatchToProps = {
    fetchPacks : fetchPacks
}

export default connect(mapStateToProps, mapDispatchToProps)(ListePackGridView);