import http from '../../utils/http'
import { useMutation } from '@tanstack/react-query';

const generateDocUrl = async(document_id) => {
    const response = await http.post('/share/document', document_id);
    console.log(response);
    return response.data;
}

export default function useGenerateDocUrl(){
    return useMutation({
        mutationKey : ['generateDocUrl'],
        mutationFn : (document_id) => generateDocUrl(document_id)
    })
    
}