import React, { useContext, forwardRef,useRef,useState } from "react";
import { FormikContext } from "formik";
import { Box, Stack, Input, Textarea, Select, Skeleton, FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import { Editor } from '@tinymce/tinymce-react';
// Hooks
import { useFetchCategories } from "../../../hooks/category";

// Context
import TypeDocumentContext from "../../../context/TypeDocumentContext";
import TinyForHeaderMceContext from "../../../context/TinyForHeaderContext";

const Information = forwardRef( ( props, ref ) => {

    const headerRef = useRef(null);
    const { setTinyInstance } = useContext(TinyForHeaderMceContext);
    
    // states
    const [editorIsReady, setEditorIsReady] = useState(false);

    const formik = useContext( FormikContext );

    const { typeDocument } = useContext(TypeDocumentContext);
    const { data:categories, isFetching } = useFetchCategories();

    

    return (
        <Stack direction='column' spacing={5}>
            <Stack direction='row' spacing={6} justifyContent='space-around'>
                <Box width='100%'>
                    <FormControl isInvalid={formik.errors.name}>
                        <FormLabel htmlFor='name' fontSize='18px' color='blue.100'>Nom</FormLabel>
                        <Skeleton isLoaded={!isFetching} borderRadius={8}>
                            <Input 
                                id="name"
                                name='name'
                                type="text"
                                height='48px'
                                color='#fff'
                                borderRadius={8}
                                borderColor='dark.300'
                                backgroundColor='dark.300'
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </Skeleton>
                        <FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>
                    </FormControl>
                </Box>
                <Box width='100%'>
                    <FormControl isInvalid={formik.errors.category}>
                        <FormLabel htmlFor='category' fontSize='18px' color='blue.100'>Catégorie</FormLabel>
                        <Skeleton isLoaded={!isFetching} borderRadius={8}>
                            <Select 
                                name='category'
                                height='48px'
                                color='#fff'
                                bg='dark.300'
                                borderRadius={8}
                                borderColor='dark.300'
                                value={formik.values.category}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder='Choississez une catégorie'
                            >
                                {
                                    categories && categories.map( category => {
                                        return (
                                            <option key={category.id} value={category['@id']}>{category.name}</option>
                                        )
                                    } )
                                }
                            </Select>
                        </Skeleton>
                        <FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>
                    </FormControl>
                </Box>
            </Stack>
            { typeDocument !== 'xlsx' && 
                <Box width='100%'>
                    <FormControl isInvalid={formik.errors.header}>
                        <FormLabel htmlFor='name' fontSize='18px' color='blue.100'>En tête</FormLabel>
                        <Skeleton isLoaded={!isFetching} borderRadius={8}>
                            <Editor
                                ref={headerRef}
                                initialValue={formik.values.header}
                                apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                                onInit={(evt, editor) => {
                                    setEditorIsReady(true);
                                    headerRef.current = editor;
                                    setTinyInstance(editor);
                                }}
                                init={{
                                    height: 200,
                                    language: 'fr_FR',
                                    plugins: 'autolink charmap image link lists searchreplace table wordcount',
                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table  | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                }}
                            />
                            {/* 
                            <Textarea 
                                id="header"
                                name='header'
                                type="text"
                                height='120px'
                                color='#fff'
                                resize='none'
                                borderRadius={8}
                                borderColor='dark.300'
                                backgroundColor='dark.300'
                                onChange={formik.handleChange}
                                value={formik.values.header}
                            >{formik.values.header}</Textarea>
                            */}
                        </Skeleton>
                        <FormErrorMessage>{formik.errors.header}e</FormErrorMessage>
                    </FormControl>
                </Box>
            }
        </Stack>
    )

});

export default Information;