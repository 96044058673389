import http from '../../utils/http';
import { useQuery } from '@tanstack/react-query';

export default function useFetchTransactions(){
 
    return useQuery({
        queryKey: ['fetchTransactions'],
        queryFn: async () => {
            const response =  await http.get(`/transactions/?pagination=false&order[createdAt]=desc`);
            return response.data['hydra:member'];
            console.log(response.data['hydra:member']);
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false
    });
    
} 
