import React, { useContext } from "react";

// Context
import PermissionsContext from "../../../context/PermissionsContext";

// Components
import MenuGroup from "./MenuGroup";

// Icons
import SettingsIcon from './assets/img/settings.svg';


function SettingsMenu(){

    const { isGranted } = useContext( PermissionsContext );
    let menus = [
    ];

    if( isGranted(['ROLE_ADMIN']) ){
        menus.push(
            {
                'to': '/dashboard/settings',
                'icon' : SettingsIcon,
                'title' : 'Parametres'
            }
        )
    }

    return <MenuGroup name="Parametres" menus={menus} />
}

export default SettingsMenu;