import React, { useContext } from 'react';
import { FormikContext } from 'formik';
import { Skeleton, FormLabel, FormControl, FormErrorMessage } from '@chakra-ui/react';

// Components
import { SelectField } from '../../../fields';

// Constants
import { VARIABLE_FIELDS } from '../../../../config/constants';

function TypeField ({ isFetching }){

    const formik = useContext( FormikContext );

    return (
        <FormControl isInvalid={formik.errors.type}>
            <FormLabel htmlFor='label' color='#787675'>Type</FormLabel>
            <Skeleton isLoaded={!isFetching} borderRadius={8}>
                <SelectField
                    name='type'
                    options={VARIABLE_FIELDS}
                    value={formik.values.type}
                    placeholder='Choississez une type'
                    onChange={(e) => {
                        if( e.target.value === 'logo' ){
                            formik.setFieldValue('description', 'logo')
                        }else{
                            formik.setFieldValue('description', '');
                        }
                        formik.setFieldValue('options', ['']);
                        formik.handleChange(e)
                    }}
                />
            </Skeleton>
            <FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>
        </FormControl>
    )

}

export default TypeField;