import React from 'react';
import { Flex, Box } from '@chakra-ui/react';

// Global
import Template from '../../components/dashboard/Template';

// Components
import Callback from './callback/Callback';
import VariableTable from './block/VariableTable';
import VariableForm from '../../components/form/variable/VariableForm';

class VariablePage extends React.Component{

    render(){
        return (
            <Template 
                title='Variables'
                callback={<Callback />}
                restrictedTo={['ROLE_ADMIN', 'ROLE_USER','ROLE_PROFESSIONAL']}
            >
                <Flex flexDirection={{ base : 'column', lg : 'row' }} gap='20px'>
                    <Box bg="dark.300" borderRadius={8} padding='26px' width={{ base : '100%', lg : '30%' }}>
                        <VariableForm />
                    </Box>
                    <Box bg="dark.300" borderRadius={8} padding='26px' width={{ base : '100%', lg : '70%' }}>
                        <VariableTable />
                    </Box>
                </Flex>
            </Template>
        )
    }

}

export default VariablePage;