import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { 
    Box, 
    Text,
    Skeleton,
    SimpleGrid, 
} from '@chakra-ui/react';

// Components
import SubTitle from '../../../components/SubTitle';
import TextInput from '../../../components/TextInput';

const ClauseItem = forwardRef( ({ clause }, ref) => {
    const label = Object.keys(clause);
    const value = Object.values(clause);
    return (
        <Box className='pack_clause_item'>
            <Text color='#787675' fontSize='16px' fontWeight={500} mb='8px'>{label}</Text>
            <TextInput value={value} />
        </Box>
    )
} )

function Clauses ({ isFetching, clauses }){

    let index = 0;

    if( !clauses.length ){
        return null;
    }

    return (
        <Skeleton isLoaded={!isFetching} minHeight={isFetching ? '180px' : 'auto'} borderRadius={8} mb={12}>
            <SubTitle style={{ marginBottom: '24px' }}>Liste des clauses :</SubTitle>
            <SimpleGrid columns={2} spacing={6} marginTop={4} className='pack_clauses_list'>
                {
                    clauses.map( clause => {
                        index++;
                        return (
                            <ClauseItem key={index} clause={clause} />
                        )
                    } )
                }
            </SimpleGrid>
        </Skeleton>
    )
}

Clauses.defaultProps = {
    isFetching : false,
    clauses : []
}

Clauses.propTypes = {
    isFetching : PropTypes.bool,
    clauses : PropTypes.array.isRequired
}

export default Clauses;