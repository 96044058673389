import http from '../../utils/http'
import { useQuery } from '@tanstack/react-query';

export default function useFetchSaisies(){
 
    return useQuery({
        queryKey: ['fetchSaisies'],
        queryFn: async () => {
            const response =  await http.get(`/shared_packs?pagination=false&order[createdAt]=desc`);
            return response.data['hydra:member'];
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false
    });
    
} 
