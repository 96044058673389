import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

// Components
import User from './User';
import Logout from './Logout';
import MenuAction from './MenuAction';
import Restricted from '../../Restricted';

function Info(){
    return(
        <Box className='info_block'>
            <Flex justifyContent={{ base: 'flex-end', lg:'space-between' }} alignItems='center' gap={6}>
                <Restricted to={['ROLE_ADMIN']}>
                    <MenuAction />
                </Restricted>
                <Logout />
                <User />
            </Flex>
        </Box>
    )
}

export default Info;