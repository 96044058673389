import React from 'react';
import { FaPowerOff } from 'react-icons/fa';
import { Box, Button, Icon } from '@chakra-ui/react';

import { handleLogout } from '../../../utils';

function Logout(){

    const logoutUser = () => {
        handleLogout();
        window.location.href = '/';
    }

    return(
        <Box className='logout_item'>
            <Button 
                minW={34}
                padding={0}
                width={34}
                height={34}
                bg='dark.100'
                borderRadius='50%'
                _hover={{bg : 'red.100'}}
                aria-label='Se déconnecter'
                onClick={() => logoutUser()}
            >
                <Icon as={FaPowerOff} color='white' />
            </Button>
        </Box>
    )
}

export default Logout;