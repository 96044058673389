import React, { useContext } from "react";
import { SlList } from "react-icons/sl";
import { 
    Box, 
    Icon, 
    HStack, 
    Tooltip,
} from '@chakra-ui/react';

// Components
import SubTitle from "../../../components/SubTitle";
import CategoryGridList from "../block/CategoryGridList";

// Context
import DocumentViewContext from "../../../context/DocumentViewContext";

function CategoryGrid (){

    // context
    const { setView } = useContext(DocumentViewContext);

    return (
        <Box id="document_view_grid">
            <HStack justifyContent='space-between' alignItems='center' marginBottom={10}>
                <SubTitle>Toutes les catégories</SubTitle>
                <Tooltip label='Afficher toutes les documents' placement='left-start' hasArrow>
                    <span style={{ cursor:'pointer' }} onClick={ () => setView('document') }><Icon fontSize={20} color='white' as={SlList} /></span>
                </Tooltip>
            </HStack>
            <CategoryGridList />
        </Box>
    )

}

export default CategoryGrid;