import http from '../../utils/http'
import { useQuery } from '@tanstack/react-query';

export default function useFetchTransaction(transactionId){
 
    return useQuery({
        enabled: !!transactionId,
        queryKey: ['fetchTransaction', transactionId],
        queryFn: async () => {
            if( transactionId === undefined ){
                return null;
            }
            const response =  await http.get(`/transactions/${transactionId}`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false
    });
    
} 