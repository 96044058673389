import React, { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { jwtDecode } from "jwt-decode";
import {
    Box,
    Button,
    AlertIcon,
    Container,
    Heading,
    Stack,
    Text,
    Flex,
    Image,
    Center,
    Alert,
    AlertDescription,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

// Components
import PasswordField from './PasswordField';

// Assets
import Logo from '../../components/parts/sidebar/assets/img/Logo.webp';

// Utils
import { handleLogin } from '../../utils';
import { VALIDATION_ERROR } from '../../config/constants';
import useFetchToken from '../../hooks/password/useFetchToken';
import useResetPassword from '../../hooks/password/useResetPassword';

// Schema
const Schema = object({
    password: string().min(4, 'Mot de passe trop court').required('Ce champ est obligatoire')
});

function NewPasswordPage() {
    const { tokenValue } = useParams();
    const [isError, setIsError] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const { data: user, isError: isErrorToken, error: tokenError } = useFetchToken(tokenValue);

    useEffect(() => {
        if (tokenError) {
            window.location.href = `${window.location.origin}/forgot-password`;
        }
    }, [tokenError]);

    const mutation = useResetPassword(tokenValue);

    const formik = useFormik({
        initialValues: {
            password: ''
        },
        validateOnChange: false,
        validationSchema: Schema,
        onSubmit: (values) => {
            console.log(values);
            setIsFetching(true);
            mutation.mutate(values, {
                onError: (error) => {
                    setIsError(true);
                    setErrorMessage(VALIDATION_ERROR);
                },
                onSuccess: (response) => {
                    localStorage.setItem("emailForget", user.user.email);
                    if (response.token) {
                        setIsError(false);
                    }
                    
                    setTimeout(() => {
                        window.location.href = `${window.location.origin}/login`;
                    }, 3000);
                
                },
                onSettled: () => {
                    setIsFetching(false);
                }
            })
        }
    });

    const renderErrorBlock = () => {
        if (isError && errorMessage !== '') {
            return (
                <Alert status='error'>
                    <AlertIcon color='white' />
                    <AlertDescription color='white'>{errorMessage}</AlertDescription>
                </Alert>
            );
        }
    };

    return (
        <Flex bg='dark.100' minHeight='100vh' alignItems='center'>
            <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
                <Center mb={6}><Image src={Logo} maxWidth={170} /></Center>
                <Stack paddingTop={8} paddingBottom={3} spacing="0" bg="dark.200">
                    <Stack spacing="6">
                        <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                            <Heading size={{ base: 'sm', md: 'md' }} color='white'>Réinitialiser votre mot de passe ici</Heading>
                            <Text paddingX={4} color='white'>
                                Les mots de passe forts comprennent les chiffres, les lettres et les signes de ponctuation
                            </Text>
                        </Stack>
                    </Stack>
                    <Box
                        py={{ base: '4', sm: '8' }}
                        px={{ base: '4', sm: '10' }}
                        bg={{ base: 'transparent', sm: 'bg.surface' }}
                        boxShadow={{ base: 'none', sm: 'md' }}
                        borderRadius={{ base: 'none', sm: 'xl' }}
                    >
                        <form onSubmit={formik.handleSubmit}>
                            <Stack spacing="6">
                                {renderErrorBlock()}
                                <Stack spacing="5">
                                    <PasswordField
                                        disabled={isFetching}
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.errors.password}
                                        errorMessage={formik.errors.password}
                                        name="password"
                                    />
                                </Stack>
                                <Stack spacing="6">
                                    <Button
                                        type='submit'
                                        height='44px'
                                        bg='yellow.100'
                                        fontWeight={500}
                                        spinnerPlacement='end'
                                        isLoading={isFetching}
                                        tabIndex={4}
                                        _hover={{ bg: 'green.100' }}
                                    >
                                        Réinitialiser le mot de passe
                                    </Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Box>
                </Stack>
            </Container>
        </Flex>
    );
}

export default NewPasswordPage;
