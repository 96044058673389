import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@chakra-ui/react';

// Components
import PackForm from './block/PackForm';

import NoContent from '../../components/NoContent';
import Template from '../../components/dashboard/Template';
import ListeCallback from './callback/ListeCallback';

function ValidatePackPage ({ documentSelected }){

    const renderContent = () => {
        if( documentSelected.length === 0 ){
            return(
                <NoContent 
                    showLink={true}
                    linkText='Créer un pack'
                    link='/dashboard/pack/create'
                    message="Aucun document n'est ajouté. Merci de sélectionner !"
                />
            )
        }
        return (
            <PackForm />
        )
    }

    return (
        <Template 
            title='Générateur de pack'
            restrictedTo={['ROLE_ADMIN', 'ROLE_USER']}
            callback={documentSelected.length ? null : <ListeCallback />}
        >
            <Box bg="dark.300" borderRadius={8} padding='26px'>
                { renderContent() }
            </Box>
        </Template>
    )

}

const mapStateToProps = ( state ) => {
    return {
        documentSelected : state.packs.docs
    }
}

export default connect(mapStateToProps)(ValidatePackPage);