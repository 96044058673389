import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { ViewIcon } from '@chakra-ui/icons';
import { ButtonGroup, Button } from "@chakra-ui/react";

function DocumentActions ({ id }){
    
    return (
        <ButtonGroup variant='outline' spacing={0} padding={0}>
            <Button 
                as={Link}
                border={0} 
                padding={0} 
                colorScheme='white' 
                _hover={{ bg : 'dark.100' }}
                to={`/dashboard/document/edit/${id}`}
            >
                <ViewIcon />
            </Button>
        </ButtonGroup>
    )
}

DocumentActions.propTypes = {
    id : PropTypes.number.isRequired,
}


export default DocumentActions;