import React, { useState, useContext, useCallback } from 'react';
import { useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate } from 'react-router-dom';
import { createColumnHelper } from "@tanstack/react-table";
import { Box, useToast, useDisclosure } from '@chakra-ui/react';

// Components
import ClauseActions from './ClauseActions';
import ClausePlaceholder from './ClausePlaceholder';
import SubTitle from '../../../components/SubTitle';
import NoContent from '../../../components/NoContent';
import DataTable from '../../../components/DataTable';
import StatusBadge from '../../../components/StatusBadge';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';

// Hooks
import { useFetchClauses, useDeleteClause } from '../../../hooks/clause';

// Context
import PermissionsContext from '../../../context/PermissionsContext';

// Constants
import { APP_ERROR, DENIED_ACTION_TITLE, DENIED_ACTION_ERROR } from "../../../config/constants";

function ClauseTable() {

    const [clauseId, setClauseId] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    // Context
    const { isGranted } = useContext(PermissionsContext) || {};

    // Hooks
    const deleteQuery = useDeleteClause();
    const toast = useToast();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { clauseId: clauseParamId } = useParams() || {};
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { data: clauseData, isFetching: isLoading } = useFetchClauses();

    const handleDelete = useCallback((id) => {
        if (!isGranted || !isGranted(['ROLE_ADMIN', 'ROLE_USER'])) {
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: DENIED_ACTION_ERROR
            });
            return false;
        }
        if (!id) {
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: "ID clause manquant"
            });
            return false;
        }
        onOpen();
        setClauseId(id);
    }, [isGranted, onOpen, toast]);

    const onConfirmation = useCallback(() => {
        if (!clauseId) {
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: "ID clause manquant"
            });
            return false;
        }

        onClose();
        setIsDeleting(true);
        deleteQuery.mutate(clauseId, {
            onSuccess: () => {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'success',
                    title: "Clause supprimée avec succès",
                });
                queryClient.invalidateQueries({ queryKey: ['fetchClauses'] });
                if (clauseId === parseInt(clauseParamId)) {
                    navigate('/dashboard/clause');
                }
            },
            onError: () => {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'error',
                    title: APP_ERROR,
                });
            },
            onSettled: () => setIsDeleting(false)
        });
    }, [clauseId, clauseParamId, deleteQuery, navigate, onClose, queryClient, toast]);

    const getColumns = () => {
        const columnHelper = createColumnHelper();
        return [
            columnHelper.accessor('title', {
                cell: (info) => info.getValue(),
                header: 'Title'
            }),
            columnHelper.accessor('slug', {
                cell: (info) => '#{' + info.getValue() + '}',
                header: 'Slug',
                enableSorting: false
            }),
            columnHelper.accessor('status', {
                cell: (info) => (<StatusBadge state={info.getValue()} />),
                header: 'Etat',
                enableSorting: false
            }),
            columnHelper.accessor('id', {
                cell: (info) => (<ClauseActions id={info.getValue()} onDelete={handleDelete} />),
                header: 'Actions',
                enableSorting: false
            })
        ];
    };

    const renderContent = () => {
        if (isLoading || isDeleting) {
            return <ClausePlaceholder />;
        }
        if (!isLoading && !clauseData) {
            return <NoContent />;
        }
        return (
            <DataTable
                data={clauseData || []}
                columns={getColumns()}
                isLoading={isLoading}
            />
        );
    };

    return (
        <Box>
            <SubTitle marginBottom={6}>Liste des clauses</SubTitle>
            <Box bg="dark.200" paddingY={4} borderRadius={10}>
                {renderContent()}
            </Box>
            <ConfirmationModal
                isOpen={isOpen}
                onClose={onClose}
                title='Supprimer une clause'
                onConfirmation={onConfirmation}
                description='En supprimant cette clause, celui ne sera plus disponible dans vos documents !!'
            />
        </Box>
    );
}

export default ClauseTable;
