import React, { useState, useContext, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {  Box, HStack, Icon, Tooltip, useToast, useDisclosure } from '@chakra-ui/react';
import { SlLayers } from 'react-icons/sl'

// Components
import PackTable from '../block/PackTable';
import SubTitle from '../../../components/SubTitle';
import NoContent from '../../../components/NoContent';
import PackTablePlaceholder from '../block/PackTablePlaceholder';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';

// Context
import ListePackViewContext from '../../../context/ListePackViewContext';
import PermissionsContext from '../../../context/PermissionsContext';

// Hooks
import { useDeletePack } from '../../../hooks/pack';

// Middleware
import { fetchPacks, deletePack } from '../../../actions/pack';

// Constants
import {APP_ERROR, DENIED_ACTION_TITLE, DENIED_ACTION_ERROR} from "../../../config/constants";

function ListePackTableView({  packs, fetchPacks, deletePack, isFetching, isError }){

    // states
    const [ packId, setPackId ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();

    // context
    const { setView } = useContext( ListePackViewContext );
    const { isGranted } = useContext( PermissionsContext );

    // hooks
    const toast = useToast();
    const deleteQuery = useDeletePack();

    // onDidMount
    useEffect( () => {

        fetchPacks();

    }, [] );

    // onDidUpdate
    useEffect( ()  => {

        setIsLoading(isFetching);

    }, [isFetching])

    // callback
    const onDelete = useCallback( (id) => {
        if( !isGranted(['ROLE_ADMIN']) ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: DENIED_ACTION_ERROR
            });
            return false;
        }
        if( !id ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: "ID pack manquant"
            });
            return false;
        }
        onOpen();
        setPackId(id);
    }, [] );

    const onConfirmation = useCallback( () => {

        if( !packId ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: "ID pack manquant"
            });
            return false;
        }
        setIsLoading(true);
        deleteQuery.mutate(packId, {
            onSuccess : () => {
                onClose();
                deletePack(packId);
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'success',
                    title: 'Pack supprimé avec succès',
                });
            },
            onError : () => {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'error',
                    title: APP_ERROR
                });
            },
            onSettled : () => setIsLoading(false)
        })

    }, [ packId ] );

    if( isLoading ){
        return (
            <PackTablePlaceholder />
        )
    }
    
    if( !isLoading && ( isError || !packs.length ) ){
        return (
            <NoContent 
                showLink = { true }
                link = '/dashboard/pack/create'
                linkText = 'Créer un pack'
                message = 'Aucun pack trouvé'
            /> 
        )
    }

    return (
        <Box id="pack_view_table"> 
            <HStack justifyContent='space-between' alignItems='center' marginBottom={10}>
                <SubTitle>Tous les packs</SubTitle>
                <Tooltip label='Afficher sous forme de grid' placement='left-start' hasArrow>
                    <span style={{ cursor:'pointer' }} onClick={() => setView('grid')} ><Icon fontSize={20} color='white' as={SlLayers} /></span>
                </Tooltip>
            </HStack>
            <PackTable 
                isFetching={false}
                packData={packs}
                onDeletePack={onDelete}
            />
            <ConfirmationModal
                isOpen = {isOpen}
                onClose = {onClose}
                title = 'Supprimé un pack'
                onConfirmation = {onConfirmation}
                description = 'Vous voulez supprimer ce pack !'
            />
        </Box>
    )
}

const mapStateToProps = ( state ) => {
    return {
        packs : state.packs.packs,
        isError : state.packs.isError,
        isFetching : state.packs.isFetching
    }
}

const mapDispatchToProps = {
    deletePack : deletePack,
    fetchPacks : fetchPacks
}

export default connect(mapStateToProps, mapDispatchToProps)(ListePackTableView);