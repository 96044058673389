import React, { useCallback } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ViewIcon, DeleteIcon } from '@chakra-ui/icons';
import { ButtonGroup, Button } from "@chakra-ui/react";
import { Icon as Iconify } from "@iconify/react/dist/iconify.js";
import styled from '@emotion/styled';

// Components
import Restricted from "../../../components/Restricted";

const StyledIconify = styled(Iconify)`
  cursor: pointer;
  color: #fff;
  margin-right: 8px;
  &:hover {
    color: #eee;
  }
`;

function VariableAction({ id, onDelete }) {
  const handleDelete = useCallback(() => {
    if (typeof onDelete === 'function') {
      onDelete(id);
    }
  }, [id, onDelete]);

  return (
    <ButtonGroup variant='outline' spacing={0} padding={0}>
      <Button 
        as={Link}
        border={0} 
        padding={0} 
        colorScheme='white'
        title="Modifier un variable"
        _hover={{ bg: 'dark.100' }}
        to={`/dashboard/variable/${id}`}
      >
        <StyledIconify icon="bitcoin-icons:edit-outline" />
      </Button>
      <Restricted to={['ROLE_ADMIN']}>
        <Button 
          border={0} 
          padding={0} 
          colorScheme='red'
          title="Supprimer un variable"
          _hover={{ bg: 'dark.100' }}
          onClick={handleDelete}
        >
          <DeleteIcon />
        </Button>
      </Restricted>
    </ButtonGroup>
  );
}

VariableAction.propTypes = {
  id: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default VariableAction;
