import React, { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { jwtDecode } from "jwt-decode";
import {
    Box,
    Button,
    AlertIcon,
    Container,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Stack,
    Text,
    Flex,
    Image,
    Center,
    Alert,
    AlertDescription,
    FormErrorMessage,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

//   Components
import PasswordField from './PasswordField';

//   Assets
import Logo from '../../components/parts/sidebar/assets/img/Logo.webp';

// Utils
import { useLogin } from '../../hooks';
import usePostForgotPassword from '../../hooks/password/useForgotPassword';
import { handleLogin } from '../../utils';
import { VALIDATION_ERROR } from '../../config/constants';

// Schema
const Schema = object({
    email: string().email('Adresse e-mail invalide').required('Ce champ est obligatoire'),
});

function ForgetPasswordPage(){

    const [isError, setIsError] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    
    // Hook
    const mutation = usePostForgotPassword();
    const formik = useFormik({
        initialValues: {
            email : '',
        },
        validateOnChange: false,
        validationSchema: Schema,
        onSubmit : ( values ) => {
            console.log(values);
            setIsFetching(true);
            mutation.mutate(values, {
                onError: (error) => {
                    setIsError(true);
                    setErrorMessage(VALIDATION_ERROR);
                },
                onSuccess : (response) => {
                    console.log(response);
                    if(response.token){
                        setIsError(false);
                       
                    }
                },
                onSettled: () => {
                    setIsFetching(false);
                }
            })
        }
    });

    // Ref
    const emailRef = useRef(null);

    // onDidMount
    useEffect(() => {
        if( emailRef ){
            emailRef.current.focus();
        }
    }, []);

    const renderErrorBlock = () => {
        if( isError && errorMessage !== '' ){
            return (
                <Alert status='error'>
                    <AlertIcon color='white' />
                    <AlertDescription color='white'>{errorMessage}</AlertDescription>
                </Alert>
            )
        }
    }

    return (
        <Flex bg='dark.100' minHeight='100vh' alignItems='center'>
            <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
                <Center mb={6}><Image src={Logo} maxWidth={170} /></Center>
                <Stack paddingTop={8} paddingBottom={3} spacing="0" bg="dark.200">
                    <Stack spacing="6">
                        <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                            <Heading size={{ base: 'sm', md: 'md' }} color='white'>Mot de passe oublié ?</Heading>
                            <Text paddingX={4} color='white'>
                            Veuillez saisir votre adresse électronique. Nous vous enverrons un e-mail avec les instructions pour réinitialiser votre mot de passe.
                            </Text>
                        </Stack>
                    </Stack>
                    <Box
                        py={{ base: '4', sm: '8' }}
                        px={{ base: '4', sm: '10' }}
                        bg={{ base: 'transparent', sm: 'bg.surface' }}
                        boxShadow={{ base: 'none', sm: 'md' }}
                        borderRadius={{ base: 'none', sm: 'xl' }}
                    >
                    <form onSubmit={formik.handleSubmit}>
                        <Stack spacing="6">
                            { renderErrorBlock() }
                            <Stack spacing="5">
                                <FormControl isInvalid={formik.errors.email}>
                                    <FormLabel htmlFor="email" color='white'>Email</FormLabel>
                                    <Input 
                                        id = "email" 
                                        height = {42} 
                                        type = "email" 
                                        color = 'white' 
                                        value = {formik.values.email} 
                                        disabled = {isFetching}
                                        onChange = {formik.handleChange}  
                                        tabIndex={1}
                                        ref={emailRef}
                                    />
                                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                                </FormControl>
                            </Stack>
                            <Stack spacing="6">
                                <Button 
                                    type='submit'
                                    height = '44px' 
                                    bg = 'yellow.100' 
                                    fontWeight = {500}
                                    spinnerPlacement = 'end'
                                    isLoading = {isFetching}
                                    tabIndex={4}
                                    _hover = {{ bg: 'green.100' }} 
                                >Réinitialisez votre mot de passe</Button>
                            </Stack>
                        </Stack>
                    </form>
                    <Link to={`/`}>
                        <Text textColor="green.100" mt={6}>Révenir à la page de connexion</Text>
                    </Link>
                    </Box>
                </Stack>
               
            </Container>
        </Flex>
    )

}

export default ForgetPasswordPage