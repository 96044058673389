import React, { useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCreditCard } from 'react-icons/fa';
import { Icon, Box, Center, Stack, Text, Button, useToast } from '@chakra-ui/react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

// Context
import PermissionsContext from '../../../context/PermissionsContext';

// Hooks
import { useFetchUserProfile } from '../../../hooks/users';

function StripePayment({ orderId }){

    // states
    const [ isReady, setIsReady ] = useState(false);
    const [ isSaving, setIsSaving ] = useState(false);

    // hooks
    const toast = useToast();
    const stripe = useStripe();
    const navigate = useNavigate();
    const elements = useElements();
    const { data:user } = useFetchUserProfile();

    // context
    const { isGranted } = useContext(PermissionsContext);

    // callback
    const handlePayment = useCallback( () => {
        let userName = '';
        let userAdresse = '';
        if( user ){
            userName = `${user.firstname} ${user.lastname}`;
            userAdresse = `${user.address} ${user.country}`;
        }
        if( !isGranted(['ROLE_USER'])){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: 'Action non autorisée',
                description: "Vous n'avez pas les droits nécessaires pour faire cette action"
            });
            return false;
        }
        if( !stripe || !elements ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: 'Système de paiement',
                description: "Impossible de traiter la demande, Stripe n'est pas initialiser"
            });
            return false;
        }
        setIsSaving(true);
        stripe.confirmPayment({
            elements,
            redirect: 'if_required',
            confirmParams: {
                return_url:`${window.location.origin}/dashboard/profile/solde/thankyou`,
                payment_method_data: {
                    billing_details: {
                        address: {
                            country: 'FR',
                            line1: userAdresse
                        },
                        name: userName
                    }
                },
            }
        })
        .then( result => {
            // on success
            if( result.paymentIntent ){
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'success',
                    title: 'Achat de crédit',
                    description: 'Votre achat est effectué avec succès'
                });
                navigate('/dashboard/profile/transaction')
            }
            // onError
            if( result.error ){
                console.log(result.error)
                if(  result.error.type === "validation_error" ){
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'error',
                        title: 'Système de paiement',
                        description: 'Veuillez valider votre methode de paiement'
                    });
                }else{
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'error',
                        title: 'Système de paiement',
                        description: result.error.message
                    });
                }
            }
        } )
        .finally( () => {
            setIsSaving(false);
        } )
    }, [stripe, elements] );

    const renderStripe = () => {
        return (
            <PaymentElement onReady={ () => setIsReady(true) } />
        )
    }

    return (         
        <Box bg='white' padding='1.5rem' borderRadius={8}>
            { renderStripe() }
            {
                isReady && 
                <Center mt={10}>
                    <Button  
                        padding={6}
                        borderWidth={1} 
                        borderRadius={8} 
                        colorScheme='yellow' 
                        borderColor='white' 
                        isLoading={isSaving}
                        spinnerPlacement = 'end'
                        onClick={handlePayment}
                    >
                        <Stack direction='row' justifyContent='center' alignItems='center' spacing={4}>
                            <Text>Confirmer votre achat</Text>
                            <Icon as={FaCreditCard}/>
                        </Stack>
                    </Button>
                </Center>
            }
        </Box>
    )
}

export default StripePayment;