import React from 'react';
import { Box } from '@chakra-ui/react';

// Components
import DetailView from './view/DetailView';

// Global
import Template from '../../components/dashboard/Template';

// Context
function SaisieDetailPage () {

    return (
        <Template 
            callback={null}
            title='Detail du saisie'
            restrictedTo={['ROLE_ADMIN','ROLE_USER']}
        >
            <Box bg="dark.300" borderRadius={8} paddingY={30}> 
                <Box paddingX={{ base:2, lg: 8 }}>
                    <DetailView />
                </Box>
            </Box>
        </Template>
    )

}

export default SaisieDetailPage;