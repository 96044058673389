import React, { useContext } from 'react';
import { FormikContext } from 'formik';
import { 
    Box, 
    Skeleton,
    FormLabel,
    SimpleGrid,
    FormControl,
    FormErrorMessage 
} from '@chakra-ui/react';

// Components
import { InputField } from '../../../components/fields'
import SubTitle from '../../../components/SubTitle';

function Customer ({ values, errors, isFetching }){
    const savedClientId = localStorage.getItem('selectedClientId');

    const formik = useContext(FormikContext)

    return (
        <>
            {!savedClientId&&(
            <Skeleton isLoaded={!isFetching} minHeight={isFetching ? '180px' : 'auto'} borderRadius={8} mb={12}>
                <SubTitle style={{ fontSize: '20px', color: 'white', marginBottom: '24px' }}>Vos informations personnelles :</SubTitle>
                <SimpleGrid columns={2} spacing={6} marginTop={4} className='pack_variables_list'>
                    <Box className='pack_variable_item'>
                        <FormControl isInvalid={errors.firstname}>
                            <FormLabel htmlFor='label' color='#787675' textTransform='uppercase'>Nom</FormLabel>
                            <InputField 
                                name='firstname'
                                value={values.firstname}
                                onChange={formik.handleChange}
                            />
                            <FormErrorMessage>{errors.firstname}</FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box className='pack_variable_item'>
                        <FormControl isInvalid={errors.lastname}>
                            <FormLabel htmlFor='label' color='#787675' textTransform='uppercase'>Prénom</FormLabel>
                            <InputField 
                                name='lastname'
                                value={values.lastname}
                                onChange={formik.handleChange}
                            />
                            <FormErrorMessage>{errors.lastname}</FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box className='pack_variable_item'>
                        <FormControl isInvalid={errors.email}>
                            <FormLabel htmlFor='label' color='#787675' textTransform='uppercase'>Email</FormLabel>
                            <InputField 
                                name='email'
                                value={values.email}
                                onChange={formik.handleChange}
                            />
                            <FormErrorMessage>{errors.email}</FormErrorMessage>
                        </FormControl>
                    </Box>
                </SimpleGrid>
            </Skeleton>
            )}
        </>
    )
}

Customer.defaultProps = {
    isFetching : false
}

export default Customer;