import http from '../../utils/http'
import { useQuery } from '@tanstack/react-query';

export default function useFetchDocument(documentId){
 
    return useQuery({
        enabled: !!documentId,
        queryKey: ['fetchDocument', documentId],
        queryFn: async () => {
            if( documentId === undefined ){
                return null;
            }
            const response =  await http.get(`/documents/${documentId}`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false
    });
    
} 
