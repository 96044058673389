import React from 'react';
import { SimpleGrid, Box } from '@chakra-ui/react';
import SettingsItem from "./settingsItem"
import useFetchSettings from '../../../hooks/settings/useFetchSettings';

export const Settings = () => {
    const { data: settings, isFetching } = useFetchSettings();
    
    return (
        <>
            <SimpleGrid bg="dark.300" padding={4} columns={2} spacingX={8} marginTop={4} rounded={10}>
                {settings?.map(setting => (
                    <Box key={setting?.name ?? Math.random()}>
                        <SettingsItem
                            value={setting?.value ?? '---'}
                            name={setting?.name ?? '---'}
                            settingsId={setting?.id ?? ''}
                            patchFunction={()=>{}}
                        />
                    </Box>
                ))}
            </SimpleGrid>
        </>
    );
}
