import React, {  useState, useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import {useQueryClient} from "@tanstack/react-query";
import { useParams, useNavigate } from 'react-router-dom';
import {
    object, 
    string, 
    boolean 
} from 'yup';
import { 
    Box, 
    Stack, 
    Button, 
    Skeleton,
    FormLabel, 
    FormControl, 
    FormErrorMessage, 
    useToast
} from '@chakra-ui/react';

// Components
import { 
    InputField, 
    SwitchField,
    ColorPickerField 
} from '../../../components/fields/';
import SubTitle from '../../../components/SubTitle';
import NoContent from '../../../components/NoContent';

// Context
import PermissionsContext from '../../../context/PermissionsContext';

// Hooks
import { usePostCategory, useFetchCategory, usePatchCategory } from '../../../hooks/category';

// Constants
import {APP_ERROR, DENIED_ACTION_TITLE, DENIED_ACTION_ERROR} from "../../../config/constants";

// Schema 
const CategorySchema = object({
    publish : boolean(),
    name : string().required(),
    color : string().required(),
})

function CategoryForm(){

    // hooks
    const { categoryId } = useParams();
    const toast = useToast();
    const navigate = useNavigate();
    const queryClient = useQueryClient()

    // context
    const { isGranted } = useContext( PermissionsContext );

    // states
    const [ isSaving, setIsSaving ] = useState(false);
    const [ isEditMode, setIsEditMode ] = useState(false);

    const formik = useFormik({
        initialValues : {
            name : '',
            status: true,
            color : '#aabbcc',
        },
        validateOnChange : false,
        validationSchema: CategorySchema,
        onSubmit : (values) => {
            const categoryData = {
                name : values.name,
                color : values.color,
                status : values.status ? 1 : 0
            }
            saveCategory(categoryData);
        }
    });

    // hooks : Query
    const postCategory = usePostCategory();
    const patchCategory = usePatchCategory();
    const { data, isFetching} = useFetchCategory( categoryId );

    // lifeCycle
    useEffect( () => {

        if( data ){
            setIsEditMode(true);
            formik.setValues({
                name : data.name,
                color : data.color,
                status : data.status,
            })
        }

        return () => {
            setIsEditMode(false);
            formik.resetForm();
        }

    }, [ data ] );

    const updateCategoriesList = () => {
        formik.resetForm();
        setIsEditMode(false);
        navigate('/dashboard/category');
        queryClient.invalidateQueries({ queryKey: ['fetchCategories'] })
    }

    // callback
    const saveCategory = (categoryData) => {
        if( !isGranted(['ROLE_ADMIN', 'ROLE_USER']) ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: DENIED_ACTION_ERROR
            });
            return false;
        }
        setIsSaving(true);
        if( isEditMode ){
            updateCategory( categoryData );
        }else{
            createCategory( categoryData );
        }
    }

    const createCategory = (categoryData) => {
        postCategory
            .mutate( categoryData, {
                onSuccess: () => {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'success',
                        title: 'Catégorie crée avec succès',
                    });
                    updateCategoriesList()
                },
                onError : () => {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'error',
                        title: APP_ERROR
                    });
                },
                onSettled : () => setIsSaving(false)
            } )
    }

    const updateCategory = (categoryData) => {
        patchCategory
            .mutate({ categoryId, category : categoryData }, {
                onSuccess: () => {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'success',
                        title: 'Catégorie modifiée avec succès',
                    });
                    updateCategoriesList()
                },
                onError : () => {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'error',
                        title: APP_ERROR
                    });
                },
                onSettled : () => setIsSaving(false)
            })
    }
    const handleCancel = () => {
        formik.resetForm();
        setIsEditMode(false);
        navigate('/dashboard/category');
    }

    if( categoryId && !data && !isFetching ){
        return (
            <>
                <NoContent 
                    showLink = { true }
                    link = '/dashboard/category'
                    linkText = 'Créer une catégorie'
                    message = 'Catégorie introuvable'
                />
            </>
        )
    }

    return (
        <Box>
            <SubTitle>{ isEditMode ? 'Modifier une catégorie' : 'Ajouter une catégorie' }</SubTitle>
            <form onSubmit={formik.handleSubmit}>
                <Stack spacing={6} marginTop={4} >
                    <FormControl isInvalid={formik.errors.name}>
                        <FormLabel htmlFor='name' color='#787675'>Nom</FormLabel>
                        <Skeleton isLoaded={!isFetching} borderRadius={8}>
                            <InputField 
                                name='name'
                                value={formik.values.name}
                                onChange={formik.handleChange}
                            />
                        </Skeleton>
                        <FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>
                    </FormControl>
                    {/* End Nom */}
                    <FormControl isInvalid={formik.errors.color}>
                        <FormLabel htmlFor='name' color='#787675'>Couleur</FormLabel>
                        <Skeleton isLoaded={!isFetching} borderRadius={8}>
                            <ColorPickerField 
                                color={formik.values.color} 
                                onChange={ (color) => formik.setFieldValue('color', color) }
                            />
                        </Skeleton>
                        <FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>
                    </FormControl>
                    {/* End Couleur */}
                    <SwitchField 
                        name='status'
                        label='Publier'
                        onChange={formik.handleChange}
                        value={Boolean(formik.values.status)}
                    />
                    {/* End Status */}
                    <Button 
                        type='submit'
                        height = '44px' 
                        bg = 'yellow.100' 
                        fontWeight = {500}
                        spinnerPlacement = 'end'
                        isLoading = {isSaving}
                        _hover = {{ bg: 'green.100' }} 
                    >{ isEditMode ? 'Mettre à jour' : 'Enregistrer' }</Button>
                    {/* End Submit */}
                    {isEditMode && (
                        <Button
                            height='44px'
                            bg='gray.100'
                            fontWeight={500}
                            _hover={{ bg: 'red.100' }}
                            onClick={handleCancel}
                        >Annuler</Button>
                    )}
                </Stack>
            </form>
        </Box>
    )
}

export default CategoryForm