import http from '../../utils/http'
import { useQuery } from '@tanstack/react-query';

export default function useFetchCategory(categoryId){
 
    return useQuery({
        enabled: !!categoryId,
        queryKey: ['fetchCategory', categoryId],
        queryFn: async () => {
            const response =  await http.get(`/document_categories/${categoryId}`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false
    });
    
} 
