import http from '../../utils/http'
import { useMutation,useQueryClient } from '@tanstack/react-query';

const postPack = async(pack) => {
    const response = await http.post('/packs', pack);
    return response.data;
}

export default function usePostPack(){
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey : ['postPack'],
        mutationFn : (pack) => postPack(pack),
        onSuccess: () => {
            queryClient.invalidateQueries(['fetchPacks']);
          },
    })
    
}