import React, { useContext, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FaFolderOpen } from 'react-icons/fa';
import { 
    Box, 
    Text, 
    Icon,
    Button,
    Stack,
    Image,
    Skeleton
} from '@chakra-ui/react';

// Icons
import PdfIcon from '../../../assets/svg/pdf.svg';
import DocIcon from '../../../assets/svg/docx.svg';
import XlsIcon from '../../../assets/svg/xlsx.svg';

// Context
import PackDetailContext from '../../../context/PackDetailContext';

// Components
import SubTitle from '../../../components/SubTitle';

const DocumentItem = forwardRef( ({ document }, ref) => {

    const renderIcon = () => {
        let icon  = null
        switch( document.type ){
            case 'pdf':
                icon = PdfIcon;
                break;
            case 'docx' :
                icon = DocIcon;
                break;
            default:
                icon = XlsIcon;
        }
        if( icon ){
            return <Image width='22px' src={icon} />
        }
        return null
    }
    return(
        <Stack direction='row' justifyContent='space-between' padding={3} bg='white' borderRadius={8} className='pack_docs_item'>
            <Stack direction='row' alignItems='center'>
                { renderIcon() }
                <Text color='dark.200'>{ document.name }</Text>
            </Stack>
        </Stack>
    )

} )

function Documents ({ isSaving, isFetching }){

    const pack = useContext( PackDetailContext );

    const renderListeDocument = () => {
        if( !pack?.documents ){
            return (
                <p>Aucun document</p>
            )
        }
        return (
            <Stack spacing={2} mb={10} className='pack_docs_list'>
                { pack?.documents.map( (document) => (
                    <DocumentItem
                        key={document.id}
                        document={document}
                    />
                ) ) }
            </Stack>
        ) 
    }

    return (
        <Skeleton isLoaded={!isFetching} borderRadius={8}>
            <Stack direction='column' spacing={6}>
                <Box bg="dark.100" borderRadius={8} padding='26px'>
                    <SubTitle style={{ marginBottom: '15px' }}>Liste des documents inclu(s) :</SubTitle>
                    { renderListeDocument() }
                    <Button 
                        type='submit'
                        padding={6} 
                        width='100%' 
                        bg='#a4c6e0' 
                        borderWidth={1} 
                        borderRadius={8} 
                        borderColor='white' 
                        isLoading={isSaving}
                        _hover={{ bg: '#3fa7f6' }} 
                        spinnerPlacement = 'end'
                        rightIcon={<Icon as={FaFolderOpen} />}
                    >Recevoir mes docs</Button>
                </Box>
            </Stack>
        </Skeleton>
    )

}

Documents.defaultProps = {
    isSaving : false,
    isFetching : false
}

Documents.propTypes = {
    isSaving : PropTypes.bool,
    isFetching : PropTypes.bool,
}

export default Documents;