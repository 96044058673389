import React, {  useState, useEffect } from 'react';
import { HexColorPicker } from 'react-colorful';
import { 
    Box,
    Text,
    Stack,
    Popover,
    PopoverTrigger,
    PopoverContent,
} from '@chakra-ui/react';

function ColorPickerField({ color, onChange }){

    const [ couleur, setCouleur ] = useState();

    useEffect( () => {
        setCouleur( color )
    }, [color] );

    const handleColorPicker = (colorPicked) => {
        setCouleur( colorPicked );
        if( typeof( onChange ) === 'function' ){
            onChange( colorPicked );
        }
    };

    return (
        <Box position='relative'>
            <Popover>
                <PopoverTrigger>
                    <Stack direction='row' bg='dark.100' justifyContent='flex-start' alignItems='center' borderRadius={8} padding='10px' spacing={4}>
                        <Box borderRadius='8px' borderWidth='3px' borderColor='white' bg={couleur} style={{ width: '28px', height: '28px' }}/>
                        <Text color='white' fontWeight={600}>Sélectionner une couleur</Text>
                    </Stack>
                </PopoverTrigger>
                <PopoverContent width='auto'>
                    <HexColorPicker color={couleur} onChange={handleColorPicker} />
                </PopoverContent>
            </Popover>
        </Box>
    )

}

export default ColorPickerField;