import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Select } from '@chakra-ui/react';
import useFetchClients from '../../../hooks/clients/useFetchClients';

function ClientSelect({ isLoading, onChange }) {
    const { data: clients, isFetching } = useFetchClients();
    const [selectedClientId, setSelectedClientId] = useState('');
    useEffect(() => {
        const savedClientId = localStorage.getItem('selectedClientId');
        if (savedClientId) {
            setSelectedClientId(savedClientId);
            onChange(savedClientId);
        }
    }, [onChange]);

    const handleChange = (event) => {
        const selectedClientId = event.target.value;
        setSelectedClientId(selectedClientId);
        localStorage.setItem('selectedClientId', selectedClientId); 
        onChange(selectedClientId);
    };

    return (
        <Skeleton isLoaded={!isLoading && !isFetching} borderRadius={8}>
            <Select 
                name='client'
                height='48px'
                color='#fff'
                bg='dark.100'
                borderRadius={8}
                borderColor='dark.100'
                onChange={handleChange}
                placeholder='Choisissez un client'
                value={selectedClientId}
            >
                {clients && clients.map(client => (
                    <option key={client.id} value={client.id}>
                        {client.firstname} {client.lastname}
                    </option>
                ))}
            </Select>
        </Skeleton>
    );
}

ClientSelect.defaultProps = {
    isLoading: false,
    onChange: () => {},
};

ClientSelect.propTypes = {
    isLoading: PropTypes.bool,
    onChange: PropTypes.func,
};

export default ClientSelect;
