import React, { useState, useContext, useEffect } from "react";
import chroma from "chroma-js";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { FaPencilAlt } from 'react-icons/fa';
import { BsCheckCircleFill } from 'react-icons/bs';
import { Icon, Box, Flex, Text, VStack, HStack } from "@chakra-ui/react";

// Context
import PackSelectContext from "../../../context/PackSelectContext";

// Middleware
import { addDocToPack, removeDocToPack } from "../../../actions/pack/PackAction";

// Styled
const Button = styled(Link)`
    font-size: 14px;
    line-height: 24px;
    color: #1C1C1C;
    padding: 0;
    width: 34px;
    height: 34px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 36px;
    background: #A4C5E0;
    opacity: 0;
    visibility: hidden;
    transition: all 400ms ease-in-out;
    &:hover,
    &:focus {
        background: #9FC77E;
    }
`;

const UnCheckButton = styled.button`
    padding: 0;
    border: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 400ms ease-in-out;
`;

const CheckedButton = styled.button`
    padding: 0;
    border: 0;
`;

const Circle = styled.span`
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${props => props.bg};
`;

export function CategoryTag({ name, color }) {
    const bgColor = chroma(color).darken(2).alpha(0.2).hex();
    return (
        <Box padding={2}>
            <Text
                fontSize={16}
                color={color ? color : 'yellow.100'}
                padding='3px 18px'
                borderRadius={20}
                background={bgColor}
            >
                <Circle bg={color ? color : '#edc755'} />{name}
            </Text>
        </Box>
    );
}

function DocumentItem({ doc, preview, addDocument, removeDocument, documentSelected }) {

    const { id, name, color, category } = doc;
    const link = `/dashboard/document/edit/${id}`;

    // state
    const [isSelected, setIsSelected] = useState(false);

    // context
    const { select } = useContext(PackSelectContext);

    useEffect(() => {
        const document = documentSelected.filter(item => item.id === id);
        setIsSelected(document.length > 0);
    }, [documentSelected, id]);

    const handleClick = (e) => {
        // Prevent triggering the container's click event when clicking the button
        if (e.target.closest('.docEdit')) {
            return;
        }
        if (isSelected) {
            removeDocToPack();
        } else {
            addDocToPack();
        }
    }

    const addDocToPack = () => {
        if (id && !preview) {
            addDocument(doc);
        }
    }

    const removeDocToPack = () => {
        if (id && !preview) {
            removeDocument(doc);
        }
    }

    const renderBadge = () => {
        if (isSelected) {
            return (
                <CheckedButton type="button" onClick={removeDocToPack} className="docEdit" style={{ cursor: preview ? 'default' : 'pointer' }} >
                    <Icon as={BsCheckCircleFill} fontSize='30px' color='green.100' />
                </CheckedButton>
            );
        }

        if (select) {
            return (
                <UnCheckButton type="button" onClick={addDocToPack} className="docEdit" style={{ cursor: preview ? 'default' : 'pointer' }} >
                    <Icon as={BsCheckCircleFill} fontSize='30px' color='green.100' />
                </UnCheckButton>
            );
        }

        return (
            <Button to={link} className="docEdit">
                <Icon size={30} as={FaPencilAlt} />
            </Button>
        );
    }

    return (
        <Box className="document_item" onClick={handleClick}>
            <VStack
                bg='dark.100'
                height={192}
                borderRadius={12}
                padding='10px'
                overflow='hidden'
                alignItems='flex-start'
                justifyContent='space-between'
                borderWidth={2}
                borderColor={isSelected ? 'green.100' : 'dark.100'}
                boxShadow={isSelected ? '0px 0 15px rgb(159 199 126 / 34%)' : 'none'}
            >
                <HStack width='100%' justifyContent='flex-end'>
                    {renderBadge()}
                </HStack>
                <CategoryTag name={category} color={color} />
            </VStack>
            <Flex justifyContent='space-between' alignItems='center' mt={18} gap={5}>
                {name &&
                    <Text
                        fontSize={16}
                        color='white'
                        whiteSpace='nowrap'
                        maxWidth='145px'
                        overflow='hidden'
                    >{name}</Text>
                }
            </Flex>
        </Box>
    );
}

DocumentItem.propTypes = {
    preview: PropTypes.bool,
    doc: PropTypes.object.isRequired,
    addDocument: PropTypes.func.isRequired,
    removeDocument: PropTypes.func.isRequired,
    documentSelected: PropTypes.array.isRequired,
};

DocumentItem.defaultProps = {
    preview: false
};

const mapStateToProps = (state) => {
    return {
        documentSelected: state.packs.docs
    }
}

const mapDispatchToProps = {
    addDocument: addDocToPack,
    removeDocument: removeDocToPack
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentItem);
