import React from 'react';
import { Badge } from '@chakra-ui/react';

const TransactionStatusBadge = (status) => {
    let statusText;
    let colorScheme;

    switch (status) {
        case 0:
            statusText = 'en cours';
            colorScheme = 'yellow';
            break;
        case 1:
            statusText = 'payé';
            colorScheme = 'green';
            break;
        case 2:
            statusText = 'échoué';
            colorScheme = 'red';
            break;
        default:
            statusText = 'Unknown';
            colorScheme = 'gray';
            break;
    }

    return (
        <Badge display="flex" alignItems="center" minWidth="80px" fontSize={12} justifyContent="center" colorScheme={colorScheme}>
            {statusText}
        </Badge>
    );
}

export default TransactionStatusBadge;
