import React, { useCallback } from 'react';
import _ from 'lodash'
import { Input } from '@chakra-ui/react';

function InputField ({ name, min, type, value, group, disabled, maxLength, onChange,placeholder }) {

    const handleChange = useCallback( (evt) => {
        const length = evt.target.value.length;
        if( length > maxLength ){
            return false;
        }
        if(_.isFunction(onChange)){
            onChange(evt);
        }
    }, [] );

    return (
        <Input 
            id={name}
            min={min}
            name={name}
            type={type}
            value={value}
            color='#fff'
            height='48px'
            borderRadius={8}
            paddingRight='16px'
            borderColor='#323232'
            disabled={disabled}
            maxLength={maxLength}
            onChange={handleChange}
            paddingLeft={ group ? '40px' : '16px' }
            placeholder={placeholder}
            style ={{fontSize:"12px"}}
        />
    )
}


InputField.defaultProps ={
    min: '',
    type : 'text',
    group : false,
    disabled: false
}

export default InputField;