import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import { isLoggedIn } from '../utils';

function PublicRoutes({ children }){

    const isLoggin = isLoggedIn();
    const { pathname } = useLocation();

    if( pathname === '/' && isLoggin ){
        return (
            <Navigate to='/dashboard' /> 
        )
    }

    return(
        <>
            { pathname === '/' && !isLoggin && children }
            { pathname !== '/' && children }
        </>
    )

}

export default PublicRoutes;