import http from '../../utils/http'
import { useQuery } from '@tanstack/react-query';

export default function useGetSetting(settingName){
 
    return useQuery({
        queryKey: ['getSetting', settingName],
        queryFn: async () => {
            if( settingName === undefined ){
                return null;
            }
            const response =  await http.get(`/settings_by_name/${settingName}`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false

    });
    
} 
