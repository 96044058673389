import React, { useContext } from "react";
import PropsTypes from 'prop-types';

// Context
import PermissionsContext from "../context/PermissionsContext";

function Restricted({ to, children }){

    const { isGranted } = useContext( PermissionsContext );
    const isAllowed = isGranted( to );

    if( !isAllowed ){
        return null;
    }

    return(
        <>{ children }</>
    )

}

Restricted.propTypes = {
    to : PropsTypes.array.isRequired
}

export default Restricted;