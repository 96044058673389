import http from '../../utils/http'
import { useQuery } from '@tanstack/react-query';

export default function useFetchDocByCategory(categoryId){
 
    return useQuery({
        queryKey: ['fetchDocByCategory', categoryId],
        queryFn: async () => {
            if( categoryId === undefined ){
                return null;
            }
            const response =  await http.get(`/document_categories/${categoryId}/documents?pagination=false&order[createdAt]=desc&status=1`);
            return response.data['hydra:member'];
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false
    });
    
} 
