import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';

// Components
import Editor from './Editor';
import Callback from './callback/Callback';

// Global
import Template from '../../components/dashboard/Template';

// Context
import TinyMceContext from '../../context/TinyMceContext';
import TinyForHeaderMceContext from '../../context/TinyForHeaderContext';
import TinyForFooterMceContext from '../../context/TinyForFooter';
import TypeDocumentContext from '../../context/TypeDocumentContext';

function DocumentCreatePage() {
    // States for each TinyMCE instance
    const [tinyHeaderIns, setTinyHeaderIns] = useState(null);
    const [tinyContentIns, setTinyContentIns] = useState(null);
    const [tinyFooterIns, setTinyFooterIns] = useState(null);
    const [typeDoc, setTypeDoc] = useState('pdf');

    // Context values
    const tinyHeader = { tinyInstance: tinyHeaderIns, setTinyInstance: setTinyHeaderIns };
    const tinyContent = { tinyInstance: tinyContentIns, setTinyInstance: setTinyContentIns };
    const tinyFooter = { tinyInstance: tinyFooterIns, setTinyInstance: setTinyFooterIns };
    const type = { typeDocument: typeDoc, setTypeDocument: setTypeDoc };

    return (
        <>
            <TinyForHeaderMceContext.Provider value={tinyHeader}>
                <TinyForFooterMceContext.Provider value={tinyFooter}>
                    <TinyMceContext.Provider value={tinyContent}>
                        <TypeDocumentContext.Provider value={type}>
                            <Template
                                title='Documents'
                                callback={<Callback />}
                                restrictedTo={['ROLE_ADMIN', 'ROLE_USER']}
                            >
                                <Box bg="dark.300" borderRadius={8} padding='26px'>
                                    <Editor />
                                </Box>
                            </Template>
                        </TypeDocumentContext.Provider>
                    </TinyMceContext.Provider>
                </TinyForFooterMceContext.Provider>
            </TinyForHeaderMceContext.Provider>
        </>
    );
}

export default DocumentCreatePage;
