import React, { useState, useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import {
    object,
    string
} from 'yup';
import {
    Box,
    Stack,
    Button,
    useToast,
    Skeleton,
    FormLabel,
    SimpleGrid,
    FormControl,
    FormErrorMessage,
} from '@chakra-ui/react';

// Context
import PermissionsContext from '../../../context/PermissionsContext';

// Hooks
import { usePostUser, usePatchUser, useFetchProfile } from '../../../hooks/users';

// Components
import SubTitle from '../../../components/SubTitle';
import NoContent from '../../../components/NoContent';
import { InputField, PasswordField } from '../../../components/fields';

// Constants
import { APP_ERROR, DENIED_ACTION_ERROR, DENIED_ACTION_TITLE } from "../../../config/constants";

// Schema 
const credentialSchema = object({
    password: string().min(4, 'Longueur minimum : 4 caractères').required('Ce champ est obligatoire'),
    email: string().email('Veuillez ajouter une adresse e-mail valide').required('Ce champ est obligatoire'),
});

const userSchema = object({
    address: string(),
    city: string(),
    country: string(),
    postalCode: string(),
    lastname: string().required(),
    firstname: string().required()
});

function UserForm() {

    // states
    const [isSaving, setIsSaving] = useState(false);

    // hooks
    const toast = useToast();
    const postQuery = usePostUser();
    const patchQuery = usePatchUser();

    const { userId } = useParams();
    const { data, error, isFetching } = useFetchProfile(userId);

    // context
    const { isGranted } = useContext(PermissionsContext);

    // formik
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            city: '',
            country: '',
            address: '',
            lastname: '',
            firstname: '',
            postalCode: '',
        },
        validateOnChange :false,
        validationSchema: () => {
            if (userId === undefined) {
                return userSchema.concat(credentialSchema);
            }
            return userSchema;
        },
        onSubmit: (values) => {
            const userData = { ...values, postalCode: values.postalCode?.toString() }
            saveUser(userData);
        },
    });

    // lifecycle
    useEffect(() => {

        if (data) {
            formik.setValues({
                city: data.city,
                country: data.country,
                address: data.address,
                lastname: data.lastname,
                firstname: data.firstname,
                postalCode: data.postalCode
            })
        }

    }, [data]);

    // callbacks
    const saveUser = (userData) => {
        if (!isGranted(['ROLE_ADMIN','ROLE_PROFESSIONAL'])) {
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: DENIED_ACTION_ERROR
            })
            return false;
        }
        setIsSaving(true);
        if (userId !== undefined) {
            updateUser(userData);
        } else {
            createUser(userData);
        }
    }

    const createUser = (userData) => {
        postQuery.mutate(userData, {
            onSuccess: (response) => {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'success',
                    title: 'Utilisateur créé',
                });
                formik.resetForm();
            },
            onError: (error) => {
                if (error.response.data['status'] == 500) {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'error',
                        title: "Cet email est déjà utilisé , choisissez un autre email"
                    })
                } else {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'error',
                        title: APP_ERROR,
                    })
                }
               
            },
            onSettled: () => {
                setIsSaving(false);
            }
        })
    }

    const updateUser = (userData) => {
        delete userData.email;
        delete userData.password;
        patchQuery.mutate({ userId, user: userData }, {
            onSuccess: (response) => {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'success',
                    title: 'Compte utilisateur mis à jour',
                })
            },
            onError: (e) => {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'error',
                    title: APP_ERROR,
                })
            },
            onSettled: () => {
                setIsSaving(false);
            }
        })
    }

    if (error) {
        return (
            <NoContent />
        )
    }

    return (
        <Box maxWidth='60%' margin='auto' bg='#111010' padding='30px' borderRadius='10px'>
            <SubTitle>{userId !== undefined ? 'Modifier un compte' : 'Un nouveau compte'} :</SubTitle>
            <form onSubmit={formik.handleSubmit} style={{ marginTop: 10 }} noValidate >
                <SimpleGrid columns={2} spacing={6} marginTop={4} >
                    <FormControl isInvalid={formik.errors.firstname}>
                        <FormLabel htmlFor='firstname' color='#787675'>Nom *</FormLabel>
                        <Skeleton isLoaded={!isFetching} borderRadius={8}>
                            <InputField
                                name='firstname'
                                value={formik.values.firstname}
                                onChange={formik.handleChange}
                            />
                        </Skeleton>
                        <FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>
                    </FormControl>
                    {/* End Nom */}
                    <FormControl isInvalid={formik.errors.lastname}>
                        <FormLabel htmlFor='lastname' color='#787675'>Prénom *</FormLabel>
                        <Skeleton isLoaded={!isFetching} borderRadius={8}>
                            <InputField
                                name='lastname'
                                value={formik.values.lastname}
                                onChange={formik.handleChange}
                            />
                        </Skeleton>
                        <FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>
                    </FormControl>
                    {/* End Prénom */}
                </SimpleGrid>
                {/* Show only on create user */}
                {
                    (undefined === userId || null === userId) &&
                    <SimpleGrid columns={2} spacing={6} marginTop={4} >
                        <FormControl isInvalid={formik.errors.email}>
                            <FormLabel htmlFor='email' color='#787675'>E-mail *</FormLabel>
                            <Skeleton isLoaded={!isFetching} borderRadius={8}>
                                <InputField
                                    name='email'
                                    type='email'
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                />
                            </Skeleton>
                            <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                        </FormControl>
                        {/* End E-mail */}
                        <PasswordField
                            disabled={isFetching}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.password}
                            errorMessage={formik.errors.password}
                        />
                        {/* End Mot de passe */}
                    </SimpleGrid>
                }
                <SimpleGrid columns={2} spacing={6} marginTop={4} >
                    <FormControl isInvalid={formik.errors.postalCode}>
                        <FormLabel htmlFor='postalCode' color='#787675'>Code postal</FormLabel>
                        <Skeleton isLoaded={!isFetching} borderRadius={8}>
                            <InputField
                                maxLength={5}
                                type='number'
                                name='postalCode'
                                value={formik.values.postalCode}
                                onChange={formik.handleChange}
                            />
                        </Skeleton>
                        <FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>
                    </FormControl>
                    {/* End Code postal */}
                    <FormControl isInvalid={formik.errors.address}>
                        <FormLabel htmlFor='address' color='#787675'>Adresse</FormLabel>
                        <Skeleton isLoaded={!isFetching} borderRadius={8}>
                            <InputField
                                name='address'
                                value={formik.values.address}
                                onChange={formik.handleChange}
                            />
                        </Skeleton>
                        <FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>
                    </FormControl>
                    {/* End Adresse */}
                </SimpleGrid>
                <SimpleGrid columns={2} spacing={6} marginTop={4} >
                    <FormControl isInvalid={formik.errors.city}>
                        <FormLabel htmlFor='city' color='#787675'>Ville</FormLabel>
                        <Skeleton isLoaded={!isFetching} borderRadius={8}>
                            <InputField
                                name='city'
                                value={formik.values.city}
                                onChange={formik.handleChange}
                            />
                        </Skeleton>
                        <FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>
                    </FormControl>
                    {/* End Ville */}
                    <FormControl isInvalid={formik.errors.country}>
                        <FormLabel htmlFor='country' color='#787675'>Pays</FormLabel>
                        <Skeleton isLoaded={!isFetching} borderRadius={8}>
                            <InputField
                                name='country'
                                value={formik.values.country}
                                onChange={formik.handleChange}
                            />
                        </Skeleton>
                        <FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>
                    </FormControl>
                    {/* End Pays */}
                </SimpleGrid>
                <Stack justifyContent='center' alignItems='center' mt={8} width={"100%"} >
                    <Button
                        type='submit'
                        height='44px'
                        bg='yellow.100'
                        width="100%"
                        fontWeight={500}
                        spinnerPlacement='end'
                        isLoading={isSaving}
                        _hover={{ bg: 'green.100' }}
                    >{userId !== undefined ? 'Mettre à jour' : 'Enregistrer'}</Button>
                </Stack>
            </form>
        </Box>
    )
}

export default UserForm;