import http from '../../utils/http';
import { useMutation,useQueryClient } from '@tanstack/react-query';

const patchDocument = async(documentId, document) => {

    const response = await http.patch(`/documents/${documentId}`, document);
    return response.data;
}

export default function usePatchDocument(){
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey : ['patchDocument'],
        mutationFn : ({ documentId, document }) => patchDocument(documentId, document),
        onSuccess: (documentId) => {
            queryClient.invalidateQueries(['fetchDocument', documentId]);
          },
    })

}