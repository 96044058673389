import React, { useContext,useState,useRef } from "react";
import PropTypes from 'prop-types';
import { FormikContext } from "formik";
import { Stack, Skeleton, Textarea, FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import { Editor } from '@tinymce/tinymce-react';

// Context
import TinyMceContext from "../../../context/TinyMceContext";

import TypeDocumentContext from "../../../context/TypeDocumentContext";

// Components
import Save from "./Save";
import TextEditor from "./TextEditor";
import ClausesDoc from "./ClausesDoc";
import Information from "./Information";
import SheetEditor from "./SheetEditor";
import VariablesDoc from "./VariablesDoc";
import VariableInexist from "./VariableInexist";
import TinyForFooterMceContext from "../../../context/TinyForFooter";

function Content({ isSaving, isEditMode, isFetching }){

    // hooks
    const footerRef = useRef(null);
    
    // context
    const { setTinyInstance } = useContext(TinyForFooterMceContext);
    
    // states
    const [editorIsReady, setEditorIsReady] = useState(false);

    const formik = useContext(FormikContext)
    const { typeDocument } = useContext(TypeDocumentContext);

    return(
        <Stack id="documentEditor" direction='column' spacing={6} position='relative'>
            <Information isFetching={isFetching} />
            { 
                typeDocument === 'xlsx' ? 
                    <SheetEditor isFetching={isFetching} /> 
                    : 
                    <TextEditor isFetching={isFetching} /> 
            }
            {
                typeDocument !== 'xlsx' &&
                <Stack>
                    <FormControl isInvalid={formik.errors.footer}>
                        <FormLabel htmlFor='category' fontSize='18px' color='blue.100'>Pied de page</FormLabel>
                        <Skeleton isLoaded={!isFetching} borderRadius={8}>
                            <Editor
                                ref={footerRef}
                                initialValue={formik.values.footer}
                                apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                                onInit={(evt, editor) => {
                                    setEditorIsReady(true);
                                    footerRef.current = editor;
                                    setTinyInstance(editor);
                                }}
                                init={{
                                    height: 200,
                                    language: 'fr_FR',
                                    plugins: 'autolink charmap image link lists searchreplace table wordcount',
                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table  | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                }}
                            />
                            <FormErrorMessage mt={3}>{formik.errors.footer}</FormErrorMessage>
                                {/*
                            <Textarea 
                                id="footer"
                                name='footer'
                                type="text"
                                height='120px'
                                color='#fff'
                                resize='none'
                                borderRadius={8}
                                borderColor='dark.300'
                                backgroundColor='dark.300'
                                value={formik.values.footer}
                                onChange={formik.handleChange}
                            >{formik.values.footer}</Textarea>
                             */}
                        </Skeleton>
                        <FormErrorMessage>{formik.errors.footer}</FormErrorMessage>
                    </FormControl>
                </Stack>
            }
            <Stack>
                <FormControl isInvalid={formik.errors.description}>
                    <FormLabel htmlFor='category' fontSize='18px' color='blue.100'>Description courte</FormLabel>
                    <Skeleton isLoaded={!isFetching} borderRadius={8}>
                        <Textarea 
                            id="description"
                            name='description'
                            type="text"
                            height='120px'
                            color='#fff'
                            resize='none'
                            borderRadius={8}
                            borderColor='dark.300'
                            backgroundColor='dark.300'
                            value={formik.values.description}
                            onChange={formik.handleChange}
                        >{formik.values.description}</Textarea>
                    </Skeleton>
                    <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
                </FormControl>
            </Stack>
            <VariableInexist/>
            <VariablesDoc />
            <ClausesDoc />
            <Save 
                isSaving={isSaving} 
                isEditMode={isEditMode}
            />
        </Stack>
    )

}

Content.defaultProps = {
    isSaving : false,
    isEditMode : false,
    isFetching : false
}

Content.propTypes = {
    isSaving : PropTypes.bool.isRequired,
    isEditMode : PropTypes.bool.isRequired,
    isFetching : PropTypes.bool.isRequired
}

export default Content;