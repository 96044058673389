import http from '../../utils/http'
import { useMutation,useQueryClient } from '@tanstack/react-query';


const patchClause = async(clauseId, clause) => {
    const response = await http.patch(`/clauses/${clauseId}`, clause);
    return response.data;
}

export default function usePatchClause(){
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey : ['patchClause'],
        mutationFn : ({ clauseId, clause }) => patchClause(clauseId, clause),
        onSuccess: () => {
            queryClient.invalidateQueries(['fetchClauses']);
          },
    })

}