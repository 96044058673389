
import http from '../../utils/http'
import { useMutation} from '@tanstack/react-query';

const postMediaObject = async(media) => {

    const response = await http.post('/media_objects', media, );
    return response.data;
}

export default function usePostMediaOject(){
    return useMutation({
        mutationKey : ['postMedia'],
        mutationFn : (media) => postMediaObject(media),
        onSuccess: (response) => {
            return response
          },
    })
    
}