import axios from 'axios';
import { COOKIE_NAME, TOKEN_NAME, SESSION_NAME, SESSION_EXPIRATION_DAYS } from '../config/constants';

// Set token
export const setToken = ( token ) => {
    localStorage.setItem( TOKEN_NAME, JSON.stringify(token) );
    return true;
}

// Get token from session
export const getToken = () => {
    const token = JSON.parse( localStorage.getItem(TOKEN_NAME) );
    return token?.token;
}

function removeToken() {
    localStorage.removeItem(TOKEN_NAME)
}

// Check if token is expired
export const isExpiredToken = ( token ) => {
    const now = new Date().getTime();
    const expirTime = token?.exp ? token.exp : 0;
    return now > expirTime;
}

// Revalidate Token
export const revalidateToken = async () => {
    const token = getTokenUser();
    try{
        const response = await axios.post(
             getApiUrl('/token/refresh'),
            { refresh_token : token?.refresh_token },
            {
                headers : {
                    'Content-Type': 'application/json'
                    }
                }
            );
            if(response.status === 200){
                let userData = getSessionUser()
                userData = {
                    ...userData,
                    exp : getTokenExpirationTime()
                }
                setToken( response.data )
                setCookie(COOKIE_NAME, userData, SESSION_EXPIRATION_DAYS)
                return response.data;
            }
            return  null
        }catch(e){
            console.error('Impossible de revalider la session');
            window.location.href = '/login';
        }
    return null
}

// Get Token expiration time
export const getTokenExpirationTime = (hours = 1) => {
    const now = new Date();
    return now.setHours( now.getHours() + hours);
}

// Get Token Info
export const getTokenUser = () => {
    return JSON.parse( localStorage.getItem(TOKEN_NAME) );
}

export const getSessionUser = () => {
    return getCookie(COOKIE_NAME)
}

// Get cookie
export const getCookie = (name) => {
    const cookies = document.cookie.split("; ")
    const value = cookies.find(c => c.startsWith(`${name}=`))?.split("=")[1]
    return (value === undefined) ? null : JSON.parse(decodeURIComponent(atob(value)))
}

// Set Cookie
export const setCookie = (name, value, expiracyDays = SESSION_EXPIRATION_DAYS) => {
    const date = new Date()
    date.setDate(date.getDate() + expiracyDays)
    document.cookie = `${name}=${btoa(encodeURIComponent(JSON.stringify(value)))}; expires=${date.toUTCString()};`
}

// Expire Cookie
export const expiresCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

export const isLoggedIn = () => {
    return getToken() !== null && getCookie(COOKIE_NAME) !== null
}

// Handle Login
export const handleLogin = (userData, tokenData) => {
    console.log(userData);
    setToken(tokenData);
    setCookie(COOKIE_NAME, userData, SESSION_EXPIRATION_DAYS)
}

// Handle Logout
export const handleLogout = () => {
    removeToken();
    expiresCookie(COOKIE_NAME)
}

// Get header
export const getHeader = () => {
    return {
        headers : {
            'Content-Type': 'application/ld+json'
        }
    }
}

// Get patch header
export const getPatchHeader = () => {
   return {
       headers: {
            'Content-Type': 'application/merge-patch+json'
       }
   }
}

// Get Api url
export const getApiUrl = (endPoint = null) => {
    let url = process.env.REACT_APP_API_URL;
    if( endPoint !== null ){
        url += endPoint;
    }
    return url;
}

// Get Base Api url
export const getBaseApiUrl = (endPoint = null) => {
    console.log(process.env.REACT_APP_API_BASE_URL);
    let url = process.env.REACT_APP_API_BASE_URL;
    if( endPoint !== null ){
        url += endPoint;
    }
    return url;
}

// Check if current user is Granted
export const isGranted = (role) => {
    const currentUser = getSessionUser();
    if( role === undefined || role === null ){
        return false;
    }
    const requireRole = role;
    const userRole = currentUser.roles;
    return requireRole.some( role => userRole.includes( role ) );
}