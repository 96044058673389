import React from 'react';
import { Box, Flex, Text, Image, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

// Assets
import PlusIcon from './assets/img/Plus.svg';

function MenuAction(){
    return(
        <Box className='info_item'>
            <Menu isLazy>
                <MenuButton>
                    <Flex bg="#504C4C" height={34} borderRadius={34} paddingX='4px' paddingY='4px' alignItems='center' gap='8px' paddingRight={3}>
                        <Image src={PlusIcon} maxWidth='24px' />
                        <Text color='#EFEFF5' fontSize={14}>Créer</Text>
                    </Flex>
                </MenuButton>
                <MenuList bg='dark.200' padding={0} border={0}>
                    <MenuItem as={Link} to='/dashboard/pack/create' bg='dark.200' color='white' _hover={{ bg: 'dark.300' }} paddingY={3} paddingX={5}>Pack</MenuItem>
                    <MenuItem as={Link} to='/dashboard/document/create' bg='dark.200' color='white' _hover={{ bg: 'dark.300' }} paddingY={3} paddingX={5}>Document</MenuItem>
                    <MenuItem as={Link} to='/dashboard/category'  bg='dark.200' color='white' _hover={{ bg: 'dark.300' }} paddingY={3} paddingX={5}>Catégorie</MenuItem>
                    <MenuItem as={Link} to='/dashboard/clause'  bg='dark.200' color='white' _hover={{ bg: 'dark.300' }} paddingY={3} paddingX={5}>Clause</MenuItem>
                    <MenuItem as={Link} to='/dashboard/variable'  bg='dark.200' color='white' _hover={{ bg: 'dark.300' }} paddingY={3} paddingX={5}>Variable</MenuItem>
                </MenuList>
            </Menu>
        </Box>
    )
}

export default MenuAction;