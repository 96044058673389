import React from 'react';
import { connect } from 'react-redux';
import { 
    Box, 
    Text, 
    Badge, 
    Stack, 
 } from '@chakra-ui/react';

// Component
import SubTitle from '../../../components/SubTitle';

function VariableInexist({variables }){


    if( !variables?.length ){
        return null;
    }

    return (
        <Box>
            <SubTitle mb={4}>Liste des variables dans le document mais indisponible dans la base de données :</SubTitle>
            <Stack direction='row' flexWrap='wrap'>
                { variables.map( variable => {
                    return (
                        <Badge 
                            key={variable}
                            paddingX={3} 
                            paddingY={2}  
                            colorScheme='red' 
                        >
                            <Stack direction='row' gap={1} justifyContent='center' alignItems='center'>
                                <Text>{variable}</Text>
                            </Stack>
                        </Badge> 
                    )
                }) }
            </Stack>
        </Box>
    )

}

const mapStateToProps = (state) => {
    return {
        variables : state.document.variablesInexist
    }
}

export default connect(mapStateToProps)(VariableInexist);