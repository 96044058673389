import React from "react";

// Components
import MenuGroup from "./MenuGroup";

// Icons
import TaskIcon from './assets/img/Task.svg';

function SaisieMenu(){
    const menus = [
        {
            'to': '/dashboard/saisie',
            'icon' : TaskIcon,
            'title' : 'Docs Partagés'
        }
    ];
    return <MenuGroup name="Saisies" menus={menus} />
}

export default SaisieMenu;