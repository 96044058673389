import http from '../../utils/http'
import { useMutation,useQueryClient } from '@tanstack/react-query';

const postClause = async(clause) => {
    const response = await http.post('/clauses', clause);
    return response.data;
}

export default function usePostClause(){
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey : ['postClause'],
        mutationFn : (clause) => postClause(clause),
        onSuccess: () => {
            queryClient.invalidateQueries(['fetchClauses']);
          },
    })
    
}