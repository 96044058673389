import React from 'react';
import { Box } from '@chakra-ui/react';

// Components


// Global
import Template from '../../components/dashboard/Template';
import TransactionDetail from './block/TransactionDetail';

// Context
function TransactionDetailPage () {

    return (
        <Template 
            callback={null}
            title='Detail du transaction'
            restrictedTo={['ROLE_ADMIN','ROLE_USER']}
        >
            <Box bg="dark.300" borderRadius={8} paddingY={30}> 
                <Box paddingX={{ base:2, lg: 8 }}>
                    <TransactionDetail/>
                </Box>
            </Box>
        </Template>
    )

}

export default TransactionDetailPage;