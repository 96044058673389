import React from "react"
import PropTypes from "prop-types"
import { FaDownload } from "react-icons/fa"
import { Box, Text, Icon, Stack, Image, Skeleton } from "@chakra-ui/react"

// Icons
import PdfIcon from "../../../assets/svg/pdf.svg"
import DocIcon from "../../../assets/svg/docx.svg"
import XlsIcon from "../../../assets/svg/xlsx.svg"

// Components
import SubTitle from "../../../components/SubTitle"

const DocumentItem = ({ document }) => {
	const renderIcon = () => {
		let icon = null
		switch (document.type) {
			case "pdf":
				icon = PdfIcon
				break
			case "docx":
				icon = DocIcon
				break
			default:
				icon = XlsIcon
		}
		if (icon) {
			return <Image width='22px' src={icon} />
		}
		return null
	}
	return (
		<Stack
			direction='row'
			justifyContent='space-between'
			paddingY={3}
			borderBottomWidth={1}
			borderBottomColor='#5f5c5c'
		>
			<Stack direction='row' alignItems='center' spacing={2}>
				{renderIcon()}
				<Text color='white'>{document.name}</Text>
			</Stack>
			<Icon as={FaDownload} color='grey' />
		</Stack>
	)
}

function PackDocs({ documents }) {
	const renderDocuments = () => {
		return (
			<Stack spacing={2}>
				{documents?.map((document) => (
					<DocumentItem key={document.id} document={document} />
				))}
			</Stack>
		)
	}

	return (
		<Box bg='dark.100' borderRadius={8} padding='26px'>
			<SubTitle style={{ marginBottom: "15px" }}>Liste des documents :</SubTitle>
			{renderDocuments()}
		</Box>
	)
}

PackDocs.propTypes = {
	documents: PropTypes.any,
}

export default PackDocs
