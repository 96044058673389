import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Card, CardHeader, CardBody, Image, Center } from '@chakra-ui/react';

// Assets
import Logo from './assets/img/Logo.webp';

// Components
import Menu from './Menu';

class Sidebar extends React.Component{
    
    render(){
        return (
            <Box width={{ base : '70px', lg : '16%' }} bg='dark.300' minHeight='100vh' padding={{ base: '10px', lg: '30px' }}>
                <Card bg='none' boxShadow='none'>
                    <CardHeader textAlign='center' p={0} display={{ base: 'none', lg: 'block' }}>
                        <Center>
                            <Link to='/dashboard'>
                                <Image src={Logo} maxWidth={135} />
                            </Link>
                        </Center>
                    </CardHeader>
                    <CardBody paddingX={0}>
                        <Menu />
                    </CardBody>
                    {/* <CardFooter padding={0} display={{ base: 'none', lg: 'block' }}>
                        <Button bg='dark.100' color='white' w='100%' fontSize={16} _hover={{ bg: '#EDC755', color: '#343434' }}>Voir plus ...</Button>
                    </CardFooter> */}
                </Card>
            </Box>
        )
    }

}

export default Sidebar; 