import React, { useContext, forwardRef, useState } from 'react';
import { Field, FormikContext, useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Box,
    Skeleton,
    FormLabel,
    SimpleGrid,
    FormControl,
    FormErrorMessage,
    Flex, Text, Input,
    Image,
    IconButton,
} from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import usePostMediaOject from '../../../hooks/logo/usePostMediaObject';
import SubTitle from '../../../components/SubTitle';
import PackDetailContext from '../../../context/PackDetailContext';

const VariableItem = forwardRef(({ values, errors, variable, sharedPackUrl }, ref) => {
    const mutation = usePostMediaOject();
    const [filePreview, setFilePreview] = useState(null);
    const [fileUrl, setFileUrl] = useState('');

    const formik = useContext(FormikContext);

    const formikMedia = useFormik({
        initialValues: {
            file: '',
            fileUrl: '',
        },
        validationSchema: Yup.object({
            file: Yup.mixed()
                .required('A file is required')
                .test(
                    'fileFormat',
                    'Unsupported Format',
                    value => value && ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'].includes(value.type)
                ),
            fileUrl: Yup.string().required('File URL is required'),
        }),
        onSubmit: values => {
            console.log(values);
        },
    });

    const handleFileChange = event => {
        const file = event.target.files[0];
        formikMedia.setFieldValue('file', file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setFilePreview(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('sharedPackUrl', sharedPackUrl);

        mutation.mutate(formData, {
            onSuccess: (response) => {
                formikMedia.setFieldValue('fileUrl', response.contentUrl);
                setFileUrl(response.contentUrl);
                formik.setFieldValue(variable.name, response.contentUrl); // Update main formik context
            },
        });
    };

    const handleFileRemove = () => {
        setFilePreview(null);
        setFileUrl('');
        formikMedia.setFieldValue('file', '');
        formikMedia.setFieldValue('fileUrl', '');
        formik.setFieldValue(variable.name, ''); // Update main formik context
    };

    if (!variable) {
        return null;
    }

    const renderFields = () => {
        switch (variable.type) {
            case "logo":
                return (
                    <>
                        <Flex
                            rounded={8}
                            height='80px'
                            bgColor="dark.100"
                            align="center"
                            justify="center"
                            cursor="pointer"
                            w="full"
                            position="relative"
                        >
                            <label htmlFor="dropzone-file">
                                <Flex
                                    cursor="pointer"
                                    flexDir="column"
                                    align="center"
                                    justify="center"
                                    pt={5}
                                    pb={6}
                                >
                                    <Icon style={{ color: "white" }} icon="bytesize:upload" />
                                    <Text
                                        mb={2}
                                        fontSize="sm"
                                        color="gray.500"
                                        className="font-semibold"
                                    >
                                        Click to upload
                                    </Text>
                                    <Text
                                        fontSize="xs"
                                        color="gray.500"
                                    >
                                        SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </Text>
                                </Flex>
                                <Input
                                    style={{ display: "none" }}
                                    id="dropzone-file"
                                    name="file"
                                    type="file"
                                    onChange={handleFileChange}
                                    className="hidden"
                                />
                            </label>
                            {formikMedia.touched.file && formikMedia.errors.file ? (
                                <div>{formikMedia.errors.file}</div>
                            ) : null}
                        </Flex>
                        <Field name="fileUrl">
                            {({ field }) => (
                                <Input
                                    type="hidden"
                                    {...field}
                                    value={fileUrl}
                                />
                            )}
                        </Field>
                        {filePreview && (
                            <Box position="relative" mt={4}>
                                <Image width={50} height={50} src={filePreview} alt="Preview" />
                                <Icon
                                    icon="ph:trash-bold"
                                    size="sm"
                                    position="absolute"
                                    style = {{cursor:"pointer", color:"#d62828",position:"absolute", top:1,left:1}}
                                    right={0}
                                    onClick={handleFileRemove}
                                    aria-label="Remove file"
                                />
                            </Box>
                        )}
                    </>
                );
            default:
                return (
                    <Field name={variable.name}>
                        {({ field }) => (
                            <Input
                                color="white"
                                onChange={formik.handleChange}
                                name={variable.label}
                                value={formik.values[variable.name]}
                                type={variable.type}
                                {...field}
                            />
                        )}
                    </Field>
                );
        }
    };

    return (
        <Box className='pack_variable_item'>
            <FormControl isInvalid={!!errors[variable.name]}>
                <FormLabel htmlFor={variable.name} color='#787675' textTransform='uppercase'>{variable.label}</FormLabel>
                {renderFields()}
                <FormErrorMessage>{errors[variable.name]}</FormErrorMessage>
            </FormControl>
        </Box>
    );
});

function Variables({ values, errors, isFetching }) {
    const pack = useContext(PackDetailContext);
    if (pack && !pack.variables.length) {
        return null;
    }
    return (
        <Skeleton isLoaded={!isFetching} minHeight={isFetching ? '180px' : 'auto'} borderRadius={8} mb={12}>
            <SubTitle style={{ fontSize: '20px', color: 'white', marginBottom: '24px' }}>Liste des variables :</SubTitle>
            <SimpleGrid columns={2} spacing={6} marginTop={4} className='pack_variables_list'>
                {pack?.variables.map(variable => (
                    <VariableItem key={variable.id} values={values} errors={errors} variable={variable} sharedPackUrl={pack.sharedPack.url} />
                ))}
            </SimpleGrid>
        </Skeleton>
    );
}

Variables.defaultProps = {
    isFetching: false
};

export default Variables;
