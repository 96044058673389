export const DOCS_PER_PAGE = 30;

/**
 * @description Message
 */
export const VALIDATION_ERROR = 'Email ou mot de passe invalide.';
export const APP_ERROR = "Une erreure est survenue sur le serveur.";
export const LOGIN_ERROR = "Le nom d'utilisateur ou le mot de passe que vous avez tapé est incorrect.";
export const DENIED_ACTION_ERROR = "Vous n'avez pas les droits nécessaires pour faire cette action";
export const DENIED_ACTION_TITLE = "Action non autorisée";

/**
 * @description Sessions
 */
export const SESSION_NAME = 'consyl';
export const COOKIE_NAME = 'consylSession';
export const TOKEN_NAME = 'consylToken';
export const SESSION_EXPIRATION_DAYS = 30;


/**
 * @description Constant permet d'ajouter un type de champ pour une variable
 */
export const VARIABLE_FIELDS = [
    {
        name : 'Type texte',
        value : 'text'
    },
    {
         name : 'Type lien',
         value : 'url'
    },
    {
        name : 'Type date',
        value : 'date'
   },
    {
        name : 'Type email',
        value : 'email'
    },
    {
        name : 'Type nombre',
        value : 'integer'
    },
    {
        name : 'Type décimal',
        value : 'float'
    },
    {
        name : 'Type paragraphe',
        value : 'textarea'
    },
    {
        name : 'Type phone',
        value : 'phone'
    },
    {
        name : 'Type logo',
        value : 'logo'
    },
    {
        name : 'Type avec option',
        value : 'enum'
    },
];

export const DEVISE = [
    {
        "name":"Euro",
        "value":"euro"
    },
    {
        "name":"Dollar",
        "value":"dollar"
    },
]