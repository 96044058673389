import http from '../../utils/http'
import { useMutation,useQueryClient } from '@tanstack/react-query';

const patchCategory = async(categoryId, category) => {
    const response = await http.patch(`/document_categories/${categoryId}`, category );
    return response.data;
}

export default function usePatchCategory(){
    const queryClient  = useQueryClient()
    return useMutation({
        mutationKey : ['patchCategory'],
        mutationFn : ({ categoryId, category }) => patchCategory(categoryId, category),
        onSuccess: () => {
            queryClient.invalidateQueries(['fetchCategories']);
          },
    })

}