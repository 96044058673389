import http from '../../utils/http'

// Constants
const END_POINT = '/packs'

export const fetchPacks = (page=null) => {

    const fetchRequest = () => {
        return {
            type : 'PACKS_FETCHING'
        }
    }

    const fetchSuccess = (playload) => {
        if( page !== null && page > 0 ){
            const totals = playload['hydra:totalItems'];
            const packs =  playload['hydra:member'];
            const page = playload['hydra:view'] ? playload['hydra:view']['@id'].split('?page=')[1] : 1;
            const lastPage = playload['hydra:view'] ? playload['hydra:view']['hydra:last'].split('?page=')[1] : 1;
            return {
                page : page,
                totals : totals,
                lastPage : lastPage,
                playload : packs,
                type : 'PACKS_FETCHING_SUCCESS',
            }
        }
        return {
            type : 'PACKS_FETCHING_SUCCESS',
            playload : playload['hydra:member']
        }
    }

    const fetchFail = () => {
        return {
            type : 'PACKS_FAIL'
        }
    }

    return ( dispatch ) => {
        dispatch( fetchRequest() );
        let url = END_POINT+'?pagination=false&order[createdAt]=desc';
        if( page !== null && page > 0 ){
            url = END_POINT+'?page='+page;
        }
        http.get(url)
            .then( response => {
                if( response.status === 200 ){
                    dispatch( fetchSuccess( response.data ) );
                }
            } )
            .catch( error => {
                dispatch( fetchFail() );
            } )
    }

}

/**
 * @name deletePack
 * @param {*} ID 
 */
export const deletePack = (ID) => {

    return ( dispatch ) => { 
        dispatch({ type : 'PACKS_DELETE_ONE_ITEM', playload : ID })
    }

}