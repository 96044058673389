import React, { useContext, useRef,useState } from 'react';
import {
    Box,
    Stack,
    Button,
    Skeleton,
    FormLabel,
    FormControl,
    FormErrorMessage,
} from '@chakra-ui/react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// Components
import {
    InputField,
    SwitchField,
    TextareaField,
} from '../../fields';

// Context
import TinyForClauseContentMceContext from '../../../context/TinyForClauseContent';
import { FormikContext } from 'formik';
import CkeditorContext from '../../../context/CkeditoContext';



const ClauseForm = ({ isSaving, isEditMode, isFetching }) => {


    // states
    const [editorIsReady, setEditorIsReady] = useState(false);

    const clauseContentRef = useRef(null);
    const { ckEditorInstance, setCkInstance } = useContext(CkeditorContext);
    

    // callback
    const formik = useContext(FormikContext)

    return (
        <Box id="ckEditor">
            <Stack spacing={6} position="relative">
                <FormControl isInvalid={formik.errors.title}>
                    <FormLabel htmlFor='title' color='#787675'>Titre</FormLabel>
                    <Skeleton isLoaded={!isFetching} borderRadius={8}>
                        <InputField
                            name='title'
                            value={formik.values.title}
                            onChange={formik.handleChange}
                        />
                    </Skeleton>
                    <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
                </FormControl>
                {/* End Titre */}
                <FormControl isInvalid={formik.errors.information}>
                    <FormLabel htmlFor='information' color='#787675'>Aide (information)</FormLabel>
                    <Skeleton isLoaded={!isFetching} borderRadius={8}>
                        <TextareaField
                            resize='none'
                            maxHeight={100}
                            name='information'
                            onChange={formik.handleChange}
                            value={formik.values.information}
                        />
                    </Skeleton>
                    <FormErrorMessage>{formik.errors.information}</FormErrorMessage>
                </FormControl>
                {/* End Information */}
                <FormControl  isInvalid={formik.errors.content}>
                    <FormLabel htmlFor='content' color='#787675'>Contenu</FormLabel>
                    <Skeleton isLoaded={!isFetching} borderRadius={8}>
                        <CKEditor
                            editor={ClassicEditor}
                            data={formik.values.content}
                            onReady={(editor) => {
                                setCkInstance(editor);
                                const editorElement = editor.ui.view.editable.element;
                                editorElement.style.height = '200px';
                                editorElement.style.minHeight = '200px';
                                editor.editing.view.change(writer => {
                                    writer.setStyle('height', '200px', editor.editing.view.document.getRoot());
                                });
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                formik.setFieldValue("content", data);
                                console.log(data);
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                        />
                    </Skeleton>
                    <FormErrorMessage>{formik.errors.content}</FormErrorMessage>
                </FormControl>
                {/* End Contenu */}
                <SwitchField
                    name='status'
                    label='Publier'
                    onChange={formik.handleChange}
                    value={Boolean(formik.values.status)}
                />
                {/* End Status */}
                <Button
                    type='submit'
                    height='44px'
                    bg='yellow.100'
                    fontWeight={500}
                    spinnerPlacement='end'
                    isLoading={isSaving}
                    _hover={{ bg: 'green.100' }}
                >{isEditMode ? 'Mettre à jour' : 'Enregistrer'}</Button>
                {/* End Submit */}
                
            </Stack>
        </Box>
    )
};

export default ClauseForm;
