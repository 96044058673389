import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { FormikContext } from 'formik';
import {  FaPlusCircle } from 'react-icons/fa';
import { Box, Icon, Stack, Text, Image } from '@chakra-ui/react';

// Icons
import EditIcon from '../../../assets/svg/edit.svg';

// Context
import SheetContext from '../../../context/SheetContext';
import TinyMceContext from '../../../context/TinyMceContext';
import TypeDocumentContext from '../../../context/TypeDocumentContext';

// // Middleware
import { addDataToSheet, addClauseToDoc } from '../../../actions/document/DocumentAction';

// Styled
const Action =  styled.button`
    padding: 0;
    margin: 0;
`;

function ClauseItem ({ clause, clauseDoc, addDataToSheet, addClauseToDoc, handleEdit  }) {

    // const dispatch = useDispatch();

    // // states
    const [ isUsed, setIsUsed ] = useState(false);

    // // contextes
    const formik = useContext(FormikContext);
    const { tinyInstance } = useContext(TinyMceContext);
    const { typeDocument } = useContext(TypeDocumentContext);
    const { sheetSelected, setSheetSelected } = useContext( SheetContext );


    useEffect( () => {

        const clauseId = clause.item ? clause.item.id : clause.id;
        const clauseIsUsed = clauseDoc.filter( used => used.id ===  clauseId);
        setIsUsed( clauseIsUsed.length > 0 );
        const clauseValues = clauseDoc.map( clause => clause['@id'] );
        formik.setFieldValue('clauses', clauseValues);
        

    }, [ clause, clauseDoc ] )


    const handleEditClause = () => {

        if( typeof(handleEdit) === 'function' ){
            handleEdit('editClause', clause);
        }

    }

    const handleAddClause = () => {

        document.getElementById('documentEditor').scrollIntoView();
        const clauseData = clause.item ? clause.item : clause;
        const clauseName = "<pre>#{"+clauseData.slug+"}</pre><br/>";
        if( tinyInstance !== null && typeDocument !== 'xlsx' ){
            tinyInstance
                .editorCommands
                    .commands.exec
                        .mceinsertcontent('add_variable_item', false, clauseName)
        }else if( sheetSelected !== null ){
            setSheetSelected( null );
            addDataToSheet({ 
                end : sheetSelected.end, 
                start: sheetSelected.start, 
                data : "#{"+clauseData.slug+"}"
            })
        }
        addClauseToDoc(clauseData);
    }

    return (
        <Box 
            padding={3} 
            borderWidth={1} 
            borderRadius={8} 
            borderColor='#7f567f' 
            className='variable_item' 
            bg={isUsed ? '#2b4b38' : 'dark.100'} 
            _hover={{ bg: isUsed ? '#2b4b38' : 'dark.200' }}
        >
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Text color='white' fontWeight={300} maxWidth='80%' fontSize={15}>#{`{${clause.item ? clause.item.slug : clause.slug}}`}</Text>
                <Stack direction="row" spacing={4}>
                    <Action type='button' onClick={ () => handleEditClause() }>
                        <Image src={EditIcon} />
                    </Action>
                    <Action type='button' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={ () => handleAddClause() }>
                        <Icon as={FaPlusCircle} color='teal' />
                    </Action>
                </Stack>
            </Stack>
        </Box>
    )

}

ClauseItem.propTypes = {
    clause : PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        clauseDoc : state.document.clauses,
    }
}

const mapDispatchToProps = {
    addDataToSheet : addDataToSheet,
    addClauseToDoc : addClauseToDoc,
}

export default connect(mapStateToProps, mapDispatchToProps)(ClauseItem);