import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import { createColumnHelper } from "@tanstack/react-table";

// Components
import DocumentActions from './DocumentActions';
import DataTable from '../../../components/DataTable';
import StatusBadge from '../../../components/StatusBadge';

// Context
import PermissionsContext from '../../../context/PermissionsContext';

function DocumentTable({ isFetching, documentData, onDeleteDocument }){
    console.log(documentData);
    // context
    const { isGranted } = useContext( PermissionsContext );

    const getColumns = () => {
        let docType = '';
        const columnHelper = createColumnHelper();
        let columns = [
            columnHelper.accessor('name', {
                cell: (info) => {
                    const docName = info.getValue();
                    switch(true){
                        case docName.includes('pdf'):
                            docType = 'PDF';
                            break;
                        case docName.includes('xlsx'):
                            docType = 'EXCEL';
                            break;
                        default :
                            docType = 'WORD';
                    }
                    return docName;
                },
                header: 'Nom'
            }),
            columnHelper.accessor('@type', {
                cell: () => docType,
                header: 'Type'
            })
        ];
        if( isGranted(['ROLE_ADMIN']) ){
            columns.push(
                columnHelper.accessor('status', {
                    cell: (info) => (<StatusBadge state={ info.getValue() } />),
                    header: 'Etat',
                    enableSorting: false
                })
            )
        }
        columns.push(
            columnHelper.accessor('createdAt', {
                cell: (info) => {
                    const dateNow = new Date();
                    const datePub = new Date( info.getValue() );
                    if( datePub === dateNow ){
                        return `Aujourd'hui à ${datePub.getHours()}:${datePub.getMinutes()}`;
                    }
                    return datePub.toLocaleDateString();
                },
                header: 'Date de création'
            })
        )
        if( isGranted(['ROLE_ADMIN']) ){
            columns.push(
                columnHelper.accessor('id', {
                    cell: (info) => ( 
                        <DocumentActions 
                            id={ info.getValue() } 
                            onDelete={onDeleteDocument}
                        /> ),
                    header: 'Actions',
                    enableSorting: false
                })
            )
        }
        return columns;
    }

    const categoryColumn =  getColumns();  

    return (
        <Box>
            <Box bg="dark.200" paddingY={4} borderRadius={10}>
                <DataTable 
                    data = {documentData}
                    columns = {categoryColumn}
                    isLoading = {isFetching}
                />
            </Box>
        </Box>
    )

}

DocumentTable.propTypes = {
    isFetching : PropTypes.bool,
    documentData : PropTypes.array,
    onDeleteDocument : PropTypes.func
}

export default DocumentTable;