const initalData = [
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null, null],
]
const initalState = {
    data : initalData
};
// TODO A optimiser le code
const SpreadSheetReducer = (state = initalState, action) => {

    switch( action.type ){
        case 'ADD_COLUMN' : 
            let sheetColumns = [];
            state.data.forEach( row => {
                sheetColumns.push([...row, ...[null]]);
            })
            return {
                ...state,
                data : sheetColumns
            }
        case 'DELETE_COLUMN' :
            let sheetCells = [];
            state.data.forEach( row => {
                let colIndex = 0;
                let cells = [];
                row.forEach( cell => {
                    if( colIndex <= row.length-2 ){
                        cells.push( cell );
                    }
                    colIndex++;
                } )
                sheetCells.push(cells);
            })
            return {
                ...state,
                data : sheetCells
            }
        case 'ADD_ROW' :
            let rows = [];
            let newRow = [];
            const columnSize = state.data[0].length-1;
            state.data.forEach( row => {
                rows.push(row);
            })
            for(let i=0; i<= columnSize; i++){
                newRow.push(null);
            }
            if( newRow.length ){
                rows.push(newRow);
            }
            return {
                ...state,
                data : rows
            }
        case 'DELETE_ROW' :
            let index = 0;
            let sheetRows = [];
            if( state.data.length > 1 ){
                state.data.forEach( row => {
                    if( index <= state.data.length-2 ){                    
                        sheetRows.push(row);
                    }
                    index++
                })
            }else{
                sheetRows = state.data
            }
            return {
                ...state,
                data : sheetRows
            }
        case 'ADD_DATA_TO_SHEET': 
            let rowIndex = 0;
            let sheetData = [];
            const start = action.playload.start;
            const end = action.playload.end;
            const data = action.playload.data;
            state.data.forEach( row => {
                let columns = [];
                let colIndex = 0;
                row.forEach( column => {
                    if( rowIndex >= start.row && rowIndex <= end.row ){
                        if( colIndex >= start.column && colIndex <= end.column ){
                            columns.push({ value : data })
                        }else{
                            columns.push(column)
                        }
                    }else{
                        columns.push(column) ;
                    }
                    colIndex++;
                })
                sheetData.push( columns );
                rowIndex++;
            } )
            return {
                ...state,
                data :sheetData
            }
        case 'DELETE_DATA_TO_SHEET': 
            let sheetEntries = [];
            state.data.forEach( row => {
                let columns = [];
                row.forEach( column => {
                    if( column === null ){
                        columns.push(column) ;
                    }else{
                        if( column.value === action.playload ){
                            columns.push(null);
                        }else{
                            columns.push(column) ;
                        }
                    }
                } )
                sheetEntries.push( columns );
            })
            return {
                ...state,
                data : sheetEntries
            }
        case 'SET_SHEET_DATA':
            return {
                ...state,
                data : action.playload
            }
        case 'RESET_SHEET':
            return {
                ...state,
                data : initalData
            }
        default : 
            return state;
    }

}

export default SpreadSheetReducer;