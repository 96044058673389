import React, { useContext } from 'react';
import { FormikContext } from 'formik';

// Components
import { InputField } from '../../../fields';

function TextField (){

    const formik = useContext( FormikContext );

    return (
        <InputField
            name='description'
            value={formik.values.description}
            onChange={formik.handleChange}
        />
    )

}

export default TextField;