import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image, HStack, Skeleton } from '@chakra-ui/react';

// Assets
import DocumentIcon from '../../../assets/svg/Document.svg';

// Components
import SubTitle from '../../../components/SubTitle';

function CategoryItem ({ name, link, isLoading }) {

    return (
        <Skeleton isLoaded={!isLoading} borderRadius={4} height='56px'>
            <HStack 
                as={Link}
                gap={4} 
                paddingX={4}
                paddingY={4}
                borderRadius={4} 
                background='dark.100' 
                className='category_item' 
                to={link}
                state={{ name : name }}
                _hover={{ bg: 'dark.200' }}
            >
                <Image src={DocumentIcon} alt={name} maxWidth={26} />
                <SubTitle color='white' fontSize={16}>{name}</SubTitle>
            </HStack>
        </Skeleton>
    )

}

CategoryItem.propTypes = {
    name : PropTypes.string.isRequired,
    link : PropTypes.string.isRequired,
    isLoading : PropTypes.bool.isRequired
}

export default CategoryItem;