import useFetchStats from '../../../hooks/dashboard/useFetchStats';
import Static from './cardStat';
import { Flex, Skeleton } from '@chakra-ui/react';
import { Icon } from '@iconify/react/dist/iconify.js';

export const SectionStat = () => {
    const { data: statData, isLoading } = useFetchStats();

    return (
        <>
            {isLoading ? (
                <>
                    <Skeleton height="50px" />
                    <Skeleton height="50px" />
                    <Skeleton height="50px" />
                    <Skeleton height="50px" />
                    <Skeleton height="50px" />
                    <Skeleton height="50px" />
                    <Skeleton height="50px" />
                </>
            ) : (
                <>
                    <Static
                        startContent={
                            <Flex alignItems={'center'}  justifyContent={'center'} borderRadius={'50%'}>
                                <Icon icon="hugeicons:transaction" style={{ fontSize: "24px", color: "white" }} />
                            </Flex>
                        }
                        name="Nombre de transactions d'achat"
                        value={statData[0]?.countBuyTransactions}
                    />
                    <Static
                        startContent={
                            <Flex alignItems={'center'} justifyContent={'center'} borderRadius={'50%'}>
                                <Icon icon="et:documents" style={{ fontSize: "24px", color: "white" }} />
                            </Flex>
                        }
                        name='Nombre de documents'
                        value={statData[0]?.countDocuments}
                    />
                    <Static
                        startContent={
                            <Flex alignItems={'center'}  justifyContent={'center'} borderRadius={'50%'}>
                                <Icon icon="mdi:package" style={{ fontSize: "24px", color: "white" }} />
                            </Flex>
                        }
                        name='Nombre de packs'
                        value={statData[0]?.countPacks}
                    />
                    <Static
                        startContent={
                            <Flex alignItems={'center'}  justifyContent={'center'} borderRadius={'50%'}>
                                <Icon icon="clarity:users-line" style={{ fontSize: "24px", color: "white" }} />
                            </Flex>
                        }
                        name="Nombre d'utilisateurs créés ce mois-ci"
                        value={statData[0]?.countUsersCreatedThisMonth}
                    />
                    <Static
                        startContent={
                            <Flex alignItems={'center'}  justifyContent={'center'} borderRadius={'50%'}>
                                <Icon icon="fluent-mdl2:sell" style={{ fontSize: "24px", color: "white" }} />
                            </Flex>
                        }
                        name='Nombre de transactions de vente'
                        value={statData[0]?.countSellTransactions}
                    />
                    <Static
                        startContent={
                            <Flex alignItems={'center'}  justifyContent={'center'} borderRadius={'50%'}>
                                <Icon icon="ic:baseline-folder-shared" style={{ fontSize: "24px", color: "white" }} />
                            </Flex>
                        }
                        name='Nombre de packs partagés'
                        value={statData[0]?.countSharedPacks}
                    />
                    <Static
                        startContent={
                            <Flex alignItems={'center'}  justifyContent={'center'} borderRadius={'50%'}>
                                <Icon icon="fa6-solid:users-between-lines" style={{ fontSize: "24px", color: "white" }} />
                            </Flex>
                        }
                        name="Nombre d'utilisateurs"
                        value={statData[0]?.countUsers}
                    />
                </>
            )}
        </>
    );
};
