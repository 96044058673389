import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';

// Global
import SubTitle from '../../components/SubTitle';
import Template from '../../components/dashboard/Template';
import { SectionStat } from './components/sectionStat';

class DashboardPage extends React.Component {

    render() {
        return (
            <Template
                title='Dashboard'
                restrictedTo={['ROLE_ADMIN', 'ROLE_USER',"ROLE_PROFESSIONAL"]}
            >
                <Box bg="dark.300" borderRadius={8} paddingY={30}>
                    <Box paddingX={{ base: 2, lg: 8 }}>
                        <SubTitle marginBottom={12}>Bienvenue dans le dashboad de Consyl Générateur</SubTitle>
                        <SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }} gap='20px' mb='20px'>
                           <SectionStat />
                        </SimpleGrid>
                    </Box>
                </Box>
            </Template>
        )
    }

}

export default DashboardPage;