import axios from 'axios';
import { createStandaloneToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { getApiUrl, getHeader, revalidateToken } from '../../utils';
import { getBaseApiUrl } from '../../utils';

const { toast } = createStandaloneToast();

const postResetPassword = async ( data, tokenValue ) => {
    const endpoint = getBaseApiUrl(`/forgot_password/${tokenValue}`);
    const response = await axios.post(endpoint, data, getHeader());
    return response.data;
}

export default function useResetPassword(tokenValue) {
    return useMutation({
        mutationKey: ['postForgotPassword'],
        mutationFn: (data) => postResetPassword( data, tokenValue ),
        onSuccess: (response) => {
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'success',
                title: 'Mot de passé changé avec succès',
            });
            return response
        },
        onError: (e) => {
            const status = e.response?.status;
            const description = e.response?.data?.detail;
            if (status === 422) {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'error',
                    title: 'Modification de mot de passe',
                    description: description || 'Une erreur est survenue',
                });
            } else {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'error',
                    title: 'Erreur',
                    description: 'Une erreur est survenue lors de la modification du mot de passe',
                });
            }
        }
    });
}
