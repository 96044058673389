import axios from "axios";
import { createStandaloneToast } from "@chakra-ui/react";
import { getHeader, getApiUrl, getPatchHeader } from "../../utils";

// Toast
const { toast } = createStandaloneToast();

// Constants
const HEADER = getHeader();
const PATCH_HERADER = getPatchHeader();
const END_POINT = getApiUrl('/packs');

/**
 * @name createAction
 * @param {{name, status }} DATA 
 * @reducer PackReducer
 * @description Store pack to API
 */
export const createAction = (DATA) => {

    const createRequest = () => {
        return {
            type : 'PACK_SAVING'
        }
    }

    const createSuccess = (pack) => {
        return {
            type : 'PACK_SAVE_SUCCESS',
            playload : pack
        }
    }

    const createFail = () => {
        return {
            type : 'PACK_FAIL'
        }
    }

    return ( dispatch ) => {
        dispatch( createRequest() );
        axios.post(END_POINT, DATA, getHeader())
            .then( ( response ) => {
                if( response.status === 201 ){
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'success',
                        title: 'Pack créer',
                    })
                    dispatch( createSuccess( response.data ) );
                }
            } )
            .catch( ( error ) => {
                dispatch( createFail() );
            } )
    }

}

/**
 * @name deleteAction
 * @param {*} ID 
 * @reducer PackReducer
 * @description Delete category from API
 */
export const deleteAction = (ID) => {

    const deleteRequest = () => {
        return {
            type : 'PACK_DELETING'
        }
    }

    const deleteSuccess = () => {
        return {
            type : 'PACK_DELETE_SUCCESS'
        }
    }

    const deleteFail = () => {
        return {
            type : 'PACK_FAIL'
        }
    }

    return ( dispatch ) => {
        const URL = END_POINT+'/'+ID;
        dispatch( deleteRequest() )
        axios.delete(URL, getHeader())
            .then( () => {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'success',
                    title: 'Pack supprimé',
                })
                dispatch( deleteSuccess() );
            } )
            .catch( error => {
                dispatch( deleteFail() );
            } )
    }

}

/**
 * @name fetchAllWithoutPaginationAction
 * @reducer PacksReducer
 * @description Fetch all pack from API
 */
export const fetchAllWithoutPaginationAction = () => {

    const fetchRequest = () => {
        return {
            type : 'PACKS_FETCHING'
        }
    }

    const fetchSuccess = (playload) => {
        const totals = playload['hydra:totalItems'];
        const packs =  playload['hydra:member'];
        const page = playload['hydra:view'] ? playload['hydra:view']['@id'].split('?page=')[1] : 1;
        const lastPage = playload['hydra:view'] ? playload['hydra:view']['hydra:last'].split('?page=')[1] : 1;
        return {
            page : page,
            totals : totals,
            lastPage : lastPage,
            playload : packs,
            type : 'PACKS_FETCHING_SUCCESS',
        }
    }

    const fetchFail = () => {
        return {
            type : 'PACK_FAIL'
        }
    }

    return ( dispatch ) => {
        dispatch( fetchRequest() );
        const url = END_POINT+'?pagination=false';
        axios.get(url, getHeader())
            .then( response => {
                if( response.status === 200 ){
                    dispatch( fetchSuccess( response.data ) );
                }
            } )
            .catch( error => {
                dispatch( fetchFail() );
            } )
    }

}

/**
 * @name fetchAllAction
 * @reducer PacksReducer
 * @description Fetch all pack from API
 */
export const fetchAllAction = (page=1) => {

    const fetchRequest = () => {
        return {
            type : 'PACKS_FETCHING'
        }
    }

    const fetchSuccess = (playload) => {
        const totals = playload['hydra:totalItems'];
        const packs =  playload['hydra:member'];
        const page = playload['hydra:view'] ? playload['hydra:view']['@id'].split('?page=')[1] : 1;
        const lastPage = playload['hydra:view'] ? playload['hydra:view']['hydra:last'].split('?page=')[1] : 1;
        return {
            page : page,
            totals : totals,
            lastPage : lastPage,
            playload : packs,
            type : 'PACKS_FETCHING_SUCCESS',
        }
    }

    const fetchFail = () => {
        return {
            type : 'PACK_FAIL'
        }
    }

    return ( dispatch ) => {
        dispatch( fetchRequest() );
        const url = END_POINT+'?page='+page;
        axios.get(url, getHeader())
            .then( response => {
                if( response.status === 200 ){
                    dispatch( fetchSuccess( response.data ) );
                }
            } )
            .catch( error => {
                dispatch( fetchFail() );
            } )
    }

}

/**
 * @name fetchMoreAction
 * @reducer PacksReducer
 * @description Fetch more packs from API
 */
export const fetchMoreAction = (page=1) => {

    const fetchSuccess = (playload) => {
        const totals = playload['hydra:totalItems'];
        const packs =  playload['hydra:member'];
        const page = playload['hydra:view']['@id'].split('?page=')[1];
        const lastPage =playload['hydra:view']['hydra:last'].split('?page=')[1];
        return {
            page : page,
            totals : totals,
            lastPage : lastPage,
            playload : packs,
            type : 'PACK_MORE_SUCCESS',
        }
    }

    const fetchFail = () => {
        return {
            type : 'PACK_FAIL'
        }
    }

    return ( dispatch ) => {
        const url = END_POINT+'?page='+page;
        axios.get(url, getHeader())
            .then( response => {
                if( response.status === 200 ){
                    dispatch( fetchSuccess( response.data ) );
                }
            } )
            .catch( error => {
                dispatch( fetchFail() );
            } )
    }

}

/**
 * @name addDocToPack
 * @param document 
 * @reducer PacksReducer
 * c
 */
export const addDocToPack = (document) => {

    return ( dispatch ) => {
        dispatch( { type : 'ADD_DOC_TO_PACK', playload : document } )
    }

}

/**
 * @name removeDocToPack
 * @param document 
 * @reducer PacksReducer
 */
export const removeDocToPack = (document) => {

    return ( dispatch ) => {
        dispatch( { type : 'REMOVE_DOC_TO_PACK', playload : document } )
    }

}