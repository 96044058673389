import React from 'react';
import { Box, Stack, Text, Heading } from '@chakra-ui/react';

function NotFound (){

    return (
        <Stack height='100vh' justifyContent='center' alignItems='center'>
            <Box>
                <Heading color='white' fontWeight={900} fontSize={105} textAlign='center'>404</Heading>
                <Text color='white' fontSize={18} fontWeight={600} align='center'>Il semble que rien n’a été trouvé à cet emplacement.</Text>
            </Box>
        </Stack>
    )

} 

export default NotFound;