import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { FormikContext } from 'formik';
import {  FaPlusCircle } from 'react-icons/fa';
import { Box, Icon, Stack, Text, Image } from '@chakra-ui/react';

// Icons
import EditIcon from '../../../assets/svg/edit.svg';

// Context
import SheetContext from '../../../context/SheetContext';
import TinyMceContext from '../../../context/TinyMceContext';
import TypeDocumentContext from '../../../context/TypeDocumentContext';

// // Middleware
import { addDataToSheet,  addVariableToDoc } from '../../../actions/document/DocumentAction';


// Styled
const Action =  styled.button`
    padding: 0;
    margin: 0;
`;

function VariableItem ({ variable, variableDoc, addDataToSheet, addVariableToDoc, handleEdit  }) {

    // const dispatch = useDispatch();

    // states
    const [ isUsed, setIsUsed ] = useState(false);

    // contextes
    const formik = useContext( FormikContext );
    const { tinyInstance } = useContext( TinyMceContext );
    const { typeDocument } = useContext( TypeDocumentContext );
    const { sheetSelected, setSheetSelected } = useContext( SheetContext );

    useEffect( () => {

        const variableId = variable.item ? variable.item.id : variable.id;
        const variableIsUsed = variableDoc.filter( used => used.id ===  variableId);
        setIsUsed( variableIsUsed.length > 0 );
        const variablesValue = variableDoc.map( variable => variable['@id'] );
        formik.setFieldValue('variables', variablesValue);

    }, [ variable, variableDoc ] )
    

    const handleEditVariable = () => {

        if( typeof(handleEdit) === 'function' ){
            handleEdit('editVariable', variable);
        }
        
    }

    const handleAddVariable = () => {

        document.getElementById('documentEditor').scrollIntoView();
        const variableData = variable.item ? variable.item : variable;
        // const variableName = "${_"+variableData.name+"_}";
        const variableName = "${"+variableData.name+"}";
        if( tinyInstance !== null && typeDocument !== 'xlsx' ){
            tinyInstance
                .editorCommands
                    .commands.exec
                        .mceinsertcontent('add_variable_item', false, variableName)
        }else if( sheetSelected !== null ){
            setSheetSelected(  null );
            addDataToSheet({ 
                data : variableName, 
                end : sheetSelected.end, 
                start: sheetSelected.start, 
            })
        }
        addVariableToDoc(variableData);

    }

    return (
        <Box 
            padding={3} 
            borderWidth={1} 
            borderRadius={8} 
            borderColor='#7f567f' 
            className='variable_item' 
            bg={isUsed ? '#2b4b38' : 'dark.100'} 
            _hover={{ bg: isUsed ? '#2b4b38' : 'dark.200' }}
        >
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Text color='white' fontWeight={300} maxWidth='80%' fontSize={12}>${`{${variable.item ? variable.item.name : variable.name}}`}</Text>
                <Stack direction="row" spacing={4}>
                    <Action type='button' onClick={ () => handleEditVariable() }>
                        <Image src={EditIcon} />
                    </Action>
                    <Action type='button' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={ () => handleAddVariable() }>
                        <Icon as={FaPlusCircle} color='teal' />
                    </Action>
                </Stack>
            </Stack>
        </Box>
    )

}

VariableItem.propTypes = {
    variable : PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        variableDoc : state.document.variables,
    }
}

const mapDispatchToProps = {
    addDataToSheet : addDataToSheet,
    addVariableToDoc : addVariableToDoc,
}

export default connect(mapStateToProps, mapDispatchToProps)(VariableItem);