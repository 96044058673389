import React, { useContext, useCallback } from 'react';
import parse from 'html-react-parser';
import { useDispatch } from 'react-redux';
import { Flex, Button } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/react';

// Component
import Restricted from '../../../components/Restricted';
import ButtonAction from '../../../components/ButtonAction';

// Hooks
import { useFetchVariables } from '../../../hooks/variable';

// Context
import TinyMceContext from '../../../context/TinyMceContext';
import TypeDocumentContext from '../../../context/TypeDocumentContext';

function Callback(){

    // hooks
    const dispatch = useDispatch();
    const { toast } = createStandaloneToast();
    const { data:variables, isError } = useFetchVariables('publish');
    
    // context
    const { tinyInstance } = useContext(TinyMceContext);
    const { typeDocument } = useContext(TypeDocumentContext);

    const matchVariables = (variablesDoc) => {
        let variableExist = [];
        let variableNotExist = [];
        if( !variablesDoc.length ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'success',
                title: `Aucune variable est trouvé dans ce document`,
            });
            return [];
        }

        if( !variables || isError ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: `Une erreur est survenue lors de la récupération des variables`,
            });
            return [];
        }
        variablesDoc.forEach( ( varDoc ) => {
            const variableMatch = variables.filter( variable => variable.name.toUpperCase() === varDoc.toUpperCase() );
            if(variableMatch.length){
                const variable = variableMatch[0];
                const variableIsAdded = variableExist.filter( item => item.id === variable.id );
                if( !variableIsAdded.length ){
                    variableExist.push(variable);
                }
            }else{
                variableNotExist.push(varDoc);
            }
        });
        if( variableNotExist.length ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: `${variableNotExist.length} variable.s inexistant trouvé dans ce document`,
            });
            dispatch({ type : 'SET_INEXIST_VARIABLES', playload : variableNotExist })
        }
        return variableExist;
    }

    const handleScan = useCallback( () => {
        if( tinyInstance ){
            let variablesDoc = [];
            const regex = /\b[A-Z]+(_[A-Z]+){0,5}\b/;
            const documentContent = tinyInstance.rtcInstance.editor.getContent({format: 'html'});
            if( documentContent ){
                dispatch({ type : 'SET_INEXIST_VARIABLES', playload : [] });
                parse(documentContent, {
                    replace : (domNode) => {
                        if(domNode.type === 'text'){
                            const entryText = domNode.data;
                            if(entryText.trim()){
                                const tabEntry = entryText.split(' ');
                                if(tabEntry.length){
                                    tabEntry.forEach( (entry) =>{
                                        if(entry.trim()){
                                            const match = regex.exec(entry);
                                            if(match){
                                                const variableName = match[0];
                                                if( variableName.length > 4 ){
                                                    variablesDoc.push(variableName);
                                                }
                                            }
                                        }
                                    } )
                                }
                            }
                        }
                    }
                });
                const variableMacthes = matchVariables(variablesDoc);
                if(variableMacthes.length){
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'success',
                        title: `${variableMacthes.length} variable.s importée depuis le document`,
                    });
                    dispatch({ type: 'SET_VARIABLES_DOC', playload : variableMacthes })
                }
            }else{
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'warning',
                    title: `Votre document est vide, veuillez ajouter des contenus`,
                });
            }
        }
    }, [tinyInstance] );

    return(
        <Restricted to={['ROLE_ADMIN', 'ROLE_USER']}>
            <Flex justifyContent='space-between' gap={4}>
                {
                    typeDocument !== 'xlsx' &&
                    <ButtonAction
                        as={Button}
                        title='Scanner le document'
                        onClick={handleScan}
                    />
                }
                <ButtonAction 
                    title='Créer une catégorie'
                    to="/dashboard/category" 
                />
                <ButtonAction 
                    color='dark.200'
                    showIcon={false} 
                    bgColor='green.100'
                    title='Liste des documents'
                    to="/dashboard/document" 
                />
            </Flex>
        </Restricted>
    )
}

export default Callback;