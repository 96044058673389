import http from '../../utils/http'
import { useMutation, useQueryClient} from '@tanstack/react-query';

const postVariable = async(variable) => {
    const response = await http.post('/variables', variable);
    return response.data;
}

export default function usePostVariable(){
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey : ['postVariable'],
        mutationFn : (variable) => postVariable(variable),
        onSuccess: () => {
            queryClient.invalidateQueries(['fetchVariables']);
          },
    })
    
}