import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import { createColumnHelper } from "@tanstack/react-table";

// Components
import PackActions from './PackActions';
import DataTable from '../../../components/DataTable';
import StatusBadge from '../../../components/StatusBadge';

// Context
import PermissionsContext from '../../../context/PermissionsContext';

function PackTable({ isFetching, packData, onDeletePack }){

    // context 
    const { isGranted } = useContext(PermissionsContext);

    const getColumns = () => {
        const columnHelper = createColumnHelper();
        let columns = [
            columnHelper.accessor('name', {
                cell: (info) => info.getValue(),
                header: 'Nom'
            }),
            columnHelper.accessor('credit', {
                cell: (info) => `${info.getValue()}`,
                header: 'Crédit'
            }),
            columnHelper.accessor('documents', {
                cell: (info) => `${info.getValue().length}`,
                header: 'Documents'
            })
        ];

        if( isGranted(['ROLE_ADMIN']) ){
            columns.push(
                columnHelper.accessor('status', {
                    cell: (info) => (<StatusBadge state={ info.getValue() } />),
                    header: 'Etat',
                    enableSorting: false
                })
            );
        }

        columns.push(
            columnHelper.accessor('@id', {
                cell: (info) => {
                    const id = info.getValue().split('/api/packs/')[1]
                    return (
                        <PackActions 
                            id={ parseInt(id) } 
                            onDelete={onDeletePack}
                        /> 
                    )
                },
                header: 'Actions',
                enableSorting: false
            })
        )

        return columns;
    }


    return (
        <Box>
            <Box bg="dark.200" paddingY={4} borderRadius={10}>
                <DataTable 
                    data = {packData}
                    columns = {getColumns()}
                    isLoading = {isFetching}
                />
            </Box>
        </Box>
    )

}

PackTable.propTypes = {
    isFetching : PropTypes.bool,
    packData : PropTypes.array,
    onDeletePack : PropTypes.func
}

export default PackTable;