import http from '../../utils/http';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const deleteVariable = async (variableId) => {
  const response = await http.delete(`/variables/${variableId}`);
  return response.data;
}

export default function useDeleteVariable() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteVariable'],
    mutationFn: (variableId) => deleteVariable(variableId),
    onSuccess: () => {
      queryClient.invalidateQueries(['fetchVariables']);
    },
  });
}
