import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Box, Center, Spinner, SimpleGrid } from "@chakra-ui/react";
import InfiniteScroll from 'react-infinite-scroll-component';

// Constants
import { DOCS_PER_PAGE } from "../../../config/constants";

// Components
import DocumentItem from "../block/DocumentItem";
import NoContent from '../../../components/NoContent';
import PackPlaceholder from '../block/PackPlaceholder';

// Middleware
import { fetchAllAction, fetchMoreAction } from "../../../actions/document/DocumentsAction";

function DocumentView({ page, totals, lastPage, documents, isFetching, fetchDocuments, fetchMoreDocuments }){

    const [ hasMore, setHasMore ] = useState(false);
    const [ index, setIndex ] = useState( parseInt(page) );

    // onDidMount
    useEffect( () => {
        fetchDocuments(1, true);
    }, [] );

    // onDidUpdate
    useEffect( () => {
        setIndex( parseInt(page) );
        if(totals > DOCS_PER_PAGE){
            setHasMore(true);
        }
    }, [page, totals] );

    // callback
    const fetchMorePacks  = useCallback( () => {
        let nextIndex = index;
        nextIndex+= 1;
        if( nextIndex <= lastPage ){
            setIndex( nextIndex )
            fetchMoreDocuments( nextIndex, true );
        }else{
            setHasMore(false);
        }
    }, [index]);


    if( isFetching ){
        return (
            <PackPlaceholder />
        )
    }

    if( !isFetching && !documents?.length ){
        return (
            <NoContent 
                message='Aucun document trouvé'
            />
        )
    }

    return (
        <Box id="pack_view_document">
            <InfiniteScroll
                hasMore={hasMore}
                dataLength={documents.length}
                next={fetchMorePacks}
                loader={ <Center padding={8}><Spinner color="white" /></Center> }
            >
                <SimpleGrid columns={{base:1, sm: 2, lg: 3, xl: 4}} spacing={10}>
                    { 
                        documents.map( (document) => {
                            const data = {
                                id : document.id,
                                name : document.name,
                                apiId: document['@id'],
                                status: document.status,
                                type: document['@type'],
                                createdAt : document.createdAt,
                                updatedAt : document.updatedAt,
                                category: document.category.name,
                                color : document.category.color,
                            }
                            return (
                                <DocumentItem 
                                    key={document.id}
                                    doc = {data}
                                />
                            )
                        } )
                    }
                </SimpleGrid>
            </InfiniteScroll>            
        </Box>
    )

}

DocumentView.defaultProps = {
    documents: []
}

const mapStateToProps = (state) => {
    return {
        page : state.documents.page,
        totals : state.documents.totals,
        lastPage : state.documents.lastPage,
        documents : state.documents.documents,
        isFetching : state.documents.isFetching,
    }
}

const mapDisptachToProps = {
    fetchDocuments : fetchAllAction,
    fetchMoreDocuments : fetchMoreAction,
}

export default connect(mapStateToProps, mapDisptachToProps)(DocumentView)