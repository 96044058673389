import React, { useCallback, useState, useContext, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { BsCopy, BsEye } from 'react-icons/bs';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { DeleteIcon } from '@chakra-ui/icons';
import { Icon as Iconify } from "@iconify/react/dist/iconify.js";
import { 
    Icon,
    Box, 
    Flex, 
    Text, 
    Skeleton, 
    Button as ChakraButton,
    useToast,
    useDisclosure,
    Modal,
    ModalBody,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    Select,
    Portal,
    HStack,
    Button
} from "@chakra-ui/react";

// context
import PermissionsContext from "../../../context/PermissionsContext";

// components
import Restricted from "../../../components/Restricted";

// constants
import { DENIED_ACTION_TITLE, DENIED_ACTION_ERROR } from "../../../config/constants";

// hooks
import useGenerateDocUrl from "../../../hooks/document/useGenerateDocUrl";
import useFetchClients from "../../../hooks/clients/useFetchClients";
import useFetchUsers from "../../../hooks/users/useFetchUsers";

// Styled
const LinkButton = styled(Link)`
    font-size: 14px;
    line-height: 24px;
    color: #1C1C1C;
    padding: 4px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 36px;
    background: #A4C5E0;
    &:hover,
    &:focus {
        background: #9FC77E;
    }
`;

function DocumentItem({ id, name, link, onDelete, isLoading }){

    const { data: clients, isFetching } = useFetchUsers();
    const [selectedClientId, setSelectedClientId] = useState('');
    const toast = useToast();
    const [url, setUrl] = useState(null);
    const [isGenerating, setIsGenerating] = useState(false);
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { isOpen: isClientModalOpen, onOpen: onClientModalOpen, onClose: onClientModalClose } = useDisclosure();
    const [selectedClient, setSelectedClient] = useState(null);
    const generateDocUrl = useGenerateDocUrl();
    const { isGranted } = useContext(PermissionsContext);

    // Load client from localStorage
    useEffect(() => {
        const savedClientId = localStorage.getItem('selectedClientId');
        if (savedClientId) {
            setSelectedClientId(savedClientId);
            setSelectedClient(savedClientId);
        }
    }, []);

    // Determine document type
    const getDocumentIcon = (name) => {
        if (name.includes('pdf')) {
            return <Iconify icon="teenyicons:pdf-solid" style={{ fontSize: "80px", color: "#e63946" }} />;
        } else if (name.includes('xlsx') || name.includes('xls')) {
            return <Iconify icon="vscode-icons:file-type-excel" style={{ fontSize: "80px", color: "#107c41" }} />;
        } else if (name.includes('docx') || name.includes('doc')) {
            return <Iconify icon="vscode-icons:file-type-word" style={{ fontSize: "80px", color: "#2b579a" }} />;
        } else {
            return <Iconify icon="teenyicons:file-solid" style={{ fontSize: "80px", color: "#888888" }} />;
        }
    };

    // Callback for generating document URL
    const handleGenerateDocUrl = useCallback(() => {
        if (!isGranted(['ROLE_ADMIN'])) {
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: DENIED_ACTION_ERROR
            });
            return;
        }
        if (!id) {
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: "ID de document manquant"
            });
            return;
        }
        onClientModalOpen();
    }, [id, isGranted, toast, onClientModalOpen]);

    // Callback for client selection
    const handleClientSelection = (clientId) => {
        setSelectedClientId(clientId);
        setSelectedClient(clientId);
        localStorage.setItem('selectedClientId', clientId);
    };

    // Proceed with document generation
    const proceedWithDocumentGeneration = useCallback((clientId) => {
        onClientModalClose();
        setIsGenerating(true);
    
        const payload = { document_id: parseInt(id) };
        if (clientId) {
            payload.client = `/api/users/${clientId}`;
        }
    
        generateDocUrl.mutate(payload, {
            onSuccess: (response) => {
                if (response) {
                    const uri = `${process.env.REACT_APP_SITE_URL}/document/${response.url}`;
                    setUrl(uri);
                    onOpen();
                } else {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'error',
                        title: 'Génération URL',
                        description: "Une erreur est survenue lors de la génération de votre URL"
                    });
                }
            },
            onError: (e) => {
                if (e.response.status === 400) {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'error',
                        title: 'Génération URL',
                        description: e.response.data.detail
                    });
                } else if (e.response.status === 403) {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'error',
                        title: DENIED_ACTION_TITLE,
                        description: DENIED_ACTION_ERROR
                    });
                } else {
                    console.error(e.response.data.detail);
                }
            },
            onSettled: () => setIsGenerating(false)
        });
    }, [id, generateDocUrl, onOpen, toast, selectedClientId]);

    // Callback for copying URL to clipboard
    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(url);
        onClose();
    }, [url, onClose]);

    // Callback for deleting a document
    const handleDeleteClick = useCallback(() => {
        if (typeof onDelete === 'function') {
            onDelete(id);
        }
    }, [id, onDelete]);

    const handleNotChoose = () => {
        proceedWithDocumentGeneration();
    };

    return (
        <Box className="document_item" mt={2}>
            <Skeleton height={192} isLoaded={!isLoading} borderRadius={12}>
                <Box
                    bg="dark.100"
                    borderRadius={12}
                    height={192}
                    position="relative"
                    textAlign="center"
                    overflow="hidden"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    {getDocumentIcon(name)}
                    <Flex position="absolute" bottom={2} gap={1}>
                        {link && 
                            <Skeleton isLoaded={!isLoading} borderRadius={36}>
                                <Link to={link}>
                                    <ChakraButton
                                        _hover={{ bg: "dark.100" }}
                                        bg="transparent"
                                    >
                                        <Iconify icon="material-symbols-light:edit-outline" color="white" style={{ fontSize: "18px" }} />
                                    </ChakraButton>
                                </Link>
                            </Skeleton>
                        }
                        <Restricted to={['ROLE_ADMIN']}>
                            <ChakraButton
                                bg="transparent"
                                _hover={{ bg: "dark.100" }}
                                onClick={handleGenerateDocUrl}
                            >
                                <Iconify icon="fluent-mdl2:generate" color="teal" style={{ fontSize: "18px" }} />
                            </ChakraButton>
                        </Restricted>
                        <ChakraButton
                            bg="transparent"
                            _hover={{ bg: "dark.100" }}
                            onClick={handleDeleteClick}
                        >
                            <Icon as={DeleteIcon} color="red.100" />
                        </ChakraButton>
                    </Flex>
                </Box>
            </Skeleton>
            <Flex justifyContent='center' alignItems='center' mt={18} gap={2}>
                {name && 
                    <Skeleton isLoaded={!isLoading} borderRadius={36}>
                        <Text 
                            fontSize={16} 
                            color='white'
                            whiteSpace='nowrap'
                            maxWidth='145px'
                            overflow='hidden'
                        >{name.slice(0,21)+''}</Text> 
                    </Skeleton>
                }
            </Flex>
            
                <Portal>
                    <Modal isCentered blockScrollOnMount={true} closeOnOverlayClick={true} isOpen={isOpen} size='xxl' onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent bg='none'>
                            <ModalBody bg='none' padding={6} maxWidth='700px' margin='auto' width='86%'>
                                <Text align='center' fontSize='20px' fontWeight={600} color='white'>Votre URL :</Text>
                                <Box bg='white' padding={4} borderRadius={8} my={6}>
                                    <Text align='center' fontSize='18px' whiteSpace='nowrap' fontWeight={500} color='black'>{url}</Text>
                                </Box>
                                <HStack justifyContent='center' spacing={4}>
                                    <Button 
                                        color='white'
                                        paddingX={8}
                                        bg='#544267' 
                                        height='52px' 
                                        fontSize='16px'
                                        fontWeight={600}
                                        onClick={handleCopy}
                                        spinnerPlacement='end'
                                        _hover={{ bg: '#331d49' }} 
                                        rightIcon={<Icon color='white' fontSize={22} as={BsCopy} />}
                                    >Copier</Button>
                                    <Button 
                                        to={url}
                                        as={Link}
                                        paddingX={8}
                                        bg='#23397f' 
                                        color='white'
                                        target='_blank'
                                        height='52px' 
                                        fontSize='16px'
                                        fontWeight={600}
                                        spinnerPlacement='end'
                                        _hover={{ bg: '#182b66' }} 
                                        onClick={onClose}
                                        rightIcon={<Icon color='white' fontSize={22} as={BsEye} />}
                                    >Voir la page</Button>
                                </HStack>
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                    <Modal isOpen={isClientModalOpen} onClose={onClientModalClose}>
                    <ModalOverlay />
                    <ModalContent textColor="white" bg="dark.200">
                        <ModalHeader textColor="white">Sélectionner le client</ModalHeader>
                        <ModalCloseButton textColor="red.100" />
                        <ModalBody padding="42">
                            <Formik
                                initialValues={{ clientId: '' }}
                                validationSchema={Yup.object({
                                    clientId: Yup.string().required('Veuillez sélectionner un client'),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    handleClientSelection(values.clientId);
                                    proceedWithDocumentGeneration(values.clientId);
                                    setSubmitting(false);
                                }}
                            >
                                <Form>
                                    <Field as={Select} style={{border:"none"}} name="clientId" placeholder="Sélectionner un client" bgColor="dark.100" textColor="white">
                                        {clients && clients.map(client => (
                                            <option key={client.id} value={client.id}>
                                                {client.firstname} {client.lastname}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="clientId" component="div" className="error-message" />
                                    <Flex justifyContent="flex-end" mt={4}>
                                        <HStack gap={2}>
                                            <Button 
                                                size='sm'
                                                onClick={onClientModalClose}
                                                colorScheme="red"
                                            >
                                                Annuler
                                            </Button>
                                            <Button 
                                                size='sm'
                                                onClick={handleNotChoose}
                                                colorScheme="teal"
                                            >
                                                Pas de client
                                            </Button>
                                            <Button 
                                                size='sm'
                                                type="submit"
                                                colorScheme="green"
                                            >
                                                Choisir
                                            </Button>
                                        </HStack>
                                    </Flex>
                                </Form>
                            </Formik>
                        </ModalBody>
                    </ModalContent>
                </Modal>
                </Portal>
        </Box>
    );
}

DocumentItem.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    onDelete: PropTypes.func.isRequired,
};

DocumentItem.defaultProps = {
    isLoading: false,
};

export default DocumentItem;
