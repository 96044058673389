import React, { useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types';
import { FormikContext } from "formik";
import { Icon, Stack, FormControl, FormLabel, Skeleton, InputGroup, Input, FormErrorMessage, InputRightElement } from '@chakra-ui/react';
import { FaPlusCircle } from 'react-icons/fa';
import { Icon as Iconify } from "@iconify/react/dist/iconify.js";
import styled from '@emotion/styled';
import theme from "../../../../config/theme";

const StyledIconify = styled(Iconify)`
  cursor: pointer;
  color: ${theme.colors.red[100]};
  margin-right: 8px;
  &:hover {
    color: ${theme.colors.red[200]};
  }
`;

function EnumFields({ values, isFetching }) {
  // states 
  const [options, setOptions] = useState(['']);

  // context
  const formik = useContext(FormikContext);

  // lifeCycle
  useEffect(() => {
    if (values.length) {
      setOptions(values);
    }
  }, [values]);

  // callback
  const handleAddItem = () => {
    const upOptions = options.concat(['']);
    setOptions(upOptions);
    formik.setFieldValue('options', upOptions);
  };

  const handleDeleteItem = (index) => {
    const upOptions = options.filter((o, i) => i !== index);
    setOptions(upOptions);
    formik.setFieldValue('options', upOptions);
  };

  const handleInputChange = (evt, index) => {
    const upOptions = options.map((opt, id) => {
      return id === index ? evt.target.value : opt;
    });
    setOptions(upOptions);
    formik.setFieldValue('options', upOptions);
  };

  return (
    <FormControl isInvalid={formik.errors.options}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <FormLabel htmlFor='label' color='#787675'>Options</FormLabel>
        <Icon as={FaPlusCircle} color='white' cursor='pointer' onClick={handleAddItem} />
      </Stack>
      <Skeleton isLoaded={!isFetching} borderRadius={8}>
        <Stack gap={4}>
          {
            options.map((opt, index) => {
              return (
                <InputGroup key={index}>
                  <Input
                    name={`option_${index}`}
                    type='text'
                    fontSize='12px'
                    width={'90%'}
                    height='42px'
                    color='#fff'
                    borderRadius={8}
                    pr='40px'
                    borderColor='#323232'
                    backgroundColor='#323232'
                    mx={'auto'}
                    value={opt}
                    onChange={(evt) => handleInputChange(evt, index)}
                    focusBorderColor='transparent'
                  />
                  {
                    index !== 0 &&
                    <InputRightElement pointerEvents='all' height='100%' zIndex={9}>
                      <StyledIconify icon='ph:trash-bold' onClick={() => handleDeleteItem(index)} />
                    </InputRightElement>
                  }
                </InputGroup>
              );
            })
          }
        </Stack>
      </Skeleton>
      <FormErrorMessage>{formik.errors.options}</FormErrorMessage>
    </FormControl>
  );
}

EnumFields.propTypes = {
  values: PropTypes.array,
  isFetching: PropTypes.bool
};

EnumFields.defaultProps = {
  values: []
};

export default EnumFields;
