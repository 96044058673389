import React, { useState, useContext, useCallback } from 'react';
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from 'react-router-dom';
import { Box, useToast, useDisclosure } from '@chakra-ui/react';
import { createColumnHelper } from "@tanstack/react-table";

// Components
import VariableAction from './VariableAction';
import SubTitle from '../../../components/SubTitle';
import NoContent from '../../../components/NoContent';
import DataTable from '../../../components/DataTable';
import VariablePlaceholder from './VariablePlaceholder';
import StatusBadge from '../../../components/StatusBadge';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';

// Hooks
import { useFetchVariables, useDeleteVariable } from '../../../hooks/variable';

// Context
import PermissionsContext from '../../../context/PermissionsContext';

// Constants
import { APP_ERROR, DENIED_ACTION_ERROR, DENIED_ACTION_TITLE } from "../../../config/constants";

function VariableTable() {
    // states
    const [variableId, setVariableId] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    // context
    const { isGranted } = useContext(PermissionsContext);

    // hooks
    const toast = useToast();
    const deleteQuery = useDeleteVariable();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { variableId: variableParamId } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { data: variableData, isFetching: isLoading } = useFetchVariables();

    const handleDelete = useCallback((id) => {
        if (!isGranted(['ROLE_ADMIN', 'ROLE_USER'])) {
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: DENIED_ACTION_ERROR
            });
            return false;
        }
        if (!id) {
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: "ID variable manquant"
            });
            return false;
        }
        onOpen();
        setVariableId(id);
    }, []);

    const onConfirmation = useCallback(() => {
        if (!variableId) {
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: "ID variable manquant"
            });
            return false;
        }
        onClose();
        setIsDeleting(true);
        deleteQuery.mutate(variableId, {
            onSuccess: () => {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'success',
                    title: "Variable supprimée avec succès",
                });
                queryClient.invalidateQueries({ queryKey: ['fetchVariables'] });
                if (variableId === parseInt(variableParamId)) {
                    navigate('/dashboard/variable');
                }
            },
            onError: () => {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'error',
                    title: APP_ERROR,
                });
            },
            onSettled: () => setIsDeleting(false)
        });
    }, [variableId]);

    const getColumns = () => {
        const columnHelper = createColumnHelper();
        return [
            columnHelper.accessor('name', {
                cell: (info) => "${" + info.getValue() + "}",
                header: 'Nom'
            }),
            columnHelper.accessor('type', {
                cell: (info) => info.getValue(),
                header: 'Type'
            }),
            columnHelper.accessor('status', {
                cell: (info) => (
                    <StatusBadge 
                        state={info.getValue()}
                    />
                ),
                header: 'État',
                enableSorting: false,
                meta: {
                    align: 'right'
                  },
            }),
            columnHelper.accessor('id', {
                cell: (info) => (
                    <VariableAction id={info.getValue()} onDelete={handleDelete} />
                ),
                header: 'Actions',
                enableSorting: false
            })
        ];
    }

    const renderContent = () => {
        if (isLoading || isDeleting) {
            return (
                <VariablePlaceholder />
            );
        }
        if (!isLoading && variableData === undefined) {
            return (
                <NoContent />
            );
        }
        return (
            <DataTable
                data={variableData}
                isLoading={isLoading}
                columns={getColumns()}
            />
        );
    }

    return (
        <Box>
            <SubTitle marginBottom={6}>Liste des variables</SubTitle>
            <Box bg="dark.200" paddingY={4} borderRadius={10}>
                {renderContent()}
            </Box>
            <ConfirmationModal
                isOpen={isOpen}
                onClose={onClose}
                title='Supprimer une variable'
                onConfirmation={onConfirmation}
                description='En supprimant cette variable, celle ci ne sera plus disponsible dans vos documents !!'
            />
        </Box>
    );
}

export default VariableTable;
