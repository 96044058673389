import React from 'react';
import { Flex } from '@chakra-ui/react';

// Component
import ButtonAction from '../../../components/ButtonAction';

function UserCallback(){
    return(
        <Flex justifyContent='space-between' gap={4}>
            <ButtonAction 
                title='Créer un client'
                to="/dashboard/users/create" 
            />
        </Flex>
    )
}

export default UserCallback;