import http from '../../utils/http'
import { useQuery } from '@tanstack/react-query';

export default function useFetchVariables(status){
 
    return useQuery({
        queryKey: ['fetchVariables', status],
        queryFn: async () => {
            let endpoint = '/variables?pagination=false&order[createdAt]=desc'
            if(status === 'publish'){
                endpoint += '&status=1'
            }
            const response = await http.get(endpoint);
            return response.data['hydra:member'];
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false
    });
    
}
