import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useFetchPackDocs } from '../../../hooks/pack';

// Components
import SearchSaisie from '../block/SearchSaisie';


function ListeCallback({saisies ,handleFilteredSaisie}) {
    const { packId } = useParams();
    const location = useLocation();
    const path = location.pathname;

    const isSaisieListRoute = path === '/dashboard/saisie';

    return (
        <Flex justifyContent='space-between' gap={4}>
            {isSaisieListRoute && <SearchSaisie saisies={saisies} onFilteredSaisie={handleFilteredSaisie} />}
        </Flex>
    );
}

export default ListeCallback;