import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import PropTypes from 'prop-types';

// Components
import MenuItem from "./MenuItem";

// Styled
const Title = styled(Text)`
    font-size: 16px;
    font-weight: 400;
    color : #787675;
    margin-bottom: 1.2rem;
`;

function MenuGroup (props) {
    let index = 0;
    const { name, menus } = props;
    return (
        <Box className="menu-group-item" marginTop={6} marginBottom={8}>
            <Title display={{ base: 'none', lg: 'block' }}>{name}</Title>
            <Flex direction='column' gap={6}>
                {
                    
                    menus.length && menus.map( menu => {
                        index++;
                        return (
                            <MenuItem 
                                key={index}
                                to={menu.to} 
                                icon={menu.icon} 
                                title={menu.title} 
                            /> 
                        )
                    })
                }
            </Flex>
        </Box>
    )
}

MenuGroup.propTypes = {
    name : PropTypes.string.isRequired,
    menus : PropTypes.array.isRequired
}

export default MenuGroup;