import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import {useLocation} from "react-router-dom";

// Components
import ListePackViewer from './view/ListePackViewer';
import ListeCallback from './callback/ListeCallback';

// Global
import Template from '../../components/dashboard/Template';

// Context
import ListePackViewContext from '../../context/ListePackViewContext';

function ListePackPage () {

    const [view, setView] = useState('table');
    const viewContext = { view, setView };

    // hooks
    const location = useLocation();

    useEffect( () => {

        if(location.state){
            setView(location.state.view)
        }

    }, [location] )
    return (
        <ListePackViewContext.Provider value={viewContext} >
            <Template 
                title='Packs'
                callback={<ListeCallback />}
                restrictedTo={['ROLE_ADMIN', 'ROLE_USER','ROLE_PROFESSIONAL']}
            >
                    <Box bg="dark.300" borderRadius={8} paddingY={30}> 
                        <Box paddingX={{ base:2, lg: 8 }}>
                            <ListePackViewer />
                        </Box>
                    </Box>
            </Template>
        </ListePackViewContext.Provider>
    )

}

export default ListePackPage;