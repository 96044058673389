import React, { forwardRef } from 'react';
import { Stack } from '@chakra-ui/react';

// Components
import Type from './Type';
import Variables from './Variables';
import ClausesVariables from './ClauseVariables';

const ClauseSidebar = forwardRef( ( { handleChange }, ref ) => {
    return(
        <Stack spacing={8} direction='column'>
            <ClausesVariables handleChange={handleChange} />
        </Stack>
    )
})

export default ClauseSidebar;