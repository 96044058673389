import React, { useContext } from 'react';
import { Skeleton, Box, Text, Flex } from '@chakra-ui/react';

// Context
import PackDetailContext from '../../../context/PackDetailContext';

function Pack ({ isFecthing }) {

    const pack = useContext(PackDetailContext)

    return (
        <Skeleton isLoaded={!isFecthing} borderRadius={8}>
            <Flex direction='row' justifyContent='space-between' alignItems='center' gap={10} className='page_inner'>
                <Box  className='page_title left_block'>
                    <Text fontSize={34} fontWeight={300} color='white' className='page_title'>Pack documentaire : {pack?.pack?.name}</Text>
                </Box>
            </Flex>
        </Skeleton>
    )

}

Pack.defaultProps = {
    isFecthing: false
}

export default Pack;