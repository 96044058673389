import React, { forwardRef } from 'react';
import { Stack } from '@chakra-ui/react';

// Components
import Type from './Type';
import Clauses from './Clauses';
import Variables from './Variables';
import { Orientation } from './Orientation';


const Sidebar = forwardRef( ( { handleChange }, ref ) => {
    return(
        <Stack spacing={8} direction='column'>
            <Type />
            <Orientation/>
            <Variables handleChange={handleChange} />
            <Clauses handleChange={handleChange} />
        </Stack>
    )
})

export default Sidebar;