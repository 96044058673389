import React, { useState, useContext, useCallback } from 'react';
import { useQueryClient } from "@tanstack/react-query";
import { Box, useToast, useDisclosure } from '@chakra-ui/react';

// Context
import PermissionsContext from '../../../context/PermissionsContext';

// Hooks
import {useFetchUsers, useDeleteUser} from "../../../hooks/users";

// Components
import UserTable from '../block/UserTable';
import NoContent from '../../../components/NoContent';
import UserPlaceholder from '../block/UserPlaceholder';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';

import {APP_ERROR, DENIED_ACTION_TITLE, DENIED_ACTION_ERROR} from "../../../config/constants";
import useFetchAdmin from '../../../hooks/users/useFetchAdmins';
import AdminTable from '../block/adminTable';

function AdminTableView(){

    // states
    const [ userId, setUserId ] = useState(null);
    const [ isDeleting, setIsDeleting ] = useState(false);

    // context
    const { isGranted } = useContext( PermissionsContext );
    
    // hooks
    const toast = useToast();
    const queryClient = useQueryClient();
    const { isOpen, onOpen, onClose } = useDisclosure();
    
    // queries
    const deleteQuery = useDeleteUser();
    const { isLoading, data:users } = useFetchAdmin()
    // callbacks
    const onDeleteUser = useCallback( (id) => {
        
        if( !isGranted(['ROLE_ADMIN']) ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: DENIED_ACTION_ERROR
            })
            return false;
        }
        if( !id ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: "ID utilisateur manquant"
            });
            return false;
        }
        setUserId( id );
        onOpen();
        
    }, [toast, onOpen, isGranted] );

    const onDeleteConfirmation = useCallback( () => {

        if( !userId ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: "ID utilisateur manquant"
            });
            return false;
        }

        if( userId !== null ){
            onClose();
            setIsDeleting(true);
            deleteQuery.mutate(userId, {
                onSuccess : (response) => {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'success',
                        title: 'Compte utilisateur supprimé',
                    })
                    queryClient.invalidateQueries({queryKey: ['fetchUsers']})
                },
                onError: (error) => {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'error',
                        title: APP_ERROR,
                    })
                },
                onSettled: () => setIsDeleting(false),
            })
        }

    }, [ userId, toast, deleteQuery, onClose ] );

    const renderContent = () => {
        if( isLoading || isDeleting ){
            return (
                <UserPlaceholder />
            )
        }
        if( !isLoading && users === undefined ){
            return (
                <NoContent
                    showLink={true}
                    message="Aucun utilisateur"
                    link="/dashboard/users"
                    linkText="Ajouter un utilisateur"
                />
            )
        }
        return (                
            <AdminTable 
                isFetching={isDeleting}
                packData={users}
                onDeleteUser={onDeleteUser}
            />
        )
    }

    return (
        <Box id="user_view_table">
            { renderContent() }
            <ConfirmationModal
                isOpen = {isOpen}
                onClose = {onClose}
                isLoading = {isDeleting}
                title = 'Supprimer un compte'
                onConfirmation = {onDeleteConfirmation}
                description = 'Vous voulez supprimer ce compte !'
            />
        </Box>
    )
}

export default AdminTableView