import React, { useState, useEffect, useCallback } from 'react';
import { FaCheck } from 'react-icons/fa';
import { Icon,  Button, Skeleton, InputGroup, InputRightElement } from '@chakra-ui/react';

// Components
import SubTitle from '../../../components/SubTitle';
import TextInput from '../../../components/TextInput';

function PackLink ({ saisie, isLoading }){

    const [ link, setLink ] = useState('#');
    const [ isCopied, setIdCopied ] = useState(false);

    // lifeCycle
    useEffect( () => {

        if(saisie){
            const url = `${process.env.REACT_APP_SITE_URL}/pack/${saisie?.url}`;
            setLink(url);
        }

        return () => {
            setLink('#');
            setIdCopied(false);
        }

    }, [saisie] );

    // callback
    const handleCopy = useCallback( () => {

        if(link !== '#'){
            navigator.clipboard.writeText(link);
            setIdCopied(true);

            setTimeout( () => {
                setIdCopied(false);
            }, 5000 );
        }

    }, [link] );

    if(!saisie){
        return null;
    }

    return (
        <Skeleton isLoaded={!isLoading} borderRadius={8} marginBottom={12}>
            <SubTitle style={{ marginBottom: '14px' }}>URL de la pack :</SubTitle>
            <InputGroup size='md'>
                <TextInput width='100%' value={link} />
                {
                    link !== '#' && 
                    <InputRightElement width='4.5rem'>
                        <Button  
                            size='sm' 
                            height='40px' 
                            marginTop='5px' 
                            colorScheme='white' 
                            onClick={handleCopy}
                            pointerEvents={ isCopied ? 'none' : 'all' }
                        >
                            { isCopied ? <Icon as={FaCheck} color='green.100' /> : 'Copier' }
                        </Button>
                    </InputRightElement>
                }
            </InputGroup>
        </Skeleton>
      )

}

PackLink.defaultProps = {
    saisie : null
}

export default PackLink;