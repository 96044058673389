import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FaFolderOpen } from "react-icons/fa";
import { Box, Center, Icon, Button } from "@chakra-ui/react";

// Components
import DocumentView from "./DocumentView";

function PackViewer({  isFetching, documentSelected }) {

    const renderCreateLink = () => {
        if( documentSelected?.length && !isFetching ){
            return(
                <Box
                    left={0}
                    bottom={0}
                    zIndex={999}
                    width='100%'
                    position='sticky'
                    padding={10}
                    bgGradient='linear(to-b, rgb(22 21 21 / 0%), rgb(22 21 21 / 100%))'
                >
                    <Center>
                        <Button 
                            as={Link}
                            to='/dashboard/pack/create/validate'
                            paddingX='60px'
                            height = '55px' 
                            bg = 'yellow.100' 
                            fontSize='16px'
                            fontWeight = {600}
                            borderWidth={1}
                            borderColor='white'
                            leftIcon={<Icon fontSize={22} as={FaFolderOpen} />}
                            spinnerPlacement = 'end'
                            _hover = {{ bg: 'green.100' }} 
                        >Créer le pack</Button>
                    </Center>
                </Box>
            )
        }
        return null;
    }

    return (
        <>
            <DocumentView />
            { renderCreateLink() }
        </>
    )

}

const mapStateToProps = (state) => {
    return {
        documentSelected : state.packs.docs,
        isFetching : state.documents.isFetching
    }
}

export default connect(mapStateToProps)(PackViewer);