import http from '../../utils/http'
import { useMutation,useQueryClient } from '@tanstack/react-query';

const patchUser = async(userId, user) => {
    const response = await http.patch(`/users/${userId}`, user);
    return response.data;
}

export default function usePatchUser(){
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn : ({ userId, user }) => patchUser(userId, user),
        onSuccess: () => {
            queryClient.invalidateQueries(['fetchUsers']);
          },
    })
}