import React from 'react';
import { Box, Image, Text, Flex, Skeleton, SkeletonCircle, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';

// Icons
import AvatarIcon from './assets/img/Avatar.png';

// Hooks
import { useFetchUserProfile } from '../../../hooks/users';
import { Link } from 'react-router-dom';

function User() {
    const { isLoading, data: user } = useFetchUserProfile();

    return (
        <Box className='info_item'>
            <Flex flexDirection='row' alignItems='center' gap='17px'>
                <Skeleton isLoaded={!isLoading} borderRadius={8}>
                    <Text color='white' fontSize={14} fontWeight={500}>Bonjour {user?.lastname}</Text>
                </Skeleton>
                <SkeletonCircle width={38} height={38} isLoaded={!isLoading}>
                    <Menu>
                        <MenuButton
                            as={Box}
                            aria-label='Options'
                        >
                            <Image src={AvatarIcon} boxSize={38} borderRadius='full' />
                        </MenuButton>
                        <Link to={'/dashboard/profile'}>
                            <MenuList bgColor="dark.200" border="none">
                                <MenuItem textColor="white" bgColor="dark.200" >
                                    Profile
                                </MenuItem>
                            </MenuList>
                        </Link>
                    </Menu>
                </SkeletonCircle>
            </Flex>
        </Box>
    );
}

export default User;
