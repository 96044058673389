import http from '../../utils/http'
import { useMutation,useQueryClient } from '@tanstack/react-query';

const deleteSaisie = async(saisieId) => {
    const response = await http.delete(`/shared_packs/${saisieId}`);
    return response.data;
}

export default function useDeleteSaisie(){
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey : ['deleteSaisie'],
        mutationFn : (saisieId) => deleteSaisie(saisieId),
        onSuccess: () => {
            queryClient.invalidateQueries(['fetchSaisies']);
          },
    })

}