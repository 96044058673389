import React, { useContext } from 'react';
import { Skeleton, Box, Text, Flex } from '@chakra-ui/react';

// Context
import DocumentDetailContext from '../../../context/DocumentContext';

function DocumentDetail ({ isFecthing }) {

    const document = useContext(DocumentDetailContext)
    console.log(document);
    return (
        <Skeleton isLoaded={!isFecthing} borderRadius={8}>
            <Flex direction='row' justifyContent='space-between' alignItems='center' gap={10} className='page_inner'>
                <Box  className='page_title left_block'>
                    <Text fontSize={34} fontWeight={300} color='white' className='page_title'>Document : {document?.document.name}</Text>
                </Box>
            </Flex>
        </Skeleton>
    )

}

DocumentDetail.defaultProps = {
    isFecthing: false
}

export default DocumentDetail;