import http from '../../utils/http'
import { useMutation,useQueryClient } from '@tanstack/react-query';

const patchVariable = async(variableId, variable) => {
    const response = await http.patch(`/variables/${variableId}`, variable);
    return response.data;
}

export default function usePatchVariable(){
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey : ['patchVariable'],
        mutationFn : ({ variableId, variable }) => patchVariable(variableId, variable),
        onSuccess: () => {
            queryClient.invalidateQueries(['fetchVariables']);
          },
    })

}