import React, { useCallback } from 'react';
import Fuse from 'fuse.js';
import styled from '@emotion/styled';
import { SearchIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';

const FormWrap = styled.div`
    background: #504c4c;
    border-radius: 38px;
    overflow: hidden;
`;

const Form = styled.form`
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    padding: 0 20px;
`;

const Input = styled.input`
    font-size: 16px;
    color: #fff;
    background: none;
    outline: none;
    box-shadow: none;
    height: 48px;
    width: 100%;
`;

function SearchSaisie({ saisies, onFilteredSaisie }) {
    
    const handleSearch = useCallback((e) => {
        const pattern = e.target.value;
        let filteredDocs = [];

        if (pattern.length >= 1 && saisies.length) {
            const fuseOptions = {
                keys: ["user.profile.firstname","user.profile.lastname"],
                findAllMatches: true,
            };
            const fuse = new Fuse(saisies, fuseOptions);
            const result = fuse.search(pattern);
            if (result.length) {
                filteredDocs = result.map(document => document.item);
            }
        } else if (pattern.length === 0) {
            filteredDocs = saisies;
        }

        onFilteredSaisie(filteredDocs);
    }, [saisies, onFilteredSaisie]);

    return (
        <Box className='search_form search_doc' width={{ base: 'auto', lg: '300px' }} display={{ base: 'none', lg: 'block' }}>
            <FormWrap>
                <Form action='/search' method='post'>
                    <SearchIcon fontSize={19} color='#fff' />
                    <Input 
                        name="search"
                        color='white'
                        className='search_input_field'
                        autoComplete='off'
                        required
                        placeholder='Rechercher une saisie' 
                        onChange={handleSearch} 
                    />
                </Form>
            </FormWrap>
        </Box>
    );
}

export default SearchSaisie;
