import React, { useContext } from "react";

// Context
import PermissionsContext from "../../../context/PermissionsContext";

// Components
import MenuGroup from "./MenuGroup";

// Icons
import TicketIcon from './assets/img/Ticket.svg';
import KnowledgeIcon from './assets/img/Knowledge.svg';

function PackMenu(){

    const { isGranted } = useContext( PermissionsContext );
    let menus = [
        {
            'to': '/dashboard/pack',
            'icon' : TicketIcon,
            'title' : 'Liste des packs'
        }
    ];

    if( isGranted(['ROLE_ADMIN']) ){
        menus.push(
            {
                'to': '/dashboard/pack/create',
                'icon' : KnowledgeIcon,
                'title' : 'Créer un pack'
            }
        )
    }

    return <MenuGroup name="Pack de documents" menus={menus} />
}

export default PackMenu;