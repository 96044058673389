import React, { useContext } from 'react';
import { 
    Box, 
    Text,
    Skeleton,
    SimpleGrid, 
} from '@chakra-ui/react';

// Components
import TextInput from '../../../components/TextInput';
import SubTitle from '../../../components/SubTitle';

// Context
import PackDetailContext from '../../../context/PackDetailContext';

function PackDetail ({ isFetching }){

    const pack = useContext( PackDetailContext );

    return (
        <Skeleton isLoaded={!isFetching} borderRadius={8} mb={12}>
            <SubTitle style={{ fontSize: '20px', color: 'white', marginBottom: '24px' }}>Information de la pack :</SubTitle>
            <SimpleGrid  columns={2} spacing={6} marginTop={4} >
                <Box>
                    <Box mb={15}>
                        <Text color='#787675' fontSize='16px' fontWeight={500} mb='8px' >Nom</Text>
                        <TextInput value={pack ? pack.pack.name : ''} />
                    </Box>
                    <Box>
                        <Text color='#787675' fontSize='16px' fontWeight={500} mb='8px' >Date de création</Text>
                        <TextInput value={ pack ? new Date(pack.pack.createdAt).toLocaleDateString() : '' } />
                    </Box>
                </Box>
                <Box>
                    <Text color='#787675' fontSize='16px' fontWeight={500} mb='8px' >Description</Text>
                    <TextInput lineHeight='22px' height='144px' value={pack ? pack.pack.description : ''} />
                </Box>
            </SimpleGrid>
        </Skeleton>
    )
}

PackDetail.defaultProps = {
    isFetching : false
}

export default PackDetail;