import React, { useContext } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

// Context
import PermissionsContext from '../../context/PermissionsContext';

// Components
import AccessDenied from '../AccessDenied';

function Template({ title, callback, children, restrictedTo }){

    const { isGranted } = useContext( PermissionsContext );

    if( !isGranted( restrictedTo ) ){
        return (
            <AccessDenied />
        )
    }

    return(
        <Box className='page' bg='dark.100' minHeight='91vh' position='relative'>
            <Box className='page_title' paddingY={{ base: 2, lg:6 }} paddingX={{ base:2, lg: 8 }}>
                <Flex direction='row' justifyContent='space-between' alignItems='center' gap={10} className='page_inner'>
                    {
                        title &&
                        <Box  className='page_title left_block'>
                            <Text fontSize={24} fontWeight={300} color='white' className='page_title'>{title}</Text>
                        </Box>
                    }
                    {
                        callback &&
                        <Box  className='page_title right_block'>
                            {callback}
                        </Box>
                    }
                </Flex>
            </Box>
            <Box className='page_content' padding={{ base:2, lg: 8 }} paddingTop={{base: 0, lg:0}}  position='relative'>
                {children}
            </Box>
        </Box>
    )
}

Template.propTypes = {
    callback: PropTypes.element,
    title: PropTypes.string.isRequired,
    restrictedTo : PropTypes.array.isRequired
}

Template.defaultProps = {
    title : 'Page title',
    restrictedTo: ['ROLE_ADMIN',"ROLE_PROFESSIONAL"]
}

export default Template;