import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Flex, Box } from "@chakra-ui/react"

// Components
import PackDocs from "../block/PackDocs"
import PackData from "../block/PackData"
import PackLink from "../block/PackLink"
import PackDetail from "../block/PackDetail"
import DocumentDetail from "../block/DocumentDetail"
import NoContent from "../../../components/NoContent"

// Hooks
import { useFetchSaisie } from "../../../hooks/saisie"

function DetailView() {
	// states
	const [packId, setPackId] = useState(undefined)
	const [documentId, setDocumentId] = useState(undefined)
	const [documents, setDocuments] = useState([])

	// hooks
	const { saisieId } = useParams()
	const { data: saisie, isLoading, isError } = useFetchSaisie(saisieId)

	// onDidUpdate
	useEffect(() => {
		if (saisie) {
			if (saisie.pack) {
				const pack = saisie.pack
				setPackId(parseInt(pack.id))
				setDocuments(saisie.pack.documents)
			} else if (saisie.document) {
				const document = saisie.document
				setDocumentId(document.id)
				setDocuments([saisie.document])
			}
		}
	}, [saisie])

	if (isError) {
		return <NoContent />
	}

	return (
		<Flex flexDirection={{ base: "column", lg: "row" }} gap='40px'>
			<Box width={{ base: "100%", lg: "70%" }}>
				{documentId && <DocumentDetail documentId={documentId} />}
				{packId && <PackDetail packId={packId} />}
				<PackLink saisie={saisie} isLoading={isLoading} />
				<PackData saisieId={saisieId} />
			</Box>
			<Box width={{ base: "100%", lg: "30%" }}>
				<PackDocs documents={documents} />
			</Box>
		</Flex>
	)
}

export default DetailView
