import React from "react"
import { Link } from "react-router-dom"
import { Box, Text, Icon, Stack, Button, Skeleton } from "@chakra-ui/react"
import { LuShoppingCart } from "react-icons/lu"

// Global
import SubTitle from "../../components/SubTitle"
import TransactionData from "./block/TransactionData"
import Template from "../../components/dashboard/Template"
import { isGranted } from "../../utils"

import { UserHooks } from "../../hooks"

function TransactionPage() {
	const { data: profile, isFetching } = UserHooks.useFetchUserProfile()
	return (
		<Template callback={null} title='Historique des Transactions' restrictedTo={["ROLE_USER","ROLE_ADMIN",""]}>
			<Box bg='dark.300' borderRadius={8} paddingY={30}>
				<Box paddingX={{ base: 2, lg: 8 }}>
					{!isGranted(["ROLE_ADMIN"]) && (
						<>
							<SubTitle style={{ marginBottom: "14px" }}>Mon solde disponsible : </SubTitle>
							<Stack direction='row' spacing={4} mb={8}>
								<Skeleton isLoaded={!isFetching} borderRadius={8}>
									<Text
										paddingY={0}
										paddingX={12}
										fontSize='20px'
										height='54px'
										lineHeight='50px'
										color='#9dc57d'
										fontWeight={600}
										borderRadius={8}
										bg='#252d1f'
									>{`${profile?.credit ? profile?.credit : 0} Crédits`}</Text>
								</Skeleton>
								<Skeleton isLoaded={!isFetching} borderRadius={8}>
									<Button
										as={Link}
										to='/dashboard/profile/solde'
										height='54px'
										bg='dark.100'
										fontSize='20px'
										fontWeight={600}
										borderRadius={8}
										paddingX={10}
										color='blue.100'
										leftIcon={<Icon fontSize={22} as={LuShoppingCart} />}
										_hover={{ bg: "green.100", color: "dark.200" }}
									>
										Recharger
									</Button>
								</Skeleton>
							</Stack>
						</>
					)}
					<TransactionData />
				</Box>
			</Box>
		</Template>
	)
}

export default TransactionPage
