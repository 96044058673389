const initialState = {
    page : 0,
    totals : 0,
    lastPage : 0,
    documents : [],
    isError : false,
    isFetching : true,
}

const DocumentsReducer = (state = initialState, action) => {

    switch( action.type ){
        case 'SET_DOCUMENTS':
            return {
                ...state,
                documents : action.playload,
            }
        case 'DOCUMENTS_FETCHING':
            return {
                ...state,
                page : 0,
                totals : 0,
                lastPage : 0,
                documents : [],
                isError : false,
                isFetching : true,
            }
        case 'DOCUMENTS_FETCH_SUCCESS':
            return {
                ...state,
                isError : false,
                isFetching : false,
                page : action.page,
                totals : action.totals,
                lastPage : action.lastPage,
                documents : action.playload
            }
        case 'DOCUMENTS_MORE_SUCCESS':
            return {
                ...state,
                isError : false,
                isFetching : false,
                page : action.page,
                totals : action.totals,
                lastPage : action.lastPage,
                documents : [...state.documents, ...action.playload]
            }
        case 'DOCUMENTS_FETCH_FAIL':
            return {
                ...state,
                page : 0,
                totals : 0,
                lastPage : 0,
                documents : [],
                isFetching : false,
                isError : true
            }    
        case 'DOCUMENTS_DELETE_ONE_ITEM' :
            return {
                ...state,
                documents : state.documents.filter( document => document.id !== action.playload )
            }
        case 'DOCUMENTS_RESET':
            return {
                ...state,
                page : 0,
                totals : 0,
                lastPage : 0,
                documents : [],
                isError : false,
                isFetching : false,
            }  
        default: 
            return state;
    }

}

export default DocumentsReducer;