import http from '../../utils/http'
import { useQuery } from '@tanstack/react-query';

export default function useFetchSaisieData(saisieId){
 
    return useQuery({
        enabled: !!saisieId,
        queryKey: ['fetchSaisieData', saisieId],
        queryFn: async () => {
            if( saisieId === undefined ){
                return null;
            }
            const response =  await http.get(`/shared_packs/${saisieId}/data`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false
    });
    
} 
