import React, { useState, useContext, useCallback } from 'react';
import {  Box, useToast, useDisclosure } from '@chakra-ui/react';
// Components
import SaisieTable from './block/SaisieTable';
import NoContent from '../../components/NoContent';
import SaisiePlaceholder from './block/SaisiePlaceholder';
import ConfirmationModal from '../../components/modal/ConfirmationModal';

// Context
import PermissionsContext from '../../context/PermissionsContext';

// Hooks
import { useDeleteSaisie, useFetchSaisies } from '../../hooks/saisie';

// Constants
import { APP_ERROR, DENIED_ACTION_TITLE, DENIED_ACTION_ERROR } from '../../config/constants';


// Global
import Template from '../../components/dashboard/Template';
import ListeCallback from './callback/ListCallback';

// Context
function SaisiePage () {

    // states
    const [ saisieId, setSaisieId ] = useState(null);
    const [ isDeleting, setIsDeleting ] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [filteredSaisies, setFilteredSaisies] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    // hooks
    const toast = useToast();
    const deleteQuery = useDeleteSaisie();
    const {data:saisies, isFetching:isLoading } = useFetchSaisies()

    // context
    const { isGranted } = useContext(PermissionsContext)

    const handleFilterSaisie = useCallback((filteredSaisies) => {
        setFilteredSaisies(filteredSaisies);
        setIsSearching(true);
    }, []);

    const onDeletePack = useCallback( (id) => {

        if( !isGranted(['ROLE_ADMIN']) ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: DENIED_ACTION_ERROR
            })
            return false;
        }
        if( !id ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: "ID pack manquant"
            });
            return false;
        }
        setSaisieId( id );
        onOpen();
        
    }, [] );

    const onDeleteConfirmation = useCallback( () => {

        if( !saisieId ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: "ID pack manquant"
            });
            return false;
        }
        if( saisieId !== null ){
            setIsDeleting(true);
            deleteQuery.mutate(saisieId, {
                onSuccess : () => {
                    onClose();
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'success',
                        title: 'Saisie supprimé avec succès',
                    });
                },
                onError : () => {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'error',
                        title: APP_ERROR
                    });
                },
                onSettled : () => setIsDeleting(false)
            })
        }

    }, [ saisieId ] );

    const renderContent = () => {
        if( isLoading ){
            return (
                <SaisiePlaceholder />
            )
        }
        if( saisies === undefined || saisies?.length === 0 ){
            return (
                <NoContent />
            )
        }
        return (                
            <SaisieTable 
                packData= {isSearching ? filteredSaisies : saisies}
                isFetching={isDeleting}
                onDeletePack={onDeletePack}
            />
        )
    }
    return (
        <Template 
            callback={ <ListeCallback saisies={saisies} handleFilteredSaisie={handleFilterSaisie} /> }
            title='Documents partagés'
            restrictedTo={['ROLE_ADMIN','ROLE_USER','ROLE_PROFESSIONAL']}
        >
            <Box bg="dark.300" borderRadius={8} paddingY={30}> 
                <Box paddingX={{ base:2, lg: 8 }}>
                    <Box id="pack_view_table"> 
                        { renderContent() }
                        <ConfirmationModal
                            isOpen = {isOpen}
                            onClose = {onClose}
                            isLoading = {isDeleting}
                            title = 'Supprimer une saisie'
                            onConfirmation = {onDeleteConfirmation}
                            description = 'Vous voulez supprimer cette saisie !'
                        />
                    </Box>
                </Box>
            </Box>
        </Template>
    )

}

export default SaisiePage;