import React from "react";
import { Badge } from "@chakra-ui/react";
import PropTypes from 'prop-types';

function StatusBadge({state, labelActive, labelDisable}){

    return (
        <>
            { Boolean(state) ? <Badge 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                size="xs" 
                ml="2" 
                width={100} 
                border="none"
                colorScheme='green'>
                {labelActive}</Badge> : <Badge
                 display="flex" 
                 justifyContent="center" 
                 alignItems="center" 
                 size="xs" 
                 ml="2" 
                 width={100} 
                 border="none"
                 colorScheme='teal'
                >{labelDisable}</Badge> }
        </>
    )

}

StatusBadge.defaultProps = {
    state : 0,
    labelActive : 'Activé',
    labelDisable : 'Desactivé',
}

StatusBadge.propTypes = {
    labelActive : PropTypes.string,
    labelDisable: PropTypes.string,
    state : PropTypes.any.isRequired
}

export default StatusBadge;