import React, { useCallback } from 'react';
import { Stack, Switch, FormLabel } from '@chakra-ui/react';
import PropTypes from 'prop-types';

function SwitchField( { name, label, value, color, onChange } ){

    const handleChange = useCallback( (evt) => {
        onChange(evt);
    }, [] );

    return (
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <FormLabel htmlFor={name} color={color} mb='0'>{label}</FormLabel>
            <Switch 
                id={name} 
                name={name}
                size='md' 
                colorScheme='teal' 
                isChecked={value} 
                onChange={handleChange}
            />
        </Stack>
    )

}

SwitchField.defaultProps = {
    color : '#787675'
}

SwitchField.propTypes = {
    name : PropTypes.string.isRequired,
    label : PropTypes.string.isRequired,
    value : PropTypes.bool.isRequired,
    onChange : PropTypes.func.isRequired
}

export default SwitchField;