import axios from "axios";
import { getHeader, getApiUrl } from "../../utils";

// Constants
const END_POINT = getApiUrl('/variables');

export const fetchVariables = () => {

    const fetchRequest = () => {
        return {
            type : 'VARIABLES_FETCHING'
        }
    }

    const fetchSuccess = (variables) => {
        return {
            type : 'VARIABLES_FETCH_SUCCESS',
            playload : variables
        }
    }

    const fetchFail = () => {
        return {
            type : 'VARIABLES_FAIL'
        }
    }

    return ( dispatch ) => {
        dispatch( fetchRequest() );
        const url = END_POINT+'?pagination=false&order[createdAt]=desc';
        axios.get(url, getHeader())
            .then( response => {
                if( response.status === 200 ){
                    const variables = response.data['hydra:member'];
                    dispatch( fetchSuccess( variables ) );
                }
            } )
            .catch( error => {
                dispatch( fetchFail() );
            } )
    }

}

/**
 * @name deleteVariable
 * @param {*} ID 
 */
export const deleteVariable = (ID) => {

    return ( dispatch ) => { 
        dispatch({ type : 'VARIABLES_DELETE_ONE_ITEM', playload : ID })
    }

}