import React from 'react';
import { SimpleGrid } from '@chakra-ui/react';

// Components
import CategoryItem from './CategoryItem';

function CategoryPlaceholder ({ isLoading }){

    let index = 0;
    const categories = Array(32).fill({ name : 'category', link : '#' })

    return (
        <SimpleGrid columns={{base:1, sm: 2, lg: 3, xl: 4}} spacing={7}>
            { 
                categories.map( (category) => {
                    index++
                    return (
                        <CategoryItem 
                            key={index}
                            name = {category.name}
                            link = {category.link}
                            isLoading = {isLoading}
                        />
                    )
                } )
            }
        </SimpleGrid>
    )

}

CategoryPlaceholder.defaultProps = {
    isLoading : true
}

export default CategoryPlaceholder;