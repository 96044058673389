import React from "react";
import { Box, SimpleGrid } from "@chakra-ui/react";

// Components
import CategoryItem from "./CategoryItem";
import NoContent from "../../../components/NoContent";
import CategoryPlaceholder from './CategoryPlaceholder';

// Hooks
import { useFetchCategories } from "../../../hooks/category";

function CategoryGridList(){

    const { data:categories, isFetching, isError } = useFetchCategories('publish');

    if(isFetching){
        return ( <CategoryPlaceholder />  )
    }

    if( !isFetching && ( isError || categories === undefined ) ){
        return ( 
            <NoContent 
                showLink = { true }
                link = '/dashboard/category'
                linkText = 'Créer une categorie'
                message = 'Aucune catégorie trouvée'
            /> 
        )
    }

    return (
        <Box>
            <SimpleGrid columns={{base:1, sm: 2, lg: 3, xl: 4}} spacing={7}>
                { 
                    categories.map( (category) => {
                        return ( 
                            <CategoryItem  
                                key={category.id}
                                isLoading={false}
                                name={category.name}
                                link = {`/dashboard/document/category/${category.id}`}
                            /> 
                        )
                    } )
                }
            </SimpleGrid>
        </Box>
    )

}

export default CategoryGridList