import { combineReducers } from '@reduxjs/toolkit';

// Document
import DocumentReducer from './document/DocumentReducer';
import DocumentsReducer from './document/DocumentsReducer';
import SpreadSheetReducer from './document/SpreadSheetReducer';

// Pack
import PacksReducer from './pack/PacksReducer';

export default combineReducers({
    // document reducers
    sheet : SpreadSheetReducer,
    document : DocumentReducer,
    documents : DocumentsReducer,
    // pack reducers
    packs : PacksReducer,
});