import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, HStack } from '@chakra-ui/react';

// Components
import PackViewer from './view/PackViewer';
import CreateCallback from './callback/CreateCallback';

// Global
import CategorySelect from './block/CategorySelect';
import Template from '../../components/dashboard/Template';

function GenerateurPackPage (){

    // hook
    const dispatch = useDispatch();

    // onMount
    useEffect(() => {
        dispatch({ type : 'PACKS_RESET' });
    }, []);

    return (
        <Template 
            title='Générateur de pack'
            callback={<CreateCallback />}
            restrictedTo={['ROLE_ADMIN']}
        >
            <Box bg="dark.300" borderRadius={8} paddingY={30} position='relative'> 
                <Box paddingX={{ base:2, lg: 8 }}>
                    <HStack justifyContent='space-between' mb={10}>
                        <CategorySelect />
                    </HStack>
                    <PackViewer />
                </Box>
            </Box>
        </Template>
    )

}
export default GenerateurPackPage;