import React from 'react';
import { Outlet } from 'react-router-dom';
import { Stack } from '@chakra-ui/react';

// Components
import PublicRoutes from '../PublicRoutes';

class FrontLayout extends React.Component{

    render(){

        return (
            <PublicRoutes>
                <Stack>
                    <Outlet />
                </Stack>
            </PublicRoutes>
        )

    }

}

export default FrontLayout;