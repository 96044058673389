import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CloseIcon } from '@chakra-ui/icons';
import { 
    Box, 
    Text, 
    Icon, 
    Badge, 
    Stack, 
    useDisclosure,
} from '@chakra-ui/react';

// Component
import SubTitle from '../../../components/SubTitle';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';

// Middelware
import { removeClauseToDoc, deleteDataFromSheet } from '../../../actions/document/DocumentAction';

// Utiliser le component modal global

function ClauseDoc({clauses, removeClause, deleteFromSheet}){

    const [clauseToDelete, setClauseToDelete] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const onDelete = (clause) => {
        onOpen();
        setClauseToDelete(clause)
    }

    const onConfirmation = () => {
        if( clauseToDelete !== null ){
            onClose();
            setClauseToDelete(null);
            removeClause(clauseToDelete);
            deleteFromSheet( "#{_"+clauseToDelete.slug+"_}" );
        }
    }

    const renderClausesList = () => {
        if( !clauses?.length ){
            return null;
        }
        return (
            <Box>
                <SubTitle mb={4}>Liste des clauses utilisées :</SubTitle>
                <Stack direction='row' flexWrap='wrap'>
                    { clauses.map( clause => {
                        return (
                            <Badge 
                                key={clause.id}
                                paddingX={3} 
                                paddingY={2}  
                                cursor='pointer'
                                colorScheme='green' 
                                onClick={ () => onDelete(clause) }  
                            >
                                <Stack direction='row' gap={1} justifyContent='center' alignItems='center'>
                                    <Text>{clause.title}</Text>
                                    <Icon as={CloseIcon} fontSize={10} />
                                </Stack>
                            </Badge> 
                        )
                    }) }
                </Stack>
                <ConfirmationModal
                    isOpen = {isOpen}
                    onClose = {onClose}
                    title = 'Supprimer une clause'
                    onConfirmation = {onConfirmation}
                    description = 'En supprimant cette clause, celle ci ne sera plus disponible dans ce document'
                />
            </Box>
        )
    }

    return (
        <>
            { renderClausesList() }
        </>
    )

}

const mapStateToProps = (state) => {
    return {
        clauses : state.document.clauses
    }
}

const mapDispatchToProps = {
    removeClause : removeClauseToDoc,
    deleteFromSheet : deleteDataFromSheet
}

export default connect(mapStateToProps, mapDispatchToProps)(ClauseDoc);