import http from '../../utils/http';
import { useQuery } from '@tanstack/react-query';
export default function useFetchProfile(userId){
 
    return useQuery({
        enabled: !!userId,
        queryKey: ['profile', userId],
        queryFn: async () => {
            if( userId === undefined ){
                return null;
            }
            const response =  await http.get('/users/'+userId+'/profile');
            return response.data;
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false
    });

} 
