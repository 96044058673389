import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { 
    Box, 
    Text,
    Skeleton,
    SimpleGrid, 
} from '@chakra-ui/react';

// Components
import SubTitle from '../../../components/SubTitle';
import TextInput from '../../../components/TextInput';

const VariableItem = forwardRef( ({ variable }, ref) => {
    const label = Object.keys(variable);
    const value = Object.values(variable);
    return (
        <Box className='pack_variable_item'>
            <Text color='#787675' fontSize='16px' fontWeight={500} mb='8px'>{label}</Text>
            <TextInput value={value} />
        </Box>
    )
} )

function Variables ({ isFetching, variables }){

    let index = 0;

    if( !variables.length ){
        return null;
    }

    return (
        <Skeleton isLoaded={!isFetching} minHeight={isFetching ? '180px' : 'auto'} borderRadius={8} mb={12}>
            <SubTitle style={{ marginBottom: '24px' }}>Liste des variables :</SubTitle>
            <SimpleGrid columns={2} spacing={6} marginTop={4} className='pack_variables_list'>
                {
                    variables.map( variable => {
                        index++;
                        return (
                            <VariableItem key={index} variable={variable} />
                        )
                    } )
                }
            </SimpleGrid>
        </Skeleton>
    )
}

Variables.defaultProps = {
    isFetching : false,
    variables : []
}

Variables.propTypes = {
    isFetching : PropTypes.bool,
    variables : PropTypes.array.isRequired
}

export default Variables;