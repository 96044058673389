import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { ViewIcon, DeleteIcon } from '@chakra-ui/icons';
import { ButtonGroup, Button } from "@chakra-ui/react";

function TransactionsActions ({ id }){

    
    return (
        <ButtonGroup variant='outline' mx="auto" spacing={0} padding={0}>
            <Button 
                as={Link}
                border={0} 
                padding={0} 
                colorScheme='white' 
                _hover={{ bg : 'dark.100' }}
                to={`/dashboard/profile/transaction/${id}`}
            >
                <ViewIcon />
            </Button>
        </ButtonGroup>
    )
}

TransactionsActions.propTypes = {
    id : PropTypes.number.isRequired,
}


export default TransactionsActions;