import React from 'react';

// Components
import DocumentTable from './DocumentTable';

function DocumentTablePlaceholder ({ isLoading }){

    const documents = Array(10).fill({ name : 'document', '@type' : 'excel', 'status' : 0, 'createdAt' : new Date().toLocaleDateString(), 'id' : 1 })

    return (
        <DocumentTable 
            documentData = {documents}
            isFetching = {isLoading}
        />
    )

}

DocumentTablePlaceholder.defaultProps = {
    isLoading : true
}

export default DocumentTablePlaceholder;