import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import Restricted from '../../../components/Restricted'
import { ViewIcon, DeleteIcon } from '@chakra-ui/icons';
import { ButtonGroup, Button } from "@chakra-ui/react";

function SaisieActions ({ id, onDelete }){

    const handleDelete = () => {
        if( typeof(onDelete) == 'function' ){
            onDelete(id);
        }
    }
    
    return (
        <ButtonGroup variant='outline' spacing={0} padding={0}>
            <Button 
                as={Link}
                border={0} 
                padding={0} 
                colorScheme='white' 
                _hover={{ bg : 'dark.100' }}
                to={`/dashboard/saisie/${id}`}
            >
                <ViewIcon />
            </Button>
            <Restricted to={['ROLE_ADMIN']}>
                <Button 
                    border={0} 
                    padding={0} 
                    colorScheme='red' 
                    onClick={ () => handleDelete() }
                    _hover={{ bg : 'dark.100' }}
                >
                    <DeleteIcon />
                </Button>
            </Restricted>
        </ButtonGroup>
    )
}

SaisieActions.propTypes = {
    id : PropTypes.number.isRequired,
}


export default SaisieActions;