import React, { useContext } from 'react';
import { Flex } from '@chakra-ui/react';

// Component
import Search from '../block/Search';
import Restricted from '../../../components/Restricted';
import ButtonAction from '../../../components/ButtonAction';

// Context
import DocumentViewContext from '../../../context/DocumentViewContext';

function Callback(){

    const { view } = useContext( DocumentViewContext );

    return(
        <Restricted to={['ROLE_ADMIN', 'ROLE_USER']}>
            <Flex justifyContent='space-between' gap={7}>
                { view !== 'category' && <Search /> }
                <ButtonAction 
                    title='Créer un document'
                    to="/dashboard/document/create" 
                />
            </Flex>
        </Restricted>
    )
}

export default Callback;