import React from "react";
import { Badge } from "@chakra-ui/react";
import PropTypes from 'prop-types';

function StatusSharedDoc({state, labelActive, labelDisable}){

    return (
        <>
            { Boolean(state) ? <Badge colorScheme='green'>{labelActive}</Badge> : <Badge>{labelDisable}</Badge> }
        </>
    )

}

StatusSharedDoc.defaultProps = {
    state : 0,
    labelActive : 'En cours',
    labelDisable : 'Terminé',
}

StatusSharedDoc.propTypes = {
    labelActive : PropTypes.string,
    labelDisable: PropTypes.string,
    state : PropTypes.any.isRequired
}

export default StatusSharedDoc;