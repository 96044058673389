import React from 'react';
import { Box  } from '@chakra-ui/react';

// Components
import UsersTableView from './view/UsersTableView';
// Global
import UserCallback from './callback/UserCallback';
import Template from '../../components/dashboard/Template';

function UtilisateursPage() {

    return (
        <Template
            title="Liste des clients"
            callback={<UserCallback />}
        >
            <Box bg="dark.300" borderRadius={8} paddingY={30}>
                <Box paddingX={{ base: 2, lg: 8 }}>
                    <UsersTableView />
                </Box>
            </Box>
        </Template>
    );
}

export default UtilisateursPage;
