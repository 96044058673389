import React, { useContext } from "react";

// Components
import ListePackGridView from "./ListePackGridView";
import ListePackTableView from "./ListePackTableView";

// Context
import ListePackViewContext from "../../../context/ListePackViewContext";

function ListePackViewer() {

    const { view } = useContext( ListePackViewContext );

    return (
        <>{ view === 'grid' ? <ListePackGridView /> : <ListePackTableView /> }</>
    )

}

export default ListePackViewer;