import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { 
    Portal, 
    Modal, 
    ModalBody ,
    ModalHeader, 
    ModalOverlay, 
    ModalContent, 
    ModalCloseButton,
} from '@chakra-ui/react';


// Components
import ClauseForm from "../../../components/form/clause/ClauseForm";

function ClauseModal ({ isOpen, onClose, clause }) {

    const [ isShow, setIsShow ] = useState(false);

    useEffect( () => {
        setIsShow(isOpen);
    }, [isOpen] );

    return (
        <Portal>
            <Modal 
                isOpen={isShow} 
                closeOnOverlayClick={true}
                onClose={() => { setIsShow(!isShow); onClose() }} 
            >
                <ModalOverlay />
                <ModalContent bg='dark.200'>
                    <ModalHeader color='white'>{ clause ? 'Modifier une clause' : 'Ajouter une clause' }</ModalHeader>
                    <ModalCloseButton color='white' />
                    <ModalBody pt={0} pb={8} paddingX={8}>
                        <ClauseForm 
                            modal={true} 
                            modalData={clause}
                            handleAction={() => onClose()}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Portal>
    )

}


ClauseModal.propTypes = {
    isOpen : PropTypes.bool.isRequired,
}

ClauseModal.defaultProps = {
    isOpen : false,
}

export default ClauseModal;