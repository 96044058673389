import React from "react"
import PropTypes from "prop-types"
import { Box, Text, Skeleton, SimpleGrid, HStack } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { Icon } from "@iconify/react/dist/iconify.js"
import { useLocation } from "react-router-dom"

// Components
import SubTitle from "../../../components/SubTitle"
import TextInput from "../../../components/TextInput"

// Hooks
import { useFetchDocument } from "../../../hooks/document"

function DocumentDetail({ documentId }) {
	const location = useLocation()
	const { data: document, isLoading } = useFetchDocument(documentId)

	return (
		<Skeleton isLoaded={!isLoading} borderRadius={8} mb={12}>
			<HStack gap={4} alignItems='center' mb={6}>
				<Link
					to='/dashboard/saisie'
					state={{ view: location.state ? location.state.view : "grid" }}
					title='Retourner'
					aria-label='Retourner'
				>
					<Icon
						icon='pepicons-pop:down-left'
						color='white'
						fontSize={22}
						position='relative'
						top='6px'
					/>
				</Link>
				<Text color='white' fontSize={22}>
					{" "}
					/{" "}
				</Text>
				<HStack gap={3}>
					<Icon icon='flat-color-icons:folder' style={{ fontSize: "24px" }} />
					<SubTitle color='white' fontSize={17}>
						{document ? document.name : ""}
					</SubTitle>
				</HStack>
			</HStack>
			{/*
            <SubTitle style={{ marginBottom: '24px' }}>
                Information de la pack :
            </SubTitle>
             */}
			<SimpleGrid columns={2} spacing={6} marginTop={4}>
				<Box mb={15}>
					<Text color='#787675' fontSize='16px' fontWeight={500} mb='8px'>
						Nom
					</Text>
					<TextInput value={document ? document.name : ""} />
				</Box>
				<Box>
					<Text color='#787675' fontSize='16px' fontWeight={500} mb='8px'>
						Description
					</Text>
					<TextInput lineHeight='22px' height='144px' value={document ? document.description : ""} />
				</Box>
			</SimpleGrid>
		</Skeleton>
	)
}

DocumentDetail.propTypes = {
	documentId: PropTypes.any,
}

export default DocumentDetail
