import React from "react";
import { Box } from "@chakra-ui/react";

// Components
import PackMenu from "./PackMenu";
import UsersMenu from "./UsersMenu";
import CompteMenu from "./CompteMenu";
import SaisieMenu from "./SaisieMenu";
import AccueilMenu from "./AccueilMenu";
import DocumentMenu from "./DocumentMenu";
import Restricted from "../../Restricted";
import SettingsMenu from "./SettingsMenu";
import { isGranted } from "../../../utils";


function Menu(){
    return (
        <Box>
            <Box marginY={5}>
                <AccueilMenu />
                <Restricted to={['ROLE_ADMIN',"ROLE_PROFESSIONAL"]}>
                    <UsersMenu />
                </Restricted>
                <Restricted to={['ROLE_ADMIN', 'ROLE_USER',"ROLE_PROFESSIONAL"]}>
                    <DocumentMenu />
                    <PackMenu />
                    <SaisieMenu />
                </Restricted>
                <CompteMenu />
                <Restricted to={['ROLE_ADMIN']}>
                    <SettingsMenu />
                </Restricted>
            </Box>
        </Box>
    )
}

export default Menu;