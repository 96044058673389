import http from '../../utils/http';
import { useQuery } from '@tanstack/react-query';

export default function useFetchUsers(){

    return useQuery({
        queryKey: ['fetchUsers'],
        queryFn: async () => {
            const response =  await http.get('/users?pagination=false&roles=ROLE_USER')
            console.log(response);
            return response.data['hydra:member'];
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false
    });

}
