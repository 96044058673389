import React from "react";
import PropTypes from 'prop-types';
import Paginate from 'react-paginate';
import { HStack, Icon } from '@chakra-ui/react';
import {  ChevronLeftIcon,  ChevronRightIcon }  from '@chakra-ui/icons';

// Constants
import { DOCS_PER_PAGE } from "../config/constants";

function Pagination ({ totals, current, range, spacing, position, onPageChange  }){

    const pageCount = totals ? Math.ceil( totals / DOCS_PER_PAGE ) : 0;

    const renderPagination = () => {
        if( totals <= DOCS_PER_PAGE ){
            return null;
        }
        return (
            <HStack justifyContent={position} mt={spacing} >
                <Paginate 
                forcePage={current}
                    breakLabel="..."
                    pageCount={pageCount}
                    nextClassName='pagi_next'
                    pageClassName='pagi_item'
                    pageRangeDisplayed={range}
                    renderOnZeroPageCount={null}
                    previousClassName='pagi_prev'
                    activeClassName='pagi_current'
                    containerClassName='pagi_coonsyl'
                    disabledClassName='pagi_disabled'
                    onPageChange={({selected}) => onPageChange(selected) }
                    nextLabel={<Icon as={ChevronRightIcon} fontSize={18} />}
                    previousLabel={<Icon as={ChevronLeftIcon} fontSize={18} />}
                />
            </HStack>
        )
    }

    return (
        <>
            { renderPagination() }
        </>
    )

}

Pagination.defaultProps = {
    range : 4,
    spacing : 16,
    current : 0,
    position: 'center'
}

Pagination.propTypes = {
    current: PropTypes.number,
    spacing : PropTypes.number,
    position: PropTypes.string,
    totals : PropTypes.number.isRequired,
    onPageChange : PropTypes.func.isRequired
}

export default Pagination;