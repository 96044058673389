import React, { useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik, FormikContext } from 'formik';
import { useQueryClient } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { 
    object, 
    string
} from 'yup';
import { 
    Box, 
    Stack, 
    Button,
    useToast,
    Skeleton, 
    FormLabel, 
    FormControl, 
    FormErrorMessage 
} from '@chakra-ui/react';

// Components
import SubTitle from '../../SubTitle';
import NoContent from '../../NoContent';
import TypeField from './fields/TypeField';
import VariableFields from './fields/VariableFields';
import EnumFields from './fields/EnumFields'; // Import EnumFields
import { InputField, SwitchField } from '../../fields';

// Context
import PermissionsContext from '../../../context/PermissionsContext';

// Hooks
import { usePostVariable, usePatchVariable, useFetchVariable } from '../../../hooks/variable';

// Middleware
import { fetchVariables } from '../../../actions/variable';

// Schema
const VariableSchema = object({
    name: string().required(),
    type: string().required(),
    label: string().required(),
    description: string().optional()
})

function VariableForm({ modal, modalData, handleAction, fetchVariables }) {

    // context
    const { isGranted } = useContext(PermissionsContext);

    // states
    const [isSaved, setISaved] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    // hooks
    const toast = useToast();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const formik = useFormik({
        initialValues: {
            name: '',
            type: '',
            label: '',
            status: true,
            options: [''],
            description: '',
        },
        validateOnChange: false,
        validationSchema: () => VariableSchema,
        onSubmit: (values) => {
            const variableData = { ...values, description: values.description?.toString() }
            saveVariable(variableData);
        }
    });

    const { variableId } = useParams();
    const postVariable = usePostVariable();
    const patchVariable = usePatchVariable();
    const { data, isFetching } = useFetchVariable(variableId);

    // lifecycle
    useEffect(() => {

        if (isSaved) {
            fetchVariables();
        }

        if (data) {
            setIsEditMode(true);
            formik.setValues({
                name: data.name,
                type: data.type,
                label: data.label,
                status: data.status,
                options: data.options,
                description: data.description,
            });
        }

        if (modalData) {
            setIsEditMode(true);
            formik.setValues({
                name: modalData.name,
                type: modalData.type,
                label: modalData.label,
                status: modalData.status,
                options: modalData.options,
                description: modalData.description,
            });
        }

        return () => {
            setISaved(false);
            setIsEditMode(false);
            formik.resetForm();
        }

    }, [data, isSaved]);

    // callback
    const onModalAction = () => {
        if (!modal) {
            return false;
        }
        if (typeof (handleAction) === 'function') {
            handleAction();
        }
        queryClient.invalidateQueries({ queryKey: ['fetchVariables'] });
    }

    const saveVariable = (variableData) => {
        if (!isGranted(['ROLE_ADMIN'])) {
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: 'Action non autorisée',
                description: "Vous n'avez pas les droits nécessaires pour faire cette action"
            });
            return false;
        }
        setISaved(false);
        setIsSaving(true);
        if (isEditMode) {
            updateVariable(variableId, variableData);
        } else {
            createVariable(variableData);
        }
    }

    const createVariable = (variableData) => {
        postVariable
            .mutate(variableData, {
                onSuccess: () => {
                    setISaved(true);
                    onModalAction();
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'success',
                        title: "Variable ajouté avec succès",
                    });
                },
                onSettled: () => {
                    setIsSaving(false);
                }
            })
    }

    const updateVariable = (id, variableData) => {
        let variableId = modalData ? modalData.id : id;
        patchVariable
            .mutate({ variableId, variable: variableData }, {
                onSuccess: () => {
                    setISaved(true);
                    setIsEditMode(false);
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'success',
                        title: "Mise à jour du variable avec succès",
                    });
                    if (modalData) {
                        onModalAction()
                    } else {
                        navigate('/dashboard/variable');
                    }
                },
                onSettled: () => {
                    setIsSaving(false);
                }
            })
    }

    const handleCancel = () => {
        formik.resetForm();
        setIsEditMode(false);
        navigate('/dashboard/variable');
    }

    if (variableId && !data && !isFetching) {
        return (
            <NoContent
                showLink={true}
                link='/dashboard/variable'
                linkText='Ajouter une variable'
                message='Variable introuvable'
            />
        )
    }

    return (
        <FormikContext.Provider value={formik}>
            <Box>
                {!modal && <SubTitle>{isEditMode ? 'Modifier une variable' : 'Ajouter une variable'}</SubTitle>}
                <form onSubmit={formik.handleSubmit} noValidate>
                    <Stack spacing={6} marginTop={4}>
                        <FormControl isInvalid={formik.errors.name}>
                            <FormLabel htmlFor='name' color='#787675'>Nom</FormLabel>
                            <Skeleton isLoaded={!isFetching} borderRadius={8}>
                                <InputField
                                    name='name'
                                    value={formik.values.name.toUpperCase()}
                                    onChange={formik.handleChange}
                                />
                            </Skeleton>
                            <FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>
                        </FormControl>
                        {/* End Nom */}
                        <FormControl isInvalid={formik.errors.label}>
                            <FormLabel htmlFor='label' color='#787675'>Label</FormLabel>
                            <Skeleton isLoaded={!isFetching} borderRadius={8}>
                                <InputField
                                    name='label'
                                    value={formik.values.label}
                                    onChange={formik.handleChange}
                                />
                            </Skeleton>
                            <FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>
                        </FormControl>
                        {/* End Label */}
                        <TypeField isFetching={isFetching} />
                        {/* End Type */}
                        {formik.values.type === 'enum' && (
                            <EnumFields values={formik.values.options} isFetching={isFetching} />
                        )}
                        {/* Conditionally render EnumFields */}
                        <VariableFields isFetching={isFetching} />
                        {/* End Fields */}
                        <SwitchField
                            name='status'
                            label='Publier'
                            onChange={formik.handleChange}
                            value={Boolean(formik.values.status)}
                        />
                        {/* End Status */}
                            <Button
                                type='submit'
                                height='44px'
                                bg='yellow.100'
                                fontWeight={500}
                                spinnerPlacement='end'
                                isLoading={isSaving}
                                _hover={{ bg: 'green.100' }}
                            >{isEditMode ? 'Mettre à jour' : 'Enregistrer'}</Button>
                            {/* End Submit */}
                            {isEditMode && (
                                <Button
                                    height='44px'
                                    bg='gray.100'
                                    fontWeight={500}
                                    _hover={{ bg: 'red.100' }}
                                    onClick={handleCancel}
                                >Annuler</Button>
                            )}
                    </Stack>
                </form>
            </Box>
        </FormikContext.Provider>
    )
}

VariableForm.defaultProps = {
    modal: false,
    isSaving: false,
    modalData: false,
    isEditMode: false,
    handleAction: null
}

const mapDispatchToProps = {
    fetchVariables: fetchVariables
}

export default connect(null, mapDispatchToProps)(VariableForm);
