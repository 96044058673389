import http from "../../utils/http";
import { getSessionUser } from "../../utils";
import { useQuery } from '@tanstack/react-query';
const user = getSessionUser();
export default function useFetchUserProfile(){
    return useQuery({
        queryKey: ['getUserProfile'],
        queryFn: async () => {
            console.log(user);
            if( !user.roles.includes('ROLE_ADMIN') ){
                const response =  await http.get('/user-profile');
                return response.data;
            }
            return {
                username : user.username,
                lastname : 'Admin'
            }
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false
        
    });
} 
