import http from '../../utils/http';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { getBaseApiUrl } from '../../utils';

export default function useFetchToken(tokenValue) {
    return useQuery({
        enabled: !!tokenValue,
        queryKey: ['fetchTokenValue', tokenValue],
        queryFn: async () => {
            try {
                if (!tokenValue) {
                    return null;
                }
                const endpoint = getBaseApiUrl(`/forgot_password/${tokenValue}`);
                const response = await axios.get(endpoint);
                console.log(response); 
                return response.data;
            } catch (error) {
                if (error.response) {
                    console.error('Error response:', error.response);
                } else if (error.request) {
                    console.error('Error request:', error.request);
                } else {
                    console.error('Error message:', error.message);
                }

                window.location.href = `${window.location.origin}/forgot-password`;
                console.error('Error fetching token value:', error);
                throw new Error('Failed to fetch token value');
            }
        },
        onError: (error) => {
            console.error('Query failed: ', error);
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false
    });
}
