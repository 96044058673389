import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

// Components
import StripePayment from "./StripePayment";
import SubTitle from "../../../components/SubTitle";
import NoContent from '../../../components/NoContent';

// Hooks
import { useGetSetting } from "../../../hooks/settings";

function PaiementForm ({ orderId, clientSecret, intentHasError }){

    // hooks
    const { data:stripeKey, isError } = useGetSetting('stripe_public');
    
    if( isError || intentHasError ){
        return (
            <NoContent message="Une erreur est survenue lors de l'initialisation du système paiement"/>
        )
    }

    return (
        <Box maxWidth='70%' margin='auto' marginTop={10} borderRadius={8}>
            <SubTitle style={{ marginBottom: '30px' }}>Information de paiement :</SubTitle>
            {
                clientSecret && stripeKey &&
                <Elements stripe={loadStripe(stripeKey.value)} options={{
                    clientSecret: clientSecret,
                    appearance: {
                        theme: 'stripe',
                        rules: {
                            '.Input': {
                                padding: '17px 25px',
                                border: '1px solid #bebebe',
                                borderRadius: '0px',
                            },
                            '.Label' : {
                                fontWeight: 600,
                                fontSize: '16px',
                                marginBottom: '10px'
                            },
                            '.Input--invalid': {
                                borderColor: 'red'
                            }        
                        }
                    }
                }}>
                    <StripePayment 
                        orderId={orderId}
                    />
                </Elements>
        } 
        </Box>
    )

}

PaiementForm.defaultProps = {
    clientSecret : null,
    intentHasError : false
}

export default PaiementForm;