import React from "react";

// Components
import MenuItem from "./MenuItem";

// Icons
import HomeIcon from './assets/img/Accueil.svg';

function AccueilMenu(){
    return <MenuItem icon={HomeIcon} to='/dashboard' title="Accueil" />
}

export default AccueilMenu;