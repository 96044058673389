import { useMutation,useQueryClient } from '@tanstack/react-query';
import http from '../../utils/http';

const patchSettings = async(settingsId, value) => {
    console.log(settingsId,value);
    const response = await http.patch(`settings/${settingsId}`, {value:value});
    return response.data;
}

export default function usePatchSettings(){
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey : ['patchSettings'],
        mutationFn : ({ settingsId, value }) => patchSettings(settingsId, value),
        onSuccess: () => {
            queryClient.invalidateQueries(['fetchSettings']);
        },
    })
}