import { ChakraProvider, Box, Flex, Image, Text, Button, Stack, IconButton, Spinner } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import Template from "../../components/dashboard/Template"
import { useFetchUserProfile } from '../../hooks/users';

export const ProfilePage = () => {
    const { data: userProfile, isFetching } = useFetchUserProfile();
    console.log(userProfile);

    if (isFetching) {
        return <Spinner size="xl" />;
    }

    if (!userProfile) {
        return <Text>Error loading profile</Text>;
    }

    const address = userProfile.address || {};
    const { address: userAddress, city, country, postalCode } = address;
    const company = userProfile.company || {};

    return (
        <Template
            callback=''
            title='Profile'
            restrictedTo={['ROLE_ADMIN', 'ROLE_USER', 'ROLE_PROFESSIONAL']}
        >
            <ChakraProvider bg="dark.100">
                <Flex direction={{ base: 'column', md: 'row' }} p={5} gap={5}>
                    <Box bg="#161515" textColor="red.100" p={5} borderRadius="md" boxShadow="md" flex="1">
                        <Flex align="center">
                            <Image
                                borderRadius="full"
                                boxSize="100px"
                                src="https://bit.ly/dan-abramov"
                                alt="Profile Image"
                            />
                            <Box ml={4}>
                                <Text fontSize="xl" fontWeight="bold">{userProfile.firstname ? userProfile.firstname:''  + ' ' + userProfile.lastname ? userProfile.lastname:''}</Text>
                                <Text>{userProfile.username ? userProfile.username :userProfile.email }</Text>
                            </Box>
                        </Flex>
                    </Box>

                    <Flex direction="column" flex="1" gap={5}>
                        {userProfile.address && (
                            <Box bg="#161515" textColor="white" p={5} borderRadius="md" boxShadow="md">
                                <Flex justify="space-between" align="center" mb={4}>
                                    <Text fontSize="xl" fontWeight="bold">Addresses</Text>
                                    <IconButton icon={<EditIcon />} aria-label="Edit" />
                                </Flex>
                                <Text>Address</Text>
                                <Text fontWeight="bold" color='#EDC755'>{userAddress || "N/A"}</Text>
                                <Text mt={4}>City</Text>
                                <Text fontWeight="bold" color='#EDC755'>{city || "N/A"}</Text>
                                <Text mt={4}>Country</Text>
                                <Text fontWeight="bold" color='#EDC755'>{country || "N/A"}</Text>
                                <Text mt={4}>Postal Code</Text>
                                <Text fontWeight="bold" color='#EDC755'>{postalCode || "N/A"}</Text>
                            </Box>
                        )}

                        {userProfile.company && (
                            <Box bg="#161515" textColor="white" p={5} borderRadius="md" boxShadow="md">
                                <Text fontSize="xl" fontWeight="bold" mb={4}>Entreprise</Text>
                                <Stack spacing={3}>
                                    <Flex justify="space-between" align="center">
                                        <Text>Nom</Text>
                                        <Button bg="#161515" width={150} textColor="white" size="sm" border="1px solid #EDC755">
                                            {company.name || "N/A"}
                                        </Button>
                                    </Flex>
                                    <Flex justify="space-between" align="center">
                                        <Text>Siret</Text>
                                        <Button bg="#161515" width={150} textColor="white" size="sm" border="1px solid #EDC755">
                                            {company.siret || "N/A"}
                                        </Button>
                                    </Flex>
                                </Stack>
                            </Box>
                        )}
                    </Flex>
                </Flex>
            </ChakraProvider>
        </Template>
    )
}
