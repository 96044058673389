import React, { useContext } from 'react';
import { SlLayers } from 'react-icons/sl';
import { 
    Box, 
    Icon, 
    HStack, 
    Tooltip,
} from '@chakra-ui/react';

// Components
import SubTitle from '../../../components/SubTitle';
import DocumentGridList from '../block/DocumentGridList';

// Context
import DocumentViewContext from '../../../context/DocumentViewContext';

function DocumentGrid () {

    // context
    const { setView } = useContext( DocumentViewContext );

    return (
        <Box id="document_view_grid">
            <HStack justifyContent='space-between' alignItems='center' marginBottom={10}>
                <SubTitle>Tous les documents créés</SubTitle>
                <Tooltip label='Afficher par catégorie' placement='left-start' hasArrow>
                    <span style={{ cursor:'pointer' }} onClick={ () => setView('category') }><Icon fontSize={20} color='white' as={SlLayers} /></span>
                </Tooltip>
            </HStack>
            <DocumentGridList />
        </Box>
    )

}

export default DocumentGrid;