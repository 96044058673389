import axios from 'axios';
import { createStandaloneToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { getApiUrl, getHeader, revalidateToken } from '../../utils';

const { toast } = createStandaloneToast();

const postSharedPackData = async(data) => {
    const endpoint = getApiUrl('/shared_pack_datas');
    const response = await axios.post(endpoint, data, getHeader());
    return response.data;
}

export default function usePostSharedPackData(){
    
    return useMutation({
        mutationKey : ['postSharedPackData'],
        mutationFn : (data) => postSharedPackData(data),
        onMutate: async (variables) => {
            await revalidateToken();
            return variables;
        },
        onSuccess : (response) => {
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'success',
                title: 'Votre pack est généré avec succès',
            });
        },
        onError : (e) => {
            if( e.response.status === 422 ){
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'error',
                    title: 'Génération pack',
                    description: e.response.data.detail,
                });
            }
        }
    })
    
}