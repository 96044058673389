import React, { useState, useCallback, useContext, useEffect } from "react"
import { connect } from "react-redux"
import { useFormik, FormikContext } from "formik"
import { Flex, Box, useToast } from "@chakra-ui/react"
import { useParams, useNavigate } from "react-router-dom"
import { object, string, array } from "yup"

// Context
import SheetContext from "../../context/SheetContext"
import TinyMceContext from "../../context/TinyMceContext"
import SheetDataContext from "../../context/SheetDataContext"
import PermissionsContext from "../../context/PermissionsContext"
import TypeDocumentContext from "../../context/TypeDocumentContext"

// Components
import Content from "./content/Content"
import Sidebar from "./sidebar/Sidebar"
import ClauseModal from "./modal/ClauseModal"
import VariableModal from "./modal/VariableModal"
import NoContent from "../../components/NoContent"

// Hooks
import { usePostDocument, usePatchDocument, useFetchDocument } from "../../hooks/document/"

// Middleware
import { setClausesDoc, setVariablesDoc, resetAction, setDataSheet } from "../../actions/document/DocumentAction"

// Constants
import { APP_ERROR, DENIED_ACTION_TITLE, DENIED_ACTION_ERROR } from "../../config/constants"
import TinyForHeaderMceContext from "../../context/TinyForHeaderContext"
import TinyForFooterMceContext from "../../context/TinyForFooter"

// schema
const DocumentSchema = object({
	name: string().required(),
	type: string().required(),
	header: string().optional(),
	footer: string().optional(),
	content: string().optional(),
	clauses: array().optional(),
	variables: array().optional(),
	category: string().required(),
	description: string().optional(),
})

function Editor({ clauses, variables, setClauses, setVariables, populateSheet, resetEditor }) {
	// states
	const [element, setElement] = useState(null)
	const [isSaved, setIsSaved] = useState(false)
	const [sheetData, setSheetData] = useState([])
	const [isSaving, setIsSaving] = useState(false)
	const [isEditMode, setIsEditMode] = useState(false)
	const [sheetSelec, setSheetSelec] = useState(false)
	const [showClauseModal, setShowClauseModal] = useState(false)
	const [showVariableModal, setShowVariableModal] = useState(false)

	// context
	const { isGranted } = useContext(PermissionsContext)
	const { tinyInstance } = useContext(TinyMceContext)
	const { tinyInstance: tinyForHeaderInstance } = useContext(TinyForHeaderMceContext)
	const { tinyInstance: tinyForFooterInstance } = useContext(TinyForFooterMceContext)
	const { typeDocument, setTypeDocument } = useContext(TypeDocumentContext)
	useEffect(() => {
		if (tinyInstance) {
			console.log("TinyMCE Instances are ready")
		} else {
			console.error("TinyMCE Instances are not ready")
		}
	}, [tinyInstance])

	// hooks
	const toast = useToast()
	const navigate = useNavigate()
	const formik = useFormik({
		initialValues: {
			name: "",
			type: "pdf",
			content: "",
			category: "",
			status: true,
			clauses: [],
			variables: [],
			description: "",
			header: "",
			footer: "",
		},
		validateOnChange: false,
		validationSchema: DocumentSchema,
		onSubmit: (values) => {
			let documentHeader = null
			let documentContent = null
			let documentFooter = null
			if (tinyInstance && typeDocument !== "xlsx") {
				documentHeader = tinyForHeaderInstance.getContent()
				documentContent = tinyInstance.getContent()
				documentFooter = tinyForFooterInstance.getContent()
			} else {
				if (sheetData.length) {
					documentContent = JSON.stringify(sheetData)
				}
			}
			if (documentContent === "" || documentContent === null || documentContent === undefined) {
				toast({
					position: "top",
					duration: 9000,
					isClosable: true,
					status: "error",
					title: "Création document",
					description: "Votre document est vide, veuillez ajouter des contenus",
				})
				return false
			}
			const documentData = {
				...values,
				header: documentHeader,
				content: documentContent,
				footer: documentFooter,
				status: values.status ? 1 : 0,
			}
			saveDocument(documentData)
		},
	})
	const { documentId } = useParams()
	const postDocument = usePostDocument()
	const patchDocument = usePatchDocument()
	const { data, isFetching, isError } = useFetchDocument(documentId)

	// lifeCycle
	useEffect(() => {
		formik.setFieldValue("clauses", clauses)
	}, [clauses])

	useEffect(() => {
		formik.setFieldValue("variables", variables)
	}, [variables])

	useEffect(() => {
		if (data) {
			setIsEditMode(true)
			initializeDocument(data)
		}
		if (isSaved) {
			resetEditor()
			formik.resetForm()
			setTypeDocument("pdf")
			if (tinyInstance !== null) {
				tinyInstance.editorCommands.commands.exec.mcenewdocument("add_variable_item")
				tinyForHeaderInstance.editorCommands.commands.exec.mcenewdocument("add_variable_item_for_header")
				tinyForFooterInstance.editorCommands.commands.exec.mcenewdocument("add_variable_item_for_footer")
			}
		}
		return () => {
			resetEditor()
			formik.resetForm()
		}
	}, [data, isSaved])

	// callback
	const handleChange = useCallback((action, value) => {
		switch (action) {
			case "editClause":
				setElement(value)
				setShowClauseModal(true)
				break
			case "editVariable":
				setElement(value)
				setShowVariableModal(true)
				break
			case "addClause":
				setElement(null)
				setShowClauseModal(true)
				break
			default:
				setElement(null)
				setShowVariableModal(true)
		}
	}, [])

	const initializeDocument = (data) => {
		formik.setValues({
			name: data.name,
			type: data.type,
			content: data.content,
			header: data.header,
			footer: data.footer,
			description: data.description,
			category: data.category["@id"],
			status: Boolean(data.status),
			orientation: data.orientation,
		})
		setClauses(data.clauses)
		setTypeDocument(data.type)
		setVariables(data.variables)
		try {
			if (data.type === "xlsx") {
				const sheetData = JSON.parse(data.content)
				populateSheet(sheetData)
			} else {
				if (tinyInstance !== null) {
					tinyForHeaderInstance.editorCommands.commands.exec.setContent(data.header)
					tinyInstance.editorCommands.commands.exec.setContent(data.content)
					tinyForFooterInstance.editorCommands.commands.exec.setContent(data.footer)
				}
			}
		} catch (e) {
			resetEditor()
			console.error(e)
		}
	}

	const saveDocument = (documentData) => {
		console.log(documentData)
		if (!isGranted(["ROLE_ADMIN", "ROLE_USER"])) {
			toast({
				position: "top",
				duration: 9000,
				isClosable: true,
				status: "error",
				title: DENIED_ACTION_TITLE,
				description: DENIED_ACTION_ERROR,
			})
			return false
		}
		setIsSaved(false)
		setIsSaving(true)
		if (isEditMode) {
			updateDocument(documentId, documentData)
		} else {
			createDocument(documentData)
		}
	}

	const createDocument = (document) => {
		postDocument.mutate(document, {
			onSuccess: () => {
				setIsSaved(true)
				navigate("/dashboard/document")
				toast({
					position: "top",
					duration: 9000,
					isClosable: true,
					status: "success",
					title: "Votre document est crée avec succès",
				})
			},
			onError: () => {
				toast({
					position: "top",
					duration: 9000,
					isClosable: true,
					status: "error",
					title: APP_ERROR,
				})
			},
			onSettled: () => setIsSaving(false),
		})
	}

	const updateDocument = (documentId, document) => {
		patchDocument.mutate(
			{ documentId, document },
			{
				onSuccess: () => {
					setIsSaved(true)
					setIsEditMode(false)
					toast({
						position: "top",
						duration: 9000,
						isClosable: true,
						status: "success",
						title: "Votre document est mis à jour avec succès",
					})
				},
				onError: () => {
					toast({
						position: "top",
						duration: 9000,
						isClosable: true,
						status: "error",
						title: APP_ERROR,
					})
				},
				onSettled: () => setIsSaving(false),
			}
		)
	}

	// variables
	const sheetD = { sheetData, setSheetData }
	const sheet = { sheetSelected: sheetSelec, setSheetSelected: setSheetSelec }

	if (isError && !isFetching) {
		return (
			<NoContent
				showLink={true}
				link='/dashboard/document/create'
				linkText='Créer un document'
				message='Document introuvable'
			/>
		)
	}

	return (
		<form onSubmit={formik.handleSubmit} noValidate>
			<FormikContext.Provider value={formik}>
				<SheetContext.Provider value={sheet}>
					<SheetDataContext.Provider value={sheetD}>
						<Flex flexDirection={{ base: "column", lg: "row" }} gap='20px'>
							<Box padding='8px' width={{ base: "100%", lg: "27%" }}>
								<Sidebar handleChange={handleChange} />
							</Box>
							<Box
								bg='dark.100'
								borderRadius={8}
								position='relative'
								padding='26px'
								width={{ base: "100%", lg: "73%" }}
							>
								
								<Content isSaving={isSaving} isEditMode={isEditMode} iisFetching={isFetching} />
								
								<ClauseModal
									clause={element}
									isOpen={showClauseModal}
									onClose={() => setShowClauseModal(false)}
								/>
								<VariableModal
									variable={element}
									isOpen={showVariableModal}
									onClose={() => setShowVariableModal(false)}
								/>
							</Box>
						</Flex>
					</SheetDataContext.Provider>
				</SheetContext.Provider>
			</FormikContext.Provider>
		</form>
	)
}

const mapStateToProps = (state) => {
	return {
		clauses: state.document.clauses,
		variables: state.document.variables,
	}
}

const mapDispatchToProps = {
	resetEditor: resetAction,
	setClauses: setClausesDoc,
	populateSheet: setDataSheet,
	setVariables: setVariablesDoc,
}

export default connect(mapStateToProps, mapDispatchToProps)(Editor)
