import React, {  useState, useContext, useCallback } from 'react';
import { BsArrowReturnLeft } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { 
    Box, 
    Icon, 
    Text, 
    Image, 
    HStack,
    useToast,
    Skeleton,
    useDisclosure 
} from '@chakra-ui/react';

// Assets
import DocumentIcon from '../../assets/svg/Document.svg';

// Components
import Callback from './callback/Callback';
import SubTitle from '../../components/SubTitle';
import DocumentTable from './block/DocumentTable';
import NoContent from '../../components/NoContent';
import Template from '../../components/dashboard/Template';
import DocumentTablePlaceholder from './block/DocumenTablePlaceholder';
import ConfirmationModal from '../../components/modal/ConfirmationModal';

// Context
import PermissionsContext from '../../context/PermissionsContext';

// Hooks
import { useFetchCategory } from '../../hooks/category';
import { useDeleteDocument, useFetchDocByCategory } from '../../hooks/document';

// Constants
import {APP_ERROR, DENIED_ACTION_TITLE, DENIED_ACTION_ERROR} from "../../config/constants";

function CategoryDocumentList(){

    // states
    const [isDeleting, setIsDeleting] = useState(false);
    const [documentId, setDocumentId] = useState(null);

    // context
    const { isGranted } = useContext(PermissionsContext);

    // hooks
    const toast = useToast();
    const { categoryId } = useParams();
    const queryClient = useQueryClient();
    const { isOpen, onOpen, onClose } = useDisclosure();

    // hooks
    const deleteQuery = useDeleteDocument();
    const { data:category, isError:isCatFail, isFetching:isCatLoading } = useFetchCategory(categoryId);
    const { data:documents, isError:isDocFail, isFetching:isDocLoading } = useFetchDocByCategory(categoryId);

    // callback
    const onDelete = useCallback( (id) => {
        if( !isGranted(['ROLE_ADMIN', 'ROLE_USER']) ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: DENIED_ACTION_ERROR
            });
            return false;
        }
        if( !id ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: 'Action non autorisée',
                description: "ID document manquant"
            });
            return false;
        }
        onOpen();
        setDocumentId(id);
        setIsDeleting(false);
    }, [] );

    const onConfirmation = useCallback( () => {

        if( !documentId ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: "ID document manquant"
            });
            return false;
        }
        setIsDeleting(true)
        deleteQuery.mutate(documentId, {
            onSuccess : () => {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'success',
                    title: 'Suppression document avec succès',
                });
                queryClient.invalidateQueries({ queryKey: ['fetchDocByCategory'] });
            },
            onError : () => {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'error',
                    title: APP_ERROR,
                });
            },
            onSettled : () => {
                onClose();
                setIsDeleting(false)
            }
        })

    }, [ documentId ] );

    const renderContent = () => {

        if( isDocLoading ){
            return (
                <DocumentTablePlaceholder />
            )
        }

        if( isCatFail || isDocFail || !documents.length ){
            return ( 
                <NoContent 
                    showLink = { true }
                    link = '/dashboard/document/create'
                    linkText = 'Créer un document'
                    message = 'Aucun document trouvé'
                /> 
            )
        }

        return (
            <DocumentTable 
                isFetching={false}
                documentData={documents}
                onDeleteDocument={onDelete}
            /> 
        )

    }

    return (
        <Template 
            title='Documents'
            callback={<Callback />}
            restrictedTo={['ROLE_ADMIN', 'ROLE_USER']}
        >
            <Box bg="dark.300" borderRadius={8} paddingY={30}> 
                <Box paddingX={{ base:2, lg: 8 }}>
                    <Skeleton isLoaded={!isCatLoading} borderRadius={8} maxW={ isCatLoading ? '300px' : 'inherit' }>
                        {
                            !isCatFail && category && 
                                <HStack gap={4} alignItems='center' mb={6}>
                                <Link to='/dashboard/document' state={{ 'view' : 'category' }} title='Retourner' aria-label='Retourner'>
                                    <Icon as={BsArrowReturnLeft} color='white' fontSize={22} position='relative' top='6px' />
                                </Link>
                                <Text color='white' fontSize={22}> / </Text>
                                <HStack gap={3}>
                                    <Image src={DocumentIcon} maxWidth={19} />
                                    <SubTitle color='white' fontSize={17}>{category?.name}</SubTitle>
                                </HStack>
                            </HStack>
                        }
                    </Skeleton>
                    { renderContent() }
                </Box>
                <ConfirmationModal
                    isOpen = {isOpen}
                    onClose = {onClose}
                    isLoading = {isDeleting}
                    title = 'Supprimé un document'
                    onConfirmation = {onConfirmation}
                    description = 'Vous voulez supprimer ce document !'
                />
            </Box>
        </Template>
    )
}


export default CategoryDocumentList;