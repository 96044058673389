import http from '../../utils/http'

const END_POINT = '/documents';
/**
 * @name fetchAllAction
 * @reducer DoucmentsReducer
 * @description Get all documents from API
 */
export const fetchAllAction = (page=1, status=false) => {

    const fetchRequest = () => {
        return {
            totals : 0,
            type : 'DOCUMENTS_FETCHING'
        }
    }

    const fetchSuccess = ( playload ) => {
        const totals = playload['hydra:totalItems'];
        const documents =  playload['hydra:member'];
        const page = playload['hydra:view'] ? playload['hydra:view']['@id'].split('?page=')[1] : 1;
        const lastPage = page - 1 > 0 ? page-1 : 1;
        return {
            page : page,
            totals : totals,
            playload : documents,
            lastPage : lastPage,
            type : 'DOCUMENTS_FETCH_SUCCESS',
        }
    }

    const fetchFail = () => {
        return {
            totals : 0,
            type : 'DOCUMENTS_FETCH_FAIL'
        }
    }

    return ( dispatch ) => {
        dispatch( fetchRequest() );
        let url = END_POINT+'?page='+page;
        if(status){
            url += '&status=1';
        }
        http.get(url)
            .then ( response => {
                if( response.status === 200 ){
                    dispatch( fetchSuccess( response.data ) );
                }else{
                    dispatch( fetchFail() );
                }
            } )
            .catch( e => {
                dispatch( fetchFail() );
            } )
    }

}

/**
 * @name fetchDocumentsByCategory
 * @reducer DoucmentsReducer
 * @description Get all documents by category from API
 */
export const fetchDocumentsByCategory = (categoryId, status=false) => {

    const fetchRequest = () => {
        return {
            totals : 0,
            type : 'DOCUMENTS_FETCHING'
        }
    }

    const fetchSuccess = ( playload ) => {
        const totals = playload['hydra:totalItems'];
        const documents =  playload['hydra:member'];
        const page = playload['hydra:view'] ? playload['hydra:view']['@id'].split('?page=')[1] : 1;
        const lastPage = page - 1 > 0 ? page-1 : 1;
        return {
            page : page,
            totals : totals,
            playload : documents,
            lastPage : lastPage,
            type : 'DOCUMENTS_FETCH_SUCCESS',
        }
    }

    const fetchFail = () => {
        return {
            totals : 0,
            type : 'DOCUMENTS_FETCH_FAIL'
        }
    }

    return ( dispatch ) => {
        dispatch( fetchRequest() );
        let url = '/document_categories/'+categoryId+'/documents';
        if( status ){
            url += '?status=1';
        }
        http.get(url)
            .then ( response => {
                if( response.status === 200 ){
                    dispatch( fetchSuccess( response.data ) );
                }else{
                    dispatch( fetchFail() );
                }
            } )
            .catch( e => {
                dispatch( fetchFail() );
            } )
    }

}

/**
 * @name fetchMoreAction
 * @reducer DoucmentsReducer
 * @description Fetch more documents from API
 */
export const fetchMoreAction = (page=1, status=false) => {

    const fetchSuccess = (playload) => {
        const totals = playload['hydra:totalItems'];
        const documents =  playload['hydra:member'];
        const page = playload['hydra:view']['@id'].split('?page=')[1];
        const lastPage = page - 1 > 0 ? page-1 : 1;
        return {
            page : page,
            totals : totals,
            lastPage : lastPage,
            playload : documents,
            type : 'DOCUMENTS_MORE_SUCCESS',
        }
    }

    const fetchFail = () => {
        return {
            type : 'DOCUMENTS_FETCH_FAIL'
        }
    }

    return ( dispatch ) => {
        let url = END_POINT+'?page='+page;
        if(status){
            url += '&status=1';
        }
        http.get(url)
            .then( response => {
                if( response.status === 200 ){
                    dispatch( fetchSuccess( response.data ) );
                }
            } )
            .catch( error => {
                dispatch( fetchFail() );
            } )
    }

}

/**
 * @name deleteDocument
 * @param {*} ID 
 */
export const deleteDocument = (ID) => {

    return ( dispatch ) => { 
        dispatch({ type : 'DOCUMENTS_DELETE_ONE_ITEM', playload : ID })
    }

}