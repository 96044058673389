import React, { useRef, useEffect, useContext, forwardRef } from "react";
import { connect } from "react-redux";
import { FormikContext } from "formik";
import { Spreadsheet } from "react-spreadsheet";
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import { Icon, Box, HStack, FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";

// Context
import SheetContext from "../../../context/SheetContext";
import SheetDataContext from "../../../context/SheetDataContext";

// Middlewrae
import { 
    addRowToSheet,
    deleteRowFromSheet,
    addColumnToSheet, 
    deleteColumnFromSheet,
} from "../../../actions/document/DocumentAction";

const SheetEditor = forwardRef( (props, ref ) => {

    const sheetRef = useRef(null);
    const formik = useContext(FormikContext);
    const { clauses, variables, addRow, sheetData, deleteRow, addCloumn, deleteColumn } = props;

    // context
    const { setSheetData } = useContext( SheetDataContext ); 
    const { setSheetSelected } = useContext( SheetContext );

    useEffect( () => {
        if( clauses.length || variables.length ){
            setSheetData(sheetData);
        }
    }, [ clauses, variables, sheetData ] )

    const handleChange = (range) => {
        if( range !== undefined ){
            setSheetSelected( range );
        }
    }

    const handleColumnChange = (action = 'add') => {
        switch( action ){
            case 'delete':
                deleteColumn();
                break;
            default :
                addCloumn();
        }
        setTimeout( () => {
            sheetRef.current.scrollLeft = sheetRef.current.scrollWidth;
        }, 300 )
    }

    const handleRowChange = (action = 'add') => {
        switch( action ){
            case 'delete':
                deleteRow();
                break;
            default :
                addRow();
        }
    }

    const renderColumnButtons = () => {
        return (
            <HStack gap={4} alignItems='center'>
                <Icon as={FaPlusCircle} color='wheat' cursor='pointer' aria-label="Ajouter une colonne" onClick={ () => handleColumnChange('add') } />
                <Icon as={FaMinusCircle} color='wheat' cursor='pointer' aria-label="Supprimer une colonne" onClick={ () => handleColumnChange('delete') } />
            </HStack>
        )
    }

    const renderRowButtons = () => {
        return (
            <HStack gap={4} alignItems='center'>
                <Icon as={FaPlusCircle} color='wheat' cursor='pointer' aria-label="Ajouter une colonne" onClick={ () => handleRowChange('add') } />
                <Icon as={FaMinusCircle} color='wheat' cursor='pointer' aria-label="Supprimer une colonne" onClick={ () => handleRowChange('delete') } />
            </HStack>
        )
    }

    return (
        <Box className={formik.errors.content ? 'sheet-editor editor-has-error' : 'sheet-editor'}>
            <FormControl isInvalid={formik.errors.content}>
                <HStack justifyContent='space-between'>
                    <FormLabel htmlFor='contenu' fontSize='18px' color='blue.100'>Contenu</FormLabel>
                    { renderColumnButtons() }
                </HStack>
                <Box 
                    ref={sheetRef} 
                    borderRadius={8}
                    overflowX='scroll' 
                    overflowY='hidden' 
                    className="custom_scrollbar_jaune" 
                >
                    <Spreadsheet 
                        data={sheetData}
                        onChange={ (data) => { setSheetData(data); } }
                        onSelect={ ({range})  => handleChange(range) } 
                    />
                </Box>
                <FormErrorMessage mt={3}>{formik.errors.content}</FormErrorMessage>
                <HStack justifyContent='flex-end' mt={4}>
                    { renderRowButtons() }
                </HStack>
            </FormControl>
        </Box>
    )

});

const mapStateToProps = state => {
    return {
        sheetData : state.sheet.data,
        clauses : state.document.clauses,
        variables : state.document.variables
    }
}

const mapDispatchToProps = {
    addRow : addRowToSheet,
    addCloumn : addColumnToSheet,
    deleteRow : deleteRowFromSheet,
    deleteColumn : deleteColumnFromSheet
}

export default connect(mapStateToProps, mapDispatchToProps)(SheetEditor);