import axios from "axios"
import { createStandaloneToast } from "@chakra-ui/react"
import { useMutation } from "@tanstack/react-query"
import { getBaseApiUrl, getHeader, revalidateToken } from "../../utils"

const { toast } = createStandaloneToast()

const postForgotPassword = async (data) => {
	const endpoint = getBaseApiUrl("/forgot_password/")
	const response = await axios.post(endpoint, data, getHeader())
	return response.data
}

export default function usePostForgotPassword() {
	return useMutation({
		mutationKey: ["postForgotPassword"],
		mutationFn: (data) => postForgotPassword(data),
		onMutate: async (response) => {
			return response
		},
		onSuccess: (response) => {
			toast({
				position: "top",
				duration: 9000,
				isClosable: true,
				status: "success",
				title: "Nous vous avons anvoyé un email pour recuperer votre mot de passe",
			})
		},
		onError: (e) => {
			if (e.response.status === 422) {
				toast({
					position: "top",
					duration: 9000,
					isClosable: true,
					status: "error",
					title: "Mot de passe oublié",
					description: e.response.data.detail,
				})
			}
		},
	})
}
