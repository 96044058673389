import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { getApiUrl, getHeader, revalidateToken } from '../../utils';

export default function useFetchByUrl(url){
    return useQuery({
        enabled: !!url,
        queryKey: ['fetchByUrl', url],
        queryFn: async () => {
            try{
                const endpoint = getApiUrl(`/shared_packs_by_url/${url}`);
                const response =  await axios.get(endpoint, getHeader());
                console.log(response);
                const data = response.data['hydra:member'];
                return data.length ? data[0] : data;
            }catch( e ){
                if( e.response.status === 401 ){
                   await revalidateToken();
                }else{
                    console.error( e.response.data.detail );
                }
            }
        }
    });
    
} 
