import http from '../../utils/http'
import { useMutation,useQueryClient } from '@tanstack/react-query';

const deleteClause = async(clauseId) => {
    const response = await http.delete(`/clauses/${clauseId}`);
    return response.data;
}

export default function useDeleteClause(){
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey : ['deleteClause'],
        mutationFn : (clauseId) => deleteClause(clauseId),
        onSuccess: () => {
            queryClient.invalidateQueries(['fetchClauses']);
          },
    })

}