import React from 'react';
import { SimpleGrid } from '@chakra-ui/react';

// Components
import PackItem from './PackItem';

function PackPlaceholder ({ isLoading }){

    let index = 0;
    const packs = Array(12).fill({ name : 'pack', link : '#' })

    return (
        <SimpleGrid columns={{base:1, sm: 2, lg: 3, xl: 4}} spacing={10}>
            { 
                packs.map( (pack) => {
                    index++
                    return (
                        <PackItem 
                            key={index}
                            name = {pack.name}
                            link = {pack.link}
                            isLoading = {isLoading}
                        />
                    )
                } )
            }
        </SimpleGrid>
    )

}

PackPlaceholder.defaultProps = {
    isLoading : true
}

export default PackPlaceholder;