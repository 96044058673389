import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';

// Components
import Callback from './callback/Callback';

// Global
import Template from '../../components/dashboard/Template';

// Context
import TinyForClauseContentMceContext from '../../context/TinyForClauseContent';
import CreateClause from "./form/clauseForm"
import TypeDocumentContext from '../../context/TypeDocumentContext';
import CkeditorContext from '../../context/CkeditoContext';

function CreateClausePage() {
    // States for each TinyMCE instance
    const [ckEditorInstance, setCkInstance] = useState(null);
    const [typeDoc, setTypeDoc] = useState('pdf');

    // Context values
    const ckeditor = { ckEditorInstance, setCkInstance };
    const type = { typeDocument: typeDoc, setTypeDocument: setTypeDoc };


    return (
        <>
            <CkeditorContext.Provider value={ckeditor}>
                <TypeDocumentContext.Provider value={type}>
                    <Template
                        title='Documents'
                        callback={<Callback />}
                        restrictedTo={['ROLE_ADMIN', 'ROLE_USER']}
                    >
                        <Box bg="dark.300" borderRadius={8} padding='26px'>
                            <CreateClause />
                        </Box>
                    </Template>
                </TypeDocumentContext.Provider>
            </CkeditorContext.Provider>
        </>
    );
}

export default CreateClausePage;
