import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, InputGroup, InputRightElement, Text, Select, Spinner } from '@chakra-ui/react';
import { InputField } from '../../../components/fields';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import usePatchSettings from '../../../hooks/settings/usePatchSettings';
import { VALIDATION_ERROR } from '../../../config/constants';
import { Icon } from '@iconify/react';
import { useToast } from '@chakra-ui/react';

const SettingsItem = ({ settingsId, value, name, width, height, lineHeight, background, fontSize, patchFunction }) => {
    const FormatLabel = (label) => {
        switch (label) {
            case "credit_price":
                return "Prix de crédit";
            case "currency":
                return "Devise";
            case "stripe_test_public_key":
                return "Stripe clé public de TEST";
            case "stripe_test_secret_key":
                return "Stripe clé secrète de TEST";
            case "stripe_live_public_key":
                return "Stripe clé public";
            case "stripe_live_secret_key":
                return "Stripe clé secrète";
            case "stripe_mode":
                return "Mode de stripe actuellement";
            case "app_path":
                return "Chemin d’accès à l’application";
            default:
                return "";
        }
    };

    const toast = useToast();
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State for password visibility

    const togglePasswordVisibility = () => setShowPassword(!showPassword); // Function to toggle password visibility

    const mutation = usePatchSettings();
    const currencyOptions = [{ name: "Euro", value: 'eur' }, { name: "Dollard", value: 'usd' }];
    const testModeOptions = [{ name: 'Test', value: "test" }, { name: 'Production', value: "live" }];

    const validationSchema = Yup.object().shape({
        [name]: Yup.string().required('Ce champ est obligatoire'),
    });

    const formik = useFormik({
        initialValues: { [name]: value ?? '' },
        validateOnChange: false,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log({ id: settingsId, value: values[name] });
            mutation.mutate({ settingsId, value: values[name] }, {
                onError: (error) => {
                    setIsError(true);
                    setErrorMessage(VALIDATION_ERROR);
                },
                onSuccess: (response) => {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'success',
                        title: `${name} changé avec succès`,
                    });
                    return response;
                },
            });
        }
    });

    return (
        <Box borderRadius={8} paddingY={30}>
            <Text color='#787675' fontSize='12px' fontWeight={500} mb='8px'>{FormatLabel(name) ?? ''}</Text>
            <form onSubmit={formik.handleSubmit}>
                <InputGroup>
                    {name === 'currency' ? (
                        <Select
                            name={name}
                            value={formik.values[name] ?? ''}
                            onChange={formik.handleChange}
                            height='48px'
                            color='#fff'
                            bg='#323232'
                            borderRadius={8}
                            width="100%"
                            fontSize={fontSize}
                            borderColor='dark.100'
                        >
                            {currencyOptions.map((option) => (
                                <option style={{ paddingRight: "4px" }} key={option.value} value={option.value}>{option.name}</option>
                            ))}
                        </Select>
                    ) : (name === 'stripe_mode' ? (
                        <Select
                            name={name}
                            value={formik.values[name] ?? ''}
                            onChange={formik.handleChange}
                            height='48px'
                            color='#fff'
                            bg='#323232'
                            borderRadius={8}
                            width="100%"
                            fontSize={fontSize}
                            borderColor='dark.100'
                        >
                            {testModeOptions.map((option) => (
                                <option style={{ paddingRight: "4px" }} key={option.value} value={option.value}>{option.name}</option>
                            ))}
                        </Select>
                    ) :
                        <InputField
                            type={name === 'stripe_test_secret_key' && !showPassword ? 'password' : 'text'}
                            name={name}
                            value={formik.values[name] ?? ''}
                            onChange={formik.handleChange}
                            bg="dark.300"
                            height={height ?? '48px'}
                            color='white'
                            fontSize={fontSize ?? '10px'}
                            padding='22px'
                            borderWidth={1}
                            borderRadius={8}
                            width={width ?? 'auto'}
                            lineHeight={lineHeight ?? '13px'}
                            borderColor='dark.100'
                        />
                    )}
                    {formik.errors[name] && formik.touched[name] && (
                        <Text color="red.500" fontSize="sm">{formik.errors[name]}</Text>
                    )}
                    <InputRightElement pl={1} pr={5} borderRadius={8} cursor="pointer" bg="dark.100" height="100%" width="15%">
                        {name === 'stripe_test_secret_key' ? (
                            <>
                                <Box onClick={togglePasswordVisibility}  display="flex"  alignItems="center"  pr={2}>
                                    <Icon
                                        icon={showPassword ? "mdi:eye-off" : "mdi:eye"}
                                        style={{ color: "teal", fontSize: "24px" }}
                                    />
                                </Box>
                                <Box onClick={formik.handleSubmit} display="flex" alignItems="center">
                                    <Icon
                                        icon="dashicons:yes"
                                        style={{ color: "#6a994e", fontSize: "24px" }}
                                    />
                                </Box>
                            </>
                        ) : (
                            mutation.isLoading ? (
                                <Spinner size="sm" />
                            ) : (
                                <Box onClick={formik.handleSubmit} display="flex" alignItems="center">
                                    <Icon
                                        icon="dashicons:yes"
                                        style={{ color: "#6a994e", fontSize: "24px" }}
                                    />
                                </Box>
                            )
                        )}
                    </InputRightElement>
                </InputGroup>
            </form>
        </Box>
    );
}

SettingsItem.propTypes = {
    value: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    lineHeight: PropTypes.string,
    background: PropTypes.string,
    fontSize: PropTypes.string,
    patchFunction: PropTypes.func.isRequired,
}

export default SettingsItem;
