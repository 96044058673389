import React from 'react';
import { Flex, Box } from '@chakra-ui/react';

// Global
import Template from '../../components/dashboard/Template';

// Components
import Callback from './callback/Callback';
import ClauseTable from './block/ClauseTable';
import ClauseForm from '../../components/form/clause/ClauseForm';

class ClausePage extends React.Component{

    render(){
        return (
            <Template 
                title='Clauses'
                callback={<Callback />}
                restrictedTo={['ROLE_ADMIN', 'ROLE_USER','ROLE_PROFESSIONAL']}
            >
                <Flex flexDirection={{ base : 'column', lg : 'row' }} gap='20px'>
                    {/*
                    <Box bg="dark.300" borderRadius={8} padding='26px' width={{ base : '100%', lg : '30%' }}>
                        <ClauseForm />
                    </Box>
                     */}
                    <Box bg="dark.300" borderRadius={8} padding='26px' width={{ base : '100%', lg : '100%' }}>
                        <ClauseTable />
                    </Box>
                </Flex>
            </Template>
        )
    }

}

export default ClausePage;