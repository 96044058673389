import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from '@chakra-ui/react';

// Components
import Clauses from './Clauses';
import Variables from './Variables';
import Customer from './Customer';
import DocumentDetail from './DocumentDetail';
import DocDocuments from './Documents';

function DocDetailView({ values, errors, isSaving, isFetching, onClauseChange }) {
    
    return (
        <Flex flexDirection={{ base: 'column', lg: 'row' }} gap='40px'>
            <Box width={{ base: '100%', lg: '70%' }}>
                <DocumentDetail
                    isFetching={isFetching}
                />
                <Customer
                    values={values}
                    errors={errors}
                    isFetching={isFetching}
                />
                <Clauses
                    isFetching={isFetching}
                    onClauseChange={onClauseChange}
                />
                <Variables
                    values={values}
                    errors={errors}
                    isFetching={isFetching}
                />
                
            </Box>
            <Box width={{ base: '100%', lg: '30%' }}>
                <DocDocuments
                    isSaving={isSaving}
                    isFetching={isFetching}
                />
            </Box>
        </Flex>
    );
}

DocDetailView.defaultProps = {
    isSaving: false,
    isFetching: false
};

DocDetailView.propTypes = {
    isSaving: PropTypes.bool,
    isFetching: PropTypes.bool,
    onClauseChange: PropTypes.func.isRequired
};

export default DocDetailView;
