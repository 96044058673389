import React, { useState, useEffect, useContext, forwardRef } from 'react';
import Fuse from 'fuse.js';
import { FormikContext } from 'formik';
import { SearchIcon } from '@chakra-ui/icons';
import { Stack, Input, Button, InputGroup, InputLeftElement, Skeleton, FormControl, FormErrorMessage } from '@chakra-ui/react';

// Components
import ClauseItem from './ClauseItem';
import SubTitle from '../../../components/SubTitle';
import NoContent from '../../../components/NoContent';

// Hooks
import { useFetchClauses } from '../../../hooks/clause';

const Clauses = forwardRef( ( { handleChange }, ref ) => {

    // states
    const [ pattern, setPattern ] = useState('');
    const [ clausesList, setClausesList ] = useState([]);

    // context
    const formik = useContext(FormikContext);
    
    // hooks
    const { data:clauses, isFetching, isError } = useFetchClauses('publish');

     // lifecycle
     useEffect( () => {

        if(clauses){
            setClausesList(clauses)
        }

    }, [ clauses ] );

    // callback
    const handleSearch = (e) => {
        const pattern = e.target.value;
        const fuseOptions = {
            keys: [
                "title",
            ],
            findAllMatches : true
        }
        setPattern( pattern );
        const fuse = new Fuse(clauses, fuseOptions);
        const result = fuse.search(pattern);
        if( result.length === 0 && pattern.length === 0 ){
            setClausesList(clauses);
        }else{
            setClausesList(result);
        }
    }

    const renderClauses = () => {
        return (
            <Stack direction='column' spacing={3} className='clauses_list custom_scrollbar' maxHeight={430} overflowY='scroll'>
                {
                    clausesList?.length && clausesList.map( clause => {
                        return (
                            <ClauseItem key={clause.item ? clause.item.id : clause.id} clause={clause} handleEdit={handleChange} />
                        )
                    } )
                }
            </Stack>
        )
    }

    if( isError || ( !isFetching && !clauses )){
        return (
            <Stack direction='column' spacing={4}>
                <SubTitle>Liste des clauses :</SubTitle>
                <NoContent 
                    showLink = { true }
                    link = '/dashboard/clause'
                    linkText = 'Ajouter une clause'
                    message = 'Clause introuvable'
                />
            </Stack>
        )
    }

    return (
        <FormControl isInvalid={formik.errors.variables}>
            <Stack direction='column' spacing={4}>
                <SubTitle>Liste des clauses :</SubTitle>
                <Skeleton isLoaded={!isFetching} borderRadius={8}>
                    <InputGroup mb={4}>
                        <InputLeftElement pointerEvents='none' height='48px'>
                            <SearchIcon color='gray.300' />
                        </InputLeftElement>
                        <Input 
                            id='search_clause'
                            name='search_clause'
                            type='search'
                            height='48px'
                            color='#fff'
                            borderRadius={8}
                            borderColor='#323232'
                            backgroundColor='#323232'
                            value={pattern}
                            onChange={e => handleSearch(e)}
                            placeholder='Rechercher une clause'
                        />
                    </InputGroup>
                    { renderClauses() }
                    <Stack spacing={clauses?.length ? 4 : 0} mt={4}>
                        <Button 
                            width='100%'
                            type='button'
                            bg = 'red.100' 
                            height = '44px'
                            color = 'white'
                            fontWeight = {500}
                            spinnerPlacement = 'end'
                            _hover = {{ bg: 'yellow.100', color: 'dark.200' }} 
                            onClick = { () => {
                                handleChange('addClause', null);
                            } }
                        >Ajouter une clause +</Button>
                    </Stack>
                </Skeleton>
            </Stack>
            <FormErrorMessage>{formik.errors.variables}</FormErrorMessage>
        </FormControl>
    )

});

Clauses.defaultProps = {
    variables : [],
    isFetching : false
}

export default Clauses;