import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Box, HStack, Text } from '@chakra-ui/react';
import { createColumnHelper } from "@tanstack/react-table";

// Components
import SaisieActions from './SaisieActions';
import DataTable from '../../../components/DataTable';
import StatusSharedDoc from '../../../components/StatusShareDoc';

// Helper function to truncate strings
const truncateString = (str, maxLength) => {
    if (!str) return "";
    if (str.length <= maxLength) {
        return str;
    }
    return str.slice(0, maxLength - 3) + '...';
};

function SaisieTable({ isFetching, packData, onDeletePack }) {

    const getUserInfo = (user) => {
        console.log(user);
        if (!user) return null;
        const firstname = user?.profile?.firstname || "";
        const lastname = user?.profile?.lastname || "";
        const userType = user["@type"] || "";
    
        return (
			<>
				{user?.roles?.includes("ROLE_ADMIN") ? (
					<Badge 
					display="flex" 
					justifyContent="center" 
					alignItems="center" 
					size="xs" 
					ml="2" 
					width={100} 
					colorScheme="yellow" 
					border="none"
				  >
					Admin
				  </Badge>
					
				):<Box display='flex' alignItems='center'>
					<Text>{firstname && lastname ? `${firstname} ${lastname}` : "---"}</Text>
						{userType && (
							<Badge className={{color:"teal"}} variant='outline' size="xs" ml='2'>
								{userType}
							</Badge>
						)}
					</Box>
				}
			</>
        );
    };

    const getColumns = () => {
        const columnHelper = createColumnHelper();
        const columns = [
            columnHelper.accessor('user', {
                cell: (info) => getUserInfo(info.getValue()),
                header: "Partagé Par"
            }),
            columnHelper.accessor('user.profile.companyName', {
                cell: (info) => truncateString(info.getValue(), 15),
                header: "Entreprise"
            }),
            columnHelper.accessor('client', {
                cell: (info) => info.getValue()?.firstname || "---",
                header: 'Destinataire'
            }),
            columnHelper.accessor('createdAt', {
                cell: (info) => new Date(info.getValue()).toLocaleDateString(),
                header: 'Date de Partage'
            }),
            columnHelper.accessor('status', {
                cell: (info) => <StatusSharedDoc state={info.getValue()} />,
                header: 'État'
            }),
            columnHelper.accessor('id', {
                cell: (info) => (
                    <SaisieActions 
                        id={info.getValue()} 
                        onDelete={onDeletePack}
                    /> 
                ),
                header: 'Actions',
                enableSorting: false
            })
        ];
        return columns;
    };

    const saisieColumns = getColumns();

    return (
        <Box>
            <Box bg="dark.200" paddingY={4} borderRadius={10}>
                <DataTable 
                    data={packData || []}
                    columns={saisieColumns}
                    isLoading={isFetching}
                />
            </Box>
        </Box>
    );
}

SaisieTable.propTypes = {
    isFetching: PropTypes.bool,
    packData: PropTypes.array,
    onDeletePack: PropTypes.func
};

export default SaisieTable;
