import React from 'react';
import { Badge, background } from '@chakra-ui/react';
import { Box,Text  } from '@chakra-ui/react';
import TextInput from '../../../components/TextInput';

const TransactionStatusDetail = ({status}) => {
    let statusText;
    let colorScheme;

    switch (status) {
        case 0:
            statusText = 'en cours';
            colorScheme = '#003554';
            break;
        case 1:
            statusText = 'payé';
            colorScheme = '#4c956c';
            break;
        case 2:
            statusText = 'échoué';
            colorScheme = '#ff595e';
            break;
        default:
            statusText = 'Unknown';
            colorScheme = 'gray';
            break;
    }

    return (
        <>
            <Box mt={4}>
                <Text color='#787675' fontSize='16px' fontWeight={500} mb='8px' >Etat</Text>
                <TextInput background={colorScheme} textColor={"dark.100"} value={statusText} />
            </Box>
        
        </>
    );
}

export default TransactionStatusDetail;
