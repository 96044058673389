import React from "react";

// Components
import MenuGroup from "./MenuGroup";
import Restricted from "../../Restricted";

// Icons
import TransactionIcon from "./assets/img/transaction.svg"

function CompteMenu(){
    const menus = [
        {
            'to': '/dashboard/profile/transaction',
            'icon' : TransactionIcon,
            'title' : 'Les transactions'
        }
    ];
    return (
        <Restricted to={['ROLE_USER','ROLE_ADMIN']}>
            <MenuGroup name="Mon compte" menus={menus} />
        </Restricted>
    )
}

export default CompteMenu;