import React from "react";

// Components
import MenuGroup from "./MenuGroup";

// Icons
import CompteIcon from './assets/img/Compte.svg';
import AdminIcon from './assets/img/admin.svg';
import ProIcon from './assets/img/pro.svg';
import { isGranted } from "../../../utils";


function UsersMenu() {
    const isAdmin = isGranted(['ROLE_ADMIN'])
    const isPro = isGranted(['ROLE_PROFESSIONAL'])
    const menusAdmin = [
        {
            'to': '/dashboard/admin',
            'icon': AdminIcon,
            'title': 'Admins'
        },
        {
            'to': '/dashboard/pro',
            'icon': ProIcon,
            'title': 'Professionels'
        },
        {
            'to': '/dashboard/users',
            'icon': CompteIcon,
            'title': 'Clients'
        },


    ];
    const menusPro = [
        {
            'to': '/dashboard/users',
            'icon': CompteIcon,
            'title': 'Clients'
        }
    ]
    return (
        <MenuGroup name="Comptes" menus={isAdmin ? menusAdmin : isPro ? menusPro : []} />
    )
}

export default UsersMenu;