import React from "react"
import { Box, Badge, Text } from "@chakra-ui/react"
import { createColumnHelper } from "@tanstack/react-table"

// Components
import SubTitle from "../../../components/SubTitle"
import DataTable from "../../../components/DataTable"
import TransactionsActions from "./TransactionsActions"
import TransactionStatusBadge from "./TransactionStatus"
import useFetchTransactions from "../../../hooks/transactions/useFetchTransactions"

function TransactionData() {
	const { data: transactionsData } = useFetchTransactions()
	console.log(transactionsData);
	const getName = (user) => {
        if (!user) return null;
        const firstname = user?.profile?.firstname || "";
        const lastname = user?.profile?.lastname || "";
        const userType = user["@type"] || "";
    
        return (
			<>
				{user?.roles?.includes("ROLE_ADMIN") ? (
					<Badge 
					display="flex" 
					justifyContent="center" 
					alignItems="center" 
					size="xs" 
					ml="2" 
					width={100} 
					colorScheme="yellow" 
					border="none"
				  >
					Admin
				  </Badge>
					
				):<Box display='flex' alignItems='center'>
					<Text>{firstname && lastname ? `${firstname} ${lastname}` : "---"}</Text>
						{userType && (
							<Badge className={{color:"teal"}} variant='outline' size="xs" ml='2'>
								{userType}
							</Badge>
						)}
					</Box>
				}
			</>
        );
    };
    
	const getType = (type) => {
        let text = "";
        let color = "";
    
        switch (type) {
            case "use_credit":
                text = "Dépense";
                color = "blue.100";
                break;
            case "buy_credit":
                text = "Achat";
                color = "teal";
                break;
            case "charge_credit":
                text = "Recharge";
                color = "teal";
                break;
            default:
                return null;
        }
    
        return (
            <Box display='flex' alignItems='center'>
                <Text ml='2' textColor={color}>
                    {text}
                </Text>
            </Box>
        );
    };
    

	const getColumns = () => {
		const columnHelper = createColumnHelper()
		const columns = [
			columnHelper.accessor("user", {
				cell: (info) => getName(info.getValue()),
				header: "Utilisateur",
			}),
			columnHelper.accessor("createdAt", {
				cell: (info) => new Date(info.getValue()).toLocaleDateString(),
				header: "Date",
			}),
			columnHelper.accessor("type", {
				cell: (info) => getType(info.getValue()),
				header: "Type",
			}),
			columnHelper.accessor("credit", {
				cell: (info) => info.getValue(),
				header: "Crédit",
			}),
			columnHelper.accessor("sharedPack.pack.name", {
				cell: (info) => (info.getValue() ? info.getValue() : "---"),
				header: "SharedPack",
			}),
			columnHelper.accessor("status", {
				cell: (info) => TransactionStatusBadge(info.getValue()),
				header: "Payment",
			}),
			columnHelper.accessor("id", {
				cell: (info) => (
					<Box textAlign='center' width={100}>
						<TransactionsActions id={info.getValue()} />
					</Box>
				),
				header: () => <Text textAlign='center'>Actions</Text>,
			}),
		]
		return columns
	}

	const transationColumn = getColumns()

	return (
		<Box>
			<Box bg='dark.200' paddingY={4} borderRadius={10}>
				<DataTable
					data={transactionsData ? transactionsData : []}
					columns={transationColumn}
					isLoading={false}
				/>
			</Box>
		</Box>
	)
}

export default TransactionData
