import http from '../../utils/http'
import { useMutation,useQueryClient } from '@tanstack/react-query';

const deleteUser = async(userId) => {
    const response = await http.delete('/users/'+userId);
    return response.data;
}

export default function useDeleteUser(){
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey : ['deleteUser'],
        mutationFn : (userId) => deleteUser(userId),
        onSuccess: () => {
            queryClient.invalidateQueries(['fetchUsers']);
          },
    })
}