import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Formik } from "formik";
import {
    Box,
    useToast,
    useDisclosure,
    Container,
    Portal,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody, Text, HStack, Button, Icon
} from '@chakra-ui/react';
import { object, string } from 'yup';

// Components
import Pack from './block/Pack';
import DetailView from './block/DetailView';
import NotFound from '../../components/NoFound';

// Context
import PackDetailContext from '../../context/PackDetailContext';

// Hooks
import { useFetchByUrl, usePostSharedPackData } from '../../hooks/shared';

// Utils
import { 
    urlSchema,
    logoSchema,
    textSchema, 
    phoneSchema,
    emailSchema,
    entierSchema,
    decimalSchema
} from '../../utils/schema';
import { BsDownload } from "react-icons/bs";

const customerSchema = {
    firstname: string().required('Ce champ est obligatoire'),
    lastname: string().required('Ce champ est obligatoire'),
    password: string().required('Ce champ est obligatoire'),
    email: string().email('Veillez renseigner une adresse email valide').required('Ce champ est obligatoire'),

};

function FormulairePage() {
    const savedClientId = localStorage.getItem('selectedClientId');
    const [values, setValues] = useState({});
    const [clauses, setClauses] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [zipURL, setZipURL] = useState(null);

    const toast = useToast();
    const { packURL } = useParams();
    const postQuery = usePostSharedPackData();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { data: pack, isError, isLoading } = useFetchByUrl(packURL);

    useEffect(() => {
        if (pack) {
            setValues(getVariables());
        }
    }, [pack]);

    const getSchema = () => {
        let schemas = {};
        if (pack) {
            pack.variables.forEach(variable => {
                switch (variable.type) {
                    case 'email':
                        Object.assign(schemas, emailSchema(variable.name));
                        break;
                    case 'url':
                        Object.assign(schemas, urlSchema(variable.name));
                        break;
                    case 'integer':
                        Object.assign(schemas, entierSchema(variable.name));
                        break;
                    case 'float':
                        Object.assign(schemas, decimalSchema(variable.name));
                        break;
                    case 'phone':
                        Object.assign(schemas, phoneSchema(variable.name));
                        break;
                    case 'logo':
                        Object.assign(schemas, logoSchema(variable.name));
                        break;
                    case 'textarea':
                    default:
                        Object.assign(schemas, textSchema(variable.name));
                }
            });
        }
        if (!savedClientId) {
            Object.assign(schemas, customerSchema);
        }
        return object(schemas);
    };
    

    const getVariables = () => {
        if (!pack) {
            return [];
        }
        let variables = [];
        pack.variables.forEach(variable => {
            variables.push({
                [variable.name]: variable.content
            });
        });
        return variables;
    };

    const getClauses = () => {
        if (!clauses.length) {
            return [];
        }
        let clausesData = [];
        clauses.forEach(clause => {
            clausesData.push({
                [clause.slug]: clause.content
            });
        });
        return clausesData;
    };

    const onClauseChange = (action, clause) => {
        switch (action) {
            case 'disableClause':
                const newClauses = clauses.filter(item => item.id !== clause.id);
                setClauses(newClauses);
                break;
            default:
                setClauses([...clauses, ...[clause]]);
        }
    };

    const handleSubmit = (values) => {
        const variables = pack.variables
            .filter(packV => packV.name in values)
            .map(packV => ({
                [packV.name]: values[packV.name]
            }));
        let clientURI = `/api/users/${savedClientId}`;
        if (!pack) {
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: 'Génération pack',
                description: "Impossible de traiter la demande, ce pack n'existe pas"
            });
            return false;
        } else {
            setIsSaving(true);
            const clauses = getClauses();
            let packData;
            if (savedClientId) {
                packData = {
                    clauses: clauses,
                    variables: variables,
                    client: clientURI,
                    sharedPack: pack.sharedPack['@id']
                };
            } else {
                packData = {
                    clauses: clauses,
                    variables: variables,
                    firstname: values.firstname,
                    lastname: values.lastname,
                    password:values.password,
                    email: values.email,
                    sharedPack: pack.sharedPack['@id']
                };
            }

            postQuery.mutate(packData, {
                onSuccess: (response) => {
                    if (response.zipUrl) {
                        onOpen();
                        setZipURL(response.zipUrl);
                    }
                    localStorage.removeItem("selectedClientId");
                },
                onError: (error) => {
                    toast({
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                        status: 'error',
                        title: 'Génération pack',
                        description: "Une erreur est survenu lors de la génération de votre document."
                    });
                },
                onSettled: () => setIsSaving(false)
            });
        }
    };

    if (isError) {
        return (
            <NotFound />
        );
    }

    return (
        <PackDetailContext.Provider value={pack}>
            <Box className='page' bg='dark.100' minHeight='91vh' position='relative'>
                <Box className='page_title' paddingY={{ base: 2, lg: 6 }} paddingX={{ base: 2, lg: 8 }} borderRadius={8}>
                    <Container maxW='86%'>
                        <Pack isFecthing={isLoading} />
                    </Container>
                </Box>
                <Box className='page_content' padding={10} bg='dark.300' minHeight='89.7vh'>
                    <Container maxW='86%'>
                        <Formik
                            initialValues={values}
                            onSubmit={(values) => {
                                if (typeof handleSubmit === 'function') {
                                    handleSubmit(values);
                                }
                            }}
                            validationSchema={() => getSchema()}
                        >
                            {({ handleSubmit, values, errors }) => (
                                <form onSubmit={handleSubmit}>
                                    <DetailView
                                        values={values}
                                        errors={errors}
                                        isSaving={isSaving}
                                        isFetching={isLoading}
                                        onClauseChange={onClauseChange}
                                    />
                                </form>
                            )}
                        </Formik>
                    </Container>
                </Box>
            </Box>
            <Portal>
                <Modal isCentered blockScrollOnMount={true} closeOnOverlayClick={true} isOpen={isOpen} size='xxl' onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent bg='none'>
                        <ModalBody bg='none' padding={6} maxWidth='900px' margin='auto' width='90%'>
                            <Text align='center' fontSize='20px' fontWeight={600} color='white'>Votre URL de téléchargement:</Text>
                            <Box bg='white' padding={4} borderRadius={8} my={6}>
                                <Text align='center' fontSize='18px' whiteSpace='nowrap' fontWeight={500} color='black'>{zipURL}</Text>
                            </Box>
                            <HStack justifyContent='center' spacing={4}>
                                <Button
                                    to={zipURL}
                                    as={Link}
                                    paddingX={8}
                                    bg='#23397f'
                                    color='white'
                                    target='_blank'
                                    height='52px'
                                    fontSize='16px'
                                    fontWeight={600}
                                    spinnerPlacement='end'
                                    _hover={{ bg: '#182b66' }}
                                    onClick={(evt) => onClose()}
                                    rightIcon={<Icon color='white' fontSize={22} as={BsDownload} />}
                                >Télécharger</Button>
                            </HStack>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </Portal>
        </PackDetailContext.Provider>
    );
}

export default FormulairePage;
