import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Layout
import BaseLayout from './components/layouts/Base';
import FrontLayout from './components/layouts/Front';

// NotFound
import NotFound from './components/NoFound';
import NoContent from './components/NoContent';

// Pages
import LoginPage from './pages/login';
import DashboadPage from './pages/dashboard';

// Document
import DocumentsPage from './pages/documents';
import DocumentCreatePage from './pages/document';
import CategoryDocumentList from './pages/documents/CategoryDocumentList';

// Utilisateur
import UtilisateursPage from './pages/users';
import CreateUserPage from './pages/users/CreateUser';

// Category
import CategoryPage from './pages/category';

// Clause
import ClausePage from './pages/clause';

// Variable
import VariablePage from './pages/variable';

// Saisies
import SaisiePage from './pages/saisie';
import SaisieDetailPage from './pages/saisie/Saisie';

// Compte
import TransactionPage from './pages/compte';
import AchatCreditPage from './pages/compte/AchatCredit';

// Pack
import ListePackPage from './pages/pack';
import ViewPackPage from './pages/pack/ViewPack';
import ValidatePackPage from './pages/pack/ValidatePack';
import GenerateurPackPage from './pages/pack/CreatePack';


// Front
import FormulairePage from './pages/front/Formulaire';
import TransactionDetailPage from './pages/compte/Transaction';
import CreateClausePage from './pages/clause/CreateClause';
import GenerateDocFormPage from './pages/documents/GenerateDocForm';
import ForgetPasswordPage from './pages/login/ForgetPassword';
import NewPasswordPage from './pages/login/NewPassword';

//settings
import { SettingsPage } from './pages/settings';
import CreateProPage from './pages/users/createPro';
import CreateAdminPage from './pages/users/createAdmin';
import AdminsPage from './pages/users/admin';
import ProPage from './pages/users/professional';
import { DropFile } from './pages/document/dropfile';
import { ProfilePage } from './pages/profile';


// TODO : Implementation react-error-boundary pour gerer l'expiration des tokens

class ConsylApp extends React.Component {

    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<FrontLayout />} >
                        <Route index element={<LoginPage />} />
                        <Route path='login' element={<LoginPage />} />
                        <Route path='forgot-password' element={<ForgetPasswordPage />} />
                        <Route path='forgot-password/:tokenValue' element={<NewPasswordPage />} />
                        <Route path='pack/:packURL' element={<FormulairePage />} />
                        <Route path='document/:documentURL' element={<GenerateDocFormPage />} />
                        <Route path='*' element={<NotFound />} />
                    </Route>
                    <Route path='/dashboard' element={<BaseLayout />}>
                        <Route index element={<DashboadPage />} />
                        {/* Utilisateurs */}
                        <Route path='profile' element={<ProfilePage />} />
                        <Route path='users' element={<UtilisateursPage />} />
                        <Route path='admin' element={<AdminsPage />} />
                        <Route path='pro' element={<ProPage />} />
                        <Route path='users/edit/:userId' element={<CreateUserPage />} />
                        <Route path='users/create' element={<CreateUserPage />} />
                        <Route path='users/create/admin' element={<CreateAdminPage />} />
                        <Route path='users/create/pro' element={<CreateProPage />} />
                        {/* End Utilisateur */}
                        {/* Category */}
                        <Route path='category' element={<CategoryPage />} />
                        <Route path='category/:categoryId' element={<CategoryPage />} />
                        {/* End Category */}
                        {/* Clause */}
                        <Route path='clause' element={<ClausePage />} />
                        <Route path='clause/create' element={<CreateClausePage />} />
                        <Route path='clause/:clauseId' element={<CreateClausePage />} />
                        {/* End Clause */}
                        {/* Variable */}
                        <Route path='variable' element={<VariablePage />} />
                        <Route path='variable/:variableId' element={<VariablePage />} />
                        {/* End Variable */}
                        {/* Documents */}
                        <Route path='document' element={<DocumentsPage />} />
                        <Route path='document/page/:pageIndex' element={<DocumentsPage />} />
                        <Route path='document/create' element={<DropFile />} />
                        <Route path='document/edit/:documentId' element={<DocumentCreatePage />} />
                        <Route path='document/category/:categoryId' element={<CategoryDocumentList />} />
                        {/* End Documents */}
                        {/* Packs */}
                        <Route path='pack' element={<ListePackPage />} />
                        <Route path='pack/:packId' element={<ViewPackPage />} />
                        <Route path='pack/create' element={<GenerateurPackPage />} />
                        <Route path='pack/create/validate' element={<ValidatePackPage />} />
                        {/* End Packs */}
                        {/* Saisies */}
                        <Route path='saisie' element={<SaisiePage />} />
                        <Route path='saisie/:saisieId' element={<SaisieDetailPage />} />
                        {/* End Saisies */}
                        {/* Profile */}
                        <Route path='profile/transaction' element={<TransactionPage />} />
                        <Route path='profile/transaction/:transactionId' element={< TransactionDetailPage />} />
                        <Route path='profile/solde' element={<AchatCreditPage />} />

                        {/*settings */}
                        <Route path='/dashboard/settings' element={<SettingsPage />} />
                        <Route path='*' element={<NoContent message='Il semble que rien n’a été trouvé à cet emplacement.' showLink={true} link='/dashboard' linkText='Allez vers le tableau de bord' />} />
                        {/* End Profile */}
                    </Route>
                </Routes>
            </BrowserRouter>
        )
    }

}

export default ConsylApp;