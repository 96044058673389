import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
    colors: {
        dark : {
            100 : '#323232',
            200 : '#1C1C1C',
            300 : '#161515'
        },
        green : {
            100: '#9FC77E',
            200 : '#bad6a4'
        },
        blue: {
            100 : '#A4C5E0'
        },
        yellow : {
            100: '#EDC755'
        },
        gray: {
            100: '#787675'
        },
        red : {
            100 : '#F34C47',
            200 : '#c1121f'
        }
    },
    components: {
        FormLabel : {
            color : '#787675'
        },
        Input : {
            baseStyle : {
                borderRadius: 8,
                borderColor: '#323232',
                backgroundColor : '#323232',
            }
        }
    }
});

export default theme;