import React from "react";

// Components
import MenuGroup from "./MenuGroup";

// Icons
import DocumentIcon from './assets/img/Document.svg';
import ContactsIcon from './assets/img/Contacts.svg';
import OpportuniteIcon from './assets/img/Opportunites.svg';
import ProspectIcon from './assets/img/Prospects.svg';

// TODO Deplacer les assets dans un assets global

function DocumentMenu(){
    const menus = [
        {
            'to': '/dashboard/document',
            'icon' : ContactsIcon,
            'title' : 'Documents'
        },
        {
            'to': '/dashboard/category',
            'icon' : DocumentIcon,
            'title' : 'Catégories'
        },
        {
            'to': '/dashboard/clause',
            'icon' : ProspectIcon,
            'title' : 'Clauses'
        },
        {
            'to': '/dashboard/variable',
            'icon' : OpportuniteIcon,
            'title' : 'Variables'
        }
    ];
    return <MenuGroup name="Documents" menus={menus} />
}

export default DocumentMenu;