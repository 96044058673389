import React, { useContext, forwardRef, useState } from "react"
import { useDispatch } from "react-redux"
import { FormikContext } from "formik"
import {
	Stack,
	Text,
	Image,
	Radio,
	RadioGroup,
	FormControl,
	FormErrorMessage,
	Skeleton,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
} from "@chakra-ui/react"

// Components
import SubTitle from "../../../components/SubTitle"

// Context
import TypeDocumentContext from "../../../context/TypeDocumentContext"

// Icons
import PdfIcon from "../../../assets/svg/pdf.svg"
import DocxIcon from "../../../assets/svg/docx.svg"
import XlsxIcon from "../../../assets/svg/xlsx.svg"

const Type = forwardRef(({ isFetching }, ref) => {
	const dispatch = useDispatch()
	const formik = useContext(FormikContext)
	const { typeDocument, setTypeDocument } = useContext(TypeDocumentContext)
	const [showConfirmationModal, setShowConfirmationModal] = useState(false)
	const [selectedType, setSelectedType] = useState(typeDocument)

	const handleTypeChange = (typeDoc) => {
		const currentType = formik.values.type
		setSelectedType(typeDoc)
		if (
			((currentType === "docx" || currentType === "pdf") && typeDoc === "xlsx") ||
			(currentType === "xlsx" && (typeDoc === "pdf" || typeDoc === "docx")) ||
			(currentType === "docx" && typeDoc === "pdf") ||
			(currentType === "pdf" && typeDoc === "docx")
		) {
			setShowConfirmationModal(true)
		} else {
			setTypeAndResetForm(typeDoc)
		}
	}

	const setTypeAndResetForm = (typeDoc) => {
		setTypeDocument(typeDoc)
		formik.setFieldValue("type", typeDoc)
		formik.setFieldValue("content", "")
		formik.setFieldValue("header", "")
		formik.setFieldValue("footer", "")
		dispatch({ type: "RESET_SHEET" })
		dispatch({ type: "DOCUMENT_RESET" })
	}

	const handleConfirmation = (confirmed) => {
		setShowConfirmationModal(false)
		if (confirmed) {
			setTypeAndResetForm(selectedType)
		} else {
			setSelectedType(typeDocument)
		}
	}

	return (
		<Stack direction='column' spacing={5}>
			<SubTitle>Type de document :</SubTitle>
			<Skeleton isLoaded={!isFetching} borderRadius={8}>
				<FormControl isInvalid={formik.errors.type}>
					<RadioGroup
						name='type'
						value={typeDocument}
						onChange={(value) => handleTypeChange(value)}
						//value={selectedType}
					>
						<Stack direction='column' spacing={4}>
							<Radio value='pdf' colorScheme='yellow' borderColor='yellow.100'>
								<Stack
									direction='row'
									gap={3}
									justifyContent='space-between'
									alignItems='center'
									marginLeft={3}
								>
									<Image src={PdfIcon} />
									<Text color='white' fontSize={16}>
										Fichier PDF
									</Text>
								</Stack>
							</Radio>
							<Radio value='docx' colorScheme='yellow' borderColor='yellow.100'>
								<Stack
									direction='row'
									gap={3}
									justifyContent='space-between'
									alignItems='center'
									marginLeft={3}
								>
									<Image src={DocxIcon} />
									<Text color='white' fontSize={16}>
										Fichier Word
									</Text>
								</Stack>
							</Radio>
							<Radio value='xlsx' colorScheme='yellow' borderColor='yellow.100'>
								<Stack
									direction='row'
									gap={3}
									justifyContent='space-between'
									alignItems='center'
									marginLeft={3}
								>
									<Image src={XlsxIcon} />
									<Text color='white' fontSize={16}>
										Fichier Excel
									</Text>
								</Stack>
							</Radio>
						</Stack>
					</RadioGroup>
					<FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>
				</FormControl>
			</Skeleton>

			<Modal isOpen={showConfirmationModal} onClose={() => setShowConfirmationModal(false)}>
				<ModalOverlay />
				<ModalContent bg='#1C1C1C'>
					<ModalHeader color='#fff'>Confirmation</ModalHeader>
					<ModalBody color='GrayText'>
						Toutes les modifications apportées au dernier document seront perdues. Voulez-vous
						continuer ?
					</ModalBody>
					<ModalFooter>
						<Button fontSize={12} colorScheme='red' mr={3} onClick={() => handleConfirmation(true)}>
							Continuer
						</Button>
						<Button fontSize={12} onClick={() => handleConfirmation(false)}>
							Annuler
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Stack>
	)
})

Type.defaultProps = {
	isFetching: false,
}

export default Type
