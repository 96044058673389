const initialState = {
    clauses : [],
    variables : [],
    isFail : false,
    isSaved : false,
    isSaving : false,
    document : null,
    isFetching : false,
    variablesInexist : []
}

const DocumentReducer = ( state = initialState, action ) => {

    switch( action.type ){
        case 'ADD_VARIABLE_TO_DOC':
            const isAlearidyUsed = state.variables?.filter( variable => variable.id === action.playload.id )
            return{
                ...state,
                variables : isAlearidyUsed.length ? [...state.variables] : [...state.variables, action.playload]
            }
        case 'ADD_CLAUSE_TO_DOC':
            const isUsed = state.clauses?.filter( clause => clause.id === action.playload.id )
            return{
                ...state,
                clauses : isUsed.length ? [...state.clauses] : [...state.clauses, action.playload]
            }
        case 'REMOVE_VARIABLE_TO_DOC':
            return{
                ...state,
                variables : state.variables?.filter( variable => variable.id !== action.playload.id )
            }
        case 'REMOVE_CLAUSE_TO_DOC':
            return{
                ...state,
                clauses : state.clauses?.filter( clause => clause.id !== action.playload.id )
            }
        case 'SET_VARIABLES_DOC':
            return {
                ...state,
                variables : action.playload
            }
        case 'SET_INEXIST_VARIABLES':
            return {
                ...state,
                variablesInexist : action.playload
            }
        case 'SET_CLAUSES_DOC' : 
            return {
                ...state,
                clauses : action.playload
            }
        case 'DOCUMENT_SAVING': 
            return {
                ...state,
                isFail : false,
                isSaving : true,
                document : null,
                isSaved : false,
                isFetching : false,
            }
        case 'DOCUMENT_SAVED':
            return {
                ...state,
                isFail : false,
                isSaved : true,
                isSaving : false,
                isFetching : false,
                clauses : [],
                variables : [],
                document : action.playload
            }
        case 'DOCUMENT_SAVE_FAIL':
            return {
                ...state,
                isFail : true,
                isSaved : false,
                document : null,
                isSaving : false,
                isFetching : false,
            }
        case 'DOCUMENT_FETCHING':
            return {
                ...state,
                isFail : false,
                isSaved : false,
                document : null,
                isSaving : false,
                isFetching : true,
            }
        case 'DOCUMENT_FETCH_SUCCESS':
            return {
                ...state,
                isFail : false,
                isSaved : false,
                isSaving : false,
                isFetching : false,
                document : action.playload,
            }
        case 'DOCUMENT_RESET':
        case 'DOCUMENT_DELETE_SUCCESS':
            return {
                ...state,
                clauses : [],
                variables : [],
                isFail : false,
                isSaved : false,
                isSaving : false,
                isFetching : false,
                document : null,
                variablesInexist : []
            }
            default : 
                return state;
    }

}

export default DocumentReducer;