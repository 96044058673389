import http from '../../utils/http'
import { useMutation,useQueryClient } from '@tanstack/react-query';

const postCategory = async(category) => {
    const response = await http.post('/document_categories', category);
    return response.data;
}

export default function usePostCategory(){
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey : ['postCategory'],
        mutationFn : (category) => postCategory(category),
        onSuccess: () => {
            queryClient.invalidateQueries(['fetchCategories']);
          },
    })
    
}