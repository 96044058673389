import http from '../../utils/http'
import { useMutation } from '@tanstack/react-query';


const deleteDocument = async(documentId) => {
    const response = await http.delete(`/documents/${documentId}`);
    return response.data;
}

export default function useDeleteDocument(){

    return useMutation({
        mutationKey : ['deleteDocument'],
        mutationFn : (documentId) => deleteDocument(documentId)
    })

}