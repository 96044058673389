import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CloseIcon } from '@chakra-ui/icons';
import { 
    Box, 
    Text, 
    Icon, 
    Badge, 
    Stack, 
    useDisclosure,
 } from '@chakra-ui/react';

// Component
import SubTitle from '../../../components/SubTitle';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';

// Middleware
import { deleteDataFromSheet, removeVariableToDoc } from '../../../actions/document/DocumentAction';

function VariablesDoc({variables, removeVariable, deleteFromSheet}){

    const [ variableToDelete, setVariableToDelete ] =  useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const onDelete = (variable) => {
        onOpen();
        setVariableToDelete(variable)
    }

    const onConfirmation = () => {
        if( variableToDelete !== null ){
            onClose();
            setVariableToDelete( null );
            removeVariable( variableToDelete );
            deleteFromSheet( "${_"+variableToDelete.name+"_}" );
        }
    }

    const renderVariablesList = () => {
        if ( !variables?.length ){
            return null
        }
        return (
            <Box>
                <SubTitle mb={4}>Liste des variables utilisées :</SubTitle>
                <Stack direction='row' flexWrap='wrap'>
                    { variables.map( variable => {
                        return (
                            <Badge 
                                key={variable.id}
                                paddingX={3} 
                                paddingY={2}  
                                cursor='pointer'
                                colorScheme='green' 
                                onClick={ () => onDelete(variable) }  
                            >
                                <Stack direction='row' gap={1} justifyContent='center' alignItems='center'>
                                    <Text>{variable.name}</Text>
                                    <Icon as={CloseIcon} fontSize={10} />
                                </Stack>
                            </Badge> 
                        )
                    }) }
                </Stack>
                <ConfirmationModal
                    isOpen = {isOpen}
                    onClose = {onClose}
                    title = 'Supprimer une variable'
                    onConfirmation = {onConfirmation}
                    description = 'En supprimant cette variable, celle ci ne sera plus disponible dans ce document'
                />
            </Box>
        )
    }

    return (
        <>
            { renderVariablesList() }
        </>
    )

}

const mapStateToProps = (state) => {
    return {
        variables : state.document.variables
    }
}

const mapDispatchToProps = {
    removeVariable : removeVariableToDoc,
    deleteFromSheet : deleteDataFromSheet
}

export default connect(mapStateToProps, mapDispatchToProps)(VariablesDoc);