import http from '../../utils/http'
import { useQuery } from '@tanstack/react-query';

export default function useFetchStats(){
 
    return useQuery({
        queryKey: ['fetchStats'],
        queryFn: async () => {
            const response =  await http.get(`/statistics/dashboard`);
            console.log(response);
            return response.data['hydra:member'];
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false
    });
    
} 
