import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useFetchPackDocs } from '../../../hooks/pack';

// Components
import SearchPack from '../block/SearchPack';
import SearchDocOnPack from '../block/SearchDocOnPack';// Ensure this is the updated version without Redux
import Restricted from '../../../components/Restricted';
import ButtonAction from '../../../components/ButtonAction';

function ListeCallback({handleFilteredDocuments}) {
    const { packId } = useParams();
    const { data: documents, isFetching: isDocLoading, isError: isDocFail } = useFetchPackDocs(packId);
    const location = useLocation();
    const path = location.pathname;

    const isPackRoute = path === '/dashboard/pack';
    const isDocRoute = path.startsWith('/dashboard/pack/');

    return (
        <Flex justifyContent='space-between' gap={4}>
            {isPackRoute && <SearchPack />}
            {isDocRoute && <SearchDocOnPack documents={documents} onFilteredDocuments={handleFilteredDocuments} />}
            <Restricted to={['ROLE_ADMIN']}>
                <ButtonAction
                    title='Créer un pack'
                    to="/dashboard/pack/create"
                />
            </Restricted>
        </Flex>
    );
}

export default ListeCallback;