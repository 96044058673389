import React, {  useState, useCallback }  from 'react';
import { connect } from 'react-redux';
import { Select, Skeleton } from '@chakra-ui/react';

// Hooks
import { useFetchCategories } from '../../../hooks/category';

// Middleware
import {  fetchAllAction, fetchDocumentsByCategory } from '../../../actions/document/DocumentsAction';

function CategorySelect ({ fetchDocuments, fetchAllDocuments }){

    // states
    const [ category, setCategory ] = useState(0);
    
    // hooks
    const { data:categories, isFetching } = useFetchCategories('publish');

    // callback
    const getDocumentsByCategory = useCallback( ( evt ) => {
        const categoryId = evt.target.value;
        setCategory( categoryId );
        if( categoryId == 0 ){
            fetchAllDocuments(1, true);
        }else{
            fetchDocuments( categoryId, true );
        }
    }, [] );


    return (
        <Skeleton height='50px' borderRadius={8} isLoaded={!isFetching}>
            <Select 
                name='category'
                height='50px'
                fontWeight={500}
                bg='blue.100'
                borderRadius={8}
                borderColor='dark.300'
                value={category}
                onChange={getDocumentsByCategory}
                placeholder='Toutes les catégories'
            >
                {
                    categories && categories.map( category => {
                        return (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        )
                    } )
                }
            </Select>
        </Skeleton>
    )

}

const mapDispatchToProps = {
    fetchAllDocuments : fetchAllAction,
    fetchDocuments : fetchDocumentsByCategory,
}

export default connect(null, mapDispatchToProps)(CategorySelect);