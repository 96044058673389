import http from '../../utils/http'
import { useQuery } from '@tanstack/react-query';

export default function useFetchVariable(variableId){
 
    return useQuery({
        enabled: !!variableId,
        queryKey: ['fetchVariable', variableId],
        queryFn: async () => {
            if( variableId === undefined ){
                return null;
            }
            const response = await http.get(`/variables/${variableId}`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false
    });
    
} 
