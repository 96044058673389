import React from 'react';
import { SimpleGrid } from '@chakra-ui/react';

// Components
import DocumentItem from './DocumentItem';

function DocumentPlaceholder ({ isLoading }){

    let index = 0;
    const documents = Array(28).fill({ name : 'document', link : '#' })

    return (
        <SimpleGrid columns={{base:1, sm: 2, lg: 3, xl: 4}} spacingX={8}>
            { 
                documents.map( (document) => {
                    index++;
                    return (
                        <DocumentItem 
                            key = {index}
                            name = {document.name}
                            link = {document.link}
                            isLoading = {isLoading}
                        />
                    )
                } )
            }
        </SimpleGrid>
    )

}

DocumentPlaceholder.defaultProps = {
    isLoading : true
}

export default DocumentPlaceholder;