import React, { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import {
    FormControl,
    FormLabel,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    useDisclosure,
    useMergeRefs,
    FormErrorMessage
} from '@chakra-ui/react';

const PasswordField = forwardRef((props, ref) => {
    const { value, disabled, isInvalid, errorMessage, onChange } = props;
    const { isOpen, onToggle } = useDisclosure()
    const inputRef = useRef(null)

    const mergeRef = useMergeRefs(inputRef, ref)
    const onClickReveal = () => {
        if( disabled ){
            return false;
        }
        onToggle()
        if (inputRef.current) {
            inputRef.current.focus({ preventScroll: true })
        }
    }

    return (
        <FormControl isInvalid={isInvalid}>
            <FormLabel htmlFor="password" color='#787675'>Mot de passe *</FormLabel>
            <InputGroup>
                <InputRightElement>
                    <IconButton
                        variant="text"
                        aria-label={isOpen ? 'Masquer' : 'Afficher'}
                        icon={isOpen ? <HiEyeOff /> : <HiEye />}
                        onClick={onClickReveal}
                        color='white'
                    />
                </InputRightElement>
                <Input
                    height={42}
                    value={value}
                    id="password"
                    color='white'
                    ref={mergeRef}
                    name="password"
                    disabled = {disabled}
                    type={isOpen ? 'text' : 'password'}
                    autoComplete="current-password"
                    onChange={e => onChange(e)}
                    borderColor='#323232'
                    backgroundColor='#323232'
                />
            </InputGroup>
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>
    )
})

PasswordField.defaultProps = {
    disabled : false,
    isInvalid : false,
    errorMessage : 'Ce champ est obligatoire'
}

PasswordField.propTypes = {
    isInvalid : PropTypes.any.isRequired,
    errorMessage : PropTypes.string.isRequired
}

export default PasswordField;