import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useFormik } from 'formik';
import { 
    bool,
    object
} from 'yup';
import { 
    Box, 
    Text, 
    Input,
    Stack,
    Divider,
    Button,
    Checkbox,
    Skeleton,
    SimpleGrid,
    FormControl,
    FormErrorMessage,
    useToast,
    CircularProgress
} from '@chakra-ui/react';

// Components
import PaiementForm from './block/PaiementForm';
import SubTitle from '../../components/SubTitle';
import Template from '../../components/dashboard/Template';

// Hooks
import { useFetchUserProfile } from '../../hooks/users';
import { useBuyCredit, useGetSetting } from '../../hooks/settings';

// Context
import PermissionsContext from '../../context/PermissionsContext';

// Schema
const AchatSchema = object({
    condition : bool().oneOf([true], 'Ce champ est obligatoire')
})

function AchatCreditPage () {

    // states
    const [ orderId, setOrderId ] = useState(null);
    const [ qttCredit, setQttCredit ] = useState(0);
    const [ userCredit, setUserCredit ] = useState(24);
    const [ totalCredit, setTotalCredit ] = useState(0);
    const [ isFetching, setIsFetching ] = useState(false);
    const [ clientIntent, setClientIntent ] = useState(null);
    const [ newCredit, setNewCredit ] = useState( userCredit );
    const [ intentHasError, setIntentHasError ] = useState(false);
    const [ visibleStripeForm, setVisibleStripeForm ] = useState(false);

    // context
    const { isGranted } = useContext( PermissionsContext );

    // hooks
    const toast = useToast();
    const buyQuery = useBuyCredit();
    const {data:profile} = useFetchUserProfile();
    console.log(profile);
    const {  data:credit, isLoading } = useGetSetting('credit_price');
    const formik = useFormik({
        initialValues: {
            condition: false
        },
        validateOnChange: false,
        validationSchema: AchatSchema,
        onSubmit: () => {
            if( !isGranted(['ROLE_USER'])){
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'error',
                    title: 'Action non autorisée',
                    description: "Vous n'avez pas les droits nécessaires pour faire cette action"
                });
                return false;
            }
            if( qttCredit <= 0 ){
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'error',
                    title: 'Achat de crédit',
                    description: "Veuillez sélectionner la quantité de crédit à acheter"
                });
                return false;
            }
            setIsFetching(true);
            buyQuery.mutate(qttCredit, {
                onSuccess: (response) => {
                    setIntentHasError(false);
                    setVisibleStripeForm(true);
                    setOrderId(response.order_id);
                    setClientIntent(response.client_secret);
                },
                onError: (e) => {
                    console.error(e);
                    setClientIntent(null);
                    setIntentHasError(true);
                    setVisibleStripeForm(true);
                },
                onSettled : () => {
                    setIsFetching(false);
                }
            })
        }
    });

    // onDidUpdate
    useEffect( () => {

        if( profile ){
            setUserCredit(profile?.credit)
            setNewCredit(profile?.credit)
        }

    }, [profile] )

    // callback
    const handleQttChange = useCallback( (evt) => {
        if( credit ){
            const qtt = parseInt(evt.target.value);
            const newCredit = userCredit+qtt;
            const total = qtt * parseFloat(credit.value);
            setQttCredit(qtt);
            setTotalCredit(total);
            setNewCredit(newCredit);
        }
    }, [credit, userCredit] );

    return (
        <Template 
            callback={null}
            title='Acheter des crédits'
            restrictedTo={['ROLE_USER']}
        >
            <Box bg="dark.300" borderRadius={8} paddingY={30}> 
                <form method='POST' onSubmit={formik.handleSubmit}>
                    <Box paddingX={{ base:2, lg: 8 }} pb={8}>
                        <SimpleGrid columns={3} gap={16} maxWidth='70%' margin='auto' mb={12} >
                            <Box>
                                <Skeleton borderRadius={8} isLoaded={!isLoading}>
                                    <SubTitle style={{ textAlign: 'center' }}>Crédit actuel</SubTitle>
                                    <Text color='#5c5b5a' fontSize={16} mb={4} textAlign='center'>votre crédit actuel</Text>
                                    <Text 
                                        paddingY={0}
                                        paddingX={12}
                                        fontSize='12px'
                                        height='58px'
                                        lineHeight='54px'
                                        color='#edc756'
                                        fontWeight={600}
                                        borderRadius={8}
                                        bg='#332a10'
                                        textAlign='center'
                                    >{`${userCredit ? userCredit:0} Crédit`}</Text>
                                </Skeleton>
                            </Box>
                            <Box>
                                <Skeleton borderRadius={8} isLoaded={!isLoading}>
                                    <FormControl>
                                        <SubTitle style={{ textAlign: 'center' }}>Crédit supplémentaire</SubTitle>
                                        <Text color='#5c5b5a' fontSize={16} mb={4} textAlign='center'>{`1 crédit pour ${credit?.value ? credit?.value :0} €`}</Text>
                                        <Stack 
                                            direction='row' 
                                            justifyContent='center' 
                                            alignItems='center'
                                            bg='#1d3040'
                                            borderRadius={8}
                                            height='58px'
                                            padding={6}
                                        >
                                            <Input
                                                border={0}
                                                fontSize='24px'
                                                lineHeight='54px'
                                                color='#9abbd4'
                                                fontWeight={600}
                                                padding={0}
                                                width='40%'
                                                min={0}
                                                type='number'
                                                defaultValue={qttCredit}
                                                onChange={handleQttChange}
                                            />
                                            <Text 
                                                color='white'
                                                lineHeight='30px'
                                                borderLeftWidth={1}
                                                borderLeftColor='white'
                                                pl='1rem'
                                            >{`Prix : ${totalCredit} €`}</Text>
                                        </Stack>
                                    </FormControl>
                                </Skeleton>
                            </Box>
                            <Box>
                                <Skeleton borderRadius={8} isLoaded={!isLoading}>
                                    <SubTitle style={{ textAlign: 'center' }}>Crédit après paiement</SubTitle>
                                    <Text color='#5c5b5a' fontSize={16} mb={4} textAlign='center'>votre crédit mis à jour</Text>
                                    <Text 
                                        paddingY={0}
                                        paddingX={12}
                                        fontSize='12px'
                                        height='58px'
                                        lineHeight='54px'
                                        color='#9dc57d'
                                        fontWeight={600}
                                        borderRadius={8}
                                        bg='#252d1f'
                                        textAlign='center'
                                    >{`${newCredit ? newCredit :0} Crédit`}</Text>
                                </Skeleton>
                            </Box>
                        </SimpleGrid>
                        {
                            !visibleStripeForm &&
                            <Stack direction='row' justifyContent='center'>
                                <Skeleton borderRadius={8} isLoaded={!isLoading}>
                                    <FormControl isInvalid={formik.errors.condition}>
                                        <Box bg='dark.100' borderRadius={5} padding={4}>
                                            <Checkbox colorScheme='yellow' color='white' name='condition' checked={formik.values.condition} onChange={formik.handleChange}>
                                                Accepter les conditions générale de vente
                                            </Checkbox>
                                        </Box>
                                        <FormErrorMessage justifyContent='center'>{formik.errors.condition}</FormErrorMessage>
                                    </FormControl>
                                </Skeleton>
                            </Stack>
                        }
                        {
                            visibleStripeForm === false ?
                                <Stack mt={16} direction='row' justifyContent='center'>
                                    <Box>
                                        {
                                            isFetching ?
                                                <CircularProgress size='28px' isIndeterminate color='white' /> 
                                            :
                                                <Skeleton borderRadius={8} isLoaded={!isLoading}>
                                                    <Button 
                                                        padding={6}
                                                        width='100%' 
                                                        type='submit'
                                                        borderWidth={1} 
                                                        borderRadius={8} 
                                                        borderColor='white' 
                                                        colorScheme='yellow' 
                                                    >
                                                        <Stack direction='row' justifyContent='center' alignItems='center' spacing={6}>
                                                            <Box>Payer : <strong style={{ fontWeight: 700, fontSize: '1.2rem' }}>{`${totalCredit} €`}</strong></Box>
                                                            <Divider height='22px' borderColor='dark' orientation='vertical' />
                                                            <Box><Text>{`Pour ${qttCredit} de crédit(s)`}</Text></Box>
                                                        </Stack>
                                                    </Button>
                                                </Skeleton>
                                        }
                                    </Box>
                                </Stack>
                            :
                                <PaiementForm 
                                    orderId={orderId}
                                    clientSecret={clientIntent} 
                                    intentHasError={intentHasError}
                                />
                        }
                    </Box>
                </form>
            </Box>
        </Template>
    )

}

export default AchatCreditPage;