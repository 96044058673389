import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import {useLocation} from "react-router-dom";

// Components
import Callback from './callback/Callback';
import DocumentViewer from './view/DocumentViewer';

// Global
import Template from '../../components/dashboard/Template';

// Context
import DocumentViewContext from '../../context/DocumentViewContext';

function DocumentsPage(){

    // states
    const [view, setView] = useState('document');
    const viewContext = { view, setView };

    // hooks
    const location = useLocation();

    useEffect( () => {

        if(location.state){
            setView(location.state.view)
        }

    }, [location] )

    return (
        <DocumentViewContext.Provider value={viewContext}>
            <Template 
                title='Documents'
                callback={<Callback />}
                restrictedTo={['ROLE_ADMIN', 'ROLE_USER','ROLE_PROFESSIONAL']}
            >
                <Box bg="dark.300" borderRadius={8} paddingY={30}> 
                    <Box paddingX={{ base:2, lg: 8 }}>
                        <DocumentViewer />
                    </Box>
                </Box>
            </Template>
        </DocumentViewContext.Provider>
    )
}

export default DocumentsPage;