import React, { useState, useEffect, useContext, useCallback } from "react";
import { connect } from 'react-redux';
import { Box, Spinner, Center, SimpleGrid, useToast, useDisclosure } from "@chakra-ui/react";
import InfiniteScroll from 'react-infinite-scroll-component';

// Constants
import { DOCS_PER_PAGE } from "../../../config/constants";

// Components
import PackItem from "./PackItem";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";

// Hooks
import { useDeletePack } from '../../../hooks/pack';

// Context
import PermissionsContext from "../../../context/PermissionsContext";

// Middleware
import { fetchPacks, deletePack } from "../../../actions/pack";

// Constants
import {APP_ERROR, DENIED_ACTION_TITLE, DENIED_ACTION_ERROR} from "../../../config/constants";

function PackGrid({ packs, page, lastPage, packCount, fetchPacks, deletePack }){

    // states
    const [ packId, setPackId ] = useState(null);
    const [ hasMore, setHasMore ] = useState(false);
    const [ packList, setPackList ] = useState([]);
    const [ isDeleting, setIsDeleting ] = useState(false);
    const [ index, setIndex ] = useState( parseInt(page) );

    // hooks
    const toast = useToast();
    const deleteQuery = useDeletePack();
    const { isOpen, onOpen, onClose } = useDisclosure();

    // context
    const { isGranted } = useContext(PermissionsContext);

    // onDidUpdate
    useEffect( () => {
        setPackList(packs);
    }, [packs] );

    // onDidUpdate
    useEffect( () => {

        setIndex( parseInt(page) );

        if(packCount > DOCS_PER_PAGE){
            setHasMore(true);
        }

    }, [page, packCount] );
    
    // callback
    const fetchMorePacks  = useCallback( () => {
        let nextIndex = index;
        nextIndex+= 1;
        if( nextIndex <= lastPage ){
            setIndex( nextIndex )
            fetchPacks( nextIndex );
        }else{
            setHasMore(false);
        }
    }, [index]);

    const onDelete = useCallback( (id) => {
        if( !isGranted(['ROLE_ADMIN']) ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: DENIED_ACTION_ERROR
            });
            return false;
        }
        if( !id ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: "ID pack manquant"
            });
            return false;
        }
        onOpen();
        setPackId(id);
    }, [] );

    const onConfirmation = useCallback( () => {

        if( !packId ){
            toast({
                position: 'top',
                duration: 9000,
                isClosable: true,
                status: 'error',
                title: DENIED_ACTION_TITLE,
                description: "ID pack manquant"
            });
            return false;
        }
        setIsDeleting(true)
        deleteQuery.mutate(packId, {
            onSuccess : () => {
                deletePack(packId);
                onClose();
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'success',
                    title: 'Pack supprimé avec succès',
                });
            },
            onError : () => {
                toast({
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                    status: 'error',
                    title: APP_ERROR
                });
            },
            onSettled : () => setIsDeleting(false)
        })

    }, [ packId ] );

    return (
        <Box className="pack_grid">
            <InfiniteScroll
                hasMore={hasMore}
                dataLength={packList.length}
                next={fetchMorePacks}
                loader={ <Center padding={8}><Spinner color="white" /></Center> }
            >
                <SimpleGrid columns={{base:1, sm: 2, lg: 3, xl: 4}} spacing={10}>
                    { 
                        packList.map( (pack) => {
                            return ( 
                                <PackItem
                                    key={pack.id}
                                    id={pack.id}
                                    name = {pack.name}
                                    onDelete={onDelete}
                                    url = {`/dashboard/pack/${ parseInt( pack.id ) }`}
                                />
                            )
                        } )
                    }
                </SimpleGrid>
            </InfiniteScroll>

            <ConfirmationModal
                isOpen = {isOpen}
                onClose = {onClose}
                isLoading = {isDeleting}
                title = 'Supprimé un pack'
                onConfirmation = {onConfirmation}
                description = 'Vous voulez supprimer ce pack !'
            />
        </Box>
    )

}

const mapStateToProps = ( state ) => {
    return {
        page : state.packs.page,
        packs : state.packs.packs,
        packCount : state.packs.totals,
        lastPage : state.packs.lastPage,
    }
}

const mapDispatchToProps = {
    fetchPacks : fetchPacks,
    deletePack : deletePack
}


export default connect(mapStateToProps, mapDispatchToProps)(PackGrid)