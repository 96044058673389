import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

// Config
import { getBaseApiUrl } from '../utils';

const handleLogin = async(credentials) => {
    const endpoint = getBaseApiUrl('/login');
    const { data } = await axios.post(endpoint, credentials, {
        headers : {
            'Content-Type': 'application/json'
        }
    });
    return data;
}

export default function useLogin(){
    return useMutation({
        mutationFn : (credentials) => handleLogin(credentials)
    })
}