import React, { useRef, useState, useContext, forwardRef } from "react";
import { FormikContext } from "formik";
import { Box, Skeleton, FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import { Editor } from '@tinymce/tinymce-react';

// Context
import TinyMceContext from "../../../context/TinyMceContext";

const TextEditor = forwardRef( (props, ref ) => {

    // hooks
    const contentRef = useRef(null);
    
    // context
    const formik = useContext(FormikContext);
    const { setTinyInstance } = useContext(TinyMceContext);
    
    // states
    const [editorIsReady, setEditorIsReady] = useState(false);

    return (
        <Box className={ formik.errors.content ? 'text-editor-wrap form-has-error' : 'text-editor-wrap' }>
            <Skeleton height={860} isLoaded={editorIsReady} borderRadius={8}>
                <FormControl isInvalid={formik.errors.content}>
                    <FormLabel htmlFor='contenu' fontSize='18px' color='blue.100'>Contenu</FormLabel>
                    <Editor
                        ref={contentRef}
                        initialValue={formik.values.content}
                        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                        onInit={(evt, editor) => {
                            setEditorIsReady(true);
                            contentRef.current = editor;
                            setTinyInstance(editor);
                        }}
                        init={{
                            height: 800,
                            language: 'fr_FR',
                            plugins: 'autolink charmap image link lists searchreplace table wordcount',
                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table  | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                        }}
                    />
                    <FormErrorMessage mt={3}>{formik.errors.content}</FormErrorMessage>
                </FormControl>
            </Skeleton>
        </Box>
    )

})

export default TextEditor;