import React from 'react';
import PropTypes from 'prop-types';
import { 
    Box,
    Skeleton,
} from '@chakra-ui/react';

// Components
import Clauses from './Clauses';
import Variables from './Variables';
import SubTitle from '../../../components/SubTitle';
import NoContent from '../../../components/NoContent';

// Hooks
import { useFetchSaisieData } from '../../../hooks/saisie';

function PackData ({ saisieId }){
    
    // hooks
    const { data, isLoading, isError }  = useFetchSaisieData(saisieId);

    if( isError ){
        return (
            <Box>
                <SubTitle>Détail saisie :</SubTitle>
                <NoContent message='Aucune donnée trouvée' />
            </Box>
        )
    }

    const renderVariablesContent = () => {
        if(data && data.variables.length){
            return (
                <Variables isFetching={isLoading} variables={data.variables} />
            )
        }
        return null;
    }

    const renderClausesContent = () => {
        if(data && data.clauses.length){
            return (
                <Clauses isFetching={isLoading} clauses={data.clauses} />
            )
        }
        return null;
    }

    return (
        <Skeleton isLoaded={!isLoading} borderRadius={8} minHeight={400}>
            { renderVariablesContent() }
            { renderClausesContent() }
        </Skeleton>
    )

}

PackData.propTypes = {
    saisieId : PropTypes.any.isRequired,
}

export default PackData;