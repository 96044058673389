import React from "react";
import { Box, Flex } from "@chakra-ui/react";

// Components
import Info from "./Info";

class Header extends React.Component{

    render(){
        return (
            <Box paddingY={{ base: 2, lg:5 }} paddingX={{ base:2, lg: 8 }} bg='dark.200'>
                <Flex justifyContent={{ base: 'flex-end', lg:'flex-end' }} alignItems='center'>
                    <Info />
                </Flex>
            </Box>
        )
    }

}

export default Header;