import http from '../../utils/http'
import { useQuery } from '@tanstack/react-query';

export default function useFetchSettings(){
 
    return useQuery({
        queryKey: ['fetchSettings'],
        queryFn: async () => {
            const response =  await http.get(`/settings`);
            return response.data['hydra:member'];
        },
        refetchOnWindowFocus: false,
        refetchOnMount:false
    });
    
} 
