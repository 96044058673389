import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

// Component
import Restricted from '../../../components/Restricted';
import ButtonAction from '../../../components/ButtonAction';

function Callback(){

    const { clauseId } = useParams();

    return(
        <Restricted to={['ROLE_ADMIN', 'ROLE_USER']}>
            <Flex justifyContent='space-between' gap={4}>
                {
                    <ButtonAction 
                        title='Créer une clause'
                        to="/dashboard/clause/create" 
                    />
                }
                {/* 
                <ButtonAction 
                    title='Créer un document'
                    to="/dashboard/document/create" 
                />
                */}
            </Flex>
        </Restricted>
    )
}

export default Callback;