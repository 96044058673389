import http from '../../utils/http'
import { useMutation,useQueryClient } from '@tanstack/react-query';

const postDocument = async(document) => {

    const response = await http.post('/documents', document, );
    return response.data;
}

export default function usePostDocument(){
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey : ['postDocument'],
        mutationFn : (document) => postDocument(document),
        onSuccess: () => {
            queryClient.invalidateQueries(['fetchDocByCategory']);
          },
    })
    
}